import React, { Component, Fragment } from 'react';
import EditImg from '../../../assets/images/ic-edit.png';
import { Pagination } from 'antd';
import moment from 'moment';

export default class AssessmentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allAssessmentArr: props.allAssessmentArr,
            pagination: props.pagination
        }
    }

    getFullName(getObj) {
        if (typeof (getObj) === 'object' && Object.keys(getObj).length) {
            return Object.values(getObj).filter(value => value !== null).join(' ');
        }
        return "";
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        return ({
            allAssessmentArr: nextProps.allAssessmentArr,
            pagination: nextProps.pagination
        })
    }

    render() {
        let { allAssessmentArr, pagination } = this.state;
        // console.log('----------------pagination:', pagination.current_page);
        return (
            <Fragment>
                <div className='container-fluid px-0 mb-2'>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <table className='table table-bordered rounded-0'>
                                <thead>
                                    <tr>
                                        <th width="6%">S.NO</th>
                                        <th width="18%">DATE & TIME</th>
                                        <th width="20%">FORM NAME</th>
                                        <th width="16%">SAVED BY</th>
                                        <th width="15%">AUTHORIZED BY</th>
                                        <th width="16%">AUTHORIZED AT</th>
                                        <th width="9%">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allAssessmentArr.map((item, index) => (
                                        <Fragment key={index}>
                                            <tr>
                                                <td>{pagination.current_page > 1 ? ((pagination.current_page - 1) * 10) + index + 1 : index + 1}</td>
                                                <td>{item?.createdAt ? moment(item.createdAt).format('DD MMM, YYYY hh:mm A') : null}</td>
                                                <td className='text-danger'>
                                                    {item?.risk_type == 1 ? "Fall Risk Assessment" : item?.risk_type == 2 ? "Nutrition Screen Tool" : "Nursing Assessment Report"}
                                                    {/* <a href="" className='text-danger'><u>
                                                        {item?.risk_type == 1 ? "Fall Risk Assessment" : item?.risk_type == 2 ? "Nutrition Screen Tool" : "Nursing Assessment Report"}
                                                    </u></a> */}
                                                </td>
                                                <td>{this.getFullName(item?.savedBy)}</td>
                                                <td>{item?.is_authorize ? this.getFullName(item?.authBy) : null}</td>
                                                <td>{item?.authorized_at ? moment(item.authorized_at).format('DD MMM, YYYY hh:mm A') : null}</td>
                                                <td className='text-center'>
                                                    <a disabled={item?.is_authorize} onClick={() => this.props.editForm(item)}><img src={EditImg} alt="edit" /></a>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        {pagination != null && pagination.total_records > 0 ?
                            <div className="col-6">
                                <div className="mb-5">
                                    <Pagination showSizeChanger={false} defaultCurrent={pagination.current_page} defaultPageSize={pagination.per_page} total={pagination.total_records} onChange={this.props.navigateDocumentToPage} />
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}