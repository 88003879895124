import React, { Component } from 'react';
import CLogoImg from "../assets/images/logo-myhealthcare-doctor.svg";
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import API_SERVICE from '../utils/apiService';
import {
    TOGGLE_PRACTICE,
    LOADER_RED,
    LOGOUT,
    USERTYPES,
    APPS_FEATURES_LIST
} from '../utils/constant';
import LS_SERVICE from '../utils/localStorage';
import { headerService$ } from '../utils/rxjs-sharing';
import Axios from 'axios';
import { Dropdown, Button, Badge } from 'antd';

class DuplicateTab extends Component {

    static propTypes = {
        location: PropTypes.object.isRequired
    }

    state = {
        hospital_id: null,
        isDoctorLoggedIn: false,
        soapConfig: null,
        pubNubAlertToggle: false,
        vitalAlerts: [],
        logo_src: null,
        hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id')),
    }

    constructor(props) {
        super(props);
        this._headerService = null;
    }

    componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        this.checkHospitalLogo();
        if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
            const arrRoute = this.props.location.pathname.split('/');
        }

        if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
            this.setState({ isDoctorLoggedIn: true });
        } else {
            this.setState({ isDoctorLoggedIn: false });
        }

        if (LS_SERVICE.has('soapConfig')) {
            this.setState({ soapConfig: LS_SERVICE.get('soapConfig') });
        }

        this._headerService = headerService$.status().subscribe(success => {
            if (success.soapConfig) {
                this.setState({ soapConfig: success.soapConfig })
            }
            if (success.setHospital) {
                this.setState({ hospital_id: success.setHospital })
            }
        });

        if (LS_SERVICE.has('slot_hospital_id')) {
            this.setState({ hospital_id: LS_SERVICE.get('slot_hospital_id') })
        }
    }

    componentWillUnmount() {
        this._headerService?.unsubscribe();
    }

    //Check Hospitals and set logo as per associations
    checkHospitalLogo = async () => {
        let logo_source = CLogoImg;
        let allHospitals = LS_SERVICE.get('slot_hospitals');
        if (!allHospitals || (allHospitals && allHospitals == null)) {
            logo_source = logo_source;
        }
        else if (allHospitals.length === 1) {
            logo_source = allHospitals[0].hospital_image !== "" ? allHospitals[0].hospital_image : logo_source;
        } else {
            //check if group ID's contains null
            if (allHospitals) {
                let nullIndex = allHospitals.findIndex((entry) => {
                    return entry.hospital_group_id === null;
                });
                if (nullIndex || nullIndex === -1) {
                    //check if all hospitals has same group
                    let checkExist = [...new Set(allHospitals.map(propYoureChecking => propYoureChecking.hospital_group_id))];
                    if (checkExist.length === 1) {
                        logo_source = allHospitals[0].hospital_group_image !== "" ? allHospitals[0].hospital_group_image : logo_source;
                    }
                }
            }
        }
        this.setState({ logo_src: logo_source });
    }

    handleLogout = async (e) => {
        e.preventDefault();
        await API_SERVICE.post(TOGGLE_PRACTICE, { practice_session: 'stop' });
        API_SERVICE.get(LOGOUT).then(success => {
            headerService$.showHeader({ showHeader: 'false' });
            var userType = LS_SERVICE.get("user_type");
            LS_SERVICE.clear();
            if (userType == USERTYPES.superadmin || userType == USERTYPES.admin) {
                this.props.history.push('/admin');
            } else {
                this.props.history.push('/');
            }
        })
    }

    render() {
        const { vitalAlerts } = this.state;
        return (
            <>
                <header id="page_header">
                    <nav className="navbar navbar-expand navbar-light bg-white">
                        <NavLink className="navbar-brand" to={{ pathname: '/' }}>
                            {/* <img src={CLogoImg} alt="EMR Logo" className="img-fluid" /> */}
                            <img src={this.state.logo_src} alt="EMR Logo" className="img-fluid" />
                        </NavLink>

                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav ml-auto align-items-center">
                                {LS_SERVICE.get('user_type') === USERTYPES.doctor || LS_SERVICE.get('user_type') === USERTYPES.nurse ? (
                                    <>
                                        {vitalAlerts?.length > 0 && (
                                            <Dropdown trigger={['click']} placement="bottomRight">
                                                <Button className="btn btn-alert alert-button mr-3" onClick={e => e.preventDefault()}>
                                                    <Badge count={vitalAlerts.length} style={{ backgroundColor: '#5c5c5c' }}>
                                                        <a href="#" className="head-example" />
                                                    </Badge>VITALS ALERTS
                                                </Button>
                                            </Dropdown>
                                        )}
                                    </>
                                ) : null}

                                <li className="nav-item align-self-center dropdown">
                                    <a className="nav-link" href="#" id="user_dropdown_btn" data-toggle="dropdown">
                                        <span className="d-none d-md-inline dropdown-toggle mr-2">
                                            {LS_SERVICE.get('user_type') === USERTYPES.doctor ? "Dr " + LS_SERVICE.get('staff_name') : LS_SERVICE.get('staff_name')}
                                        </span>
                                        {/* <img src={LS_SERVICE.get('staff_image')} alt="User Image" className="img-fluid profile-image" /> */}
                                        <img src={LS_SERVICE.get('staff_image')} alt="User Image" className="img-fluid profile-image" />
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="user_dropdown_btn">
                                        {LS_SERVICE.get('user_type') == USERTYPES.doctor ? <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/profile'>Profile</NavLink> : null}
                                        {/* <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/change-password'>Change Password</NavLink> */}
                                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/' onClick={this.handleLogout}>Logout</NavLink>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {APPS_FEATURES_LIST.header_powered_by && <div className="poweredby">
                            Powered By
                            <NavLink exact={true} to='/'>
                                <img src={CLogoImg} alt="Powered By - MyHealthcare" className="img-fluid" />
                            </NavLink>
                        </div>}
                    </nav>
                </header>
                <div className="row">
                    <div className="col-12">
                        <div className='tab-pane mt-5'>
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <div className="col-md-12 text-center">
                                        {LOADER_RED}
                                        <br />
                                        <br />
                                        <h3>Application is already running in another Tab</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DuplicateTab;