import axios from 'axios';
import qs from 'qs';
import React, { Component } from 'react';

import {
  headerService$
} from "../../utils/rxjs-sharing";
import {
  Modal,
  notification,
  Button,
  Tooltip
} from 'antd';
import {
  REFERENCE_TYPE,
  LOADER,
  ISALLOWEDITSOAP,
  PATIENT_DATA,
  APPLY_ORDERSET,

  LAB_TEST_SEARCH,
  PLAN_TEST_ADVICED_LIST,
  PLAN_TEST_ADVICED_STORE,
  LAB_TEST_CONSTANTS,
  DELETE_TEST,
  DELETE_ALL_TESTS,
  LAB_TEST_FAVOURITE
} from "../../utils/constant";

import LS_SERVICE from '../../utils/localStorage';
import SoapFlow from '../../utils/soap';
import TestForm from "../../components/tests/test-form";
import OrdersetList from "../../components/orderset/orderset-list";
import TestTable from "../../components/tests/test-table";
class PlanTestComponentSS extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allowEdit: true,
      isPracticing: false,
      isPracticingModal: false,
      deleteConfirmModal: false,

      selectedOrderSet: [],
      selectedConfirmedOrderSetData: [],
      planFavTestsModal: false,
      testData: [],
      addedTestList: [],

      constants: {},
      favourite: {},
      favourite_list_all: {},

      dataLoaded: false,
      test_success: '',
      diableConfrimButton: true,
    };
    this.handleDeleteAllTestData = this.handleDeleteAllTestData.bind(this)
  }

  async componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    this.setState({
      isDataFetching: true,
    });
    const { patient} = this.props;
    const hospital_id = patient.qmsToken.hospital_id;
    const patient_id = patient.patient_id;
    const reference_id = patient.qms_token_id;
    const isPracticingHospitalId = LS_SERVICE.get("practicing")?.hospital_id;
    await this.loadData();
    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true,
      });
    }
    if (this.state.patient) {
      await this.setState({
        patientData: this.state.patient,
      });
    } else {
      await this.getPatientData(reference_id);
    }

    let hospitalConfigData = LS_SERVICE.get("hospitalConfigData") ?? [];
    hospitalConfigData = hospitalConfigData.find((item) => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData
      ?
      ISALLOWEDITSOAP(
        hospitalConfigData.pres_modification,
        hospitalConfigData.allow_hours,
        LS_SERVICE.get("appointmentCompletedTime")[reference_id],
        "button"
      )
      :
      true
      ;
    this.setState({ hospital_id, patient_id, reference_id, patient, allowEdit }, async () => {
      if (LS_SERVICE.has("hospitalConfigData")) {
        const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
        let hospitalConfigObj = hospitalConfigData.find((obj) => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout });
        this.setSoapFlow();
        this.handleInitialData();
      }
    });
    await this.setState({
      dataLoaded: true
    })
  }

  /* async componentWillReceiveProps(){
    await this.handleInitialData();
  } */

  async setSoapFlow() {
    const { hospital_id } = this.state;
    SoapFlow.hospital(hospital_id)
  }

  async handleDeleteAllTestData(props) {
    var PARAMS = {
      all_test_id: props,
    }
    await axios.post(DELETE_ALL_TESTS, PARAMS)
      .then((success) => {
        this.handleInitialData();
        this.setState({
          spin_loading: false
        });
      })
      .catch((err) => console.log(err));
  }


  selectFavTest = async (e, data, checkIndex) => {
    e.preventDefault();
    if(e.target.checked == true){
      await this.updateTestData({
        event: data.event,
        fav: data.fav,
        i: -1,
        id: null,
        instructions: data.instructions,
        is_requistion: null,
        lmp_event_duration: data.lmpEventDuration,
        lmp_event_duration_type: data.lmpEventDurationType,
        requisition_comments: "",
        start_date: null,
        test_id: data.testId,
        test_name: data.name,
        type: data.type,
        urgency: data.urgency,
        view_id: ""
      });
    }
    await this.handleInitialData();
  }

  ApplyOrdersets = async () => {
    var selected_order_set = []
    for(var data of this.state.selectedOrderSet){
      await axios.get(APPLY_ORDERSET({
        orderset_id: data.orderSetId,
        patient_id: this.state.patient?.patient_id,
        reference_type: REFERENCE_TYPE,
        reference_id: this.state.patient?.qmsToken?.qms_token_id
      }))
      selected_order_set.push(data)
    }
    await this.setState({
      showOrdersetModal: false,
      selectedConfirmedOrderSetData: this.state.selectedOrderSet,
      selectedOrderSet: this.state.selectedOrderSet
    })

    this.props.updateInstructionFromParent();

    this.loadData().then(success2 => {
      this.handleInitialData()
    })
  };

  markTestFavorite = (data) => {
    axios.post(LAB_TEST_FAVOURITE, qs.stringify({
      id: data.testId,
      type: data.type,
      urgency: data.urgency,
      instructions: data.instructions,
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
    })).then(success => {
      this.loadData().then(success2 => {
        this.handleInitialData()
      })
    }).catch(err => {
      console.log(err)
    })
  }

  handlePracticeModal = data => {
    this.handleModalPopup(null, data);
  }

  getPatientData = (_) => {
    const { patient} = this.props;
    const patient_id = patient.patient_id;
    const reference_id = patient.qms_token_id;
    axios.get(PATIENT_DATA({ patient_id, reference_id }))
      .then((success) => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken ? success.data.data.results.qmsToken.qms_token_id : null,
        };
        // this.setState({ patient });
      })
      .catch((err) => console.log(err));
  };

  handleModalPopup = (e, popupName, data = null, actionType = "", objId = "", patientDocs, idx) => {
    if (e !== null) e.preventDefault();
    this.setState(
      {
        [popupName]: !this.state[popupName],
      },
      () => {
        const { viewDocumentModal } = this.state;

        this.setState(
          {
            actionText: "Add",
            editData: null,
            editIndex: -1,
          },
          () => {
            const { deleteConfirmModal } = this.state;

            if (deleteConfirmModal) {
              // For Tests
              if (actionType === "deleteTest") {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete test?" });
              }
            }
          }
        );
      }
    );
    this.loadData().then(success2 => {
      this.handleInitialData()
    })
  };

  handleOrderSetPopup = async (type) => {
    await this.setState({
      showOrdersetModal: type == 'show' ? true : false,
    })
    if(type == 'hide'){
      await this.setState({
        selectedOrderSet: this.state.selectedConfirmedOrderSetData,
        selectedConfirmedOrderSetData: this.state.selectedConfirmedOrderSetData
      })
    }
    this.loadData().then(success2 => {
      this.handleInitialData()
    })
  }

  handleOnItemDelete = async (e, actionType, objId) => {
    e.preventDefault();
    var stateVariable = null;
    var stateAddedVariable = null;
    var propKey = null;
    var deleteUrl = null;

    // For Test
    if (actionType === "deleteTest") {
      stateVariable = "testData";
      stateAddedVariable = "addedTestList";
      propKey = "test_id";
      deleteUrl = DELETE_TEST;
    }

    let dataArray = this.state[stateVariable];
    var deleteObj = dataArray[objId];
    var deleteId = deleteObj.id;

    this.setState({
      spin_loading: true,
    });
    dataArray.splice(objId, 1);
    let addedArray = [];
    dataArray.map((v, k) => {
      addedArray.push(v[propKey]);
    });
    this.setState({
      deleteConfirmModal: false,
      [stateVariable]: dataArray,
      [stateAddedVariable]: addedArray,
    });
    axios.get(deleteUrl({ id: deleteId }))
      .then((success) => {
        this.handleInitialData();
        this.setState({
          spin_loading: false
        });

      })
      .catch((err) => console.log(err));
  };

  updateTestData = async (data) => {
    let testData = this.state.testData;
    let addedTestList = this.state.addedTestList;
    var index = data.i;
    if (index == -1) index = testData == null ? 0 : testData.length;
    testData[index] = data;
    addedTestList = testData.map((v, k) => {
      return v.test_id;
    });

    const PARAMS = {
      tests_data: [data],
      reference_type: REFERENCE_TYPE,
      reference_id: this.state.patient.qms_token_id,
      patient_id: this.state.patient.patient_id,
    };
    const testFormPromise = await axios.post(PLAN_TEST_ADVICED_STORE, qs.stringify(PARAMS));
    this.setState({ editData: null, editIndex: -1, /* showTestModal: false, */ testData, addedTestList });
    if (testFormPromise.data) {
      await this.loadData();
      await this.handleInitialData();
      this.setState({
        test_success: data.test_name+' added successfully'
      })
      setTimeout(async () => {
        this.setState({
          test_success: ''
        })
      }, 7000);
      /* notification.success({
        message: testFormPromise.data.message,
        placement: "topRight",
      }); */
    }
  };

  selectOrderSet = async (data) => {
    if (data.diableConfrimButton.length > 0) {
      this.setState({
        diableConfrimButton: false
      })

    } else {
      this.setState({
        diableConfrimButton: true
      })
    }

    var selectedOrderSet = this.state.selectedOrderSet
    data.is_selected = 'Y';
    selectedOrderSet.push(data)
    await this.setState({
      selectedOrderSet: selectedOrderSet
    })
  };

  loadData = async () => {
    let { constants, favourite } = this.state;

    // Test Constants
    const testConstants = await axios.get(LAB_TEST_CONSTANTS);
    if (testConstants.data.hasOwnProperty("data") === true) {
      constants["radiologyViews"] = testConstants.data?.constants?.radiologyViews;
      constants["drugUrgency"] = testConstants.data?.constants?.testUrgency;
    }

    // Fav Tests
    let test_params = {
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null,
      distinct: 'Y'
    };
    const testsFavourite = await axios.post(LAB_TEST_SEARCH, qs.stringify(test_params));
    if (testsFavourite.data.hasOwnProperty("test_list") === true) {
      favourite.tests = testsFavourite.data.test_list;
    }

    await this.setState({
      constants: constants,
      favourite: favourite,
    });
  };

  handleInitialData = async () => {
    const { patient_id, qms_token_id } = this.state.patient;
    const planTestAdvPromise = await axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id, patient_id }))
    if (planTestAdvPromise.data.status === 'success') {
      const { duplicateOrderTests, details } = planTestAdvPromise.data.data;
      let addedTestList = [];
      if (details && details.length > 0) {
        details.map((v, k) => {
          addedTestList.push(v.test_id);
        });
      }
      await this.setState({
        testAdvisedDuplicateOrders: duplicateOrderTests,
        testData: details,
        addedTestList
      })
      if(details.length >0 ){
        this.props.viewTestData();
        this.props.viewlengthTestData(details.length);
      }
      else{
        this.props.viewlengthTestData(0);
      }
    }
    var tests = this.state.testData;
    await this.state.favourite?.tests?.map(function(x){
      var result=tests.filter(test=> test.test_id==x.testId);
      if(result.length>0) { x.is_selected='Y'}
      else{x.is_selected='N'}
      return x
    })
    var fav = this.state.favourite;
    fav.tests = this.state.favourite?.tests;
    await this.setState({
      favourite: fav
    });

    await this.setState({
      favourite_list_all: this.state.favourite
    })
  };

  handleCancel = () => {
    this.setState({ planFavTestsModal: false });
    this.loadData().then(success2 => {
      this.handleInitialData()
    })
  }

  FavouriteListLoad = async (e) => {

    var searchTerm = e?.target?.value;

    let favourite_list_all = this.state.favourite;
    let filtered = favourite_list_all.tests.filter(list => list.name.toString().toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0)

    await this.setState({
      favourite_list_all: {tests: filtered}
    })
  };

  render() {
    const { isPracticing, dataLoaded } = this.state;

    return (
      <>
        {
          dataLoaded == true
            ?
            <>
              <div className="card-body tests_body p-1">
                <div className='container-fluid tests pb-2'>
                  {/* <div className='row mb-2'>
                  <div className='col-12 text-right'>
                    <a 
                      class="btn btn-outline-secondary mr-2 px-2"
                      id="care_protocol"
                      href="#"
                      onClick={(e) => {
                        this.state.allowEdit && this.handleOrderSetPopup('show');
                      }}
                    >
                      ORDER SET
                    </a>
                    <a 
                      class="btn btn-outline-primary ml-2 px-2"
                      id="diagnosis"
                      href="#"
                      onClick={(e) => {
                        this.state.allowEdit && this.handleModalPopup(e, !isPracticing ? "isPracticingModal" : "showTestModal");
                      }}
                    >
                      + TESTS
                    </a>
                  </div>
                </div> */}

                  {
                    this.state.favourite?.tests?.length > 0
                      ?
                      <div className="card card-blue-card mt-0 mb-2">
                        <div className="card-body">
                          <div className='row '>
                            <div className='col-1'>
                              <div className='row'>
                                <div className='col-12'>
                                  <span className=''>Favourites</span>
                                </div>
                                {
                                  this.state.favourite?.tests?.length > 20
                                    ?
                                    <div className='col-12'>
                                      <a className='text-secondary' onClick={() => { this.setState({ planFavTestsModal: true }) }}><u>View All</u></a>
                                    </div>
                                    :
                                    null
                                }
                              </div>
                            </div>
                            <div className='col-11'>
                              <div className='row'>
                                <div className='col-12'>
                                  <ul className='list-unstyled d-flex flex-wrap'>
                                    {this.state.favourite?.tests?.map((data, i) => {
                                      return(
                                        <>
                                          {
                                            i < 20
                                              ?
                                              <li className='pr-3 pb-2 col-3'>
                                                <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                  <input type="checkbox" className="custom-control-input" name="fav_list" checked={data.is_selected == 'Y' ? true : false} id={'fav_'+data.type+'_'+data.testId} disabled={data.is_selected == 'Y' ? true : false} onChange={e => this.selectFavTest(e, data, i)} />
                                                  <label className="custom-control-label" htmlFor={'fav_'+data.type+'_'+data.testId}>
                                                    <Tooltip overlayInnerStyle={{color:"#fff",backgroundColor:'#666',borderRadius:'6px'}} placement="top" title={data.name}>
                                                      <div style={{ overflow: 'hidden', maxWidth: '20ch', textOverflow: 'ellipsis', whiteSpace: 'nowrap',fontSize:'0.95rem' }}>{data.name}</div>
                                                    </Tooltip>
                                                  </label>
                                                  <span className="pl-2">
                                                    <i className="icon_unique icon_star favourite align-star cursor-pointer" onClick={() => this.markTestFavorite(data)}></i>
                                                  </span>
                                                </div>
                                              </li>
                                              :
                                              null
                                          }
                                        </>
                                      )
                                    })}
                                  </ul>

                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      :
                      null
                  }

                  <TestTable
                    testData={this.state.testData}
                    viewType={"soap-view"}
                    showAction={this.state.allowEdit ? true : false}
                    eventBased={this.state.isEventBased && this.state.isEventBased == 'Y' ? true : false}
                    showSelectCheck={false}
                    constants={this.state.constants}
                    updateSelection={this.handleSelectCheck}
                    handlePopUp={this.handleModalPopup}
                    isPracticing={this.state.isPracticing}
                    practiceModal={this.handlePracticeModal}
                    handleDeleteAllTest={this.handleDeleteAllTestData}
                    deleteAllTestAtOnce= {true}
                  />
                </div>
              </div>

              {/* Favorite Tests Complete List */}
              <Modal
                destroyOnClose={true}
                title='Favourite Tests'
                visible={this.state.planFavTestsModal}
                onCancel={() => this.handleCancel()}
                footer={false}
                width="850px"
                className="tests_modal"
              >
                <div className='row fav_modal emrfrm'>
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="search-template">
                        Search Favourites
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="search-template"
                        placeholder="Search Favourites"
                        onChange={this.FavouriteListLoad}
                      />
                    </div>
                    <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                      <thead>
                        <tr>
                          <td width="10%"></td>
                          <td width="75%">Test NAME</td>
                          <td width="15%">FAVOURITE</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.favourite_list_all?.tests?.map((data, i) => {
                          return(<tr>
                            <td className='text-center'>
                              <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                <input type="checkbox" className="custom-control-input" name="fav_check" checked={data.is_selected == 'Y' ? true : false} id={'fav_'+data.type+'_'+data.testId} disabled={data.is_selected == 'Y' ? true : false} onChange={e => this.selectFavTest(e, data, i)}/>
                                <label className="custom-control-label" htmlFor={'fav_'+data.type+'_'+data.testId}></label>
                              </div>
                            </td>
                            <td>{data.name}</td>
                            <td className='text-center'>
                              <span className="">
                                <i className="icon_unique icon_star favourite align-star cursor-pointer" onClick={() => this.markTestFavorite(data)}></i>
                              </span>
                            </td>
                          </tr>)
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal>

              {/* Add New Test  */}
              {this.state.showTestModal ? (
                <Modal
                  title={this.state.actionText + " Test"}
                  visible={this.state.showTestModal}
                  onCancel={(e) => this.handleModalPopup(e, "showTestModal")}
                  footer={this.state.test_success != '' ?
                    <div className="text-center mt-1">
                      <span style={{ color: '#548f3a' }}>{this.state.test_success}</span>
                    </div> : false
                  }
                  width="850px"
                  className="tests_modal"
                >
                  {this.state.showTestModal ? (
                    <TestForm
                      formType={"copy-to-visit"}
                      actionText={this.state.actionText}
                      updateData={this.updateTestData}
                      testAdvisedDuplicateOrders={this.state.testAdvisedDuplicateOrders}
                      eventBased={this.state.isEventBased && this.state.isEventBased == 'Y' ? true : false}
                      autofocus={false}
                      editData={this.state.editData}
                      editIndex={this.state.editIndex}
                      addedTestList={this.state.addedTestList}
                      constants={this.state.constants}
                      clearEdit={this.clearEdit}
                      hospital_id={this.state.hospital_id}
                    />
                  ) : null}
                </Modal>
              ) : null}

              {/* Delete added test */}
              <Modal
                title={false}
                visible={this.state.deleteConfirmModal}
                onCancel={(e) => this.handleModalPopup(e, "deleteConfirmModal")}
                footer={false}
                closable={false}
                className="tests_modal"
                // footer={
                //   <div className="col-12 text-center">
                //     <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={(e) => this.handleOrderSetPopup('hide')}>
                //       CANCEL
                //     </Button>
                //     <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary"  disabled={this.state.diableConfrimButton} onClick={() => this.ApplyOrdersets()}>
                //       CONFIRM
                //     </Button>
                //   </div>
                // }
                width="992px"
              // bodyStyle={{ padding: "10px 20px" }}
              >
                <div className="row mb-3">
                  <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
                </div>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, "deleteConfirmModal")}>
                    CANCEL
                  </button>
                  <button
                    ref={this.deleteButton}
                    type="button"
                    className="btn btn-primary px-5"
                    onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}
                  >
                    DELETE
                  </button>
                </div>
              </Modal>

              {/* Apply orderset  */}
              {this.state.showOrdersetModal ? (
                <Modal
                  title="Apply Order Set"
                  visible={this.state.showOrdersetModal}
                  onCancel={(e) => this.handleOrderSetPopup('hide')}
                  className="tests_modal"
                  footer={
                    <div className="col-12 text-center">
                      <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={(e) => this.handleOrderSetPopup('hide')}>
                        CANCEL
                      </Button>
                      <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" disabled={this.state.diableConfrimButton} onClick={() => this.ApplyOrdersets()}>
                        CONFIRM
                      </Button>
                    </div>
                  }
                  width="992px"
                // bodyStyle={{ padding: "10px 20px" }}
                >
                  {this.state.showOrdersetModal ? (
                    <OrdersetList
                      formType={"copy-to-visit"}
                      selectOrderSet={this.selectOrderSet}
                      patient={this.state.patient}
                      selectedOrderSet={this.state.selectedOrderSet}
                    />
                  ) : null}
                </Modal>
              ) : null}
            </>
            :
            LOADER
        }
      </>
    );
  }
}

export default PlanTestComponentSS;