import React, { Component } from 'react';
import AsideLeft from '../../components/aside/asideleft';
import API_SERVICE from '../../utils/apiService';
import {
  PHR_DOCS,
  LOADER_RED,
  PATIENT_DATA
} from '../../utils/constant';
import qs from 'qs';
import moment from 'moment';
import PdfIcon from "../../assets/images/ic-pdficon.png";
import ImgIcon from "../../assets/images/ic-img.svg";
import Moment from 'react-moment';
import { Modal, Button } from 'antd';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';


class PHR extends Component {

  constructor(props) {
    super(props);

    this.state = {
      patient: null,
      prescriptions: [],
      documents: [],
      searchText: '',
      dateFilter: '3 months',
      requestSent: false,
      loading: false,
      file: {
        type: null,
        path: null,
        name: null
      },
      showDocumentModal: false,
      preset_ranges: {
        'Last 24 Hours': [moment().subtract(24, 'hours'), moment()],
        '1 Week': [moment().subtract(1, 'weeks'), moment()],
        '2 Weeks': [moment().subtract(2, 'weeks'), moment()],
        '3 Weeks': [moment().subtract(3, 'weeks'), moment()],
        '1 Month': [moment().subtract(1, 'month'), moment()],
        '2 Months': [moment().subtract(2, 'month'), moment()],
        '3 Months': [moment().subtract(3, 'month'), moment()],
        '1 Year': [moment().subtract(1, 'year'), moment()]
      },
      search_start_date: null,
      search_end_date: null,
      dateFormat: 'DD/MM/YYYY',
      filterByValue: '3 Months'
    }

    this.dateFilter = this.dateFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.setState({
        patient: this.props?.location?.state?.patient
      }, () => {
        this.setInitialSearch();
      })
    } else {
      this.getPatientData()
    }
  }

  getPatientData = _ => {
    const { match: { params } } = this.props;
    const { patient_id, reference_id } = params;
    API_SERVICE.get(PATIENT_DATA({ patient_id, reference_id }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken.qms_token_id
        }
        this.setState({
          patient
        }, () => {
          this.setInitialSearch();
        })
      })
      .catch(err => console.log(err))
  }

  async searchPhr() {
    this.setState({
      loading: true,
      prescriptions: [],
      documents: [],
      requestSent: false
    });
    const { patient } = this.state;
    // const arrRoute = this.props.location.pathname.split('/');
    // const pid = arrRoute[arrRoute.length - 1];
    // this.setState({
    //   patientId: pid
    // });
    const dateFormat = 'DD/MM/YYYY';

    // 3 months
    // const start = moment().subtract(3, 'months').format(dateFormat);
    // const end = moment().format(dateFormat);
    // Last 24 hours
    // const start = moment().subtract(1, 'days').format(dateFormat);
    // const end = moment().format(dateFormat);
    const start = this.state.moment_start_date;
    const end = this.state.moment_end_date;

    const qstring = qs.stringify({
      startdate: start,
      enddate: end,
      search: this.state.searchText
    });
    const url = PHR_DOCS.replace('{patientId}', patient.patient_id) + '?' + qstring;

    const phrlist = await API_SERVICE.get(url);
    if (phrlist.documents != undefined && phrlist.documents.length > 0) {
      this.setState({ documents: phrlist.documents });
    }
    if (phrlist.prescriptions != undefined && phrlist.prescriptions.length > 0) {
      this.setState({ prescriptions: phrlist.prescriptions });
    }
    this.setState({ loading: false, requestSent: true });
    return;
  }

  setInitialSearch() {
    const start = moment().subtract(3, 'months').toObject();
    const end = moment().toObject();
    this.setState({
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      moment_start_date: moment().subtract(3, 'months').format(this.state.dateFormat),
      moment_end_date: moment().format(this.state.dateFormat),
      searchText: '',
      filterByValue: '3 Months'
    }, () => {
      this.searchPhr();
    });
  }

  dateFilter(start, end, label) {
    const crlabel = start.format('LL') + ' - ' + end.format('LL');
    this.setState({
      filterByValue: (label === 'Custom Range') ? crlabel : label,
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      moment_start_date: start.format(this.state.dateFormat),
      moment_end_date: end.format(this.state.dateFormat)
    }, () => {
      this.searchPhr();
    });
  }

  getDocumentImg(document) {
    const path = document.document_path;
    return path.substr(path.length - 3) == 'pdf' ? PdfIcon : ImgIcon;
  }

  showFile(document, name) {
    const path = document.document_path;
    this.setState({
      file: {
        type: (path.substr(path.length - 3) == 'pdf') ? 'pdf' : 'img',
        path: path,
        name: name
      },
      showDocumentModal: true
    });
  }

  render() {
    const { patient } = this.state;
    return (
      <React.Fragment>

        {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title">PHR</h1>
                </div>
                {/* <div className="float-right">
                    <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onclick="window.history.go(-1); return false;">BACK</a>
                  </div> */}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12">
                <form className="emrfrm" id="reportSearchForm" onSubmit={(e) => { e.preventDefault(); this.searchPhr(); }}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="form-label-group input-group">
                          <input onChange={(e) => this.setState({ searchText: e.target.value })} type="text" value={this.state.searchText} id="search" name="search" className="form-control" placeholder="Document Name" />
                          <span onClick={() => this.searchPhr()} id="reportSpanSubmit" className="input-group-addon bg-primary" style={{ cursor: 'pointer', bordeRadius: '0 4px 4px 0', maxWidth: 116 + 'px', padding: '5px 15px 0px 10px', fontSize: 1.125 + 'rem', color: '#fff' }}><img className="mr-2" src="/images/search-ic.png" alt="" />Search</span>
                          <label htmlFor="search">Document Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">

                        {this.state.search_start_date != null ? <DateRangePicker
                          onCallback={this.dateFilter}
                          initialSettings={{
                            startDate: this.state.search_start_date,
                            endDate: this.state.search_end_date,
                            ranges: this.state.preset_ranges
                          }}
                        >
                          <button type="button" className="text-left form-control">{this.state.filterByValue}</button>
                        </DateRangePicker> : null}

                        <label className="control-label" htmlFor="search">Filter by duration</label>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div onClick={() => { this.setInitialSearch(); }} className="btn btn-primary reset float-right cursor-pointer">RESET</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row display-no-data">
            </div>
            <div className="row display-phr-data">

              {this.state.loading ? <div className="col-lg-12 mb-5 text-center">
                {LOADER_RED}
              </div> : null}

              {this.state.prescriptions.length > 0 ? <div className="col-lg-12 mb-5">
                <h1 className="page-title">Prescriptions</h1>
                <div className="row">

                  {this.state.prescriptions.map((prescription, index) => (
                    prescription.documents_path.map((document, dindex) => (
                      <div className="col-md-4" key={index + '-' + dindex}>
                        <div className="media border p-3 mb-3">
                          <a style={{ width: 80 + 'px' }} className="show-phr-modal" onClick={() => this.showFile(document, prescription.document_name)}>
                            <img className="mr-3" src={this.getDocumentImg(document)} alt="ic-pdf-image" />
                          </a>
                          <div className="media-body">
                            <h6 className="mt-0">{prescription.document_name}</h6>
                            <small><Moment date={prescription.record_date} format="D MMM, YYYY" /></small>
                          </div>
                        </div>
                      </div>
                    ))
                  ))}

                </div>
              </div> : null}

              {this.state.documents.length > 0 ? <div className="col-lg-12 mb-5">
                <h1 className="page-title">Documents</h1>
                <div className="row">

                  {this.state.documents.map((prescription, index) => (
                    prescription.documents_path.map((document, dindex) => (
                      <div className="col-md-4" key={index + '-' + dindex}>
                        <div className="media border p-3 mb-3">
                          <a style={{ width: 80 + 'px' }} className="show-phr-modal" onClick={() => this.showFile(document, prescription.document_name)}>
                            <img className="mr-3" src={this.getDocumentImg(document)} alt="ic-pdf-image" />
                          </a>
                          <div className="media-body">
                            <h6 className="mt-0">{prescription.document_name}</h6>
                            <small><Moment date={prescription.record_date} format="D MMM, YYYY" /></small>
                          </div>
                        </div>
                      </div>
                    ))
                  ))}

                </div>
              </div> : null}

            </div>

            {this.state.requestSent && this.state.documents.length == 0 && this.state.prescriptions.length == 0 ?
              <div className="row display-no-data">
                <div className="col-12">
                  <h4 className="d-flex justify-content-center">This patient has not uploaded any documents for the selected duration.</h4>
                </div>
              </div> : null}

          </div>
        </div>

        <div className="modal fade" aria-hidden="true" id="alert_confirm_message" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">Add Condition</h6>
                <button type="button" className="close" data-dismiss="modal">×</button>
              </div>
              <div className="modal-body">
                <div className="row" style={{ marginTop: -30 + 'px' }}>
                  <div className="col-12 my-3 text-center">
                    <iframe frameBorder="0" width="100%" height="700" id="frame"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title={this.state.file.name}
          visible={this.state.showDocumentModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showDocumentModal: false, file: { name: null, type: null, path: null } }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.file.path != null && this.state.file.type == 'pdf' ? <iframe src={this.state.file.path} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                {LOADER_RED}
              </iframe> : null}
              {this.state.file.path != null && this.state.file.type != 'pdf' ?
                <img src={this.state.file.path} alt={this.state.file.name} style={{ width: '100%' }} />
                : null}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PHR;