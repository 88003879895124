import React, { Component, Fragment } from 'react';
import ObjectiveComponentSS from '../objectivess';
import ChiefComplaintSection from '../assessment/chief-complaint-section';
import LS_SERVICE from '../../../utils/localStorage';
import { REFERENCE_TYPE,DURATION_TYPE_OBJECT_REVERSE,SOAP_OBJECTIVE_DETAILS } from '../../../utils/constant';
import axios from 'axios';
import qs from 'qs';
export default class PatientHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //field_status_patient_history: props.field_status_patient_history,
            patient: props.patient,            
            consult_type: props.consult_type,
            getPatientData: props.getPatientData,
            dataLoading: props.dataLoading,
            initialSetup: props.initialSetup,
            reference_id: props.reference_id,
            field_status:props.field_status,
            fall_risk:props.fall_risk_object
        }
    }

    componentDidMount() {
        const ss_configuration = LS_SERVICE.get('ss_configuration');
        console.log('ss_configuration',ss_configuration);
        const patient_full_data = LS_SERVICE.get('call_patient_detail');
        this.setState({
          ss_configuration: ss_configuration,
          consult_type: patient_full_data?.consult_type == 1 ? 'vc' : 'opd'
        })
        this.initialSetup();
    }


    handleTabClick = (tab) => {
        if (tab == 'objective') {
            this.setState({
                isObjectiveShow: true
            });
        }
        else if (tab == "plan_test") {
            this.setState({
                isTestShow: true
            });
        }
        else if (tab == "plan_medicine") {
            this.setState({
                isMedShow: true
            });
        }
    }

    initialSetup = () => {
        const { patient,hospital_id } = this.state;

        const PARAMS = {
          reference_type: REFERENCE_TYPE,
          patient_id: patient.patient_id,
          reference_id: patient.qms_token_id || patient.qmsToken.qms_token_id,
          hospital_id:1
        }
        axios.post(SOAP_OBJECTIVE_DETAILS, qs.stringify(PARAMS))
          .then((res) => {
            const { details, field_status } = res.data;
            let { followUpRadio, follow_up_days, follow_up_Others, otherVal } = this.state;
    
            let followUpType = null;
            let followUpDays = null;
            if (details.followUp !== null) {
              followUpType = details.followUp?.followUpType;
              followUpDays = details.followUp?.followUpDays;
    
              if (followUpType !== 0 && followUpType !== undefined) {
                followUpRadio = -1;
                otherVal = followUpDays;
                follow_up_Others = DURATION_TYPE_OBJECT_REVERSE[followUpType]?.toLowerCase();
              } else if (followUpDays !== undefined) {
                follow_up_days = followUpDays;
                followUpRadio = followUpDays
              }
            }
    
            const bookNowAllowFn = _ => {
              if (otherVal !== null || follow_up_days !== null) {
                if (otherVal > 0) return false;
                if (follow_up_days > 0) return false;
              }
              return true;
            }
    
            this.setState({
              details,
              constants: details.constants,
              field_status,
              dataLoading: false,
              physiotherapy_advised: details?.physiotherapyAdvised === null || details?.physiotherapyAdvised === 0 ? 2 : 1,
              admissionChecked: details?.admissionAdvised == 1 ? true : false,
              physioText: details?.physiotherapyComment ?? '',
              admissionComment: details?.admissionComment ?? '',
              isAdmissionAdvisedPriority: details.admissionAdvisedPriority == null || details.admissionAdvisedPriority == 0 ? false : true,
              followUpRadio,
              otherVal,
              follow_up_Others,
              follow_up_days,
              bookNowAllow: bookNowAllowFn(),
            })
          })
          .catch((err) => {
            console.log(err.data)
          })
      }

      

    render() {
        //console.log('ss_configuration===>2',ss_configuration);
        var field_status_patient_history=[];
        const {ss_configuration, consult_type,field_status} = this.state;
        if (field_status?.patient_history?.length > 0 && ss_configuration?.length>0) {
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 4 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
            field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "allergies"))
          }
          if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 5 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
            field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "existing_conditions"))
          }
          if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 6 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
            field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "surgical_hx"))
          }
          if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 7 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
            field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "social_hx"))
          }
          if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 8 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
            field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "family_hx"))
          }
        }
        return (
            <Fragment>
                <div className='container-fluid px-0'>

                    <div className='assessment-card'>
                        <Fragment>
                            <div className="accordion soap_accordion py-2" id="accordionExample">
                                <div className="card pathistory-card mb-0">
                                    <div className="card-header pathistory-card-head p-0" id="headingTwo">
                                        <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapsePatientHistory" aria-expanded="false" aria-controls="collapsePatientHistory" onClick={(e) => this.handleTabClick('objective')}>
                                            <div className='row'>
                                                <div className='col-2 d-flex align-self-center'>
                                                    <h6>PATIENT HISTORY</h6>
                                                </div>                                                

                                                {field_status_patient_history?.map((item, i) =>
                                                    <>
                                                        <div className='col-2'>
                                                            {item.status ? <span className='checkmark'></span> : ""}
                                                            <span className='pl-2 pathis-headings'>{item.name}</span>
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                        </button>
                                    </div>
                                    <div id="collapsePatientHistory" className="collapse" aria-labelledby="headingTwo" >
                                        {this.state.patient ?
                                            <ObjectiveComponentSS sourcescreen="singlescreen"
                                                consult_type={this.state.consult_type}
                                                patient={this.state.patient}
                                                dataLoading={this.state.dataLoading}
                                                initialSetup={this.state.initialSetup}
                                                getPatientData={this.state.getPatientData}
                                                />
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                    {this.state.patient && this.state.patient?.qmsToken ? <ChiefComplaintSection patient={this.state.patient} reference_id={this.state.reference_id} /> : null}
                </div>
            </Fragment>
        )
    }

}
