import React, { Component, Fragment } from "react";
import { Button, Modal, Checkbox, Select, Spin, notification } from "antd";
import axios from "axios";
import qs from "qs";
import "antd/dist/antd.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import MedicineTable from "../../components/drugs/medicine-table";
import InstructionTable from "../../components/instructions/instructions-table";
import SupportingMaterialTable from "../../components/supporting-material/supporting-material-table";

import LS_SERVICE from "../../utils/localStorage";
import { jQueryLabels } from "../../utils/jQueryLabels";
import {
    ORDERSET_SEARCH,
    DELETE_ORDERSET,
    FAVORITE_ORDERSET,
    VIEW_ORDERSET,
    LOADER_RED,
    HOSPITAL_AGENT_LIST,
    SPECIALITY_AGENT_LIST,
    ALL_SPECIALITY_AGENT_LIST,
    DOCTOR_AGENT_LIST,
    ALL_DOCTOR_AGENT_LIST,
    USERTYPES,
    ORDERSET_NEW_MAPPING,
    PARENT_ORDERSET_ACTIVATION,
    SUB_ORDERSET_ACTIVATION,
} from "../../utils/constant";

const { Option } = Select;

class Ordersetlist extends Component {
    constructor() {
        super();
        this.state = {
            viewNo: 0,
            templateList: [],
            templateView: [],
            isModalVisible: false,
            hospital_id: [],
            templateId: "",
            $isTestFavCheckedVal: [],
            NoRecords: 0,
            mapOrderSetList: [],
            mapHospitalbuttonchk: true,
            selectchecked: false,
            listingLoader: true,
            mapToHospitalDefault: [],
            allSpecialityList: [],
            allDoctorList: [],
            hospital_master: "N",
        };
        // this.TemplateOnListLoad = this.TemplateOnListLoad.bind(this);
        this.is_favoriteArr = [];
    }

    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) {
            if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
                await this.setState({
                  filter_hospital_id: LS_SERVICE.get("admin_hospital_id"),
                });
            }
            await this.hospitalList();
            await this.specialityList();
            await this.doctorList();
            this.allDoctorList();
        }
        this.allSpecialityList();
        // await this.doctorList();
        await this.TemplateOnListLoad();
        $(document).ready(function () {
            jQueryLabels();
        });
    }

    // Show pop-up Model for delete
    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    // OK click on delete model
    handleOk = () => {
        this.setState({ isModalVisible: false });
        this.TemplateOndelete(this.state.templateId);
    };

    // Cancel click on delete model
    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };

    // Get orderset list - This will fetch the list of orderset from API with or without filter
    TemplateOnListLoad = async (e) => {
        var search_params = {
            searchTerm: e?.target?.value,
        };
        if (LS_SERVICE.get("user_type") == USERTYPES.doctor) {
            search_params.filter_hospital_id = LS_SERVICE.get("slot_hospital_id");
            search_params.filter_speciality_id = LS_SERVICE.get("speciality_id");
            search_params.doctor_Id = LS_SERVICE.get("staff_id");
            search_params.hospital_master = LS_SERVICE.get('slot_hospital_master') ?? 'N';
        } else {
            if (this.state.filter_doctor_id) {
                search_params.filter_doctor_id = this.state.filter_doctor_id;
            }
            if (this.state.filter_owner_id) {
                search_params.filter_owner_id = this.state.filter_owner_id;
            }
            if (this.state.filter_speciality_id) {
                search_params.filter_speciality_id =
                    this.state.filter_speciality_id;
            }
            if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                if (this.state.filter_hospital_id) {
                    search_params.filter_hospital_id = this.state.filter_hospital_id;
                }
                search_params.hospital_master = this.state.hospital_master;
            }
            if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
                search_params.filter_hospital_id = LS_SERVICE.get("admin_hospital_id");
                search_params.hospital_master = LS_SERVICE.get('slot_hospital_master') ?? 'N';
            }
        }
        axios.post(ORDERSET_SEARCH, qs.stringify(search_params))
        .then((success) => {
            this.is_favoriteArr = [];
            success.data.data.orderset.forEach((data, i) => {
                this.is_favoriteArr.push(data.is_favorite);
                if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                    this.setState({
                        ["active_for_" + data.orderSetId]:
                            data.active == "Y" ? true : false,
                    });
                } else if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
                    if (
                        data.deactivatedHospitalId &&
                        data.deactivatedHospitalId != ""
                    ) {
                        var deactivatedHospitals =
                            data.deactivatedHospitalId
                                .split(",")
                                .map((x) => +x) || [];
                        if (
                            deactivatedHospitals.includes(
                                LS_SERVICE.get("admin_hospital_id")
                            )
                        ) {
                            this.setState({
                                ["subactive_for_" + data.orderSetId]: false,
                            });
                        } else {
                            this.setState({
                                ["subactive_for_" + data.orderSetId]: true,
                            });
                        }
                    } else {
                        this.setState({
                            ["subactive_for_" + data.orderSetId]: true,
                        });
                    }
                }
            });
            if (success.data.message === "No Records Found!") {
                this.setState({
                    NoRecords: 0,
                    templateList: [],
                    listingLoader: false,
                });
            } else {
                this.setState({
                    templateList: success.data.data.orderset,
                    $isTestFavCheckedVal: this.is_favoriteArr,
                    NoRecords: success.data.data.orderset.length,
                    listingLoader: false,
                });
            }
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                listingLoader: false,
            });
        });
    };

    // // Get hospital list
    // hospitalList = async (search) => {
    //     let PARAMS = { hospital_id: 0 };
    //     if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
    //         PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
    //         await this.setState({
    //             filter_hospital_id: LS_SERVICE.get("admin_hospital_id"),
    //         });
    //     }
    //     await axios.post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS))
    //     .then((res) => {
    //         var hospital_list = res.data.data.results;
    //     });
    // };

    // Get hospital list
    hospitalList = async () => {
        let PARAMS = { };
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
              filter_hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }
        await axios.post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
            this.setState({
                hospitalList: res?.data?.data?.results ? res?.data?.data?.results : [],
            });
        });
    };

    // // Get speciality list
    // specialityList = async () => {
    //     let PARAMS = { hospital_id: 0 };
    //     if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
    //         PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
    //     }
    //     await axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS))
    //     .then((res) => {
    //         this.setState({
    //             specialityList: res.data.status
    //                 ? res.data.data.results
    //                 : [],
    //         });
    //     });
    // };

    // Get speciality list
    specialityList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
              filter_hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        if(this.state.filter_hospital_id == -1){
            await axios.post(ALL_SPECIALITY_AGENT_LIST).then((res) => {
                this.setState({
                    specialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else{
            if(this.state.filter_hospital_id){
                PARAMS = { 
                    hospital_id: this.state.filter_hospital_id 
                };
                await axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        specialityList: res.data.status ? res.data.data.results : [],
                    });
                });
            }
            else{
                this.setState({
                    specialityList: [],
                });
            }
        }
    };

    // Get speciality list
    allSpecialityList = async () => {
        var PARAMS = {};
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                  allSpecialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else{
            await axios.post(ALL_SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    allSpecialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
    };

    // Get doctor list
    // doctorList = async (search) => {
    //     const PARAMS = { search };
    //     await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
    //         this.setState({
    //             doctorList: res.data.status ? res.data.data.results : [],
    //         });
    //     });
    // };
  
    // Get doctor list
    doctorList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
              filter_hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        // if(this.state.filter_hospital_id && this.state.filter_speciality_id){
        //     PARAMS = { 
        //       hospital_id: this.state.filter_hospital_id,
        //       speciality_id: this.state.filter_speciality_id
        //     };
            
        //     await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
        //         this.setState({
        //             doctorList: res.data.status ? res.data.data.results : [],
        //         });
        //     });
        // }
        if(this.state.filter_hospital_id == -1 && this.state.filter_speciality_id){
            PARAMS = {
              speciality_id: this.state.filter_speciality_id
            }
            await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                var final_doctor = [];
                for(var doc of res?.data?.data?.results){
                    final_doctor.push(doc)
                }
                this.setState({
                    doctorList: final_doctor,
                });
            });
        }
        else if(this.state.filter_hospital_id && this.state.filter_speciality_id){
            PARAMS = { 
              hospital_id: this.state.filter_hospital_id,
              speciality_id: this.state.filter_speciality_id
            };
            
            await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    doctorList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else if(this.state.filter_hospital_id && !this.state.filter_speciality_id){
            if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                if(this.state.filter_hospital_id == -1){
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
                else{
                    PARAMS = { hospital_id: this.state.filter_hospital_id };
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
            }
            else{
                PARAMS = { hospital_id: this.state.filter_hospital_id };
                await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        doctorList: res?.data?.data?.results || [],
                    });
                });
            }
        }
        else{
            this.setState({
                doctorList: [],
            });
        }
    };

    allDoctorList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
        }

        await axios.post(ALL_DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
            this.setState({
                allDoctorList: res.data.status ? res.data.data.results : [],
            });
        });
    };

    // Handle hospital change on hospital dropdown for map to hospital
    selectHospitalChange = async (val, props) => {
        let hospital_id = [];
        props.forEach((element) => {
            hospital_id.push(element.dataid.hospital_id);
        });
        await this.setState({
            hospital_id: hospital_id,
            mapToHospitalDefault: hospital_id,
        });
        this.saveButtonChk();
    };

    // Handle hospital change on filter
    // selectHospitalFilterChange = async (val, props) => {
    //     let hospital_id = [];

    //     // Apply single hospital filter
    //     hospital_id.push(props?.dataid?.hospital_id);

    //     /* Below code is for multiple hospital filter (Keeping the code so that in case we 
    //     need to make it multiple hospital filter in future, code will be readily available)
    //     P.S. No need to change the API. API is already handled for multiple hospital search.
    //     Just comment the above one line and uncomment the below four lines and in HTML include 
    //     mode="multiple" in the hospital <Select> */
    //     // // Apply multiple hospital filter
    //     // props.forEach(element => {
    //     //   hospital_id.push(element.dataid.hospital_id);
    //     // });
    //     /* End code for multiple hospital filter */

    //     await this.setState({
    //         filter_hospital_id: hospital_id.join(),
    //     });
    //     this.TemplateOnListLoad();
    // };

    selectHospitalFilterChange = async (val, props) => {
        let hospital_id = [];

        // Apply single hospital filter
        hospital_id.push(props?.dataid?.hospital_id);

        await this.setState({
            filter_hospital_id: hospital_id.join(),
            hospital_master: props ? props.own_master : 'N'
        });

        var final_speciality = [];
        await this.specialityList();
        var speciality_array = this.state.specialityList
        if(speciality_array && speciality_array.length > 0){
            if(this.state.filter_speciality_id){
                var SpecialityDefaultValue = this.state.filter_speciality_id?.split(',');
                for(var sp of SpecialityDefaultValue){
                    if(speciality_array.some(el => el.speciality_id === sp)) {
                        final_speciality.push(sp)
                    }
                }
            }
        }
        await this.setState({
            filter_speciality_id: final_speciality.join(),
        });

        var final_doctor = [];
        var final_owner = [];
        await this.doctorList();
        var doctor_array = this.state.doctorList;
        if(doctor_array && doctor_array.length > 0){
            if(this.state.filter_doctor_id){
                var DoctorDefaultValue = this.state.filter_doctor_id?.split(',');
                for(var doc of DoctorDefaultValue){
                    if(doctor_array.some(el => el.staff_id === doc)) {
                        final_doctor.push(doc)
                    }
                }
            }
            if(this.state.filter_owner_id){
                var OwnerDefaultValue = this.state.filter_owner_id?.split(',');;
                for(var own of OwnerDefaultValue){
                    if(doctor_array.some(el => el.staff_id === own)) {
                        final_owner.push(doc)
                    }
                }
            }
        }
        await this.setState({
            filter_doctor_id: final_doctor.join(),
            filter_owner_id: final_owner.join(),
        });

        // if(this.state.filter_hospital_id){
        //     var final_speciality = [];
        //     await this.specialityList();
        //     var speciality_array = this.state.specialityList
        //     if(speciality_array && speciality_array.length > 0){
        //         if(this.state.filter_speciality_id){
        //             var SpecialityDefaultValue = this.state.filter_speciality_id?.split(',');
        //             for(var sp of SpecialityDefaultValue){
        //                 if(speciality_array.some(el => el.speciality_id === sp)) {
        //                     final_speciality.push(sp)
        //                 }
        //             }
        //         }
        //     }
        //     await this.setState({
        //         filter_speciality_id: final_speciality.join(),
        //     });

        //     if(this.state.filter_speciality_id){
        //         var final_doctor = [];
        //         var final_owner = [];
        //         await this.doctorList();
        //         var doctor_array = this.state.doctorList;
        //         if(doctor_array && doctor_array.length > 0){
        //             if(this.state.filter_doctor_id){
        //                 var DoctorDefaultValue = this.state.filter_doctor_id?.split(',');
        //                 for(var doc of DoctorDefaultValue){
        //                     if(doctor_array.some(el => el.staff_id === doc)) {
        //                         final_doctor.push(doc)
        //                     }
        //                 }
        //             }
        //             if(this.state.filter_owner_id){
        //                 var OwnerDefaultValue = this.state.filter_owner_id?.split(',');;
        //                 for(var own of OwnerDefaultValue){
        //                     if(doctor_array.some(el => el.staff_id === own)) {
        //                         final_owner.push(doc)
        //                     }
        //                 }
        //             }
        //         }
        //         await this.setState({
        //             filter_doctor_id: final_doctor.join(),
        //             filter_owner_id: final_owner.join(),
        //         });
        //     }
        //     else{
        //         await this.setState({
        //             filter_doctor_id: "",
        //             filter_owner_id: "",
        //             doctorList: [],
        //         });
        //     }
        // }
        // else{
        //     await this.setState({
        //         filter_speciality_id: "",
        //         filter_doctor_id: "",
        //         filter_owner_id: "",
        //         specialityList: [],
        //         doctorList: [],
        //     });
        // }
        this.TemplateOnListLoad();
    };

    // Handle speciality change on filter
    // selectSpecialityFilterChange = async (val, props) => {
    //     let speciality_id = [];
    //     props.forEach((element) => {
    //         speciality_id.push(element.dataid.speciality_id);
    //     });

    //     await this.setState({
    //         filter_speciality_id: speciality_id.join(),
    //     });
    //     this.TemplateOnListLoad();
    // };

    // Handle speciality change
    selectSpecialityFilterChange = async (val, props) => {
        let speciality_id = [];
        props.forEach((element) => {
            speciality_id.push(element.dataid.speciality_id);
        });
        await this.setState({
            filter_speciality_id: speciality_id.join(),
        });

        var final_doctor = [];
        var final_owner = [];
        await this.doctorList();
        var doctor_array = this.state.doctorList;
        if(doctor_array && doctor_array.length > 0){
            if(this.state.filter_doctor_id){
                var DoctorDefaultValue = this.state.filter_doctor_id?.split(',');
                for(var doc of DoctorDefaultValue){
                    if(doctor_array.some(el => el.staff_id === doc)) {
                        final_doctor.push(doc)
                    }
                }
            }
            if(this.state.filter_doctor_id){
                var OwnerDefaultValue = this.state.filter_owner_id?.split(',');
                for(var own of OwnerDefaultValue){
                    if(doctor_array.some(el => el.staff_id === own)) {
                        final_owner.push(doc)
                    }
                }
            }
        }
        await this.setState({
            filter_doctor_id: final_doctor.join(),
            filter_owner_id: final_owner.join(),
        });

        // if(this.state.filter_speciality_id){
        //     var final_doctor = [];
        //     var final_owner = [];
        //     await this.doctorList();
        //     var doctor_array = this.state.doctorList;
        //     if(doctor_array && doctor_array.length > 0){
        //         if(this.state.filter_doctor_id){
        //             var DoctorDefaultValue = this.state.filter_doctor_id?.split(',');
        //             for(var doc of DoctorDefaultValue){
        //                 if(doctor_array.some(el => el.staff_id === doc)) {
        //                     final_doctor.push(doc)
        //                 }
        //             }
        //         }
        //         if(this.state.filter_doctor_id){
        //             var OwnerDefaultValue = this.state.filter_owner_id?.split(',');
        //             for(var own of OwnerDefaultValue){
        //                 if(doctor_array.some(el => el.staff_id === own)) {
        //                     final_owner.push(doc)
        //                 }
        //             }
        //         }
        //     }
        //     await this.setState({
        //         filter_doctor_id: final_doctor.join(),
        //         filter_owner_id: final_owner.join(),
        //     });
        // }
        // else{
        //     await this.setState({
        //         filter_doctor_id: "",
        //         filter_owner_id: "",
        //         doctorList: [],
        //     });
        // }
        this.TemplateOnListLoad();
    };

    // Handle doctor change on filter
    selectDoctorFilterChange = async (val, props) => {
        let doctor_id = [];
        props.forEach((element) => {
            doctor_id.push(element.dataid.staff_id);
        });

        await this.setState({
            filter_doctor_id: doctor_id.join(),
        });
        this.TemplateOnListLoad();
    };

    // Handle owner change on filter
    selectOwnerFilterChange = async (val, props) => {
        let owner_id = [];
        props.forEach((element) => {
            owner_id.push(element.dataid.staff_id);
        });

        await this.setState({
            filter_owner_id: owner_id.join(),
        });
        this.TemplateOnListLoad();
    };

    // On check of orderset select checkboxes for mapping (Super admin only)
    selectOrdersetForCopy = async (e) => {
        const { value, checked } = e.target;
        var mapOrderSetList = this.state.mapOrderSetList || [];
        if (checked == true) {
            mapOrderSetList.push(value);
        } else {
            const index = mapOrderSetList.indexOf(value);
            if (index > -1) {
                mapOrderSetList.splice(index, 1);
            }
        }
        await this.setState({
            mapOrderSetList: mapOrderSetList,
        });
        this.saveButtonChk();
    };

    // Activate / Deactivate Orderset - Parent Level
    ParentOrdersetActivation = async (e) => {
        const { value, checked } = e.target;
        this.setState({ ["active_for_" + value]: checked });
        await axios
            .post(
                PARENT_ORDERSET_ACTIVATION,
                qs.stringify({
                    orderset_id: value,
                })
            )
            .then((success) => {
                this.TemplateOnListLoad();
                notification.destroy();
                notification.success({
                    message: success.data.message,
                    placement: "topRight",
                });
            })
            .catch((err) => console.log(err));
    };

    // Activate / Deactivate Orderset - Sub Level
    SubOrdersetActivation = async (e) => {
        const { value, checked } = e.target;
        this.setState({ ["subactive_for_" + value]: checked });
        await axios
            .post(
                SUB_ORDERSET_ACTIVATION,
                qs.stringify({
                    orderset_id: value,
                    active: checked == true ? "Y" : "N",
                })
            )
            .then((success) => {
                this.TemplateOnListLoad();
                notification.destroy();
                notification.success({
                    message: success.data.message,
                    placement: "topRight",
                });
            })
            .catch((err) => console.log(err));
    };

    // Check save button status to copy
    saveButtonChk() {
        if (
            this.state?.hospital_id?.length > 0 &&
            this.state?.mapOrderSetList?.length > 0
        ) {
            this.setState({ mapHospitalbuttonchk: false });
        } else {
            this.setState({ mapHospitalbuttonchk: true });
        }
    }

    // Map selected templates to selected hospitals
    mapToHospital = async (e) => {
        e.preventDefault();
        this.setState({
            templateList: [],
            mapToHospitalDefault: [],
        });
        await axios
            .post(
                ORDERSET_NEW_MAPPING,
                qs.stringify({
                    hospital_ids: this.state.hospital_id,
                    orderset_ids: this.state.mapOrderSetList,
                })
            )
            .then((success) => {
                this.TemplateOnListLoad();
                notification.success({
                    message: "Order sets mapped to the selected hospital",
                    placement: "topRight",
                });
            })
            .catch((err) => console.log(err));
    };

    // Delete an orderset from the list
    TemplateOndelete(val) {
        axios
            .post(DELETE_ORDERSET, qs.stringify({ orderSetId: parseInt(val) }))
            .then((success) => {
                this.TemplateOnListLoad();
                notification.success({
                    message: success.data.message,
                    placement: "topRight",
                });
            })
            .catch((err) => console.log(err));
    }

    // Mark a orderset as favourite
    TemplateOnFavourite(val, index) {
        if (this.is_favoriteArr[index]) {
            this.is_favoriteArr[index] = 0;
        } else {
            this.is_favoriteArr[index] = 1;
        }

        this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
        axios
            .post(
                FAVORITE_ORDERSET,
                qs.stringify({ orderSetId: parseInt(val) })
            )
            .then((success) => {
                this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
                this.TemplateOnListLoad();
                //   notification.success({
                //     message: success.data.message,
                //     placement: 'topRight'
                //   });
            })
            .catch((err) => {
                console.log(err);
                if (!this.is_favoriteArr[index]) {
                    this.is_favoriteArr[index] = 1;
                } else {
                    this.is_favoriteArr[index] = 0;
                }
                this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
            });
    }

    // View a orderset details
    TemplateOnView = (templateId) => {
        axios
            .post(VIEW_ORDERSET, qs.stringify({ orderSetId: templateId }))
            .then((success) => {
                this.setState({ templateView: success.data });
            })
            .catch((err) => console.log(err));
    };

    // View orderset medicine details
    templateViewDrugs() {
        if (
            !this.state.templateView.orderSetDrugData ||
            this.state.templateView.orderSetDrugData.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title">Medicines</h4>
                <MedicineTable
                    drugsData={this.state.templateView.orderSetDrugData}
                    viewType={"list-visit"}
                    showAction={false}
                    showSelectCheck={false}
                    constants={this.state.templateView.constants}
                />
            </Fragment>
        );
    }

    // View orderset test details
    templateViewtests() {
        if (
            !this.state.templateView.orderSetTestData ||
            this.state.templateView.orderSetTestData.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title mt-4">Tests</h4>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th width={50 + "%"}>TEST NAME</th>
                            <th>INSTRUCTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.templateView.orderSetTestData.map(
                            (test, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{test.test_name}</td>
                                        <td>
                                            {test.instructions
                                                ? test.instructions
                                                : ""}
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </Fragment>
        );
    }

    // View orderset instruction details
    templateViewInstruction() {
        if (
            !this.state.templateView.orderSetInstruction ||
            this.state.templateView.orderSetInstruction.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title mt-4">Instructions</h4>
                <div className="row mt-2">
                    <div className="col-10">
                        <ul className="custom-list list-success">
                            <InstructionTable
                                instructionsData={
                                    this.state.templateView.orderSetInstruction
                                }
                                viewType={"full"}
                                constants={null}
                            />
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }

    templateViewMaterial() {
        let materialData = [];
        if (
            !this.state.templateView.orderSetSupportingMaterial ||
            this.state.templateView.orderSetSupportingMaterial.length === 0
        ) {
            return null;
        }else{
            return (
                <Fragment>
                    <h4 className="page-title mt-4">Supporting Material</h4>
                    <div className="row mt-2">
                        <div className="col-10">
                                <SupportingMaterialTable
                                    supportingMaterialData={this.state.templateView.orderSetSupportingMaterial}
                                    viewType={'full'}
                                    hospitalId={this.state.hospital_id}
                                    constants={this.state.constants} />
                        </div>
                    </div>
                </Fragment>
            );
        } 
    }

    // List the orderset details on UI in table
    templateRowLoad() {
        // if (!this.state.templateList) {
        //   return null
        // }
        var colspan = 0;
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
            colspan = 10;
        } else if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            colspan = 8;
        } else {
            colspan = 6;
        }
        return (
            <table className="table table-default">
                <thead>
                    <tr>
                        <th>ORDER SET NAME</th>
                        {LS_SERVICE.get("user_type") == USERTYPES.superadmin ? (
                            <th width="200">Hospital</th>
                        ) : null}
                        {LS_SERVICE.get("user_type") == USERTYPES.superadmin ||
                        LS_SERVICE.get("user_type") == USERTYPES.admin ? (
                            <th width="200">Speciality</th>
                        ) : null}
                        {LS_SERVICE.get("user_type") == USERTYPES.superadmin ||
                        LS_SERVICE.get("user_type") == USERTYPES.admin ? (
                            <th width="200">Doctor</th>
                        ) : null}
                        <th width="120">MEDICINES</th>
                        <th width="120">TESTS</th>
                        <th width="60">View</th>
                        {LS_SERVICE.get("user_type") == USERTYPES.superadmin ||
                        LS_SERVICE.get("user_type") == USERTYPES.admin ? (
                            <th width="60" style={{ textAlign: "center" }}>
                                Active
                            </th>
                        ) : null}
                        {LS_SERVICE.get("user_type") == USERTYPES.superadmin ? (
                            <th width="60" style={{ textAlign: "center" }}>
                                Select
                            </th>
                        ) : null}
                        {LS_SERVICE.get("user_type") == USERTYPES.doctor ? (
                            <th width="100" style={{ textAlign: "center" }}>
                                Favourite
                            </th>
                        ) : null}
                        <th width="80" style={{ textAlign: "center" }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody id="template-list">
                    <>
                        {this.state.NoRecords > 0 ? (
                            this.state.templateList.map((template, i) => {
                                var hospital = [];
                                var deactivatehospital = [];
                                if (
                                    LS_SERVICE.get("user_type") ==
                                    USERTYPES.superadmin
                                ) {
                                    var hospital_array =
                                        template?.hospital_id
                                            ?.split(",")
                                            .map((x) => +x) || [];
                                    var deactivatehospital_array =
                                        template?.deactivatedHospitalId
                                            ?.split(",")
                                            .map((x) => +x) || [];
                                    if (
                                        hospital_array &&
                                        hospital_array.length > 0
                                    ) {
                                        this.state?.hospitalList?.find(
                                            function (
                                                this_hospital,
                                                index
                                            ) {
                                                for (var hosp of hospital_array) {
                                                    if (
                                                        this_hospital.hospital_id ==
                                                        hosp
                                                    ) {
                                                        if (
                                                            deactivatehospital_array.includes(
                                                                hosp
                                                            )
                                                        ) {
                                                            deactivatehospital.push(
                                                                <li>
                                                                    <p
                                                                        style={{
                                                                            color: "#b2b5b8",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this_hospital.name
                                                                        }
                                                                    </p>
                                                                </li>
                                                            );
                                                        } else {
                                                            hospital.push(
                                                                <li>
                                                                    {
                                                                        this_hospital.name
                                                                    }
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                }
                                            }
                                        );

                                    }
                                    if(hospital_array[0] == -1){ hospital.push(<li>MHC Hospitals</li>) }
                                }
                                if(hospital?.length == 0){
                                  hospital.push(<li>MHC Hospitals</li>);
                                }
                                var doctor = [];
                                var doctor_array = template?.doctor_id
                                    ?.split(",")
                                    .map((x) => +x);
                                if (
                                    doctor_array &&
                                    doctor_array.length > 0
                                ) {
                                    this.state?.allDoctorList?.find(function (
                                        this_doctor,
                                        index
                                    ) {
                                        for (var hosp of doctor_array) {
                                            if (
                                                this_doctor.staff_id == hosp
                                            ) {
                                                doctor.push(
                                                    <li>
                                                        {
                                                            this_doctor.first_name
                                                        }
                                                    </li>
                                                );
                                            }
                                        }
                                    });
                                }
                                var speciality = [];
                                var speciality_array =
                                    template?.speciality_id
                                        ?.split(",")
                                        .map((x) => +x);
                                if (
                                    speciality_array &&
                                    speciality_array.length > 0
                                ) {
                                    this.state?.allSpecialityList?.find(
                                        function (this_speciality, index) {
                                            for (var hosp of speciality_array) {
                                                if (
                                                    this_speciality.speciality_id ==
                                                    hosp
                                                ) {
                                                    speciality.push(
                                                        <li>
                                                            {
                                                                this_speciality.name
                                                            }
                                                        </li>
                                                    );
                                                }
                                            }
                                        }
                                    );
                                }
                                if(speciality?.length == 0){
                                  speciality.push(<li>All Specialities</li>);
                                }
                                return (
                                    <Fragment key={i}>
                                        <tr>
                                            <td>{template.orderSetName}</td>

                                            {LS_SERVICE.get("user_type") ==
                                            USERTYPES.superadmin ? (
                                                <>
                                                    {hospital?.length > 0 ||
                                                    deactivatehospital?.length >
                                                        0 ? (
                                                        <td>
                                                            {/* {hospital.join(', ')} */}
                                                            {/* {(hospital?.length > 0 && deactivatehospital?.length > 0)?",":""}
                            <p style={{color:'#b2b5b8'}}>{deactivatehospital.join(', ')}</p> */}
                                                            <ul>
                                                                {hospital}
                                                                {
                                                                    deactivatehospital
                                                                }
                                                                
                                                            </ul>
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            -
                                                        </td>
                                                    )}
                                                </>
                                            ) : null}

                                            {LS_SERVICE.get("user_type") ==
                                                USERTYPES.superadmin ||
                                            LS_SERVICE.get("user_type") ==
                                                USERTYPES.admin ? (
                                                <>
                                                    {speciality?.length >
                                                    0 ? (
                                                        <td>
                                                            <ul>
                                                                {speciality}
                                                            </ul>
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            -
                                                        </td>
                                                    )}
                                                </>
                                            ) : null}

                                            {LS_SERVICE.get("user_type") ==
                                                USERTYPES.superadmin ||
                                            LS_SERVICE.get("user_type") ==
                                                USERTYPES.admin ? (
                                                <>
                                                    {doctor?.length > 0 ? (
                                                        <td>
                                                            <ul>
                                                                {doctor}
                                                            </ul>
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            -
                                                        </td>
                                                    )}
                                                </>
                                            ) : null}

                                            <td>
                                                {template.drug_count}{" "}
                                                {template.drug_count ===
                                                    1 ||
                                                template.drug_count === 0
                                                    ? "Medicine"
                                                    : "Medicines"}
                                            </td>

                                            <td>
                                                {template.test_count}{" "}
                                                {template.test_count ===
                                                    1 ||
                                                template.test_count === 0
                                                    ? "Test"
                                                    : "Tests"}{" "}
                                            </td>

                                            <td
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Button
                                                    type="link p-0"
                                                    className="nostyle-link view_order_set"
                                                    onClick={() =>
                                                        this.openView(
                                                            template.orderSetId
                                                        )
                                                    }
                                                >
                                                    <i className="icon_view"></i>
                                                </Button>
                                            </td>

                                            {LS_SERVICE.get("user_type") ==
                                            USERTYPES.superadmin ? (
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={true}
                                                        onClick={
                                                            this
                                                                .ParentOrdersetActivation
                                                        }
                                                        checked={
                                                            this.state[
                                                                "active_for_" +
                                                                    template.orderSetId
                                                            ]
                                                        }
                                                        name={
                                                            "active_for_" +
                                                            template.orderSetId
                                                        }
                                                        value={
                                                            template.orderSetId
                                                        }
                                                    ></Checkbox>
                                                </td>
                                            ) : null}

                                            {LS_SERVICE.get("user_type") ==
                                            USERTYPES.admin ? (
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={true}
                                                        onClick={
                                                            this
                                                                .SubOrdersetActivation
                                                        }
                                                        checked={
                                                            this.state[
                                                                "subactive_for_" +
                                                                    template.orderSetId
                                                            ]
                                                        }
                                                        name={
                                                            "subactive_for_" +
                                                            template.orderSetId
                                                        }
                                                        value={
                                                            template.orderSetId
                                                        }
                                                    ></Checkbox>
                                                </td>
                                            ) : null}

                                            {LS_SERVICE.get("user_type") ==
                                            USERTYPES.superadmin ? (
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Checkbox 
                                                        disabled={template.own_master == 'Y'} 
                                                        onClick={
                                                            this
                                                                .selectOrdersetForCopy
                                                        }
                                                        value={
                                                            template.orderSetId
                                                        }
                                                    ></Checkbox>
                                                </td>
                                            ) : null}
                                            {LS_SERVICE.get("user_type") ==
                                            USERTYPES.doctor ? (
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Button
                                                        type="link p-0"
                                                        className="nostyle-link"
                                                        onClick={(e) =>
                                                            this.TemplateOnFavourite(
                                                                template.orderSetId,
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                this.state
                                                                    .$isTestFavCheckedVal[
                                                                    i
                                                                ] === 1
                                                                    ? "icon_star favourite"
                                                                    : "icon_star star"
                                                            }
                                                        ></i>
                                                    </Button>
                                                </td>
                                            ) : null}
                                            <td
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <NavLink
                                                    to={{
                                                        pathname:
                                                            "/order-set/edit/" +
                                                            template.orderSetId,
                                                        state: { template },
                                                    }}
                                                    className="nostyle-link mr-2"
                                                >
                                                    <i className="icon-edit"></i>
                                                </NavLink>
                                                {LS_SERVICE.get(
                                                    "user_type"
                                                ) == USERTYPES.doctor ? (
                                                    <Button
                                                        type="link p-0"
                                                        className="nostyle-link data-delete"
                                                        onClick={() => {
                                                            this.setState({
                                                                templateId:
                                                                    template.orderSetId,
                                                            });
                                                            this.showModal();
                                                        }}
                                                    >
                                                        <i className="icon_delete"></i>
                                                    </Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                        <tr
                                            className="view_details"
                                            style={
                                                this.state.viewNo ===
                                                template.orderSetId
                                                    ? {
                                                          display:
                                                              "table-row",
                                                      }
                                                    : { display: "none" }
                                            }
                                        >
                                            <td
                                                colSpan={colspan}
                                                className="inner_table"
                                            >
                                                <div className="text-center">
                                                    {this.state.templateView
                                                        .length === 0
                                                        ? LOADER_RED
                                                        : null}
                                                </div>
                                                {this.templateViewDrugs()}
                                                {this.templateViewtests()}
                                                {this.templateViewInstruction()}
                                                {this.templateViewMaterial()}
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={colspan}>No Order Set</td>
                            </tr>
                        )}
                    </>
                </tbody>
            </table>
        );
    }

    // Open view orderset or not
    openView(no) {
        if (this.state.viewNo === no) {
            this.setState({ viewNo: 0, templateView: [] });
        } else {
            this.setState({ viewNo: no, templateView: [] });
            this.TemplateOnView(no);
        }
    }

    render() {
        const { hospitalList, specialityList, doctorList, ordersetList } =
            this.state;
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row" id="data-preloader">
                    {
                        this.state.listingLoader == true 
                    ? 
                        (
                            <div className="col-12 text-center">{LOADER_RED}</div>
                        )
                    : 
                        <>
                            <div className="col-lg-12 emrfrm">
                                <div className="px-2">
                                    <h1 className="page-title mb-3">
                                        Order Sets Lists
                                    </h1>

                                    {LS_SERVICE.get("user_type") ==
                                        USERTYPES.superadmin ||
                                    LS_SERVICE.get("user_type") ==
                                        USERTYPES.admin ? (
                                        <div className="row">
                                            <div className="col-12 col-md-3">
                                                <div className="form-group hasdata">
                                                    <Select
                                                       // showSearch
                                                        id="hospital_name"
                                                        placeholder=" "
                                                        disabled={LS_SERVICE.get("user_type") == USERTYPES.admin ? true : false}
                                                        name="hospital_name"
                                                        style={{ width: 100 + "%", }}
                                                        onChange={ this.selectHospitalFilterChange }
                                                        // mode={ LS_SERVICE.get('user_type') == USERTYPES.superadmin ? "multiple" : "single" }
                                                        mode="single"
                                                        className="remove_search_icon"
                                                        defaultValue={this.state?.filter_hospital_id ? parseInt(this.state?.filter_hospital_id) : null}
                                                        value={this.state?.filter_hospital_id ? parseInt(this.state?.filter_hospital_id) : null}
                                                        allowClear
                                                        filterOption={(input,option) => {
                                                            return (
                                                                option.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input
                                                                            .toString()
                                                                            .toLowerCase()
                                                                    ) >= 0
                                                            );
                                                        }}
                                                        notFoundContent={
                                                            this.state
                                                                .spinner ? (
                                                                <Spin size="small" />
                                                            ) : (
                                                                <div>
                                                                    No Result
                                                                    Found
                                                                </div>
                                                            )
                                                        }
                                                    >
                                                        <Option dataid={{hospital_id:-1}} value={-1} key={-1} own_master='N'>
                                                            MHC Hospitals
                                                        </Option>
                                                        {this.state
                                                            .hospitalList &&
                                                        hospitalList !==
                                                            undefined &&
                                                        hospitalList.length > 0
                                                            ? hospitalList.map(
                                                                  (d) => (
                                                                      <Option
                                                                          dataid={
                                                                              d
                                                                          }
                                                                          value={
                                                                              d.hospital_id
                                                                          } 
                                                                          own_master={
                                                                              d.own_master
                                                                            } 
                                                                          key={
                                                                              d.hospital_id
                                                                          }
                                                                      >
                                                                          {
                                                                              d.name
                                                                          }
                                                                      </Option>
                                                                  )
                                                              )
                                                            : null}
                                                    </Select>
                                                    <label htmlFor="hospital_name">
                                                        Hospital
                                                        <span className="text-danger"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 ">
                                                <div className="form-group ">
                                                    <Select
                                                        showSearch
                                                        id="speciality_name"
                                                        placeholder=" "
                                                        name="speciality_name"
                                                        style={{
                                                            width: 100 + "%",
                                                        }}
                                                        onChange={
                                                            this
                                                                .selectSpecialityFilterChange
                                                        }
                                                        className="drug-input Priority"
                                                        mode="multiple"
                                                        allowClear
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) => {
                                                            return (
                                                                option.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input
                                                                            .toString()
                                                                            .toLowerCase()
                                                                    ) >= 0
                                                            );
                                                        }}
                                                        notFoundContent={
                                                            this.state
                                                                .spinner ? (
                                                                <Spin size="small" />
                                                            ) : (
                                                                <div>
                                                                    No Result
                                                                    Found
                                                                </div>
                                                            )
                                                        }
                                                    >
                                                        {this.state
                                                            .specialityList &&
                                                        specialityList !==
                                                            undefined &&
                                                        specialityList.length >
                                                            0
                                                            ? specialityList.map(
                                                                  (d) => (
                                                                      <Option
                                                                          dataid={
                                                                              d
                                                                          }
                                                                          value={
                                                                              d.speciality_id
                                                                          }
                                                                          key={
                                                                              d.speciality_id
                                                                          }
                                                                      >
                                                                          {
                                                                              d.name
                                                                          }
                                                                      </Option>
                                                                  )
                                                              )
                                                            : null}
                                                    </Select>
                                                    <label htmlFor="speciality_name">
                                                        Speciality
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <Select
                                                        showSearch
                                                        id="doctor_name"
                                                        placeholder=" "
                                                        name="doctor_name"
                                                        style={{
                                                            width: 100 + "%",
                                                        }}
                                                        onChange={
                                                            this
                                                                .selectDoctorFilterChange
                                                        }
                                                        className=""
                                                        mode="multiple"
                                                        allowClear
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) => {
                                                            return (
                                                                option.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input
                                                                            .toString()
                                                                            .toLowerCase()
                                                                    ) >= 0
                                                            );
                                                        }}
                                                        notFoundContent={
                                                            this.state
                                                                .spinner ? (
                                                                <Spin size="small" />
                                                            ) : (
                                                                <div>
                                                                    No Result
                                                                    Found
                                                                </div>
                                                            )
                                                        }
                                                    >
                                                        {this.state
                                                            .doctorList &&
                                                        doctorList !==
                                                            undefined &&
                                                        doctorList.length > 0
                                                            ? doctorList.map(
                                                                  (d) => (
                                                                      <Option
                                                                          dataid={
                                                                              d
                                                                          }
                                                                          value={
                                                                              d.staff_id
                                                                          }
                                                                          key={
                                                                              d.staff_id
                                                                          }
                                                                      >
                                                                        Dr. {d.first_name} {d.last_name} {d.woodlands_mobile ? '('+d.woodlands_mobile+')' : null}
                                                                      </Option>
                                                                  )
                                                              )
                                                            : null}
                                                    </Select>
                                                    <label htmlFor="doctor_name">
                                                        Doctor's Name
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <Select
                                                        showSearch
                                                        id="owner_name"
                                                        placeholder=" "
                                                        name="owner_name"
                                                        style={{
                                                            width: 100 + "%",
                                                        }}
                                                        onChange={
                                                            this
                                                                .selectOwnerFilterChange
                                                        }
                                                        className=""
                                                        mode="multiple"
                                                        allowClear
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) => {
                                                            return (
                                                                option.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input
                                                                            .toString()
                                                                            .toLowerCase()
                                                                    ) >= 0
                                                            );
                                                        }}
                                                        notFoundContent={
                                                            this.state
                                                                .spinner ? (
                                                                <Spin size="small" />
                                                            ) : (
                                                                <div>
                                                                    No Result
                                                                    Found
                                                                </div>
                                                            )
                                                        }
                                                    >
                                                        {this.state
                                                            .doctorList &&
                                                        doctorList !==
                                                            undefined &&
                                                        doctorList.length > 0
                                                            ? doctorList.map(
                                                                  (d) => (
                                                                      <Option
                                                                          dataid={
                                                                              d
                                                                          }
                                                                          value={
                                                                              d.staff_id
                                                                          }
                                                                          key={
                                                                              d.staff_id
                                                                          }
                                                                      >
                                                                          Dr. {d.first_name} {d.last_name}
                                                                      </Option>
                                                                  )
                                                              )
                                                            : null}
                                                    </Select>
                                                    <label htmlFor="owner_name">
                                                        Owner
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="form-group">
                                        <label htmlFor="search-template">
                                            Search Order Sets
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search-template"
                                            placeholder="Search Order Sets"
                                            onChange={this.TemplateOnListLoad}
                                        />
                                    </div>

                                    <div
                                        className="table-responsive"
                                        id="order-set-tab"
                                    >
                                        {this.templateRowLoad()}
                                    </div>

                                    {LS_SERVICE.get("user_type") ==
                                    USERTYPES.superadmin ? (
                                        <>
                                            <div className="mt-2">&nbsp;</div>
                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="form-group">
                                                        <Select
                                                            showSearch
                                                            id="hospital_name"
                                                            placeholder=" "
                                                            name="hospital_name"
                                                            style={{
                                                                width:
                                                                    100 + "%",
                                                            }}
                                                            onChange={
                                                                this
                                                                    .selectHospitalChange
                                                            }
                                                            mode="multiple"
                                                            className=""
                                                            allowClear
                                                            defaultValue={
                                                                this.state
                                                                    .mapToHospitalDefault
                                                            }
                                                            value={
                                                                this.state
                                                                    .mapToHospitalDefault
                                                            }
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) => {
                                                                return (
                                                                    option.children
                                                                        .toString()
                                                                        .toLowerCase()
                                                                        .indexOf(
                                                                            input
                                                                                .toString()
                                                                                .toLowerCase()
                                                                        ) >= 0
                                                                );
                                                            }}
                                                            notFoundContent={
                                                                this.state
                                                                    .spinner ? (
                                                                    <Spin size="small" />
                                                                ) : (
                                                                    <div>
                                                                        No
                                                                        Result
                                                                        Found
                                                                    </div>
                                                                )
                                                            }
                                                        >
                                                            {this.state
                                                                .hospitalList &&
                                                            hospitalList !==
                                                                undefined &&
                                                            hospitalList.length >
                                                                0
                                                                ? hospitalList.map(
                                                                      (d) => (
                                                                        d.own_master == 'N' ? 
                                                                            <Option
                                                                                dataid={
                                                                                    d
                                                                                }
                                                                                value={
                                                                                    d.hospital_id
                                                                                }
                                                                                key={
                                                                                    d.hospital_id
                                                                                }
                                                                            >
                                                                                {
                                                                                    d.name
                                                                                }
                                                                            </Option> 
                                                                        : null
                                                                      )
                                                                  )
                                                                : null}
                                                        </Select>
                                                        <label htmlFor="hospital_name">
                                                            Map to Hospital
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <div className="text-right">
                                                        <Button
                                                            type="button"
                                                            className="btn btn-primary px-4 small text-uppercase"
                                                            id="drug-save"
                                                            data-drug="-1"
                                                            data-prescriptionid="-1"
                                                            disabled={
                                                                this.state
                                                                    .mapHospitalbuttonchk
                                                            }
                                                            onClick={(e) =>
                                                                this.mapToHospital(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    <div className="mt-5">&nbsp;</div>
                                </div>
                            </div>
                            <div className="fixed-cta full">
                                <NavLink
                                    to="/create-order-set"
                                    className="btn btn-primary px-3"
                                >
                                    CREATE ORDER SET
                                </NavLink>
                            </div>
                        </>
                    }
                    </div>
                </div>

                <Modal
                    id="modalConfirm"
                    className="templateModal"
                    title="Are you sure you want to delete?"
                    visible={this.state.isModalVisible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    closable={false}
                    footer={false}
                    width="400px"
                >
                    <div className="row">
                        <div className="col-12 text-center">
                            <Button
                                className="btn btn-outline-secondary px-4 mr-3 cancelOrderSet"
                                onClick={() => this.handleCancel()}
                            >
                                CANCEL
                            </Button>
                            <Button
                                className="btn btn-primary px-4"
                                type="primary"
                                onClick={() => this.handleOk()}
                            >
                                DELETE
                            </Button>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
export default Ordersetlist;
