import React, { Component } from 'react';
import { DOCTOR_LIST, HOSPITAL_DOCTOR_LIST, REFERENCE_TYPE, REFERAL_INFORMATION, SAVE_REFERRAL, DELETE_REFERRAL, SPECIALITY_LIST, LOADER_BLACK, PATIENT_DATA, LOADER, REFERRAL_LIST, TOGGLE_PRACTICE, HANDLEVALIDANTMESSAGES } from '../../utils/constant'
import LS_SERVICE from '../../utils/localStorage';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { DatePicker, Radio, Modal, Select, Button, Form, Checkbox, Input, notification } from 'antd';
import qs from 'qs';
import Axios from 'axios';
import { jQueryLabels } from '../../utils/jQueryLabels';
import API_SERVICE from '../../utils/apiService';
import { headerService$, practiceService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from '../speech-to-text/speech-recognition';
import { Link } from 'react-router-dom';

const { Option } = Select;

class ReferralComponentSS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            data_selected: "",
            speciality_selected_name: "",
            doctor_selected_name: "",
            doc_note: '',
            stage_radio: '1',
            isDocLIstFetching: false,
            isDoctorDataLoaded: false,
            docList: [],
            isDoc: false,
            tableListing: [],
            specialityList: [],
            isSpecLIstFetching: false,
            isSpecLIstLoaded: false,
            spec_selected: '',
            formSaving: false,
            speech: {
                identifier: null,
                state: false
            },
            isPracticing: false,
            isPracticingModal: false,
            addReferralModal: false,
            formModeEdit: false,
            referral_message: false,
            allowEdit: props.allowEdit,
        }
    }

    async componentDidMount() {
        let Elem = document.querySelectorAll('.autoHeightText');
        Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        const hospital_id = this.props.patient.qmsToken.hospital_id;
        const patient_id = this.props.patient.patient_id;
        const reference_id = this.props.patient.qms_token_id;

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id,
        }
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.handleSerachSpecList('');
                this.handleSerachDoctorList('');
                this.handleReferralInformation();
                jQueryLabels();

            }

            this.subscription = speechTextService.status().subscribe(data => {
                if (this.state.speech.identifier === 'doctors-note' && this.state.speech.state) {
                    this.setState({ doc_note: data.text });
                    document.querySelector("#doc_note").style.height = document.querySelector("#doc_note").scrollHeight + "px";
                    document.querySelector("#doc_note2").style.height = document.querySelector("#doc_note").scrollHeight + "px";
                }
                // document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
            });
        });

    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    handleRadio = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    handleNotes = (e) => {
        this.setState({ doc_note: e.target.value });
    }

    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'doctors-note') {
            initialText = this.state.doc_note != undefined ? this.state.doc_note : '';
        }

        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    handleDocListChange = async (doctor_selected, props) => {
        const allergy_selected_data = {};
        Object.assign(allergy_selected_data, props.dataid);

        await this.setState({
            data_selected: allergy_selected_data,
            speciality_selected_name: doctor_selected,
            doctor_selected_name: doctor_selected
            // allergy_selected_data
        });
    }

    handleReset = () => {
        this.setState({
            doc_note: '',
            priority: 0,
            stage_radio: '1',
            data_selected: null,
            speciality_selected_name: '',
            doctor_selected_name: '',
            formModeEdit: false,
            referral_message: false,
        })
    }

    handleSaveButtonDisabled = _ => {
        const { doc_note, speciality_selected_name, doctor_selected_name, formSaving, isDoc } = this.state;

        if (formSaving) return true;

        if (isDoc) {
            if (doc_note.length === 0 || doctor_selected_name.length === 0) return true;
        } else {
            if (doc_note.length === 0 || speciality_selected_name.length === 0) return true;
        }

        return false;
    }

    handleCancel = () => {
        this.setState({ addReferralModal: false });
    }

    handleSerachSpecList = search => {
        this.setState({ specialityList: [], isSpecLIstFetching: true });

        const PARAMS = { search }

        Axios.post(SPECIALITY_LIST, qs.stringify(PARAMS))
            .then(res => {
                this.setState({
                    specialityList: res.data.status ? res.data.data.results : [],
                    isSpecLIstFetching: false,
                    isSpecLIstLoaded: true
                })
            })

        // if (docDataPromise.status) {
        //     let { result } = docDataPromise.data;
        //     this.setState({ docList: result, isDocLIstFetching: !this.state.formIsFetching });
        // }
    }

    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }

    handleSerachDoctorList = search => {
        this.setState({ docList: [], isDocLIstFetching: true });
        const doctor_id = LS_SERVICE.get("staff_id");
        const PARAMS = { search : search, hospital_id: this.state.hospital_id, doctor_id: doctor_id }
        Axios.post(HOSPITAL_DOCTOR_LIST, qs.stringify(PARAMS))
            .then(res => {
                this.setState({
                    docList: res.data.status ? res.data.data.results : [],
                    isDocLIstFetching: false,
                    isDoctorDataLoaded: true
                })
            })
            .catch(err => {
                this.setState({
                    docList: [],
                    isDocLIstFetching: false,
                    isDoctorDataLoaded: true
                })
            })
    }

    hadleRefferalForm = (e) => {
        e.preventDefault();
        this.handleReset();
        this.setState({ addReferralModal: true, formModeEdit: false, referral_message: false, isDoc: false });
    }

    handleEditDelete = (e, isDelete, data) => {
        e.preventDefault();
        // const { id } = e.target.parentElement.parentElement.dataset;
        const { tableListing } = this.state;
        // const data = tableListing.filter(item => id == item.id)[0];

        if (!isDelete) {
            const stage_radio = data.priorityname == "Urgent" ? '0' : '1';
            this.setState({
                doc_note: data.instructions,
                stage_radio
            })

            if (data.doctor.doctor_id != null) {
                this.setState({
                    data_selected: { ...data.doctor },
                    isDoc: true,
                    doctor_selected_name: data.doctor.doctor_name + ' (' + data.speciality.name + ')'
                })
            } else {
                this.setState({
                    data_selected: { ...data.speciality },
                    isDoc: false,
                    speciality_selected_name: data.speciality.name
                })
            }
            this.setState({ addReferralModal: true, formModeEdit: true });
        } else {
            Axios.post(DELETE_REFERRAL, qs.stringify({ id: data.id }))
                .then(res => {
                    this.handleReferralInformation();
                    notification.success({
                        message: 'Referral Deleted Succesfully',
                        placement: 'topRight'
                    });
                })
        }
    }

    handleTabChange = e => {
        e.preventDefault();

        this.setState({
            isDoc: !this.state.isDoc,
        }, this.handleReset)
    }

    handleReferralSave = () => {

        const { stage_radio, isDoc, doc_note, data_selected, patient, speech } = this.state;

        if (speech.state) {
            this.initSpeech({ identifier: 'doctors-note' })
        }

        this.setState({
            formSaving: true
        })

        const PARAMS = {
            type: (data_selected.speciality_id == null) ? "doctor" : "speciality",
            priority: stage_radio,
            instructions: doc_note,
            referenceType: REFERENCE_TYPE,
            referenceId: patient.qms_token_id,
            referalDoctorId: null,
            referid: null,
        }

        if (PARAMS.type === 'speciality') {
            PARAMS.speciality = data_selected.speciality_id
        } else {
            PARAMS.doctor = data_selected.doctor_id
        }

        Axios.post(SAVE_REFERRAL, qs.stringify(PARAMS))
            .then(res => {
                this.handleReferralInformation();
                
                if (this.state.formModeEdit) {
                    this.setState({
                        referral_message: false,
                        addReferralModal: false,
                        formSaving: false
                    });
                    notification.success({
                        message: 'Referral Updated Succesfully',
                        placement: 'topRight'
                    });
                }
                else
                {
                    this.setState({
                        formSaving: false
                    }, this.handleReset)
                    this.setState({
                        referral_message: true,
                    });
                    setTimeout(() => {
                        this.setState({referral_message: false});
                    }, 3000);
                }
            })
            .catch(err => {
                this.setState({
                    tableListing: [],
                }, this.handleReset)
            })
    }

    handleOnClick = _ => {
        this.setState({
            isDoctorDataLoaded: false
        })
    }

    handleReferralInformation = _ => {
        const { patient } = this.state;

        const PARAMS = {
            referenceType: REFERENCE_TYPE,
            referenceId: patient.qms_token_id
        }

        Axios.post(REFERRAL_LIST, qs.stringify(PARAMS))
            .then(res => {
                this.setState({
                    tableListing: res.data.data?.results,
                })
            })
    }

    render() {
        const { patient, docList, isDocLIstFetching, stage_radio, tableListing, doc_note, isDoc, specialityList,
            isDoctorDataLoaded, speciality_selected_name, doctor_selected_name, formModeEdit } = this.state;
        return (
          <>
          <div className='container-fluid  new_referral my-2'>
          <div className='row'>
                <div className='col-xl-12 col-lg-4'>
                    {/* <MyHcSpeechRecognition /> */}
                    <div className='container-fluid referral-head mt-0' >
                        <div className='row'>
                            <div className='col-7 d-flex align-items-center'>
                                <h6 className='mb-0'>REFERRAL</h6>
                            </div>
                            {this.state.allowEdit && (
                                <div className='col-5 text-right pr-5'>
                                    <a class="btn btn-outline-primary small px-2" id="referral" onClick={(e) => this.hadleRefferalForm(e)}>+ REFERRAL</a>
                                </div>
                            )}
                        </div>
                    </div>

                    {tableListing?.length > 0 && tableListing?.map((item, i) => (
                        <div className='row mt-2' id="referral" key={item.id}>
                            <div className='col-12 mb-1'>
                                <div class="card reff_card rounded-0">
                                    <div class="card-body single-referral-card">
                                        <a href="#" class="nostyle-link text-dark">
                                            <div class="row">
                                                <div class="col-10 col-md-11">
                                                    <span class="font-weight-semibold">REFERRAL {i + 1}</span>
                                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">Speciality : <strong>{item.speciality.name != '' ? item.speciality.name : "-"}</strong></span>
                                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Name : <strong>{item.doctor.doctor_name != '' ? item.doctor.doctor_name : '-'}</strong> </span>

                                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">Priority : <span className="text-danger">{item.priorityname}</span></span>

                                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Note : <strong>{item.instructions}</strong></span>
                                                </div>
                                                {this.state.allowEdit ? (
                                                <div class="col-2 col-md-1 align-self-center text-right">
                                                    
                                                        <a className='btn nostyle-link p-0 mr-1' href="#" onClick={(e) => this.handleEditDelete(e, false, item)}>
                                                            <i className="icon-edit" />
                                                        </a>
                                                        <a className='btn nostyle-link p-0 ml-1' href="#" onClick={(e) => this.handleEditDelete(e, true, item)}>
                                                            <i className="icon_delete" />
                                                        </a>
                                                </div>
                                                ) : null}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
          </div>
           

                {/* Referral Modal */}

                <Modal
                    title={`${formModeEdit ? "Edit " : "Add "} Referral`}
                    visible={this.state.addReferralModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="700px"

                >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-pills justify-content-center myhealthcare-tabs">
                                        <li className="nav-item">
                                            <a href="#" className={`nav-link ${!isDoc ? 'active' : ''}`} onClick={this.handleTabChange}>
                                                By Speciality
                                        </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className={`nav-link ${isDoc ? 'active' : ''}`} onClick={this.handleTabChange}>
                                                By Doctor
                                        </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className={`tab-pane fade ${!isDoc ? 'show active' : ""} `} id="pills-speciality" role="tabpanel" aria-labelledby="pills-Speciality-tab">
                                            <Form
                                                className="emrfrm"
                                                ref={this.specialityRef}
                                            >
                                                <div className="row mt-4">
                                                    <div className="col-md-12">

                                                        <Form.Item
                                                            id="speciality_selected"
                                                            name="speciality_selected"
                                                            label="Speciality"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <span className="d-none">{speciality_selected_name}</span>
                                                            <Select
                                                                showSearch
                                                                autoFocus
                                                                value={speciality_selected_name}
                                                                optionFilterProp="children"
                                                                notFoundContent={null}
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onChange={this.handleDocListChange}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {specialityList !== undefined && specialityList.length > 0 ? specialityList.map(d => (
                                                                    <Option key={d.speciality_id} dataid={d} label={d.name}>{d.name}</Option>
                                                                )) : null}
                                                            </Select>

                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mt-1">
                                                            <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="stage_urgent" name="stage_radio" className="custom-control-input float-right"
                                                                    value='0' checked={0 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_urgent">Urgent</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="stage_routine" name="stage_radio" className="custom-control-input float-right"
                                                                    value='1' checked={1 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_routine">Routine</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className={`form-group micwrap ant-mic-wrap ${doc_note !== "" ? "hasdata" : ""}`}>
                                                            <Link
                                                                onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'doctors-note' }) }}
                                                                className={(this.state.speech.identifier === 'doctors-note' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                                            {/* <label htmlFor="doctors-note" className="control-label">Chief Complaints <span className="text-danger">*</span></label> */}
                                                            <textarea id="doc_note" className="form-control diagnosis_notes_class speech-field autoHeightText" onInput={(e) => this.autoHeight(e)} rows="1" placeholder="Doctor's Note"
                                                                value={doc_note} onChange={this.handleNotes} />
                                                            <label htmlFor="doctor-note" className="required">
                                                                Doctor's Note<span>*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group mb-0 mt-2 text-center">
                                                            <button type="button" className="btn btn-outline-secondary  mr-3" onClick={this.handleReset}>CLEAR</button>
                                                            <button type="button" className="btn btn-primary text-uppercase" disabled={this.handleSaveButtonDisabled()}
                                                                onClick={this.handleReferralSave}>
                                                                Save{this.state.formSaving ? LOADER : null}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-center mt-1">
                                                    {this.state.referral_message ?
                                                        <span style={{ color: '#548f3a' }}>Referral Added Successfully</span>
                                                        :
                                                        null
                                                    }
                                                </div>

                                            </Form>
                                        </div>
                                        <div className={`tab-pane fade ${isDoc ? 'show active' : ""} `} id="pills-doctor" role="tabpanel" aria-labelledby="pills-Doctor-tab">
                                            <Form
                                                className="emrfrm"
                                            >
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <Form.Item
                                                            id="doctor_selected"
                                                            name="doctor_selected"
                                                            label="Doctor's Name"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <span className="d-none">{doctor_selected_name}</span>
                                                            <Select
                                                                showSearch
                                                                autoFocus
                                                                value={doctor_selected_name}
                                                                optionFilterProp="children"
                                                                notFoundContent={null}
                                                                onChange={this.handleDocListChange}
                                                                filterOption={(input, option) => {
                                                                    return (
                                                                    option.children
                                                                        .toString()
                                                                        .toLowerCase()
                                                                        .indexOf(input.toString().toLowerCase()) >= 0
                                                                    );
                                                                }}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {docList !== undefined && docList.length > 0 ? docList.map(d => (
                                                                    <Option key={d.staff_id} dataid={d} label={d.first_name +(d.last_name ? ' '+ d.last_name :'') + (d.speciality_name ? '(' + d.speciality_name + ')': '')}>{d.first_name +(d.last_name ? ' '+ d.last_name :'') + (d.speciality_name ? '(' + d.speciality_name + ')': '')}</Option>
                                                                )) : null}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mt-1">
                                                            <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="stage_urgent" name="stage_radio" className="custom-control-input float-right"
                                                                    value='0' checked={0 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_urgent">Urgent</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="stage_routine" name="stage_radio" className="custom-control-input float-right"
                                                                    value='1' checked={1 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_routine">Routine</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className={`form-group ant-mic-wrap micwrap ${doc_note != "" ? "hasdata" : ""}`}>
                                                            <Link
                                                                onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'doctors-note' }) }}
                                                                className={(this.state.speech.identifier === 'doctors-note' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                                            <textarea id="doc_note2" className="form-control diagnosis_notes_class speech-field autoHeightText" onInput={(e) => this.autoHeight(e)} rows={1} placeholder="Doctor's Note"
                                                                value={doc_note} onChange={this.handleNotes} />
                                                            <label htmlFor="doctor-note2" className="required">
                                                                Doctor's Note<span>*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group mb-0 mt-2 text-center">
                                                            <button type="button" className="btn btn-outline-secondary  mr-3" onClick={this.handleReset}>CLEAR</button>
                                                            <button type="button" className="btn btn-primary text-uppercase" disabled={this.handleSaveButtonDisabled()}
                                                                onClick={this.handleReferralSave}>
                                                                Save{this.state.formSaving ? LOADER : null}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-1">
                                                    {this.state.referral_message ?
                                                        <span style={{ color: '#548f3a' }}>Referral Added Successfully</span>
                                                        :
                                                        null
                                                    }
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>
            </>
        );
    }
}

export default ReferralComponentSS;