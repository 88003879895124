import React, { Component, Fragment, createRef } from 'react';
import { DatePicker, Radio, notification, Modal, Select, Button, Form, Checkbox, Input, AutoComplete } from 'antd';
import AsideLeft from '../../components/aside/asideleft';
import VitalsSection from './assessment/vitals-section';
import PatientHistory from './assessment/patient-history-section';
import AssessmentTable from './assessment/assessment-table';
import EditImg from '../../assets/images/ic-edit.png';
import LS_SERVICE from '../../utils/localStorage';
import AppHelpers from '../../utils/AppHelpers';
import qs from 'qs';
import axios from 'axios';
import moment from 'moment';
import { REFERENCE_TYPE, LOADER_RED, FORM_TYPE, NST_RADIO_VALUE, EVALUTION_INTERPRETATION as RISK, BMI_CALCULATE, BMI_SCORE_CALCULATE, SOAP_OBJECTIVE_DETAILS, PATIENT_DATA, STORE_FALL_RISK_ASSESSMENT, STORE_NUTRITION_SCREENING_TOOL, STORE_NURSE_ASSESSMENT, GET_ALL_ASSESSMENT, LOADER_BLACK, USERTYPES, DRUG, FETCH_DEFAULT_CORRECTION_DOSE, HOSPITALDEFAULTS, SEARCH_DRUGS, MEDICINES_GETALL_CURRENT_MEDICINES, ADD_DRUGS, DRUGS_CONSTANT, LAB_TEST_CONSTANTS, MEDICINES_GETALL, ASSESSMENT_SCORE_DETAILS, STORE_ASSESSMENT_REPORT, STORE_ASSESSMENT_MEDICINE, GET_MODEOF_ARRIVAL } from '../../utils/constant';
import { AntSelectLabels, AntClearLabels, jQueryLabels } from '../../utils/jQueryLabels';
// import { headerService$, practiceService, speechService, speechTextService } from '../../utils/rxjs-sharing';
const { Option } = Select;

export default class AssessmentFormRfh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            reviewTxt: '',
            is_recall_doctor: 0,
            dataLoading: true,
            details: [],
            constants: {},
            field_status: [],
            is_vitals_available: false,
            currentMeds: [],
            currentMedsLists: [],
            constantCurrentMeds: [],
            target_blood_sugar_max: null,
            target_blood_sugar_min: null,
            drug_days_in_week: [],
            drug_days_in_week_taper: [],
            drugDaysInWeek: {},

            pagination: null,
            activePage: 1,
            allAssessmentArr: [],
            selected_drug: '',
            since_day: '',
            last_dose_date: '',
            pain_score: '',
            pain_status: '',
            nut_score: '',
            nut_status: '',
            datetime_arrival: '',
            mode_arrival: null,
            mode_arrival_array: [],
            selected_dosage: '',

            formType: "fall-risk-assessment",
            // formType: "nutrition-screen-tool",
            // formType: 'nursing-assessment-report',
            hospital_id: null,
            patient_id: null,
            reference_id: null,
            formMode: "SAVE",
            is_authorize: 0,
            formSubmitted: false,
            edit_assessment_id: null,

            fra_field_arr: [],
            fra_any_checked: false,
            fra_not_applicable: null,
            fra_history_falls: null,
            fra_help_toilet: null,
            fra_help_moving: null,
            fra_affecting_mobility: null,
            fra_peripheral_neuropathy: null,
            fra_gait_imbalance: null,
            fra_side_weakness: null,
            fra_lower_ext_weakness: null,
            fra_well_oriented: null,
            fra_forgets_limitations: null,
            fra_psychotropics: null,
            fra_total_score: null,
            fra_interpretation: null,

            nst_field_arr: [],
            nst_height: null,
            nst_weight: null,
            nst_bmi: null,
            nst_bmi_score: null,
            nst_lost_weight: null,
            nst_weight_range: null,
            nst_gained_weight: null,
            nst_nutritional_score: 0,
            nst_visit_date: null,
            nst_screen_valid_date: null,
            showAddDrugButton: true,
            formIsFetching: false,
            MedicineAddInitialListed: props.Input_data ? props.Input_data : [],
            drugDosage: {},
            DRUGSConstants: [],
            homeMedication: [],
            drugDailyFrequency: {},
            drug_id_list: [],
            medicineFormValidate: true,
            drug_dosage_form_text: null,
            drug_daily_frequency: '',
            allowEdit: true,
            AddMedicineButton: true,
            editData: null,
            editMedicationInfo: null,
            editMedicationId: null
        }
        this.assessmentFormRef = createRef();
    }

    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;
        const ss_configuration = LS_SERVICE.get('ss_configuration');
        const patient_full_data = LS_SERVICE.get('call_patient_detail');
        this.setState({
            hospital_id, patient_id, reference_id,
            ss_configuration: ss_configuration,
            consult_type: patient_full_data?.consult_type == 1 ? 'vc' : 'opd'
            // consult_type: 'vc'
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                //   const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                //   let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                //   headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.getPatientData();
                this.getAllAssessment();
                //   this.handleSubjectivesData();
                //   SoapFlow.hospital(+hospital_id);
                //   this.loadInstructionData();
            }
        });
        await this.loadData();
        await this.MasterDrugApi('');
        this.getCurrentMedicines();
        await this.getModeOfArrival();
    }

    loadData = async () => {
        let { constants } = this.state;
        const drugsConstantsPromise = await axios.get(DRUGS_CONSTANT);
        const testConstantsPromise = await axios.get(LAB_TEST_CONSTANTS);

        axios.all([drugsConstantsPromise, testConstantsPromise]).then(
            axios.spread((...responses) => {
                const responseOne = responses[0];
                const responseTwo = responses[1];

                if (responseOne.data.hasOwnProperty("data") === true) {
                    constants = responseOne.data.data;
                }
                if (responseTwo.data.hasOwnProperty("data") === true) {
                    constants["radiologyViews"] = responseTwo.data.data;
                }
                this.setState({
                    dataLoaded: true,
                    constants,
                    DRUGSConstants: constants?.dailyFrequency
                });
            })
        );
    };

    getPatientData = async _ => {
        const { formType, reference_id } = this.state;
        await axios.get(PATIENT_DATA({ reference_id })).then(success => {
            const patient = { ...success.data.data.results };
            const reviewTxt = success.data.data.results.visit.review_notes;
            const is_recall_doctor = success.data.data.results?.qmsToken?.is_recall_doctor;
            this.setState({
                patient,
                reviewTxt,
                is_recall_doctor,
                nst_visit_date: success?.data?.data?.results?.qmsToken?.Booking?.booking_date || new Date(),
                target_blood_sugar_max: success?.data?.data?.results?.target_blood_sugar_max,
                target_blood_sugar_min: success?.data?.data?.results?.target_blood_sugar_min
            }, () => {
                this.initialSetup();
            })
        }).catch(err => console.log(err));
    }

    setFormtype(val) {
        this.setState({ formType: val });
        if (val === "nursing-assessment-report") {
            const { patient_id, hospital_id, reference_id } = this.state;
            const PARAMS = { patient_id, reference_id, reference_type: REFERENCE_TYPE };
            axios.post(ASSESSMENT_SCORE_DETAILS, qs.stringify(PARAMS)).then((res) => {
                if (res.data.status == true) {
                    this.setState({
                        nut_score: res.data.data.nutritionScreenScore.nutritional_score,
                        nut_status: res.data.data.nutritionScreenScore.nutritional_status
                    });
                }
            });
        }
    }

    setPainScreening(val) {
        this.setState({
            pain_score: val
        });

        if (val == 1) {
            this.setState({
                pain_status: 'Good'
            });
        }
    }

    initialSetup = () => {
        const { patient_id, hospital_id, reference_id } = this.state;
        const PARAMS = {
            patient_id, reference_id, hospital_id,
            reference_type: REFERENCE_TYPE,
        }

        axios.post(SOAP_OBJECTIVE_DETAILS, qs.stringify(PARAMS)).then((res) => {
            const { details, field_status } = res.data;
            // let { followUpRadio, follow_up_days, follow_up_Others, otherVal } = this.state;

            // let followUpType = null;
            // let followUpDays = null;
            // if (details.followUp !== null) {
            //     followUpType = details.followUp?.followUpType;
            //     followUpDays = details.followUp?.followUpDays;

            //     if (followUpType !== 0 && followUpType !== undefined) {
            //         followUpRadio = -1;
            //         otherVal = followUpDays;
            //         follow_up_Others = DURATION_TYPE_OBJECT_REVERSE[followUpType]?.toLowerCase();
            //     } else if (followUpDays !== undefined) {
            //         follow_up_days = followUpDays;
            //         followUpRadio = followUpDays
            //     }
            // }

            // const bookNowAllowFn = _ => {
            //     if (otherVal !== null || follow_up_days !== null) {
            //         if (otherVal > 0) return false;
            //         if (follow_up_days > 0) return false;
            //     }
            //     return true;
            // }

            let height = null, weight = null, bmi = null, bmi_score = null;
            if (details?.visitVitals && Object.keys(details?.visitVitals).length > 0) {
                let vitals = Object.values(details.visitVitals);
                height = vitals[0]['V001'] || null;
                weight = vitals[0]['V002'] || null;
                bmi = vitals[0]['V003'] || null;
                bmi_score = bmi ? BMI_SCORE_CALCULATE(bmi) : null;

                const nstFormFieldValues = {
                    nst_height: height,
                    nst_weight: weight,
                    nst_bmi: bmi,
                    nst_bmi_score: bmi_score,
                }
                this.assessmentFormRef.current.setFieldsValue(nstFormFieldValues);

                this.setState({
                    details,
                    constants: details.constants,
                    field_status,
                    dataLoading: false,
                    nst_height: height,
                    nst_weight: weight,
                    nst_bmi: bmi,
                    nst_bmi_score: bmi_score,
                    is_vitals_available: height && weight,
                    // physiotherapy_advised: details?.physiotherapyAdvised === null || details?.physiotherapyAdvised === 0 ? 2 : 1,
                    // admissionChecked: details?.admissionAdvised == 1 ? true : false,
                    // physioText: details?.physiotherapyComment ?? '',
                    // admissionComment: details?.admissionComment ?? '',
                    // isAdmissionAdvisedPriority: details.admissionAdvisedPriority == null || details.admissionAdvisedPriority == 0 ? false : true,
                    // followUpRadio,
                    // otherVal,
                    // follow_up_Others,
                    // follow_up_days,
                    // bookNowAllow: bookNowAllowFn(),
                });
            }
            else {
                console.log('details visitVitals not available!');
            }
        }).catch((err) => {
            console.log('initial-setup-error:', err.data);
        })
    }

    getAllAssessment = async (getPagination = 1) => {
        try {
            const getAllAsmt = await axios.get(GET_ALL_ASSESSMENT({ page: getPagination, patient_id: +this.state.patient_id, reference_id: +this.state.reference_id }));
            if (getAllAsmt?.data?.status) {
                this.setState({
                    allAssessmentArr: getAllAsmt?.data?.data || [],
                    pagination: getAllAsmt?.data?.pagination || null,
                });
            }
        } catch (err) {
            console.log('get-all-assessment-error:', err);
        }
    }

    navigateDocumentToPage = (stratIndex) => {
        if (stratIndex) {
            // this.setState({ activePage: stratIndex });
            this.getAllAssessment(stratIndex);
        }
    }

    setFRAScore = (e) => {
        let { fra_field_arr } = this.state;
        let newFraArr = fra_field_arr;
        let score = null, interpretation = null;
        if (e.id === "fra_not_applicable" && e.checked == true) {
            score = 0;
            newFraArr.push(e.id);
        }
        else if (e.id === "fra_not_applicable" && e.checked == false) {
            score = null;
            newFraArr = newFraArr.filter(item => item !== e.id);
        }
        else if (e.checked == true) {
            score = 1;
            newFraArr.push(e.id);
        }
        else if (e.checked == false) {
            score = 1;
            newFraArr = newFraArr.filter(item => item !== e.id);
        }

        interpretation = score === 1 && newFraArr.length > 0 ? "High Risk" : score === 0 ? "Low Risk" : "";
        score = newFraArr.length > 0 ? score : "";
        this.setState({
            fra_total_score: score,
            fra_any_checked: newFraArr.length > 0 ? true : false,
            fra_interpretation: interpretation,
            fra_field_arr: newFraArr,
        });

        const fraFormFieldValues = {
            fra_total_score: score,
            fra_interpretation: interpretation
        }
        this.assessmentFormRef.current.setFieldsValue(fraFormFieldValues);
    }

    editForm = async (editObj) => {
        if (editObj?.is_authorize) {
            return
        }
        if (editObj?.risk_type == 1) {
            let editFraData = editObj?.FallRiskAssessment;
            let fraFormFieldOnly = {
                fra_not_applicable: editFraData.not_applicable || null,
                fra_history_falls: editFraData.history_falls || null,
                fra_help_toilet: editFraData.help_toilet || null,
                fra_help_moving: editFraData.help_moving || null,
                fra_affecting_mobility: editFraData.affecting_mobility || null,
                fra_peripheral_neuropathy: editFraData.peripheral_neuropathy || null,
                fra_gait_imbalance: editFraData.gait_imbalance || null,
                fra_side_weakness: editFraData.side_weakness || null,
                fra_lower_ext_weakness: editFraData.lower_ext_weakness || null,
                fra_well_oriented: editFraData.well_oriented || null,
                fra_forgets_limitations: editFraData.forgets_limitations || null,
                fra_psychotropics: editFraData.psychotropics || null
            }

            /*-------------------------- filter fra form field arr --------------------------*/
            let nonNullKeys = { ...fraFormFieldOnly };
            Object.keys(nonNullKeys).forEach((key) => (nonNullKeys[key] == undefined) && delete nonNullKeys[key]);
            nonNullKeys = Object.keys(nonNullKeys);
            /*-------------------------- filter fra form field arr --------------------------*/

            fraFormFieldOnly.fra_total_score = editFraData.total_score;
            fraFormFieldOnly.fra_interpretation = editFraData.interpretation || null;

            // ------------------------- set state
            let statObj = fraFormFieldOnly;
            statObj.edit_assessment_id = editObj.id;
            statObj.formType = "fall-risk-assessment";
            statObj.formMode = "UPDATE";
            statObj.fra_field_arr = nonNullKeys;
            statObj.fra_any_checked = nonNullKeys.length > 0 ? true : false;
            this.setState(statObj, () => {
                this.assessmentFormRef.current.setFieldsValue(fraFormFieldOnly);
            });
        }
        else if (editObj?.risk_type == 2) {
            let editNstData = editObj?.NutritionScreeningTool;
            let nstFormFieldOnly = {
                nst_lost_weight: editNstData.lost_weight,
                nst_gained_weight: editNstData.gained_weight,
                nst_weight_range: editNstData.weight_range,
                nst_loss_appetite: editNstData.loss_appetite,
                nst_edema: editNstData.edema,
                nst_nausea_vomitin: editNstData.nausea_vomitin,
                nst_diarrhea: editNstData.diarrhea
            }

            /*-------------------------- filter fra form field arr --------------------------*/
            let nonNullKeys = { ...nstFormFieldOnly };
            nonNullKeys = Object.entries(nonNullKeys).filter(([key, value]) => value !== null).map(([key, value]) => ({ [key]: value }));
            /*-------------------------- filter fra form field arr --------------------------*/

            nstFormFieldOnly.nst_height = editNstData.height;
            nstFormFieldOnly.nst_weight = editNstData.weight;
            nstFormFieldOnly.nst_bmi = editNstData.bmi;
            nstFormFieldOnly.nst_bmi_score = editNstData.bmi_score;
            nstFormFieldOnly.nst_loss_weight = editNstData.loss_weight;
            nstFormFieldOnly.nst_gain_weight = editNstData.gain_weight;
            nstFormFieldOnly.nst_bmi_score = editNstData.bmi_score;
            nstFormFieldOnly.nst_loss_weight = editNstData.loss_weight;
            nstFormFieldOnly.nst_gain_weight = editNstData.gain_weight;
            nstFormFieldOnly.nst_nutritional_score = editNstData.nutritional_score;
            nstFormFieldOnly.nst_nutritional_status = editNstData.nutritional_status;
            nstFormFieldOnly.nst_nutritional_score = editNstData.nutritional_score;
            nstFormFieldOnly.nst_nutritional_status = editNstData.nutritional_status;
            nstFormFieldOnly.nst_interpretation = editNstData.interpretation;
            nstFormFieldOnly.nst_implication = editNstData.implication;
            nstFormFieldOnly.nst_action = editNstData.action;
            nstFormFieldOnly.nst_remarks = editNstData.remarks;
            nstFormFieldOnly.nst_care_education = editNstData.care_education;
            nstFormFieldOnly.nst_screen_valid_date = moment(editNstData.screen_valid_date).format('YYYY-MM-DD') || null;
            nstFormFieldOnly.nst_visit_date = moment(editNstData.visit_date).format('YYYY-MM-DD') || null;

            // ------------------------- set form and state -------------------------
            let statObj = { ...nstFormFieldOnly };
            statObj.edit_assessment_id = editObj.id;
            statObj.formType = "nutrition-screen-tool";
            statObj.formMode = "UPDATE";
            statObj.nst_field_arr = nonNullKeys;
            // statObj.nst_any_checked = nonNullKeys.length > 0 ? true : false;
            this.setState(statObj, () => {
                this.assessmentFormRef.current.setFieldsValue(nstFormFieldOnly);
            });
        }
        else if (editObj?.risk_type == 3) {
            let nursingAssessmentReport = editObj?.NursingAssessmentRecord;
            const assessmentMedicine = await axios.get(STORE_ASSESSMENT_MEDICINE + '/' + nursingAssessmentReport.assessment_id + '/' + nursingAssessmentReport.id);
            this.setState({
                edit_assessment_id: editObj.id,
                formType: 'nursing-assessment-report',
                formMode: 'UPDATE',
                datetime_arrival: moment(nursingAssessmentReport.datetime_arrival).format('YYYY-MM-DD') || null,
                mode_arrival: nursingAssessmentReport.mode_arrival || null,
                pain_score: nursingAssessmentReport.pain_score || null,
                pain_status: nursingAssessmentReport.pain_status || null,
                homeMedication: assessmentMedicine.data.data || []
            }, () => {
                let narFormFieldOnly = {
                    mode_arrival: nursingAssessmentReport.mode_arrival || null
                }
                this.assessmentFormRef.current.setFieldsValue(narFormFieldOnly);
            });

            this.setFormtype(this.state.formType);
        }
    }

    setNSTScore = (e, otherFieldName = null) => {
        let { nst_field_arr } = this.state;
        var updatedNSTArr = nst_field_arr;
        // get field name & value
        if (otherFieldName) {
            // for nst_weight_range field
            var fieldValue = e;
            var fieldName = otherFieldName;
        } else {
            var fieldValue = parseInt(e.target.value);
            var fieldName = e.target.name;
        }

        if (updatedNSTArr.length) {
            let indexToUpdate = updatedNSTArr.findIndex(obj => obj.hasOwnProperty(fieldName));
            if (indexToUpdate !== -1) {
                updatedNSTArr[indexToUpdate][fieldName] = fieldValue;
            }
            else {
                updatedNSTArr.push({ [fieldName]: fieldValue });
            }
        }
        else {
            updatedNSTArr.push({ [fieldName]: fieldValue });
        }

        if (fieldName == "nst_lost_weight" && [0, 2].includes(fieldValue)) {
            let lostObj = {};
            if (updatedNSTArr.find(item => "nst_weight_range" in item)) {
                let filterArr = updatedNSTArr.filter(obj => !("nst_weight_range" in obj));
                updatedNSTArr = filterArr;
                lostObj.nst_weight_range = null;
            }

            if (updatedNSTArr.find(item => "nst_gained_weight" in item)) {
                let filterArr = updatedNSTArr.filter(obj => !("nst_gained_weight" in obj));
                updatedNSTArr = filterArr;
                lostObj.nst_gained_weight = null;
            }
            this.setState({ nst_weight_range: null, nst_gained_weight: null });
            this.assessmentFormRef.current.setFieldsValue(lostObj);
        }

        let newNutriScore = updatedNSTArr.reduce((acc, obj) => {
            let values = Object.values(obj);
            values = Object.keys(obj)[0] === "nst_weight_range" ? NST_RADIO_VALUE[values[0]] : values[0];
            return acc + parseInt(values);
        }, 0);

        this.setState({
            [fieldName]: fieldValue,
            nst_field_arr: updatedNSTArr,
            nst_nutritional_score: newNutriScore,
        });

        const nstFormFieldValues = {
            nst_nutritional_score: newNutriScore,
            nst_action: newNutriScore > 2 ? RISK.YES.nst_action : RISK.NO.nst_action,
            nst_implication: newNutriScore > 2 ? RISK.YES.nst_implication : RISK.NO.nst_implication,
            nst_nutritional_status: newNutriScore > 2 ? RISK.YES.nst_nutritional_status : RISK.NO.nst_nutritional_status
        }
        this.assessmentFormRef.current.setFieldsValue(nstFormFieldValues);
    }

    setWeightRange(value, fieldName) {
        this.setNSTScore(value, fieldName);
    }

    calculateBMI(val, field) {
        let { nst_height, nst_weight } = this.state;
        let newHeight = field === "nst_height" ? val : nst_height;
        let newWeight = field === "nst_weight" ? val : nst_weight;
        if (newHeight > 0 && newWeight > 0) {
            let bmi = BMI_CALCULATE(newHeight, newWeight);
            let bmi_score = bmi ? BMI_SCORE_CALCULATE(bmi) : null;
            const nstFormFieldValues = {
                nst_height: newHeight,
                nst_weight: newWeight,
                nst_bmi: bmi,
                nst_bmi_score: bmi_score,
            }
            this.assessmentFormRef.current.setFieldsValue(nstFormFieldValues);
            this.setState({
                nst_height: newHeight,
                nst_weight: newWeight,
                nst_bmi: bmi,
                nst_bmi_score: bmi_score,
            });
        } else {
            this.setState({ [field]: val });
        }
    }

    handleDate(date, field) {
        this.setState({ [field]: date });
    }

    handleDisabledDate(current) {
        const minDate = moment().subtract(0, 'days');
        return current && current < minDate.startOf('day');
    }

    disabledDateNew = (current, type) => {
        const next7Days = moment(this.state.nst_visit_date).add(6, 'days');
        return current && (current < moment().startOf('day') || current > next7Days.endOf('day'));
    }

    resetStateField() {
        let { nst_height, nst_weight, is_vitals_available } = this.state;
        let resetObj = {
            edit_assessment_id: null,
            is_authorize: 0,

            fra_field_arr: [],
            fra_any_checked: false,
            fra_not_applicable: null,
            fra_history_falls: null,
            fra_help_toilet: null,
            fra_help_moving: null,
            fra_affecting_mobility: null,
            fra_peripheral_neuropathy: null,
            fra_gait_imbalance: null,
            fra_side_weakness: null,
            fra_lower_ext_weakness: null,
            fra_well_oriented: null,
            fra_forgets_limitations: null,
            fra_psychotropics: null,
            fra_total_score: null,
            fra_interpretation: null,

            nst_field_arr: [],
            nst_height: is_vitals_available ? nst_height : null,
            nst_weight: is_vitals_available ? nst_weight : null,
            nst_bmi: null,
            nst_bmi_score: null,
            nst_lost_weight: null,
            nst_weight_range: null,
            nst_gained_weight: null,
            nst_nutritional_score: 0,

            datetime_arrival: null,
            mode_arrival: null,
            medication: null,
            pain_score: null,
            pain_status: null,
            homeMedication: [],
        }
        resetObj.formMode = "SAVE";
        resetObj.formSubmitted = false;
        this.setState(resetObj);
    }

    async submit(formValues) {
        let { formType, fra_any_checked, fra_total_score, patient } = this.state;
        let formName = formType.replaceAll("-", " ");
        formName = AppHelpers.capitalizeFirstLetter(formName);
        let patient_age = patient?.age || moment().diff(patient?.dob, 'years') || 0;
        // LS_SERVICE.get('staff_name'); LS_SERVICE.get('staff_id');
        notification.destroy();
        try {
            if (formType === "fall-risk-assessment") {
                if (!fra_any_checked) {
                    notification.error({
                        message: formName,
                        description: "Check any field!"
                    });
                    return;
                }
                this.setState({ formSubmitted: true });

                let fraData = {
                    id: +this.state.edit_assessment_id,
                    reference_type: REFERENCE_TYPE,
                    patient_id: +this.state.patient_id,
                    patient_age: +patient_age,
                    reference_id: +this.state.reference_id,
                    not_applicable: formValues.fra_not_applicable ? 1 : null,
                    history_falls: formValues.fra_history_falls ? 1 : null,
                    help_toilet: formValues.fra_help_toilet ? 1 : null,
                    help_moving: formValues.fra_help_moving ? 1 : null,
                    affecting_mobility: formValues.fra_affecting_mobility ? 1 : null,
                    peripheral_neuropathy: formValues.fra_peripheral_neuropathy ? 1 : null,
                    gait_imbalance: formValues.fra_gait_imbalance ? 1 : null,
                    side_weakness: formValues.fra_side_weakness ? 1 : null,
                    lower_ext_weakness: formValues.fra_lower_ext_weakness ? 1 : null,
                    well_oriented: formValues.fra_well_oriented ? 1 : null,
                    forgets_limitations: formValues.fra_forgets_limitations ? 1 : null,
                    psychotropics: formValues.fra_psychotropics ? 1 : null,
                    total_score: formValues.fra_total_score,
                    interpretation: formValues.fra_interpretation || null,
                    is_authorize: this.state.is_authorize,
                    risk_type: FORM_TYPE[formType],
                }
                const fallAsmtRes = await axios.post(STORE_FALL_RISK_ASSESSMENT, fraData);
                if (fallAsmtRes?.data?.status) {
                    notification.success({
                        message: formName,
                        description: fallAsmtRes?.data?.message
                    });
                    this.assessmentFormRef.current.resetFields();
                    this.resetStateField();
                    this.getAllAssessment(1);
                } else {
                    notification.error({
                        message: formName,
                        description: fallAsmtRes?.data?.message
                    });
                }
            }
            else if (formType === "nutrition-screen-tool") {
                let nstData = {
                    id: +this.state.edit_assessment_id,
                    reference_type: REFERENCE_TYPE,
                    patient_id: +this.state.patient_id,
                    reference_id: +this.state.reference_id,
                    height: formValues.nst_height,
                    weight: formValues.nst_weight,
                    bmi: formValues.nst_bmi,
                    bmi_score: formValues.nst_bmi_score,
                    lost_weight: formValues.nst_lost_weight,
                    loss_weight: formValues.nst_loss_weight,
                    gained_weight: formValues.nst_gained_weight,
                    gain_weight: formValues.nst_gain_weight,
                    weight_range: formValues.nst_weight_range,
                    loss_appetite: formValues.nst_loss_appetite,
                    edema: formValues.nst_edema,
                    nausea_vomitin: formValues.nst_nausea_vomitin,
                    diarrhea: formValues.nst_diarrhea,
                    nutritional_score: formValues.nst_nutritional_score,
                    nutritional_status: formValues.nst_nutritional_status,
                    implication: formValues.nst_implication,
                    action: formValues.nst_action,
                    care_education: formValues.nst_care_education,
                    remarks: formValues.nst_remarks,
                    screen_valid_date: formValues.nst_screen_valid_date ? moment(formValues.nst_screen_valid_date).format('YYYY-MM-DD') : '',
                    visit_date: this.state.nst_visit_date ? moment(this.state.nst_visit_date).format('YYYY-MM-DD') : '',
                    is_authorize: this.state.is_authorize,
                    risk_type: FORM_TYPE[formType],
                }

                const nutritionScreeningRes = await axios.post(STORE_NUTRITION_SCREENING_TOOL, nstData);
                if (nutritionScreeningRes?.data?.status) {
                    notification.success({
                        message: formName,
                        description: nutritionScreeningRes?.data?.message
                    });
                    this.assessmentFormRef.current.resetFields();
                    this.resetStateField();
                    this.getAllAssessment(1);
                } else {
                    notification.error({
                        message: formName,
                        description: nutritionScreeningRes?.data?.message
                    });
                }
            }
            else { // nursing-assessment-report
                let assessmentReport = {
                    id: +this.state.edit_assessment_id,
                    reference_type: REFERENCE_TYPE,
                    patient_id: +this.state.patient_id,
                    reference_id: +this.state.reference_id,
                    datetime_arrival: moment(this.state.datetime_arrival).format('YYYY-MM-DD') || null,
                    mode_arrival: this.state.mode_arrival || null,
                    medication: this.state.homeMedication || null,
                    pain_score: +this.state.pain_score || null,
                    pain_status: this.state.pain_status || null,
                    is_authorize: this.state.is_authorize,
                    risk_type: FORM_TYPE[formType],
                }
                const assessmentReportRes = await axios.post(STORE_ASSESSMENT_REPORT, assessmentReport);
                if (assessmentReportRes?.status == 200) {
                    notification.success({
                        message: formName,
                        description: "Form has been submit succesfully!...."
                    });
                    this.resetStateField();
                    this.getAllAssessment(1);
                } else {
                    notification.error({
                        message: formName,
                        description: "Unable to submit form!...."
                    });
                }
            }
        }
        catch (err) {
            notification.error({
                message: formName,
                description: "Something went wrong!"
            });
        }
    }

    optionDrugLoad() {
        if (this.state.searchDrugs !== undefined && this.state.searchDrugs.length > 0) {
            return this.state.searchDrugs.map((obj, i) => {
                return (<Option key={i} value={obj.id} fav={obj.fav} disabled={this.props.addedDrugsList && this.props.addedDrugsList.includes(obj.id)} title={obj.name}>
                    <span className="desc">
                        {obj.name}
                    </span>
                    <span className="float-right text-primary">
                        {(LS_SERVICE.get("user_type") == USERTYPES.doctor) ?
                            <i className={`icon_unique icon_star align-star ${obj.fav ? "favourite" : ""}`}></i>
                            : null}
                        {this.state.cimsallow ? (
                            <Button onClick={(e) => { e.stopPropagation(); this.showModalInfo() }} className="nostyle-link p-0">
                                <i className='icon_info'></i>
                            </Button>
                        ) : null}
                    </span>
                </Option>
                )
            })
        } else return null
    }

    onChangeDrugsSelect = (value, props) => {
        let disabled = this.state.MedicineAddInitialListed.filter((data, i) => data.drug_id === value);
        axios.post(DRUG, qs.stringify({
            id: value
        })).then(success => {
            if (this.props.formType != 'template') {
                let { patient } = this.state;
                if (patient == null || typeof patient == 'undefined') {
                    patient = this.props.patient;
                }

                const { patient_id } = patient;
                this.setState({
                    correction_dose_value: [],
                    correction_dose_data_value: [],
                    correction_dose_validate: [],
                    correction_dose_data: [],
                }, () => {
                    axios.post(FETCH_DEFAULT_CORRECTION_DOSE, qs.stringify({
                        drug_id: value,
                        patient_id: patient_id
                    })).then(success => {
                        var value_data = [];
                        var validation_data = [];
                        if (success?.data?.data?.length > 0) {
                            for (var dose of success?.data?.data) {
                                value_data.push({
                                    correction_dose_from: dose.range_min,
                                    correction_dose_to: dose.range_max,
                                    correction_dose_unit: dose.range_unit,
                                })
                                validation_data.push([dose.range_min, dose.range_max, dose.range_unit])
                            }
                        }
                        this.setState({
                            correction_dose_value: value_data,
                            correction_dose_data_value: value_data,
                            correction_dose_validate: validation_data,
                            correction_dose_data: validation_data,
                        })
                        var overlap_status = this.checkRangeOverlap(validation_data) || false
                        this.setState({ AddCorrectionDoseButton: overlap_status })
                    });
                });
            }
            const { currentMeds, polyPharmacyFlag, medicine_remarks, cimsDrugInteractionErrorDummy } = this.state;
            const response = success.data.data.result;
            this.setState({ localDoseRule: this.state.doseRule, responseDrugDosage: response.drug_dosage[0], correction_dose_applicable: 'N', dose_for_schedule_applicable: 'N' })
            if (this.props.formType == "soap-full") {
                let drug_name = response.name;
                drug_name = drug_name.toLowerCase();
                let drugInteraction = Object.keys(cimsDrugInteractionErrorDummy).find(key => drug_name.includes(key));
                let interactingDrugs = cimsDrugInteractionErrorDummy[drugInteraction];
                if (typeof interactingDrugs != 'undefined') {
                    let interactionDrugs = this.props.interactionDrugs;
                    let showInteractionModal = false;
                    let interactionDrugName = '';
                    interactingDrugs.map((v, k) => {
                        interactionDrugs.map((drug, key) => {
                            drug = drug.toLowerCase();
                            if (drug.includes(v)) {
                                showInteractionModal = true;
                                interactionDrugName = v
                            }
                        })
                    })
                    interactionDrugName = interactionDrugName.charAt(0).toUpperCase() + interactionDrugName.substr(1).toLowerCase();
                    this.setState({ isModalInteractionVisible: showInteractionModal, interactionDrugName })
                }
            }
            let strength;
            let strength_txt;
            let uom_first = '';
            let number_of_days;
            let frequencyid = '';
            let route = '';
            let instruction = 4;
            let dosage = 2;
            let start_date = null;
            let end_date = null;
            let quantity = null;
            let quantity_text = '';
            let schedules = [];
            let schedules_name = [];
            var dose_for_schedule = 'N';
            var correction_dose = 'N';
            if (response.drug_dosage.length > 0) {
                dose_for_schedule = response.drug_dosage[0].dose_for_schedule;
                correction_dose = response.drug_dosage[0].correction_dose;
                strength = response.drug_dosage[0].strength;
                if (response.drug_dosage[0].uom_first) {
                    uom_first = Object.keys(response.drug_dosage[0].uom_first).length === 0 ? '' : response.drug_dosage[0].uom_first.short_description;
                }
                strength_txt = (parseFloat(strength) == 0 && uom_first.toLowerCase() == 'na') || strength == '' ? 'NA' : (parseFloat(strength) + ' ' + uom_first).trim();
                dosage = response.drug_dosage && response.drug_dosage.length > 0 && response.drug_dosage[0].in_every ? response.drug_dosage[0].in_every : 1;
                var dosageValue
                //If speciality is Paediatrics
                if (this.state.isPaediatricSpeciality == 1 && this.state.isPaediatrics && this.props.formType != 'template') {
                    // For paediatrics
                    dosageValue = this.doseCalculator(response.drug_dosage[0]);
                    dosage = dosageValue == 0 ? null : Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == dosageValue);
                    // if (typeof dosage == 'undefined'){
                    //   dosage = this.getClosestDosage(dosageValue);
                    // }else
                    dosage = dosageValue;

                }
                dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == dosage);
                if (this.props.formType != 'template' && typeof dosage == 'undefined' && dosageValue != null && dosageValue != '') {
                    var DRUGSConstants = this.state.constants;
                    var drugDosage = this.state.drugDosage;
                    var highestDosageId = this.state.highestDosageId;
                    this.state.constants.drugDosage.map((obj, i) => {
                        highestDosageId = highestDosageId == null || highestDosageId < obj.id ? obj.id : highestDosageId;
                    })
                    drugDosage[parseInt(highestDosageId) + 1] = "" + dosageValue;
                    DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: dosageValue });
                    this.setState({ drugDosage, DRUGSConstants });
                    quantity = dosageValue;
                    dosage = parseInt(highestDosageId) + 1;
                } else if (this.props.formType != 'template') {
                    dosage = typeof dosage == 'undefined' ? null : parseInt(dosage);
                    quantity = this.state.drugDosage[dosage];
                } else {
                    dosage = typeof dosage == 'undefined' ? 1 : parseInt(dosage);
                    quantity = this.state.drugDosage[dosage];
                }
            }

            var dosage_per_day = null;
            var day_interval = null;
            if (response.drug_frequency.length > 0) {
                number_of_days = response.drug_frequency[0].duration;
                start_date = moment().format('YYYY-MM-DD');
                if (typeof response.drug_frequency[0].frequency_id != 'undefined') {
                    day_interval = response.drug_frequency[0].frequency_id.interval_per;
                    day_interval = response.drug_duration_time || day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
                    var times_correction = response.drug_frequency[0].frequency_id.total_times;
                    dosage_per_day = response.drug_frequency[0].frequency_id.total_repeats;
                    if (day_interval != 1)
                        number_of_days = Math.round(number_of_days / dosage_per_day);

                    var endDatedays = (number_of_days * day_interval * times_correction) - 1;
                    end_date = moment(start_date, 'YYYY-MM-DD').add(endDatedays, 'days').format('YYYY-MM-DD');
                }

            }
            if (response.drug_frequency.length > 0 && typeof response.drug_frequency[0].frequency_id != 'undefined') {
                frequencyid = response.drug_frequency[0].frequency_id.id;
                let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === frequencyid)
                schedules = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.id });
                schedules_name = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-');
            }

            if (response?.form_id?.fixed_quantity && response?.form_id?.fixed_quantity != null) {
                //hello
                quantity = response.form_id.fixed_quantity;
                quantity_text = 'Unit';
            }
            else {
                let formObj = this.state.constants?.drugForm?.find(item => item?.id == response?.form_id?.id);
                if (formObj?.fixedQuantity != null) {
                    quantity = formObj.fixedQuantity;
                    quantity_text = 'Unit';
                }
            }
            let drug_dosage_form_text = typeof response.drug_dosage[0]?.uom_second == 'undefined' || Object.keys(response.drug_dosage[0]?.uom_second).length === 0 ? '-' : response.drug_dosage[0]?.uom_second?.short_description;

            // let drug_frequency = frequencyid!=''?(frequencyid==0?3:1):1;
            let drug_frequency = frequencyid == '' || frequencyid == 0 ? 1 : 1;
            let drug_number_of_days = response.drug_frequency[0]?.duration;
            if (drug_frequency == 3) {
                start_date = null;
                end_date = null;
                drug_number_of_days = null;
            }
            // else if (times_correction == 0) {
            //   start_date = null;
            //   end_date = null;
            // }

            let availableDrugRoutesTemp = response.drug_route_for_form;
            let availableDrugRoutes = [];
            let defaultRouteExist = false;
            let defaultDrugRoute = response.drug_route[0]?.route_id ? response.drug_route[0]?.route_id : response.drug_route[0]?.id;
            if (availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null)
                availableDrugRoutes = this.state.constants.route;
            else
                availableDrugRoutesTemp.map((v, k) => {
                    if (defaultDrugRoute == v.routeId)
                        defaultRouteExist = true;
                    availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
                })

            var drug_number_of_days_calculated;
            if (props.fav == 1) {
                drug_number_of_days_calculated = drug_number_of_days
            }
            else {
                drug_number_of_days_calculated = null;
            }
            if (this.props.formType != 'template' && (!drug_number_of_days_calculated || drug_number_of_days_calculated == null || drug_number_of_days_calculated == "")) {
                end_date = null;
            }
            //this.setState({ drug_duration_time: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months' });
            this.setState({ drug_duration_time: response.drug_duration_time && response.drug_duration_time != undefined ? response.drug_duration_time : day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months' })
            this.setState({
                DrugApi: response,
                drug_id: response.id,
                drug_name: props.title,
                drug_dosage: props.fav == 1 && response.dosage ? response.dosage : dosage,
                drug_dosage_value: dosage != null ? this.state.drugDosage[dosage] : '',
                selected_dosage: (response.dosage) ? this.state.drugDosage[response.dosage] : dosage ? this.state.drugDosage[dosage] : '',
                // drug_number_of_days: drug_number_of_days,
                drug_number_of_days: props.fav == 1 ? drug_number_of_days : '',
                drug_frequency: response.frequency ? response.frequency : drug_frequency,
                drug_days_in_week: props.fav == 1 && response.days_in_week != null ? response.days_in_week.split(',').map(element => Number(element)) : this.state.drug_days_in_week,
                drug_strength: strength_txt,
                drug_dosage_form_text: drug_dosage_form_text,
                drug_form: response.form_id?.id,
                drug_form_defaulted: response.form_id?.id ? true : false,
                drug_route: defaultRouteExist ? defaultDrugRoute : '',
                drug_daily_frequency: frequencyid,
                drug_schedules: props.fav == 1 && response.schedules != null ? response.schedules.split(',').map(element => Number(element)) : (props.fav == 1 && response.schedules == null) ? [] : schedules,
                drug_schedule_name: schedules_name,
                drug_instruction: props.fav == 1 && response.instruction ? response.instruction : 4,
                drug_instruction_morning: '',
                drug_instruction_afternoon: '',
                drug_instruction_evening: '',
                drug_instruction_night: '',
                drug_medicine_remarks: props.fav == 1 ? response.medicine_remarks : '',
                drug_urgency: response.urgency ? response.urgency : 3,
                drug_dosage_form: 0,
                dose_for_schedule: dose_for_schedule,
                correction_dose: correction_dose,
                drug_fixed_qty: response.form_id?.fixed_quantity && response.form_id?.fixed_quantity != null ? response.form_id?.fixed_quantity : null,
                drug_quantity: quantity,
                drug_dosage_form_text_quantity: quantity_text != '' ? quantity_text : drug_dosage_form_text,
                drug_start_date: start_date,
                drug_end_date: end_date,
                drug_generic: response.drug_generic,
                drug_daily_frequency_repeats: day_interval != 1 ? 1 : dosage_per_day,
                selected_drug: props.title,
                isDisabled: true,
                drug_id_list: [...this.state.drug_id_list, response.id],
                isFavCheckedVal: props.fav,
                availableDrugRoutes: availableDrugRoutes,
                AddMedicineButton: false,
                medicineFormValidate: true,
                isBorderColor: true

            }, () => { this.updateQuantity(); this.checkDrugSaveBtn(); this.handleTextareaAutoHeight('drug_medicine_remarks'); });
            AntSelectLabels();
            if (this.props.formType != 'template') {
                if (currentMeds.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
                    this.showPolyModal()
                }
            }
        }).catch(err => console.log(err))
    }

    MasterDrugApi = (search) => {
        this.setState({
            formIsFetching: true
        })

        let params = { search: search };
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null;
        } else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
            params.hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
        } else {
            params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
        }

        axios.post(SEARCH_DRUGS, qs.stringify(params)).then(success => {
            this.setState({
                searchDrugs: success.data.medicine_list,
                formIsFetching: false,
                AddMedicineButton: true,
                showAddDrugButton: (search.length > 2 && success.data?.medicine_list?.length == 0 && this.props.formType != 'template') ? false : true,
            });
        }).catch(err => console.log(err))
    }

    onDrugssearch = (value) => {
        this.MasterDrugApi(value);
        this.setState({
            selected_drug: value,
            AddMedicineButton: false,
        });
    }

    checkRangeOverlap(ranges) {
        let correction_dose_error_messsage = [];
        let n;
        if (ranges?.length > 0) {
            //ranges = ranges.filter(function () { return true });
            n = ranges.length;
            for (var i = 0; i < n; i++) {
                if (ranges[i] != undefined && ranges[i][0] != undefined && ranges[i][1] != undefined && ranges[i][2] != undefined) {
                    if (ranges[i][0] >= 0 && ranges[i][1] >= 0 && ranges[i][2] >= 0) {
                        if (parseInt(ranges[i][0]) < parseInt(ranges[i][1])) {
                            for (var u = 0; u < n; u++) {
                                if (ranges[u] != undefined && ranges[u][0] != undefined && ranges[u][1] != undefined) {
                                    if (i == u) // skip if comparing the same range
                                        continue
                                    var rangeA = ranges[i]
                                    var rangeB = ranges[u]
                                    if (parseInt(rangeA[0]) <= parseInt(rangeB[1]) && parseInt(rangeA[1]) >= parseInt(rangeB[0])) {
                                        correction_dose_error_messsage[u] = 'Overlapping values, please check';
                                        this.setState({ correction_dose_error_messsage });
                                        return true
                                    }
                                }
                            }
                        }
                        else {
                            correction_dose_error_messsage[i] = 'To value should always be greater than From Value';
                            this.setState({ correction_dose_error_messsage });
                            /* this.setState({correction_dose_error_messsage: 'To value should always be greater than From Value'}); */
                            return true
                        }
                    }
                    else if (ranges[i][0] < 0 && ranges[i][1] < 0 && ranges[i][2] < 0) {
                        this.setState({ correction_dose_error_messsage });
                        //this.setState({correction_dose_error_messsage: ''});
                        return false;
                    }
                    else if (!ranges[i][0] && !ranges[i][1] && !ranges[i][2]) {
                        this.setState({ correction_dose_error_messsage });
                        //this.setState({correction_dose_error_messsage: ''});
                        return false;
                    }
                    else {
                        this.setState({ correction_dose_error_messsage });
                        //this.setState({correction_dose_error_messsage: ''});
                        return true
                    }
                }
                else if (ranges[i] != undefined) {
                    if (ranges[i][0] == undefined && ranges[i][1] == undefined && ranges[i][2] == undefined) {
                        // Do nothing
                    }
                    else {
                        this.setState({ correction_dose_error_messsage });
                        //this.setState({correction_dose_error_messsage: ''});
                        return true
                    }
                }
            }
        }
        else {
            this.setState({ correction_dose_error_messsage });
            //this.setState({correction_dose_error_messsage: ''});
            return true;
        }

        if (ranges?.length > 0) {
            let all_undefined_rows_count = 0;
            let total_rows = 0;
            for (var i = 0; i < n; i++) {
                if (ranges[i] != undefined) {
                    total_rows++;
                    if (ranges[i][0] == undefined && ranges[i][1] == undefined && ranges[i][2] == undefined) {
                        all_undefined_rows_count++;
                    }
                }
            }
            if (all_undefined_rows_count == total_rows) {
                this.setState({ correction_dose_error_messsage });
                return true;
            }
        }
        this.setState({ correction_dose_error_messsage });
        //this.setState({correction_dose_error_messsage: ''});
        return false
    }

    optionDosageLoad() {
        if (!this.state.constants.drugDosage || this.state.constants.drugDosage.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }

        // var highestDosageId = this.state.highestDosageId;
        /*var htmlText =  this.state.DRUGSConstants.drugDosage.map((obj, i) => {
          // highestDosageId = highestDosageId==null || highestDosageId<obj.id?obj.id:highestDosageId;
          this.state.drugDosage[obj.id] = ""+obj.description;
          return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
          )
        }, this)
        // this.setState({highestDosageId});
        return htmlText;*/

        var options = [];
        this.state.constants.drugDosage.map((obj, i) => {
            this.state.drugDosage[obj.id] = "" + obj.description;
            options.push({ value: obj.description })
        }, this)
        return options;
    }

    onSelectDailyFrequencyChange = (val, props) => {
        var day_interval = props.dataid.interval;
        day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
        var times_correction = props.dataid.total_times;
        var dosage_per_day = props.dataid.total_repeats;

        var start_date = this.state.start_date;
        if (!start_date && start_date != undefined) {
            if (this.state.editData != null && this.state.editData.start_date != null)
                start_date = this.state.editData.start_date
            else if (this.state.editData != null && this.state.editData.start_date == null && this.state.editData.previousEndDate != null && this.state.editData.taper_row)
                start_date = moment(this.state.editData.previousEndDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
            else
                start_date = moment().format('YYYY-MM-DD');
        }
        if (times_correction == 0) {
            start_date = moment().format('YYYY-MM-DD');
            if (!this.state.isModalTaperDoseVisible)
                this.setState({ disableNumberOfDays: false })
            else
                this.setState({ disableNumberOfDaysTaper: true })
        }
        else {
            if (start_date == null) {
                start_date = moment().format('YYYY-MM-DD');
            }

            if (!this.state.isModalTaperDoseVisible)
                this.setState({ disableNumberOfDays: false })
            else
                this.setState({ disableNumberOfDaysTaper: false })
        }
        if (!this.state.isModalTaperDoseVisible)
            this.setState({
                drug_start_date: start_date,
                drug_schedules: props.dataSchedule.map(function (obj) { return obj.id }),
                drug_schedule_name: props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-'),
                drug_daily_frequency: props.dataid.id,
                drug_daily_frequency_repeats: day_interval != 1 ? 1 : parseInt(dosage_per_day),
                drug_duration_time: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
            }, () => { this.updateQuantity(); this.updateEndDate(); this.checkDrugSaveBtn(); })
        else
            this.setState({
                drug_start_date: start_date,
                drug_schedules_taper: props.dataSchedule.map(function (obj) { return obj.id }),
                drug_schedule_name_taper: props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-'),
                drug_daily_frequency_taper: props.dataid.id,
                drug_daily_frequency_repeats: day_interval != 1 ? 1 : parseInt(dosage_per_day),
                drug_duration_time_taper: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
            }, () => { this.updateQuantity(); this.updateEndDate(); this.checkDrugSaveBtn(); })
        AntSelectLabels();
    }

    optiondaily_frequencyLoad() {
        if (!this.state.constants.dailyFrequency || this.state.constants.dailyFrequency.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }

        var newArr = {};
        let optionsList = this.state.constants.dailyFrequency.map((obj, i) => {
            newArr[obj.id] = obj.description;
            // this.state.drugDailyFrequency[obj.id] = obj.description;
            return (<Option key={i} value={obj.id} dataSchedule={obj?.schedule} dataid={obj} title={obj.description}>{obj.description}</Option>)
        });
        // this.setState({ drugDailyFrequency: newArr });
        // console.log('------------optionsList:', optionsList);
        return optionsList;
    }

    updateQuantity = async () => {
        let drug_fixed_qty, drug_number_of_days, drug_dosage, drug_frequency, drug_daily_frequency, drug_dosage_form_text_quantity;
        drug_fixed_qty = this.state.drug_fixed_qty;
        drug_dosage_form_text_quantity = this.state.drug_dosage_form_text_quantity;
        var day_interval;
        if (!this.state.isModalTaperDoseVisible) {
            drug_number_of_days = this.state.drug_number_of_days;
            drug_dosage = this.state.drug_dosage;
            drug_frequency = this.state.drug_frequency;
            drug_daily_frequency = this.state.drug_daily_frequency;
            day_interval = this.state.drug_duration_time && this.state.drug_duration_time != null ? this.state.drug_duration_time.toLowerCase() : '';
        }
        else {
            drug_number_of_days = this.state.drug_number_of_days_taper;
            drug_dosage = this.state.drug_dosage_taper;
            drug_frequency = this.state.drug_frequency_taper;
            drug_daily_frequency = this.state.drug_daily_frequency_taper;
            day_interval = this.state.drug_duration_time_taper && this.state.drug_duration_time != null ? this.state.drug_duration_time_taper.toLowerCase() : '';
        }
        if (drug_dosage && drug_frequency) {
            let quantity = '';
            if (!isNaN(parseInt(drug_fixed_qty))) quantity = parseInt(drug_fixed_qty);
            else {
                var number_of_days = drug_number_of_days;
                var dosageVal = this.state.drugDosage[drug_dosage];
                drug_dosage = parseFloat(eval(dosageVal.replace(/[^\d.-/]/g, '')));
                // var day_interval;
                var dosage_per_day;
                if (drug_daily_frequency != null && drug_daily_frequency != '' && this.state.DRUGSConstants.dailyFrequency) {
                    let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === drug_daily_frequency)
                    frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)];
                    // day_interval = frequencyObjKey.interval;
                    var times_correction = frequencyObjKey.total_times;
                    dosage_per_day = frequencyObjKey.total_repeats;
                }


                day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
                // if (day_interval != 1) dosage_per_day = 1;
                if (drug_frequency == 3) {
                    quantity = drug_dosage;
                } else {
                    // if (times_correction == 0) {
                    //   // $('#number_of_days').prop('disabled', true);
                    //   number_of_days = 1;
                    // }
                    // else {
                    //   // $('#number_of_days').prop('disabled', false);
                    // }
                    if (drug_dosage_form_text_quantity != 'Unit') {
                        if (this.state.drug_daily_frequency === 15) {
                            let numberOfDays = Math.ceil(number_of_days / 2);
                            quantity = Math.ceil(parseInt(numberOfDays) * drug_dosage * dosage_per_day);
                        } else {
                            quantity = parseInt(number_of_days) * drug_dosage * dosage_per_day;
                        }
                    } else {
                        quantity = 1;
                    }
                }
                if (!this.state.isModalTaperDoseVisible)
                    this.setState({ drug_number_of_days: number_of_days }, () => { this.checkDrugSaveBtn() })
                else
                    this.setState({ drug_number_of_days_taper: number_of_days }, () => { this.checkDrugSaveBtn() })
            }

            if (drug_dosage_form_text_quantity != 'Unit' && this.state.dose_for_schedule_applicable == 'Y') {
                var morning = this.state.dosageDropDownMorning && this.state.dosageDropDownMorning != "" ? parseFloat(this.state.dosageDropDownMorning) : 0;
                var afternoon = this.state.dosageDropDownAfternoon && this.state.dosageDropDownAfternoon != "" ? parseFloat(this.state.dosageDropDownAfternoon) : 0;
                var evening = this.state.dosageDropDownEvening && this.state.dosageDropDownEvening != "" ? parseFloat(this.state.dosageDropDownEvening) : 0;
                var night = this.state.dosageDropDownNight && this.state.dosageDropDownNight != "" ? parseFloat(this.state.dosageDropDownNight) : 0;
                quantity = (morning + afternoon + evening + night) * parseInt(number_of_days);
            }
            let formObj = await this.state.DRUGSConstants?.drugForm?.find(item => item.id == this.state.drug_form);
            if (formObj && formObj.fixedQuantity && formObj.fixedQuantity != null) {
                quantity = formObj.fixedQuantity;
            }

            if (!isNaN(quantity) || drug_number_of_days == "") {
                quantity = !isNaN(quantity) ? quantity : '';
                this.setState({ drug_quantity: quantity }, () => { AntSelectLabels(); AntClearLabels(); jQueryLabels(); this.checkDrugSaveBtn() })
            }
        }
    }

    checkDrugSaveBtn = async () => {
        let drugId = this.state.drug_id;
        let drugForm = this.state.drug_form;
        let strength = this.state.drug_strength;
        let dosage = this.state.drug_dosage;
        let route = this.state.drug_route;
        let drugFrequency = this.state.drug_frequency;
        let drugDaysInWeek = this.state.drug_days_in_week;
        let dailyFrequency = this.state.drug_daily_frequency;
        let schedule = this.state.drug_schedules;
        let urgency = this.state.drug_urgency;
        let event = this.state.drug_event;
        let lmp_event_duration = this.state.drug_lmp_event_duration;
        let lmp_event_duration_type = this.state.drug_lmp_event_duration_type;
        let numberOfDays = this.state.drug_number_of_days;
        let startDate = this.state.drug_start_date;
        let endDate = this.state.drug_end_date;

        if (!this.state.addTaperDose && this.state.isModalTaperDoseVisible) {
            dosage = this.state.drug_dosage_taper;
            route = this.state.drug_route_taper;
            strength = this.state.drug_strength_taper;
            numberOfDays = this.state.drug_number_of_days_taper;
            drugFrequency = this.state.drug_frequency_taper;
            drugDaysInWeek = this.state.drug_days_in_week_taper;
            dailyFrequency = this.state.drug_daily_frequency_taper;
            schedule = this.state.drug_schedules_taper;
        }

        let disableBtn = false;

        if (this.checkEmptyValue(drugId)) disableBtn = true;
        if (this.checkEmptyValue(drugForm)) disableBtn = true;
        if (this.checkEmptyValue(strength)) disableBtn = true;
        if (this.checkEmptyValue(route)) disableBtn = true;
        if (this.checkEmptyValue(drugFrequency)) disableBtn = true;
        if (this.checkEmptyValue(dosage)) disableBtn = true;
        if (this.state.dose_for_schedule_applicable == 'Y') {
            if (this.state.drug_schedules.includes(1)) {
                if (this.checkEmptyValue(this.state.dosageDropDownMorning)) disableBtn = true;
                if (this.checkEmptyValue(this.state.drug_instruction_morning)) disableBtn = true;
            }
            if (this.state.drug_schedules.includes(2)) {
                if (this.checkEmptyValue(this.state.dosageDropDownAfternoon)) disableBtn = true;
                if (this.checkEmptyValue(this.state.drug_instruction_afternoon)) disableBtn = true;
            }
            if (this.state.drug_schedules.includes(3)) {
                if (this.checkEmptyValue(this.state.dosageDropDownEvening)) disableBtn = true;
                if (this.checkEmptyValue(this.state.drug_instruction_evening)) disableBtn = true;
            }
            if (this.state.drug_schedules.includes(4)) {
                if (this.checkEmptyValue(this.state.dosageDropDownNight)) disableBtn = true;
                if (this.checkEmptyValue(this.state.drug_instruction_night)) disableBtn = true;
            }
        }
        if (this.state.eventBased == true) {
            if (this.checkEmptyValue(event)) disableBtn = true;
            if (event == 1 || event == 2) {
                if (this.checkEmptyValue(lmp_event_duration)) disableBtn = true;
                if (this.checkEmptyValue(lmp_event_duration_type)) disableBtn = true;
            }
        }
        if (drugFrequency != 3) {
            let day_interval = null;
            let times_correction = null;
            let dosage_per_day = null;
            if (this.checkEmptyValue(dailyFrequency)) disableBtn = true;
            else {
                if (this.state.constants.dailyFrequency) {
                    let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === dailyFrequency)
                    frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)];
                    // day_interval = frequencyObjKey.interval;
                    day_interval = this.state.drug_duration_time && this.state.drug_duration_time != null ? this.state.drug_duration_time.toLowerCase() : '';
                    day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
                    if (this.state.isModalTaperDoseVisible) {
                        day_interval = this.state.drug_duration_time_taper && this.state.drug_duration_time_taper != null ? this.state.drug_duration_time_taper.toLowerCase() : '';
                        day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
                    }
                    dosage_per_day = frequencyObjKey.total_repeats
                    times_correction = frequencyObjKey.total_times;
                    if (schedule && schedule.length > 0) {
                        if ((dosage_per_day <= 4 && (dailyFrequency != 9 && dailyFrequency != 8)) && schedule && schedule.length != dosage_per_day)
                            disableBtn = true;
                        else if ((dosage_per_day <= 4 && (dailyFrequency == 9 || dailyFrequency == 8)) && schedule && schedule.length != 1)
                            disableBtn = true;
                        else if (dosage_per_day > 4 && schedule && schedule.length != 4)
                            disableBtn = true;
                    }
                    if ((dailyFrequency == 9 || dailyFrequency == 11) && (day_interval < 7)) {
                        disableBtn = true;
                    }
                    else if ((dailyFrequency == 8 || dailyFrequency == 10) && (day_interval < 30)) {
                        disableBtn = true;
                    }
                }
            }

            if (drugFrequency == 2 && this.checkEmptyMultiSelectValue(drugDaysInWeek)) disableBtn = true;
            // if (this.checkEmptyMultiSelectValue(schedule)) disableBtn = true;
            if (drugDaysInWeek.length > 0) disableBtn = false;
            if (this.checkEmptyValue(urgency)) disableBtn = true;
            // if (this.checkEmptyValue(numberOfDays)) disableBtn = true;

            if (this.props.formType != 'template' && times_correction != null && times_correction != 0) {
                if (this.checkEmptyValue(startDate)) disableBtn = true;
                // if (this.checkEmptyValue(endDate)) disableBtn = true;
            }
        }
        this.setState({ AddMedicineButton: false });

    }

    handleTextareaAutoHeight = (textarea, clear = false) => {
        let Elem = document.querySelectorAll('.' + textarea);
        if (!clear) {
            Elem.forEach((el) => { el.style.height = el.scrollHeight + "px"; });
        } else {
            Elem.forEach((el) => { el.style.height = "40px"; });
        }
    }

    checkEmptyValue(val) {
        return typeof val == 'undefined' || val == null || val == '' ? true : false;
    }

    checkEmptyMultiSelectValue(val) {
        return typeof val == 'undefined' || val == null || val.length == 0 ? true : false;
    }

    async getCurrentMedicines() {
        let { patient } = this.state;
        if (patient == null || typeof patient == 'undefined') {
            patient = this.props.patient;
        }
        const { patient_id } = patient;
        axios.get(MEDICINES_GETALL({ patient_id })).then(success => {
            let currentMedsList = success.data.data.details.current_medicines;
            let constant = success.data.data.constants;
            var tempArr = [];
            if (currentMedsList) {
                tempArr = currentMedsList.map(function (v) { return v.drug_id });
                tempArr = tempArr.filter(function (item, pos) { return tempArr.indexOf(item) == pos; })
            }
            this.setState({
                currentMeds: tempArr,
                currentMedsLists: currentMedsList,
                constantCurrentMeds: constant
            })
        });
    }

    saveMedicineData = async () => {
        if (this.state.editMedicationInfo == "allow") {
            this.state.homeMedication.splice(this.state.editMedicationId, 1);
            this.setState(prevState => ({
                homeMedication: [...prevState.homeMedication, {
                    selected_drug: this.state.selected_drug,
                    drug_daily_frequency: this.state.drug_daily_frequency,
                    last_dose_date: moment(this.state.last_dose_date).format('YYYY-MM-DD'),
                    since_day: moment(this.state.since_day).format('YYYY-MM-DD'),
                    selected_dosage: +this.state.selected_dosage
                }]
            }));
        }
        else {
            this.setState(prevState => ({
                homeMedication: [...prevState.homeMedication, {
                    selected_drug: this.state.selected_drug,
                    drug_daily_frequency: this.state.drug_daily_frequency,
                    last_dose_date: moment(this.state.last_dose_date).format('YYYY-MM-DD'),
                    since_day: moment(this.state.since_day).format('YYYY-MM-DD'),
                    selected_dosage: +this.state.selected_dosage
                }]
            }));
        }
        this.setState({ AddMedicineButton: false });
        this.resetDosageField();
    }

    resetDosageField = () => {
        this.setState({
            selected_drug: null,
            drug_daily_frequency: null,
            last_dose_date: undefined,
            since_day: '',
            selected_dosage: null,
            editMedicationInfo: null,
            editMedicationId: ''
        });
    }

    // Function to delete a user from the state array
    deleteHomeMedication = (id) => {
        const updatedUsers = [...this.state.homeMedication]; // create a copy of the array
        updatedUsers.splice(id, 1); // remove the item at the specified index
        this.setState({ homeMedication: updatedUsers }); // update the state with the new array
    }

    editHomeMedication = (index) => {
        let newArray = this.state.homeMedication[index];
        this.setState({
            AddMedicineButton: false,
            editMedicationInfo: 'allow',
            selected_drug: newArray['selected_drug'],
            drug_daily_frequency: newArray['drug_daily_frequency'],
            last_dose_date: moment(newArray['last_dose_date']).format('YYYY-MM-DD hh:mm'),
            since_day: moment(newArray['since_day']).format('YYYY-MM-DD'),
            selected_dosage: newArray['selected_dosage'],
            editMedicationId: index
        });
    }

    handleChange = (name, e) => {
        this.setState({ [name]: e?.target?.value || e });
    };

    handleSinceDayChange = (e) => {
        this.setState({ 'since_day': moment(e).format('YYYY-MM-DD') });
    };

    handleLastDosageChange = (e) => {
        this.setState({ 'last_dose_date': moment(e).format('YYYY-MM-DD hh:mm') });
    };

    getFrequencyNameById = (id) => {
        const nameObj = this.state.constants.dailyFrequency.find((item) => item.id === id);
        if (nameObj) {
            return nameObj.description;
        }
    }

    updateEndDate = async () => {
        let drug_number_of_days, drug_daily_frequency, drug_start_date, drug_duration_time, drug_duration_time_taper;
        drug_start_date = this.state.drug_start_date;
        var day_interval;
        if (!this.state.isModalTaperDoseVisible) {
            drug_number_of_days = this.state.drug_number_of_days;
            drug_daily_frequency = this.state.drug_daily_frequency;
            day_interval = (this.state.drug_duration_time && this.state.drug_duration_time != null) ? this.state.drug_duration_time.toLowerCase() : '';
        }
        else {
            drug_number_of_days = this.state.drug_number_of_days_taper;
            drug_daily_frequency = this.state.drug_daily_frequency_taper;
            day_interval = (this.state.drug_duration_time_taper && this.state.drug_duration_time_taper != null) ? this.state.drug_duration_time_taper.toLowerCase() : '';
        }
        if (drug_number_of_days && drug_daily_frequency && drug_start_date) {
            var number_of_days = parseInt(drug_number_of_days);
            var start_date = drug_start_date;
            var end_date = null;
            let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === drug_daily_frequency)
            frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)]
            day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
            var times_correction = frequencyObjKey.total_times;
            var dosage_per_day = frequencyObjKey.total_repeats;
            if (typeof number_of_days != 'undefined' && !isNaN(number_of_days) && typeof start_date != 'undefined' && start_date != '' && times_correction != 0) {
                if (day_interval != 1)
                    // number_of_days = Math.round(number_of_days / dosage_per_day);
                    //  number_of_days = number_of_days * day_interval * times_correction;
                    number_of_days = number_of_days * day_interval;
                end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
            }
            if (times_correction == 0) {
                end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
                await this.setState({ drug_start_date: start_date, drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
            } else {
                await this.setState({ drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
            }
        }

        else if (drug_start_date == null)
            this.setState({ drug_end_date: null }, () => { this.checkDrugSaveBtn() });
    }

    async getModeOfArrival() {
        axios.get(GET_MODEOF_ARRIVAL).then(response => {
            this.setState({ mode_arrival_array: response.data.data || [] });
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    render() {
        const { patient, patient_id, reference_id, formType, formSubmitted, formMode, edit_assessment_id, pagination, fra_total_score, fra_field_arr, is_vitals_available, nst_height, nst_weight, nst_bmi, nst_bmi_score, nst_lost_weight, nst_gained_weight, ss_configuration, consult_type } = this.state;
        var field_status_patient_history = [];

        // console.log('patient====>', this.state.homeMedication);
        // enable or disable fra form field
        let fra_field_disable = null, age = 0;
        if (formType == "fall-risk-assessment") {
            if (fra_field_arr.length) {
                fra_field_disable = fra_field_arr.includes("fra_not_applicable");
            }
            var years = patient?.age || moment().diff(patient?.dob, 'years');
            age = years || 0;
        }

        return (
            <Fragment>
                <AsideLeft
                    patient_id={patient_id}
                    reference_id={reference_id}
                />
                <div className="content-wrapper mb-3">
                    <div className="container-fluid emrfrm">
                        <div className="row">
                            <div className="col-12 mb-3 pt-1">
                                <div className="float-left">
                                    <h1 className="page-title mb-0">ASSESSMENT FORMS</h1>
                                </div>
                            </div>
                        </div>
                        <Form
                            layout='vertical'
                            className='emrfrm'
                            autoComplete="off"
                            ref={this.assessmentFormRef}
                            initialValues={null}
                            onFinish={(e) => this.submit(e)}
                        >
                            <div className='row mt-2'>
                                <div className='col-auto d-flex align-items-center'>
                                    <label className='text-dark font-weight-bold'>Select Form</label>
                                </div>
                                <div className='col-3'>
                                    <div className="antSelectItem hasdata custom_mb-0">
                                        <Form.Item label="Form">
                                            <Select
                                                style={{ width: '100%', marginBottom: '0px' }}
                                                disabled={edit_assessment_id}
                                                defaultValue={formType}
                                                value={formType}
                                                // onChange={(val) => this.setState({ formType: val })}
                                                onChange={(val) => this.setFormtype(val)}
                                            >
                                                <Option value="fall-risk-assessment">Fall Risk Assessment</Option>
                                                <Option value="nutrition-screen-tool">Nutrition Screening Tool for Nurse</Option>
                                                <Option value="nursing-assessment-report">Nursing Assessment Report</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                {/* for selection of Nutrition Screening Tool for Nurse option */}
                                {
                                    formType === "nutrition-screen-tool" ?
                                        <Fragment>
                                            <div className={'show col-2'}>
                                                <div className='antInputItem hasdata custom_mb-0'>
                                                    <Form.Item
                                                        label="Visit Date"
                                                        className='mb-0'
                                                        name="nst_visit_date"
                                                        valuePropName={'nst_visit_date'}
                                                    >
                                                        <DatePicker
                                                            placeholder='Visit Date'
                                                            className='mb-0'
                                                            disabled={true}
                                                            style={{ width: 100 + "%", height: 44 + "px", }}
                                                            defaultValue={this.state.nst_visit_date ? moment(this.state.nst_visit_date, 'YYYY-MM-DD') : null}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className={'show col-2'}>
                                                <div className='antInputItem hasdata custom_mb-0'>
                                                    <Form.Item
                                                        label="Screening Valid Till"
                                                        className='mb-0'
                                                        name="nst_screen_valid_date"
                                                        valuePropName={'nst_screen_valid_date'}
                                                    >
                                                        <DatePicker
                                                            placeholder='Screening Valid Till'
                                                            className='mb-0'
                                                            onChange={(e) => this.handleDate(e, 'nst_screen_valid_date')}
                                                            disabledDate={(current) => this.disabledDateNew(current, "nst_screen_valid_date")}
                                                            style={{ width: 100 + "%", height: 44 + "px", }}
                                                            value={this.state.nst_screen_valid_date ? moment(this.state.nst_screen_valid_date) : null}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : null
                                }

                                {/* for selection of Nursing Assessment Report option */}
                                {
                                    formType === "nursing-assessment-report" ?
                                        <Fragment>
                                            <div className={'show col-2'}>
                                                <div className='antInputItem hasdata custom_mb-0'>
                                                    <Form.Item
                                                        name="datetime_arrival"
                                                        className='mb-0'
                                                        label="Date & Time of Arrival"
                                                        valuePropName={'datetime_arrival'}
                                                    >
                                                        <DatePicker
                                                            format="DD MMM, YYYY"
                                                            // placeholder=''
                                                            className='mb-0'
                                                            style={{ width: 100 + "%", height: 44 + "px" }}
                                                            onChange={(e) => this.handleDate(e, 'datetime_arrival')}
                                                            value={this.state.datetime_arrival ? moment(this.state.datetime_arrival) : null}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className={'show col-2'}>
                                                <div className="antSelectItem hasdata custom_mb-0">
                                                    <Form.Item
                                                        label="Mode of Arrival"
                                                        name="mode_arrival"
                                                        valuePropName={'mode_arrival'}
                                                    >
                                                        <Select
                                                            // placeholder="Select Mode"
                                                            onChange={(val) => this.handleChange('mode_arrival', val)}
                                                            value={this.state.mode_arrival || null}
                                                        >
                                                            {this.state.mode_arrival_array.length && this.state.mode_arrival_array.map(item => (
                                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : null
                                }
                            </div>

                            {formType === "fall-risk-assessment" ?
                                <Fragment>
                                    <div className='container-fluid px-0'>
                                        <div className='row mt-3'>
                                            <div className='col-12'>
                                                <span className='text-danger'>If the answer to any of the question above is <strong>'Yes'</strong>.&nbsp;
                                                    Patient will be categorised as <strong>'High risk'</strong> for fall. Else the Patient will be categorised as a
                                                    <strong>'Low Risk'</strong> for fall.<br />
                                                    *Any child below 10 years will be considered as <strong>'High Risk'</strong> for fall.
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            age < 10 ? null : <div className='row mt-3'>
                                                <div className='col'>
                                                    <Form.Item noStyle name="fra_not_applicable" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable !== null && !fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Not Applicable</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        }
                                        <div className='row mt-4'>
                                            <div className='col-auto'>
                                                <label className='text-dark font-weight-bold mb-0'>Patient Factors</label>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_history_falls" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>History of falls</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_help_toilet" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Need help transferring to commodeor toilet</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className=''>
                                                    <Form.Item noStyle name="fra_help_moving" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Need help moving from bed to chair or requires a complete transfer</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-auto'>
                                                <label className='text-dark font-weight-bold mb-0'>Sensory Deficts</label>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_affecting_mobility" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Visual/auditory impairment affecting mobility</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_peripheral_neuropathy" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Peripheral neuropathy</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-auto'>
                                                <label className='text-dark font-weight-bold mb-0'>Motor Deficts</label>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_gait_imbalance" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Gait imbalance</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_side_weakness" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Right or left side weakness</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_lower_ext_weakness" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Lower-extremity weakness</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-auto'>
                                                <label className='text-dark font-weight-bold mb-0'>Mental Status</label>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_well_oriented" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Well oriented</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_forgets_limitations" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Forgets/over estimates limitations</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-auto'>
                                                <label className='text-dark font-weight-bold mb-0'>Medications</label>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-auto'>
                                                <div className='pr-5'>
                                                    <Form.Item noStyle name="fra_psychotropics" valuePropName="checked">
                                                        <Checkbox disabled={fra_field_disable} onChange={(e) => this.setFRAScore(e.target)}>
                                                            <span>Psychotropics (eg: Sleep medications, hypnotics, sedatives, anxioytics) or anticonvulsan</span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-auto d-flex align-items-center'>
                                                <label className='text-dark font-weight-bold'>Total Score</label>
                                            </div>
                                            <div className='col-1'>
                                                <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                    <Form.Item label="" name="fra_total_score">
                                                        <Input type="number" disabled={true} />
                                                        {/* <Input type="number" defaultValue={fra_total_score} disabled={true} /> */}
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                    <Form.Item
                                                        label="Interpretation"
                                                        name="fra_interpretation"
                                                        rules={[
                                                            { required: false, message: 'Required!' },
                                                            { pattern: /^[A-Za-z0-9- ]*$/, message: 'Only alphabets and numbers are allowed!' }
                                                        ]}
                                                    >
                                                        <Input maxLength={250} disabled={fra_field_disable} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-12 text-center'>
                                                <button className="btn btn-outline-secondary text-uppercase px-4 mr-2" type="submit" disabled={formSubmitted}>
                                                    {formMode == "SAVE" ? 'SAVE ' : 'UPDATE '}
                                                    {formSubmitted ? LOADER_RED : null}
                                                </button>
                                                <button className="btn btn-primary text-uppercase px-4 ml-2" type="submit" onClick={() => this.setState({ is_authorize: 1 })} disabled={formSubmitted}>AUTHORIZE</button>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>

                                : formType === "nutrition-screen-tool" ?
                                    <Fragment>
                                        <div className='container-fluid px-0'>
                                            <div className='row mt-3'>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="Height"
                                                            name="nst_height"
                                                            onChange={(e) => this.calculateBMI(e.target.value, "nst_height")}
                                                            rules={[
                                                                { required: false, message: 'Required!' },
                                                                { pattern: /^\d+$/, message: 'Max 3 digits required!' },
                                                                { max: 3, message: 'Max 3 digit allowed!' }
                                                            ]}
                                                        >
                                                            <Input
                                                                addonAfter="cm"
                                                                maxLength={3}
                                                                value={nst_height}
                                                                disabled={is_vitals_available}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="weight"
                                                            name="nst_weight"
                                                            onChange={(e) => this.calculateBMI(e.target.value, "nst_weight")}
                                                            rules={[
                                                                { required: false, message: 'Required!' },
                                                                { pattern: /^\d+$/, message: 'Max 3 digits required!' },
                                                                { max: 3, message: 'Max 3 digit allowed!' }
                                                            ]}
                                                        >
                                                            <Input
                                                                addonAfter="Kg"
                                                                maxLength={3}
                                                                value={nst_weight}
                                                                disabled={is_vitals_available}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item label="BMI" name="nst_bmi">
                                                            <Input addonAfter="kg/㎡" disabled={true} value={nst_bmi} />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item label="BMI Score" name="nst_bmi_score">
                                                            <Input disabled={true} value={nst_bmi_score} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>Has the Patient Lost Weight</label>
                                                </div>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <div className="" style={{ paddingLeft: '50px' }}>
                                                        <Form.Item name="nst_lost_weight" >
                                                            <Radio.Group
                                                                name="nst_lost_weight"
                                                                onChange={this.setNSTScore}
                                                                value={nst_lost_weight}
                                                            >
                                                                <Radio value={0} name="no">No</Radio>
                                                                <Radio value={1} disabled={nst_gained_weight == 1} name="yes">Yes</Radio>
                                                                <Radio value={2} name="unsure">Unsure</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                {
                                                    nst_lost_weight == 1 ?
                                                        <Fragment>
                                                            <div className='col-auto d-flex align-self-center'>
                                                                <label className="text-dark mb-0" style={{ fontWeight: '500' }}>How Much?</label>
                                                            </div>
                                                            <div className='col-1 d-flex align-items-center'>
                                                                <div className='antInputItem hasdata custom_mb-0'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="nst_loss_weight"
                                                                        rules={[
                                                                            { required: false, message: 'Required!' },
                                                                            { pattern: /^\d+$/, message: 'Max 3 digits required!' },
                                                                            { max: 2, message: 'Max 3 digit allowed!' }
                                                                        ]}
                                                                    >
                                                                        <Input addonAfter="kg" maxLength={2} />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                        : null
                                                }
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>Has the Patient Gained Weight</label>
                                                </div>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <div style={{ paddingLeft: '30px', paddingRight: '95px' }}>
                                                        <Form.Item name="nst_gained_weight" >
                                                            <Radio.Group
                                                                name="nst_gained_weight"
                                                                onChange={this.setNSTScore}
                                                            // value={[1, 2].includes(nst_lost_weight) ? '0' : null}
                                                            >
                                                                <Radio
                                                                    // disabled={[1, 2].includes(nst_lost_weight)}
                                                                    value={0}
                                                                    name="no">No
                                                                </Radio>
                                                                <Radio
                                                                    disabled={[1, 2].includes(nst_lost_weight)}
                                                                    value={1}
                                                                    name="yes">Yes
                                                                </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                {
                                                    nst_gained_weight && ![1, 2].includes(nst_lost_weight) ?
                                                        <Fragment>
                                                            <div className='col-auto d-flex align-self-center'>
                                                                <label className="text-dark mb-0" style={{ fontWeight: '500' }}>How Much?</label>
                                                            </div>
                                                            <div className='col-1 d-flex align-items-center'>
                                                                <div className='antInputItem hasdata custom_mb-0'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="nst_gain_weight"
                                                                        rules={[
                                                                            { required: false, message: 'Required!' },
                                                                            { pattern: /^\d+$/, message: 'Max 3 digits required!' },
                                                                            { max: 2, message: 'Max 3 digit allowed!' }
                                                                        ]}
                                                                    >
                                                                        <Input addonAfter="kg" maxLength={2} />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                        : null
                                                }
                                            </div>

                                            {/* if lost weight is yes */}
                                            <div className={nst_lost_weight == 1 ? 'row mt-3 show' : 'hide'}>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>How much weight has the patient lost </label>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='row'>
                                                        <div className='col-9 pr-0'>
                                                            <div className='antInputItem hasdata custom_mb-0'>
                                                                <Form.Item
                                                                    label="Weight"
                                                                    name="nst_weight_range"
                                                                >
                                                                    <Select
                                                                        style={{ width: '100%', marginBottom: '0px' }}
                                                                        onChange={(val) => this.setWeightRange(val, 'nst_weight_range')}
                                                                    >
                                                                        <Option value="1-5">1-5</Option>
                                                                        <Option value="6-10">6-10</Option>
                                                                        <Option value="11-14">11-14</Option>
                                                                        <Option value="15">{`>=15`}</Option>
                                                                        <Option value="Unsure">Unsure</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 pl-0'>
                                                            <div style={{ border: 'solid 1px #ccc', backgroundColor: '#eff0f0', height: '44px', paddingTop: '10px', textAlign: 'center', verticalAlign: 'middle', borderTopRightRadius: '3px', borderBottomRightRadius: '3px' }}>
                                                                Kg
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>Is there loss of appetite </label>
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div style={{ paddingLeft: '80px', paddingRight: '60px' }}>
                                                        <Form.Item name="nst_loss_appetite" >
                                                            <Radio.Group
                                                                name="nst_loss_appetite"
                                                                onChange={this.setNSTScore}
                                                            >
                                                                <Radio value={0} name="no">No</Radio>
                                                                <Radio value={1} name="yes">Yes</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>Does the patient have the below mentioned symptoms? </label>
                                                </div>
                                            </div>

                                            <div className='row mt-2'>
                                                <div className='col-2 d-flex align-items-center'>
                                                    <label className="text-primary mb-0"><span className='dot_assessment'></span>Edema</label>
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div style={{ paddingLeft: '45px' }}>
                                                        <Form.Item name="nst_edema" >
                                                            <Radio.Group
                                                                name="nst_edema"
                                                                onChange={this.setNSTScore}
                                                            >
                                                                <Radio value={0} name="no">No</Radio>
                                                                <Radio value={1} name="yes">Yes</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-2 d-flex align-items-center'>
                                                    <label className="text-primary mb-0"><span className='dot_assessment'></span>Nausea/Vomitin</label>
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div style={{ paddingLeft: '45px' }}>
                                                        <Form.Item name="nst_nausea_vomitin" >
                                                            <Radio.Group
                                                                name="nst_nausea_vomitin"
                                                                onChange={this.setNSTScore}
                                                            >
                                                                <Radio value={0} name="no">No</Radio>
                                                                <Radio value={1} name="yes">Yes</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-2 d-flex align-items-center'>
                                                    <label className="text-primary mb-0"><span className='dot_assessment'></span>Diarrhea</label>
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div style={{ paddingLeft: '45px' }}>
                                                        <Form.Item name="nst_diarrhea" >
                                                            <Radio.Group
                                                                name="nst_diarrhea"
                                                                onChange={this.setNSTScore}
                                                            >
                                                                <Radio value={0} name="no">No</Radio>
                                                                <Radio value={1} name="yes">Yes</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-2 d-flex align-self-center'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>Evaluation of Final Score </label>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                                <Form.Item
                                                                    label="Nutritional Score"
                                                                    name="nst_nutritional_score"
                                                                >
                                                                    <Input disabled={true} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                                <Form.Item
                                                                    label="Nutritional Status"
                                                                    name="nst_nutritional_status"
                                                                >
                                                                    <Input disabled={true} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-3'>
                                                    <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                        <Form.Item
                                                            label="Implication"
                                                            name="nst_implication"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-3'>
                                                    <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                        <Form.Item
                                                            label="Action"
                                                            name="nst_action"
                                                        >
                                                            <Input
                                                                id="action"
                                                                name="action"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-auto d-flex align-items-center'>
                                                    <label className="text-dark mb-0" style={{ fontWeight: '500' }}>4. Nutrition Care Education Given </label>
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div style={{ paddingLeft: '45px' }}>
                                                        <Form.Item name="nst_care_education" >
                                                            <Radio.Group>
                                                                <Radio value={0} name="no">No</Radio>
                                                                <Radio value={1} name="yes">Yes</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-12'>
                                                    <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                        <Form.Item
                                                            label="Remarks"
                                                            name="nst_remarks"
                                                            rules={[
                                                                { required: false, message: 'Required!' },
                                                                { pattern: /^[A-Za-z0-9- ]*$/, message: 'Only alphabets and numbers are allowed!' }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-12 text-center'>
                                                    <button className="btn btn-outline-secondary text-uppercase px-4 mr-2" type="submit" disabled={formSubmitted}>
                                                        {formMode == "SAVE" ? 'SAVE ' : 'UPDATE '}
                                                        {formSubmitted ? LOADER_RED : null}
                                                    </button>
                                                    <button className="btn btn-primary text-uppercase px-4 ml-2" type="submit" onClick={() => this.setState({ is_authorize: 1 })} disabled={formSubmitted}>AUTHORIZE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>

                                    : formType === "nursing-assessment-report" ?
                                        <Fragment>
                                            <div className='container-fluid px-0 single_page emrfrm'>
                                                <div className='row mt-4 emrfrm'>
                                                    {patient ? <VitalsSection patient={patient} handleModalPopupStateLift={this.handleModalPopup} /> : null}
                                                </div>
                                                <div className='row assessment-card'>
                                                    <div className='col-12'>
                                                        {
                                                            patient && this.state.reference_id ?
                                                                <PatientHistory
                                                                    field_status_patient_history={field_status_patient_history}
                                                                    patient={patient}
                                                                    consult_type={consult_type}
                                                                    getPatientData={this.getPatientData}
                                                                    dataLoading={this.state.dataLoading}
                                                                    initialSetup={this.initialSetup}
                                                                    field_status={this.state.field_status}
                                                                    reference_id={this.state.reference_id}
                                                                /> : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row mt-4 emrfrm'>
                                                    <h6 className="col-auto text-primary mb-0 d-flex align-self-center">HOME MEDICATION</h6>
                                                    <div className='col-3'>
                                                        <div className='antInputItem hasdata mb-0 custom_mb-0 form-label-group input-group'>
                                                            <AutoComplete
                                                                id="drug_id"
                                                                name="drug_id"
                                                                style={{ width: 100 + '%' }}
                                                                showSearch
                                                                showArrow={this.state.showAddDrugButton}
                                                                notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                                                                loading={this.state.formIsFetching}
                                                                onSelect={this.onChangeDrugsSelect}
                                                                onSearch={this.onDrugssearch}
                                                                className="select-drugs"
                                                                value={this.state.selected_drug}
                                                                filterOption={false}
                                                                dropdownClassName="custom_drug_select"
                                                                autoFocus={this.state.selected_drug != null && this.state.selected_drug != '' ? false : true}
                                                                showAction={this.state.selected_drug != null && this.state.selected_drug != '' ? [] : ['focus', 'click']}
                                                                disabled={this.props.editData != null || this.props.selectedDrug != null}
                                                            >
                                                                {this.optionDrugLoad()}
                                                            </AutoComplete>
                                                            <label htmlFor="drug_id">Medicine Name and Strength<span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <div className='antInputItem hasdata mb-0 custom_mb-0 form-label-group input-group'>
                                                                    <Form.Item
                                                                        label="Dosage"
                                                                        name="drug_dosage"
                                                                        valuePropName={this.state.selected_dosage}
                                                                    >
                                                                        <Input
                                                                            id="dosage"
                                                                            value={this.state.selected_dosage}
                                                                            type="text"
                                                                            onChange={(e) => this.handleChange('selected_dosage', e)}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='antInputItem hasdata mb-0 custom_mb-0'>

                                                                    <Form.Item
                                                                        name="since_day"
                                                                        className='mb-0'
                                                                        label="Since"
                                                                        valuePropName={'since_day'}
                                                                    >
                                                                        <DatePicker
                                                                            format="DD MMM, YYYY"
                                                                            placeholder=''
                                                                            className='mb-0'
                                                                            style={{
                                                                                width: 100 + "%",
                                                                                height: 35 + "px",
                                                                            }}
                                                                            onChange={(e) => this.handleDate(e, 'since_day')}
                                                                            // value={this.state.datetime_arrival ? moment(this.state.datetime_arrival) : null}
                                                                            value={this.state.since_day ? moment(this.state.since_day) : null}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col'>
                                                        <div className='row'>
                                                            <div className='col-5'>
                                                                <div className='form-label-group input-group'>
                                                                    <Select
                                                                        id="daily_frequency"
                                                                        name="daily_frequency"
                                                                        style={{ width: 100 + '%' }}
                                                                        placeholder="Frequency"
                                                                        onChange={this.onSelectDailyFrequencyChange}
                                                                        className="drug-input dailyFrequency"
                                                                        value={this.state.drug_daily_frequency}
                                                                    >
                                                                        {this.optiondaily_frequencyLoad()}
                                                                    </Select>
                                                                    <label htmlFor="daily_frequency">Frequency{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                                                                </div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className='antInputItem hasdata custom_mb-0 form-label-group input-group'>
                                                                    <Form.Item
                                                                        name="last_dose_date"
                                                                        className='mb-0'
                                                                        label="Last Dose Date & Time"
                                                                        valuePropName={'last_dose_date'}
                                                                    >
                                                                        <DatePicker
                                                                            format="DD MMM, YYYY HH:mm"
                                                                            placeholder=''
                                                                            className='mb-0'
                                                                            style={{
                                                                                width: 100 + "%",
                                                                                height: 35 + "px",
                                                                            }}
                                                                            onChange={(e) => this.handleDate(e, 'last_dose_date')}
                                                                            // value={this.state.datetime_arrival ? moment(this.state.datetime_arrival) : null}
                                                                            value={this.state.last_dose_date ? moment(this.state.last_dose_date) : null}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                {/* <button className="btn btn-outline-primary text-uppercase px-3 ml-2">ADD</button> */}
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-primary px-4  text-uppercase mandatory-save"
                                                                    id="drug-save"
                                                                    data-drug="-1"
                                                                    data-prescriptionid="-1"
                                                                    disabled={!this.state.allowEdit ? true : this.state.AddMedicineButton}
                                                                    onClick={() => { this.saveMedicineData(); }}
                                                                >{this.state.editMedicationInfo !== null ? 'Update' : 'Add'}</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-4'>
                                                    <div className='col-12'>
                                                        <table className='table table-bordered rounded-0'>
                                                            <thead>
                                                                <tr>
                                                                    <th width="16%" style={{ verticalAlign: 'middle' }}>MEDICATION NAME/MEDICINE NAME</th>
                                                                    <th width="8%" style={{ verticalAlign: 'middle' }}>DOSAGES</th>
                                                                    <th width="11%" style={{ verticalAlign: 'middle' }}>SINCE</th>
                                                                    <th width="9%" style={{ verticalAlign: 'middle' }}>FREQUENCY</th>
                                                                    <th width="12%" style={{ verticalAlign: 'middle' }}>END DATE/LAST DOSE</th>
                                                                    <th width="8%" style={{ verticalAlign: 'middle' }}>ACTION</th>
                                                                </tr>
                                                            </thead>
                                                            {this.state.homeMedication.length ? (
                                                                <tbody>
                                                                    {this.state.homeMedication.map((item, index) => (
                                                                        <tr>
                                                                            <td>{item.selected_drug}</td>
                                                                            <td>{item.selected_dosage}</td>
                                                                            <td>{item.since_day}</td>
                                                                            <td>{this.getFrequencyNameById(item.drug_daily_frequency)}</td>
                                                                            <td>{moment(item.last_dose_date).format('YYYY-MM-DD')}</td>
                                                                            <td className='text-center'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <a onClick={() => this.editHomeMedication(index)}><img src={EditImg} alt="edit" style={{}} /></a>
                                                                                    </div>
                                                                                    <div className='col-md-6 mt-1'>
                                                                                        <a onClick={() => this.deleteHomeMedication(index)}><i className="icon_delete"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>) : (
                                                                <tbody>
                                                                    <tr><td colspan="6">No Medicine Data Found.</td></tr>
                                                                </tbody>
                                                            )}
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <h6 className="col-12 text-primary mb-0">NUTRITION SCREENING</h6>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-2'>
                                                        <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                            <Form.Item
                                                                label="Nutritional Score"
                                                            >
                                                                <Input
                                                                    id="nut_score"
                                                                    name="nut_score"
                                                                    type="number"
                                                                    defaultValue={this.state.nut_score}
                                                                    disabled={true}
                                                                    value={this.state.nut_score}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                            <Form.Item
                                                                label="Nutritional Status"
                                                            >
                                                                <Input
                                                                    id="nut_status"
                                                                    name="nut_status"
                                                                    type="text"
                                                                    defaultValue={this.state.nut_status}
                                                                    disabled={true}
                                                                    value={this.state.nut_status}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-4'>
                                                    <h6 className="col-12 text-primary mb-0">PAIN SCREENING</h6>
                                                </div>

                                                <div className='row mt-3 emrfrm'>
                                                    <div className='col-2'>
                                                        <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                            <Form.Item
                                                                label="Pain Score"
                                                                id="pain_score"
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    name="pain_score"
                                                                    value={this.state.pain_score}
                                                                    onChange={(val) => this.setPainScreening(val)}
                                                                >
                                                                    <Option value="1">1</Option>
                                                                    <Option value="2">2</Option>
                                                                    <Option value="3">3</Option>
                                                                    <Option value="4">4</Option>
                                                                    <Option value="5">5</Option>
                                                                    <Option value="6">6</Option>
                                                                    <Option value="7">7</Option>
                                                                    <Option value="8">8</Option>
                                                                    <Option value="9">9</Option>
                                                                    <Option value="10">10</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='antInputItem hasdata mb-0 custom_mb-0'>
                                                            <Form.Item
                                                                label="Pain Status"
                                                            >
                                                                <Input
                                                                    id="pain_status"
                                                                    name="pain_status"
                                                                    value={this.state.pain_status}
                                                                    type="text"
                                                                    onChange={(e) => this.handleChange('pain_status', e)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-4'>
                                                    <div className='col-12 text-center'>
                                                        <button className="btn btn-outline-secondary text-uppercase px-4 mr-2" type="submit" disabled={formSubmitted}>
                                                            {formMode == "SAVE" ? 'SAVE ' : 'UPDATE '}
                                                            {formSubmitted ? LOADER_RED : null}
                                                        </button>
                                                        <button className="btn btn-primary text-uppercase px-4 ml-2" type="submit" onClick={() => this.setState({ is_authorize: 1 })} disabled={formSubmitted}>AUTHORIZE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : null
                            }
                        </Form>

                        {this.state.allAssessmentArr.length ?
                            <AssessmentTable
                                allAssessmentArr={this.state.allAssessmentArr}
                                editForm={(data) => { this.editForm(data) }}
                                pagination={pagination}
                                navigateDocumentToPage={this.navigateDocumentToPage}
                            />
                            : null
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}