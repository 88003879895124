import React, { Component } from "react"
import { DatePicker } from 'antd';
import moment from 'moment';

function QueueCalender(props){
    const {isCalendarEnabeld, previous_date, next_date, queue_date, handleAppointmentDate } = props;

    const handlePreviousNextDate = async (type) => {
      let appointment_date;
      if(type === "previous"){
        appointment_date =  (previous_date && previous_date != null)? previous_date : moment().format('YYYY-MM-DD');
      }else if(type === "next"){
        appointment_date =  (next_date && next_date != null)? next_date : moment().format('YYYY-MM-DD');
      }

      handleAppointmentDate(appointment_date);
    }

    return (
        <div className="container-fluid patient-queue-calendar mt-2 mb-4" style={{position: "relative"}}>
        <div className={(isCalendarEnabeld)? "show" : "hide"} style={{position: "absolute", backgroundColor: "#eff0f0", opacity: "0.7", top: 0, right: 0, bottom: 0, left: 0, zIndex: 1}}></div>
        <div className="row calendar_row">
            <div className="col-5 " >
            <span className=""><a disabled={(previous_date === null)?true:false} onClick={(previous_date === null)? false : () => handlePreviousNextDate(`previous`)}><span className="float-left chevron chevron-left arrow-left" /><span className="prev" style={(previous_date === null)? {color: "rgb(153 153 151)", opacity: "0.7", zIndex: 1} : null}></span></a></span>
            </div>
            <div className="col-2" >
            <span className="dashboard_calendar ml-4 ">
                <DatePicker
                id="dash_cal"
                name="dash_cal"
                format="dddd, DD MMM YYYY"
                allowClear={false}
                className="calendar_dash datetimepicker"
                selected={queue_date}
                value={moment(queue_date)}
                onChange={handleAppointmentDate}
                />
            </span>
            </div>
            <div className="col-5" >
            <span disabled="disabled" className="float-right"><a disabled={(next_date === null)?true:false } onClick={(next_date === null)? false : () => handlePreviousNextDate(`next`)}><span className="next" style={(next_date === null)? {color: "rgb(153 153 151)", opacity: "0.7", zIndex: 1} : null}></span><span className="float-right chevron chevron-right arrow-right" /></a></span>
            </div>
        </div>
        </div>
    )

}

export default QueueCalender;