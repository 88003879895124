import React from "react";
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from "../utils/localStorage";
import { AUTO_LOGIN, SYNC_TOKEN, USERTYPES, ENCRYPTION_KEY, LOADER_RED } from "../utils/constant";
import { headerService$, vcOpdEnabledSubjectService } from "../utils/rxjs-sharing";
import crypto from 'crypto';

class DoctorAutoLogin extends React.Component {
    state = {
        otp: '',
        syncTokenProcessing: false,
        staff_id: '',
        staff_name: '',
        status: '',
        token: '',
        user_type: '',
        otpDisabled: false,
        verifyOtpBtnDisabled: false,
        isInputSecure: true,
        loginType: '',
        refreshTab: false,
        tabId: Date.now().toString(),
    }

    componentDidMount() {
        // distroy session and local storege
        LS_SERVICE.clear();
        localStorage.clear();
        sessionStorage.clear();

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        LS_SERVICE.set('xl-auto', token);
        LS_SERVICE.set('currentTabId', this.state.tabId);
        this.getUserData(token);
    }

    getUserData = async (token) => {
        try {
            let userData = await Axios.get(AUTO_LOGIN({ token: token }));
            // console.log('auto-login-data:', userData?.data);
            if (userData?.data?.status) {
                let { staff_id, staff_name, token, user_type, hid } = userData?.data?.data;
                hid = hid !== undefined && hid !== null ? hid : 1;
                LS_SERVICE.set('token', token);
                LS_SERVICE.set('staff_name', staff_name);
                LS_SERVICE.set('staff_id', staff_id);
                LS_SERVICE.set('user_type', user_type);
                // LS_SERVICE.delete('loginMobile');
                // LS_SERVICE.delete('xlToken');
                this.setState({
                    staff_id,
                    staff_name,
                    token
                }, () => {
                    this.handleSyncToken(token, hid);
                });
            }
            else {
                window.location.href = '/';
            }
        }
        catch (err) {
            window.location.href = '/';
        }
    }

    handleSyncToken = (token, hid) => {
        const { staff_id } = this.state;
        this.setState({ syncTokenProcessing: true })
        let syncTokenPayload = {
            staff_id: staff_id,
            hospital_id: hid
        }
        Axios.defaults.headers.common["Authorization"] = token;
        Axios.post(SYNC_TOKEN, qs.stringify(syncTokenPayload)).then(success => {

            const result = success.data;
            if (result.status) {
                let profile = this.decryptColumn(result.staff.profile_picture);
                LS_SERVICE.set('staff_image', profile);

                LS_SERVICE.set('is_authenticated', true);

                let SLOTS = result.slots;
                let isHM = result?.is_homecare_manager !== undefined && result?.is_homecare_manager !== null ? result?.is_homecare_manager : 0;
                LS_SERVICE.set('IS_HOMECARE_MANAGER', isHM);

                if (typeof SLOTS != 'undefined' && SLOTS.length > 0) {
                    for (let index = 0; index < SLOTS.length; index++) {
                        const doctorObj = SLOTS[index].doctor;
                        doctorObj.id = this.decryptColumn(doctorObj.id);
                        doctorObj.name = this.decryptColumn(doctorObj.name);
                        doctorObj.first_name = this.decryptColumn(doctorObj.first_name);
                        doctorObj.last_name = this.decryptColumn(doctorObj.last_name);
                        doctorObj.middle_name = this.decryptColumn(doctorObj.middle_name);
                        doctorObj.woodlands_email = this.decryptColumn(doctorObj.woodlands_email);
                        doctorObj.woodlands_mobile = this.decryptColumn(doctorObj.woodlands_mobile);
                        doctorObj.is_chat_enable = this.decryptColumn(doctorObj.is_chat_enable);
                        doctorObj.is_enable_for_multiple_calling = this.decryptColumn(doctorObj.is_enable_for_multiple_calling);
                        result.slots.doctor = doctorObj;
                    }
                }


                if (result.staff.role_id == USERTYPES.doctor) {
                    LS_SERVICE.set('doctor', {
                        name: ('Dr ' + result.staff.first_name + ' ' + (result.staff.last_name != null ? result.staff.last_name : '')),
                        code: result.staff.code
                    });

                    if (result.is_junior_doctor && result.is_junior_doctor != null) {
                        LS_SERVICE.set('is_junior_doctor', result.is_junior_doctor)
                    }
                    if (result.senior_doctor && result.senior_doctor.length > 0) {
                        LS_SERVICE.set('senior_doctor', result.senior_doctor)
                    }
                    if (result.junior_doctor_complete_appointment && result.junior_doctor_complete_appointment != null && result.junior_doctor_complete_appointment != undefined) {
                        LS_SERVICE.set('junior_doctor_complete_appointment', result.junior_doctor_complete_appointment)
                    }

                    if (result.pending_patients && result.pending_patients.length > 0) {
                        LS_SERVICE.set(
                            'pending_patients',
                            result.pending_patients
                        );
                    } else {
                        LS_SERVICE.delete('pending_patients');
                    }
                    if (result.staff.doctor_speciality != undefined && result.staff.doctor_speciality.length > 0) {
                        LS_SERVICE.set('speciality_id', result.staff.doctor_speciality[0]);
                    } else {
                        LS_SERVICE.delete('speciality_id');
                    }

                    if (result.slots && result.slots.length > 0) {
                        LS_SERVICE.set(
                            'slot_hospital_id',
                            result.slots[0]['id']
                        );
                        LS_SERVICE.set(
                            'slot_hospital_master',
                            result.slots[0]['own_master']
                        );
                        LS_SERVICE.set('slot_hospitals', result.slots);
                    } else {
                        LS_SERVICE.delete('slot_hospital_id');
                    }

                    if (result.slots && result.slots.length == 1) {
                        this.props.history.push('/patient-queue/' + result.slots[0].id);
                    } else {
                        this.props.history.push('dashboard');
                    }
                }

                if (result.staff.role_id == USERTYPES.nurse) {
                    // set selected nurse doctor ids
                    if (result?.nurse_doctors.length > 0) {
                        LS_SERVICE.set('nurse_doctor_id', result?.nurse_doctors);
                    }

                    //Check if nurse logged in and set doctor speciality
                    if (result.doctor && result.doctor.length > 0) {
                        let allDoctors = result.doctor;
                        var doctorSpecialties = [];
                        allDoctors.forEach((v, k) => {
                            var obj = {
                                "id": v.id,
                                "specialtiesIds": Object.keys(v.specialityDetail)
                            };
                            doctorSpecialties.push(obj);
                        });
                        LS_SERVICE.set('doctor_speciality_id', doctorSpecialties);
                    }

                    if (result.hospital && result.hospital.length > 0) {
                        LS_SERVICE.set(
                            'slot_hospital_id',
                            result.hospital[0]['hospital_id']
                        );
                        LS_SERVICE.set(
                            'slot_hospital_master',
                            result.hospital[0]['own_master']
                        );
                        LS_SERVICE.set('slot_hospitals', result.hospital);
                    } else {
                        LS_SERVICE.delete('slot_hospital_id');
                    }
                    if (result.doctor && result.doctor.length > 0) {
                        LS_SERVICE.set('nurse_doctors', result.doctor);
                    } else {
                        // LS_SERVICE.delete('nurse_doctors');
                        LS_SERVICE.set('nurse_doctors', []);
                        LS_SERVICE.set('nurse_homecare', true)
                    }
                    this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                    LS_SERVICE.set('nurse_doctors', result.doctor);
                    const isVcopdEnabled = result.is_vc_opd_enabled ? result.is_vc_opd_enabled : 0;
                    LS_SERVICE.set('nurse_is_vc_opd_enabled', isVcopdEnabled);
                    vcOpdEnabledSubjectService.init({
                        isVcOpdEnabled: isVcopdEnabled
                    });
                }
                headerService$.showHeader({ showHeader: 'true' });
            }
        }).catch(err => console.log(err))
    }

    decryptColumn = (string) => {
        if (string == null || string == '') {
            return null;
        }
        const encryption_key = ENCRYPTION_KEY;
        const encryptionMethod = 'AES-256-CBC';
        const secret = encryption_key.substring(0, 32); // must be 32 char length
        const iv = secret.substring(0, 16);

        const decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
        // decryptor.setAutoPadding(false);
        const decryptedString = decryptor.update(String(string), 'base64', 'utf8') + decryptor.final('utf8');
        return decryptedString;
    }

    loader() {
        return (
            <div className="col-md-12 text-center">{LOADER_RED}</div>
        )
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className='tab-pane mt-5'>
                                <div className="row">
                                    <div className="col-12 mt-5">
                                        {this.loader()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default DoctorAutoLogin;