import LS_SERVICE from './localStorage';
import moment from "moment";
import { FileViewerService } from './rxjs-sharing';
import Axios from 'axios';
import { GET_ARRAY_BUFFER_FORM_URL } from './constant';

const AppHelpers = {

    setDashboardSlots: function (info) {
        const now = moment().format('DD-MM-YYYY');
        let slots = {};
        slots[now] = info.slots;
        const lsSlots = LS_SERVICE.get('DASHBOARD_SLOTS');
        let newLsSlots = {};
        if (lsSlots != null) {
            if (typeof lsSlots[now] != 'undefined') {
                delete lsSlots[now];
            }
            newLsSlots = { ...slots, ...lsSlots };
        } else {
            newLsSlots = slots;
        }
        LS_SERVICE.set('DASHBOARD_SLOTS', newLsSlots);
        return true;
    },

    getDashboardSlots: function () {
        const now = moment().format('DD-MM-YYYY');
        let lsSlots = LS_SERVICE.get('DASHBOARD_SLOTS');
        let slots = null;
        if (lsSlots != null && typeof lsSlots[now] != 'undefined') {
            slots = lsSlots[now];
        }
        return slots;
    },

    showRx: function (rx) {
        if (typeof rx.pdf == 'undefined') {
            return;
        }

        let toPrint = (typeof rx.print != 'undefined' && rx.print) ? true : false;
        if (typeof rx.consult_type != 'undefined' && rx.consult_type == 1) {
            toPrint = false;
        }
        if (toPrint) {
            Axios.post(
                GET_ARRAY_BUFFER_FORM_URL,
                { file_url: rx.pdf },
                { responseType: 'arraybuffer' }
            ).then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                this.launchCompRxViewer(url, toPrint);
            }).catch((error) => {
                console.log(error);
                this.launchCompRxViewer(rx.pdf, false);
            })
        } else {
            this.launchCompRxViewer(rx.pdf, toPrint);
        }

        return;
    },

    launchCompRxViewer: function (pdfUrl, toPrint) {
        FileViewerService.init({
            modalTitle: 'View Prescription',
            showFileModal: true,
            files: [{
                type: 'pdf',
                path: pdfUrl
            }],
            print: toPrint
        });
    },

    capitalizeFirstLetter: function (string) {
        if (string !== null && string !== "" && string !== undefined) {
            let newString = string.toString();
            return newString.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        } else {
            return;
        }
    },

};

export default AppHelpers;