import React, { Fragment } from 'react';
import { withRouter } from "react-router-dom";
import Axios from 'axios';
import { CANCEL_HUNDRED_MS_CALL, HUNDRED_MS_CALL_API, MPC_DOCTORS, MPC_HUNDREDMS_DOCTOR_JOIN_CALL, HUNDRED_MS_P2P_CALL } from "../../../utils/constant";
import { notification, Avatar, Drawer, Input, List, Form, Radio } from 'antd';
import LS_SERVICE from '../../../utils/localStorage';
import './hundredms.css';
import { MultiPartyCallService, callService } from '../../../utils/rxjs-sharing';
import VC_HELPER from '../../../utils/vc-helpers';
import { UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';

const { Search } = Input;

class HundredmsCall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            room_code: "",
            boxMaxsize: true,
            multi_party_calling: false,
            showDoctorList: false,
            selectedAddonDoctor: {
                id: null,
                name: null
            },
            searchingDoctor: false,
            callInfo: {},
            addOnDoctors: [],
            junior_doctor: false,
            room_id: "",
        };
    }

    componentDidMount() {
        this.mpcSubscription = MultiPartyCallService.status().subscribe(result => {
            if (result && result.start && result.video_call_driver == 3) {
                if (result.session_id) {
                    this.setState({
                        room_code: result.room_code,
                        room_id: result.session_id,
                        junior_doctor: true
                    })
                } else {
                    this.findRoomCode(result.booking_id);
                }
                LS_SERVICE.set('is_homecarecall', result.is_homecarecall);
                if (result.is_homecarecall) {
                    LS_SERVICE.set('homecare_booking_id', result.booking_id);
                }
                this.setState({
                    callInfo: result,
                    multi_party_calling: true
                }, () => {
                    this.getAddOnDoctors('');
                });

            }
            if (result && result.end && result.video_call_driver == 3) {

            }
        });
        this.p2pSubscription = callService.status().subscribe(result => {
            if (result && result.start && result.video_call_driver == 3) {
                this.findRoomCode(result.booking_id);
                this.setState({
                    callInfo: result,
                });
            }
            if (result && result.end && result.video_call_driver == 3) {

            }
        });
        window.addEventListener('offline', this.handleOnlineStatusChange);
    }

    componentWillUnmount() {
        if (this.p2pSubscription) {
            this.p2pSubscription.unsubscribe();
        }
        if (this.mpcSubscription) {
            this.mpcSubscription.unsubscribe();
        }
        window.removeEventListener('offline', this.handleOnlineStatusChange);
    }

    handleOnlineStatusChange = () => {
        this.setState({
            room_code: ""
        })
    }

    async findRoomCode(booking_id) {
        try {
            const PARAMS = {
                booking_id: booking_id,
            }
            let response = await Axios.post(HUNDRED_MS_CALL_API, PARAMS);
            if (response?.data?.code) {
                this.setState({
                    room_code: response.data.code,
                    room_id: response.data.room_id,
                })
            }
            else if (response?.data?.status == 101) {
                notification.error({
                    message: response?.data?.message ? "Patient is not logged into the Patient App" : "Room code not found so not able to start the call",
                    placement: 'topRight'
                });
            }
            else {
                notification.error({
                    message: "Room code not found so not able to start the call",
                    placement: 'topRight'
                });
            }
        } catch (error) {
            notification.error({
                message: "Room code not found so not able to start the call",
                placement: 'topRight'
            });
        }
    }

    async getAddOnDoctors(keyword) {
        this.setState({
            searchingDoctor: true
        });
        try {
            const { callInfo } = this.state;
            const hospitalId = callInfo.hospital_id;
            // const hospitalId = 2; // :Fix
            const response = await Axios.get(MPC_DOCTORS + '?hospital_id=' + hospitalId + '&keyword=' + keyword);
            if (typeof response.data != 'undefined' && typeof response.data.doctors != 'undefined') {
                this.setState({
                    addOnDoctors: response.data.doctors,
                    searchingDoctor: false
                });
            } else {
                this.setState({
                    addOnDoctors: [],
                    searchingDoctor: false
                });
            }
        } catch (error) {
            this.setState({
                addOnDoctors: [],
                searchingDoctor: false
            });
        }
    }

    cancelVccall() {
        const { callInfo } = this.state;
        const PARAMS = {
            booking_id: callInfo.booking_id,
            room_id: this.state.room_id
        }
        Axios.post(CANCEL_HUNDRED_MS_CALL, PARAMS);
        // this.setState({
        //     room_code: "11234564567854567%*$(##($#)@#($%$(#)@(#$%*$#(@#$*%$(#@#*$#(35676545678765"
        // }, () => {
        //     // this.props.updatevcPopupcPopup("");
        //     this.setState({
        //         room_code: "",
        //         room_id: "",
        //         boxMaxsize: true,
        //         multi_party_calling: false
        //     })
        // })
        this.setState({
            room_code: "",
            room_id: "",
            boxMaxsize: true,
            multi_party_calling: false
        })
    }

    changesize() {
        const { boxMaxsize } = this.state;
        this.setState({
            boxMaxsize: !boxMaxsize
        }, () => {
            if (!boxMaxsize) {
                document.getElementById('vmp_vc_dialog_v2').removeAttribute('style');
            }
        })
    }

    callSettings() {
        this.setState({
            showDoctorList: true,
            selectedAddonDoctor: {
                id: null,
                name: null
            }
        });
    }

    async inviteDoctorToJoinCall() {
        notification.destroy();
        const { selectedAddonDoctor, callInfo, room_id } = this.state;
        try {
            const response = await Axios.post(MPC_HUNDREDMS_DOCTOR_JOIN_CALL, {
                booking_id: callInfo.booking_id,
                doctor_id: selectedAddonDoctor.id,
                session_id: room_id
            });
            if (typeof response.data != 'undefined' && typeof response.data.token != 'undefined') {
                this.setState({
                    showDoctorList: false
                }, () => {
                    notification.open({
                        message: 'Invite Doctor',
                        description: 'Invite has been sent to doctor for joining the call.'
                    });
                });
            } else {
                notification.error({
                    message: 'Invite Doctor',
                    description: response?.data?.message
                });
            }
        } catch (error) {
            notification.error({
                message: 'Invite Doctor',
                description: JSON.stringify(error)
            });
        }
    }

    render() {
        setTimeout(() => {
            VC_HELPER.dragElementV2();
        }, 500);

        const { showDoctorList, junior_doctor, selectedAddonDoctor, searchingDoctor, addOnDoctors, multi_party_calling, boxMaxsize } = this.state;
        return (
            <Fragment>
                {this.state.room_code ? <>
                    <div id="vmp_vc_dialog_v2" className={boxMaxsize ? "hundredms-overlay" : "hundredms-small-overlay"}>
                        {boxMaxsize ? null : <div id="vmp_vc_dialog_drag_v2" title="Hold and drag this element" className="drag-item-panel">:::</div>}
                        <div className='hundredms-header'>
                            VC Conferencing
                            <button className='btn btn-primary btn-sm pull-right mr-1'
                                onClick={() => this.cancelVccall()}
                            >Close</button>
                            <button className='btn btn-primary btn-sm pull-right mr-1'
                                onClick={() => this.changesize()}
                            >{boxMaxsize ? "Minimize" : "Maximize"}</button>
                            {/* {multi_party_calling && !junior_doctor ?
                                <button onClick={() => this.callSettings()} className="btn btn-success btn-sm pull-right mr-1">
                                    Add Doctor
                                </button>
                                : null} */}
                        </div>
                        <div className=''>
                            <iframe
                                title="100ms-app"
                                allow="camera *;microphone *;display-capture *"
                                src={`${HUNDRED_MS_P2P_CALL}${this.state.room_code}`}
                                style={{ height: "94vh", width: "100%", border: "0" }}
                            >
                            </iframe>
                        </div>
                    </div>
                </> : null}

                <Drawer
                    title="Add Doctor to Call"
                    placement="right"
                    visible={showDoctorList}
                    onClose={() => this.setState({ showDoctorList: false })}
                    footer={(
                        <button onClick={() => this.inviteDoctorToJoinCall()} disabled={selectedAddonDoctor.id == null} className={"btn btn-block " + (selectedAddonDoctor.id == null ? 'btn-primary' : 'btn-success')}>
                            {selectedAddonDoctor.id != null ? <React.Fragment>
                                Call <strong>{selectedAddonDoctor.name}</strong>
                            </React.Fragment> : 'Add to Call'}
                        </button>
                    )}
                    style={{ zIndex: "9999" }}
                >
                    <div className="row pb-2">
                        <div className="col-12">
                            <Search size="large" placeholder="Search by name" prefix={<UserOutlined />} loading={searchingDoctor} onSearch={(e) => this.getAddOnDoctors(e)} enterButton />
                        </div>
                    </div>

                    <List
                        dataSource={addOnDoctors}
                        renderItem={item => (
                            <List.Item
                                key={Math.random()}
                                onClick={(e) => this.setState({
                                    selectedAddonDoctor: {
                                        id: item.doctor_id,
                                        name: item.doctor_name
                                    }
                                })}
                                style={{ cursor: 'pointer' }}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={item.doctor_profile_picture} />}
                                    title={<strong style={{ textTransform: 'capitalize' }}>{item.doctor_name}</strong>}
                                    description={item.doctor_speciality}
                                />
                                <div>
                                    <Form.Item initialValue={null}>
                                        <Radio
                                            checked={item.doctor_id == selectedAddonDoctor.id}
                                            value={item.doctor_id}
                                            label={item.doctor_name}
                                        ></Radio>
                                    </Form.Item>
                                </div>
                            </List.Item>
                        )}
                    />
                </Drawer>
            </Fragment>
        )
    }
}

export default withRouter(HundredmsCall);