import Axios from 'axios';
import qs from 'qs';
import React, { Component } from 'react';
import LS_SERVICE from '../../../utils/localStorage';
import {
  LOADER,
  REFERENCE_TYPE,
  ASSESSMENT_API,
  ASSESSMENT_DELETE,
  ASSESSMENT_LIST,
  ASSESSMENT_STATUS_UPDATE,
  ASSESSMENT_STORE,
  FAVOURITE_DIAGNOSIS,
  CIMSALLOW,
  CIMS_ALLOW_HOSPITALS,
  CIMS_DIAGNOSIS_INTERACTION_EXISTS,
  CIMS_DIAGNOSIS_INTERACTION,
} from '../../../utils/constant';
import { Link } from 'react-router-dom';
import { jQueryLabels, AntSelectLabels, FixedCta } from '../../../utils/jQueryLabels';
import { DatePicker, Radio, Modal, Select, Button, Form, Checkbox, Input, notification, Tooltip } from 'antd';
import AssessmentTableSS from "../../../components/assessments/assessment-table-ss";
import AssessmentForm from "../../../components/assessments/assessment-form";
import CopyToVisitSS from "../../copy-to-visit/copytovisitss";
import icImagereference from '../../../assets/images/ic-imagereference.png';
import SearchImg from '../../../assets/images/ic-search.svg';
const { Option } = Select;
const TYPE = ['Primary', 'Secondary'];
const STAGE = ['Provisional', 'Final'];


class AssessmentComponentSS extends Component {

	constructor(props) {
		super(props);
		this.state = {
			patient: props.patient,
			hospital_id: props.hospital_id,
			patient_id: props.patient.patient_id,
			reference_id: props.patient.qms_token_id,
			allowEdit: props.allowEdit,
      previousDiagnosis: [],
      assessmentData: [],
      addedAssessmentList: [],
      constants: [],
      planSuggestedDiagnosisModal: false,
      planSuggestedCPModal: false,
      assessDataLoaded: false,
			addDiagnosisModal: false,
      deleteConfirmModal: false,
      template_id: null,
      showCareProtocolModal: false,
      cp_name: null,
      spin_loading: false,
      delete_text: '',
      delete_data: null,
      actionText: 'Add',
      drug_id_Interaction_Modalurl:[],
      careProtoColId:0,
			referenceType:0,
			patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
			referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
			StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
			FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
			enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      diagnoseData:{},
      diagnoseId:0,
      hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id'))
		};
    this.assessFormRef = React.createRef();
	}

	componentDidMount() {
		this.handleAssessmentData();
    jQueryLabels();
    FixedCta();
	}

  handleStatusSwitch = async (e, i, data) => {
    if(!this.state.allowEdit)
        return;

    const { id, status } = data;
    let { assessmentData } = this.state;

    const switchPromise = await Axios.get(ASSESSMENT_STATUS_UPDATE({ id, status: e ? 0 : 1 }))

    if (switchPromise) {
        assessmentData[i].status = e ? 0 : 1;
        this.setState({ assessmentData });

        const { data } = switchPromise;

        notification.success({
            message: data.message,
            placement: 'topRight'
        })
    }
  }

  handleCancel = () => {
    this.setState({ 
      planSuggestedDiagnosisModal: false,
      planSuggestedCPModal: false 
    });
  }

  handleAssessmentData = async () => {
    const { patient } = this.state;
    const { qms_token_id } = patient;

    const assessDataPromise = await Axios.get(ASSESSMENT_API({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id }))

    if (assessDataPromise.data) {
      let { data } = assessDataPromise.data;
      let assessmentTempArray = [];
      let addedAssessmentList = [];
      if (data.details || data.details.length > 0) {
        data.details.map((data, i) => {
          var tempAssessmentObj = {
            description: data?.diagnosisInfo?.diagnose_description,
            diagnosis_id: data?.diagnosisId,
            icd_code: data?.diagnosisInfo?.icd_code,
            id: data.id,
            image_comment: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
            image_uploaded: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
            stage: data.stage,
            type: data.type,
            status: data.status,
            diagnosis_notes: data.diagnosisNotes,
          }
          assessmentTempArray.push(tempAssessmentObj);
          addedAssessmentList.push(tempAssessmentObj.diagnosis_id)
        });
      }
      var constants = {
        assessmentStage: STAGE,
        assessmentType: TYPE
      };
      if(assessmentTempArray.length >0 ){
        this.props.viewAssesmentData(assessmentTempArray.length);
      }
      else{
        this.props.viewAssesmentData(0);
      }

      this.setState({
        previousDiagnosis: data.activeDiagnosis,
        assessmentData: assessmentTempArray,
        addedAssessmentList: addedAssessmentList,
        constants: constants,
        assessDataLoaded: true
      })
    }
  }

  handleModalPopup = async (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
    if (e !== null)
      e.preventDefault();

    await this.setState({
      planSuggestedCPModal: false,
      [popupName]: !this.state[popupName]
    }, () => {
      const { imageReferenceModal, deleteConfirmModal, addDiagnosisModal, showCareProtocolModal } = this.state;
      this.props.loadCareProtocolAddedData();
      if(addDiagnosisModal){
        this.setState({
          showMessageType: undefined
        })
      }
      if(!addDiagnosisModal){
        this.clearEdit()
      }
      if(!showCareProtocolModal){
        this.setState({
          template_id: null,
          cp_name: null
        })
      }
      if (deleteConfirmModal) {
        if (data !== null) {
          this.setState({
            delete_text: data.data.description,
            delete_data: data.data
          })
        }
      }
    })
  }

  clearEdit = () => {
    this.setState({
      editData: null,
      editIndex: -1,
      actionText: 'Add'
    })
  }

  handleTemplateRadioSubmit = (templateId) => {
    const { patient, isPracticing, hospital_id } = this.state;
    const { patient_id, qms_token_id } = patient;

    /* if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    } */

    if (templateId) {
      this.props.history.push({
        /* pathname: `/template/select/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}/${templateId}`,
        state: { patient } */
        pathname: `/template/selectss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}/${templateId}`,
        state: { patient }
      });
    }
  }

  updateAssessmentData = async (data) => {
    if (data.i != -1)
        data.assessment_id = data.id
    data.reference_type = REFERENCE_TYPE;
    data.reference_id = this.state.patient.qms_token_id;

    const assFormPromise = await Axios.post(ASSESSMENT_STORE, qs.stringify(data))
    this.setState({ editData: null, editIndex: -1 })
    if (assFormPromise.data) {
      if (data.i != undefined && data.i != -1){
        this.handleModalPopup(null, 'addDiagnosisModal');
        /* this.setState({
          showMessageType: 'Update'
        }) */
        notification.success({
          message: 'Diagnosis updated successfully.',
          placement: 'topRight'
        })
      } else {
        this.setState({
          showMessageType: 'Add'
        });
        setTimeout(() => this.setState({
          showMessageType: undefined
        }), 2000)
      }
        this.handleAssessmentData();
        /* notification.success({
            message: assFormPromise.data.message,
            placement: 'topRight'
        }) */
        /*this.setState({
            formModeEdit: false,
            isProcessing: false
        })*/
    }
  }

  handleEdit = async (data, i) => {

    /* const { isPracticing } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    } */
    await this.handleModalPopup(null, 'addDiagnosisModal');
    this.setState({
      actionText: "Edit",
      editData: this.state.assessmentData[i],
      editIndex: i
    })
    AntSelectLabels();
  }

  handleDeletePopSubmit = (e) => {
    const { delete_data } = this.state;

    this.setState({
      spin_loading: true
    })

    Axios.get(ASSESSMENT_DELETE({ id: delete_data.id }))
      .then(success => {
        this.handleAssessmentData();
        this.setState({
          spin_loading: false
        })
        this.handleModalPopup(null, 'deleteConfirmModal');

        this.assessFormRef?.current?.FormClear();
      })
      .catch(err => console.log(err))
      e.preventDefault();
  }

  IsApplyFav = async (data) => {
    let {referenceId,StoreId,FacilityId,enterpriseId,patientId,referenceType} = this.state;
    let careProtoColId      = 0;
    let drugIds             = 0;
    let orederSetId         = 0;
    let medicineScreen      = 'medicineform';
    let addedAssessmentList = data.id;
    if (CIMSALLOW && CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId)) {
    await Axios.get(CIMS_DIAGNOSIS_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList }))
      .then(success => {
        if (success?.data?.interaction) {
          this.checkCimsInteractionfav = true;
          this.setState({ drug_id_Interaction_Modalurl:drugIds,careProtoColId: careProtoColId, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId,drugData:data, isModalInteractionVisibleFav: this.checkCimsInteractionfav ,diagnoseId:addedAssessmentList,diagnoseData:data});
        }
        else {
          this.checkCimsInteractionfav = false;
          this.addFavMed               = true;
          let e ={
            checkConditon:true,
            target:{checked:true}
          }
          this.selectFavDiagnosis(e,data,'','yes');
        }
      })
      .catch(err => console.log(err))
          
    }else{
      this.checkCimsInteractionfav = false;
      this.addFavMed               = true;
      let e ={
        checkConditon:true,
        target:{checked:true}
      }
      this.selectFavDiagnosis(e,data,'','yes');
    }
  }

  handleInteractionCancel = () => {
    this.setState({ isModalInteractionVisibleFav: false });
  };

  handleInteractionOk = async () => {
    
    await this.setState({ isModalInteractionVisibleFav: false})
    const {diagnoseData} = this.state
    let e ={
      checkConditon:true,
      target:{checked:true}
    }
    this.selectFavDiagnosis(e,diagnoseData,'','yes')
  };


  selectFavDiagnosis = async (e, data, checkIndex, type) => {
    if(type == 'No'){
      e.preventDefault();
    }
    if (e?.target.checked == true) {
      if(type=='No'){
        this.IsApplyFav(data)
        return;
      }
    } else {
      this.addFavMed = false;
    }
    if(e.target.checked == true){
      await this.updateAssessmentData({
        description: data.description,
        diagnosis_id: data.id,
        icd_code: data.icd_code,
        id: null,
        image_comment: '',
        stage: 0,
        type: 0,
        status: 0,
        diagnosis_notes: '',
        assessment_id: null,
        reference_type: REFERENCE_TYPE,
        reference_id: this.state.reference_id
      })
    }
  }

  updateCPName = (name) => {
    this.setState({
      cp_name: name
    })
  }

  updateData = () => {
    this.handleModalPopup(null, 'showCareProtocolModal');
    this.handleAssessmentData();
    this.props.loadCareProtocolAddedData();
  }

	render() {
    const { patient } = this.state;
    let addedAssessmentList = [...new Set(this.state.addedAssessmentList)];
    var finalSuggestedDiagnosticList = [];
    if(this.props.suggestedDiagnosis?.length > 0){
      for(var suggestedDiagnose of this.props.suggestedDiagnosis){
        var added = 'N';
        for(var addedAssessment of addedAssessmentList){      
          if(suggestedDiagnose.id == addedAssessment){
            suggestedDiagnose.is_selected = 'Y'
            finalSuggestedDiagnosticList.push(suggestedDiagnose)
            added = 'Y'
            break;
          }
        }
        if(added == 'N'){
          suggestedDiagnose.is_selected = 'N'
          finalSuggestedDiagnosticList.push(suggestedDiagnose)
        }
      }
    }
		return (
			<>
				{/*##### Suggested Care protocols card  and Diagnosis Card #### */}
				<div className='row'>
					{/* Diagnosis Card */}

					<div className='col-12'>
						<div className="card card-bg-lightblue diagnosis-card pb-2 mb-0">
							<div className="card-body p-0">
								<div className='container-fluid px-0'>
									<div className='row d-flex align-items-center'>
										<div className='col-2'>
											<h6 className='text-darks text-uppercase mb-0'>DIAGNOSIS</h6>
										</div>
										{/* <div className='col text-right'>
											<span className='pr-2'><img style={{ width: "18px", height: '18px' }} src={SearchImg} alt="search" className="icon" /></span>
											<a className='text-secondary'><u>Search Diagnosis</u></a>
										</div> */}
                    {this.state.allowEdit ?
                      <div className="col-10 text-right pr-5">
                        {
                          this.props.allCP?.length > 0
                        ?
                          <a className='text-primary mr-4' onClick={() => { this.setState({ planSuggestedCPModal: true }) }}><u>Apply Care Protocols</u></a>
                        :
                          null
                        }
                        {/* {isPracticing ? ( */}
                          {/* <Link to={{
                            pathname: `/template/select/${this.state.hospital_id}/${this.state.patient?.patient_id}/${REFERENCE_TYPE}/${this.state.patient?.qms_token_id}`,
                            state: { patient }
                          }} className="btn btn-outline-primary" id="from-template">FROM CARE PROTOCOL</Link> */}
                          <a onClick={() => {  this.handleModalPopup(null, 'addDiagnosisModal'); }}
                          className="btn btn-outline-primary small px-2" id="from-template">+ DIAGNOSIS</a>
                        {/* ) : ( */}
                          {/* <a className="btn btn-outline-primary" id="from-template" href="#!" onClick={e => this.handleModalPopup(e, 'isPracticingModal')}>FROM CARE PROTOCOL</a> */}
                        {/* )} */}
                      </div>
                      : null}
									</div>

                  {
                    this.props.suggestedCP?.length > 0 || this.props.suggestedDiagnosis?.length > 0
                  ?
                    <div className='row mt-2'>
                      {
                        this.props.suggestedCP?.length > 0
                      ?
                        <div className='col-6'>
                            <div className="card card-bg-lightblue suggest-protocol-card mb-0 " style={{height:'100%'}}>
                                <div className="card-body">
                                    <div className='container-fluid'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-auto'>
                                                {
                                                  this.props.cpDiagnosisType == 'Related'
                                                ?
                                                  <h6 className='text-primary text-uppercase mb-0'>RELATED CARE PROTOCOL</h6>
                                                : 
                                                  <h6 className='text-primary text-uppercase mb-0'>FAVOURITE CARE PROTOCOL</h6>
                                                }
                                            </div>
                                            <div className='col text-right'>
                                              <div className='col text-right'>
                                                <a className='text-primary' onClick={() => { this.setState({ planSuggestedCPModal: true }) }}><u>View All</u></a>
                                              </div>
                                            </div>
                                        </div>

                                        <div className='row mt-2'>
                                            <div className='col-12 d-flex align-items-center flex-wrap'>
                                                <ul className='list-unstyled d-flex flex-wrap mb-0'>
                                                  {this.props.suggestedCP?.map((data, i) => {
                                                    return(
                                                      <li className='pr-3 pb-1'>
                                                          {/* <div className="custom-control custom-checkbox custom-control-inline mr-1"> */}
                                                          <div className="custom-checkbox custom-control-inline mr-1">
                                                              {/* <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"/>
                                                              <label className="custom-control-label" htmlFor="fav"></label> */}
                                                              <a href="#" className='text-primary' onClick={(e) => { e.preventDefault(); this.setState({template_id: data.template_id}); this.handleModalPopup(null, 'showCareProtocolModal'); }}><span><u>{data.name}</u></span></a>
                                                              {/* <Link to={{
                                                                pathname: `/template/select/${this.state.hospital_id}/${this.state.patient?.patient_id}/${REFERENCE_TYPE}/${this.state.patient?.qms_token_id}/${data.template_id}`,
                                                                state: { patient }
                                                              }}>
                                                              <span><u>{data.name}</u></span>
                                                              </Link> */}
                                                              {/* <span className="pl-2">
                                                                <i className={data.is_favorite_flag == "Y" ? "icon_unique icon_star favourite align-star" : "icon_unique icon_star align-star"}></i>
                                                              </span> */}
                                                          </div>
                                                      </li>
                                                    )
                                                  })}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                      :
                        null
                      }

                      {
                        this.props.suggestedDiagnosis?.length > 0
                      ?
                        <div className={this.props.suggestedCP?.length > 0 ? 'col-6' : 'col-12'}>
                            <div className="card card-bg-lightblue suggest-protocol-card mb-0" style={{height:'100%'}}>
                                <div className="card-body">
                                    <div className='container-fluid'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-auto'>
                                                {
                                                  this.props.cpDiagnosisType == 'Related'
                                                ?
                                                  <h6 className='text-primary text-uppercase mb-0'>RELATED DIAGNOSIS</h6>
                                                : 
                                                  <h6 className='text-primary text-uppercase mb-0'>FAVOURITE DIAGNOSIS</h6>
                                                }
                                            </div>
                                            {
                                              this.props.suggestedDiagnosis?.length > 20
                                            ?
                                              <div className='col text-right'>
                                                  <a className='text-primary' onClick={() => { this.setState({ planSuggestedDiagnosisModal: true }) }}><u>View All</u></a>
                                              </div>
                                            :
                                              null
                                            }
                                            {/* <div className='col text-right'>
                                                <span className='pr-2'><img style={{ width: "18px", height: '18px' }} src={SearchImg} alt="search" className="icon" /></span>
                                                <a className='text-secondary' onClick={() => { this.setState({ addDiagnosisModal: true }) }}><u>Search Diagnosis</u></a>
                                            </div> */}
                                        </div>

                                        <div className='row mt-2'>
                                            <div className='col-12 d-flex align-items-center flex-wrap'>
                                                <ul className='list-unstyled d-flex flex-wrap mb-0'>
                                                  {this.props.suggestedDiagnosis?.map((data, i) => {
                                                    return(
                                                      <>
                                                        {
                                                          i <= 19
                                                        ?
                                                          <li className='pr-3 pb-1 col-6'>
                                                              <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                  <input type="checkbox" className="custom-control-input" name="fav_list" checked={data.is_selected == 'Y' ? true : false} id={'fav_'+data.id} disabled={data.is_selected == 'Y' ? true : false} onClick={e => this.selectFavDiagnosis(e, data, i, 'No')} />
                                                                  <label className="custom-control-label" htmlFor={'fav_'+data.id}>
                                                                    <span className='text-dark' onClick={() => { this.setState({ diagnosisparticularModal: true }) }}>
                                                                      <Tooltip className='diag_tooltip' overlayInnerStyle={{color:"#fff",backgroundColor:'#666',borderRadius:'6px'}} placement="top" title={data.description}>
                                                                        <div style={{ overflow: 'hidden', maxWidth: '27ch', textOverflow: 'ellipsis', whiteSpace: 'nowrap',fontSize:'0.95rem' }}>{data.description}</div>
                                                                      </Tooltip>
                                                                    </span>
                                                                  </label>
                                                                  {/* <span className="pl-2">
                                                                    <i className={data.fav == 1 ? "icon_unique icon_star favourite align-star" : "icon_unique icon_star align-star"}></i>
                                                                  </span> */}
                                                              </div>
                                                          </li>
                                                        :
                                                          null
                                                        }
                                                      </>
                                                    )
                                                  })}
                                                  {/* <li>
                                                      <a style={{ position: 'absolute', right: '0' }} class="btn btn-outline-secondary mr-2" id="care_protocol" href="#">ADD</a>
                                                  </li> */}
                                                </ul>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                      :
                        null
                      }
                    </div>
                  :
                    null
                  }

                  {this.state.assessDataLoaded ? 
                    <>
                      <AssessmentTableSS 
                        assessmentData={this.state.assessmentData} 
                        viewType={"soap-full"} 
                        statusSwitch={this.handleStatusSwitch}
                        showAction={this.state.allowEdit?true:false} 
                        showSelectCheck={false} 
                        constants={this.state.constants} 
                        /* updateSelection={this.handleSelectCheck} */
                        handlePopUp={this.handleModalPopup} 
                        handleEdit={this.handleEdit} 
                        /* isPracticing={isPracticing}  */
                        /* practiceModal={this.handlePracticeModal}  */
                        allowEdit={this.state.allowEdit}
                      />
                    </>
                  : null}
								</div>
							</div>
						</div>
					</div>

				</div>

				{/* Assessment - Add Diagnosis Modal */}
				<Modal
          destroyOnClose={true}
					title={`${this.state.actionText} Diagnosis`}
					visible={this.state.addDiagnosisModal}
					onCancel={e => this.handleModalPopup(e, 'addDiagnosisModal')}
					footer={false}
					width="1000px"
				>
          {this.state.assessDataLoaded ? 
            <AssessmentForm 
             ref={this.assessFormRef} 
             patient={this.state.patient} 
             formType={"soap-full"} 
             showMessage={true}
             messageType={this.state.showMessageType}
             updateData={this.updateAssessmentData} 
             previousDiagnosis={this.state.previousDiagnosis} 
             autofocus={false} 
             editData={this.state.editData} 
             editIndex={this.state.editIndex} 
             addedAssessmentList={this.state.addedAssessmentList}
             handleTemplateRadioSubmit={this.handleTemplateRadioSubmit} 
             clearEdit={this.clearEdit} 
             /* isPracticing={isPracticing} 
             practiceModal={this.handlePracticeModal} */ 
             allowEdit={this.state.allowEdit}
            />
          : null}
				</Modal>

        {/* || DELETE MODAL */}
        <Modal
          visible={this.state.deleteConfirmModal}
          onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
          style={{ top: 40 }}
          closable={false}
          footer={[
            <div className="text-center">
              <button className="btn btn-outline-secondary px-5 mr-2 cancel-delete-history"
                onClick={e => this.handleModalPopup(e, 'deleteConfirmModal')}>CANCEL</button>
              <button className="btn btn-primary px-5 submit-delete-history"
                disabled={this.state.spin_loading}
                onClick={e => this.handleDeletePopSubmit(e)}>
                DELETE{this.state.spin_loading ? LOADER : ''}</button>
            </div>
          ]}
        >
          <div className="row">
            <div className="col-12 text-center confirm-message">
              <h6 className="mb-0">Are you sure you want to delete assessment for {this.state.delete_text}?</h6>
            </div>
          </div>
        </Modal>

        {/* Assessment - Care Protocol Modal */}  
        <Modal
          destroyOnClose={true}
					title={`Care Protocol - ${this.state.cp_name != null ? this.state.cp_name : ''}`}
					visible={this.state.showCareProtocolModal}
					onCancel={e => this.handleModalPopup(e, 'showCareProtocolModal')}
					footer={false}
					width="1080px"
				>
          {this.state.template_id != null ? 
            <CopyToVisitSS
              match={{params: {reference_id: String(this.state.reference_id), template_id: this.state.template_id, hospital_id: this.state.hospital_id, patient_id: this.state.patient_id}}}
              location={{state: {patient: this.state.patient, sameRxdate: ''}, pathname: '/template/select'}}
              type={'popup'}
              updateCPName={this.updateCPName}
              updateData={this.updateData}
            />
          : null}
        </Modal>

        {/* Suggested Diagnosis Complete List */}
        <Modal
          title='Related Diagnosis'
          visible={this.state.planSuggestedDiagnosisModal}
          onCancel={() => this.handleCancel()}
          footer={false}
          width="850px"
        >
          <div className='row fav_modal'>
            <div className='col-12'>
              <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                <thead>
                  <tr>
                    <td width="10%"></td>
                    <td width="75%">Diagnosis</td>
                    <td width="15%">ICD Code</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.suggestedDiagnosis?.map((data, i) => {
                    return(<tr>
                      <td className='text-center'>
                        <div className="custom-control custom-checkbox custom-control-inline mr-0">
                          <input type="checkbox" className="custom-control-input" name="fav_list" checked={data.is_selected == 'Y' ? true : false} id={'fav_'+data.id} disabled={data.is_selected == 'Y' ? true : false} onClick={e => this.selectFavDiagnosis(e, data, i, 'No')} />
                          <label className="custom-control-label" htmlFor={'fav_'+data.id}></label>
                        </div>
                      </td>
                      <td>{data.description}</td>
                      <td>{data.icd_code}</td>
                    </tr>)
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

        {/* Suggested CP Complete List */}
        <Modal
          title='Care Protocol'
          visible={this.state.planSuggestedCPModal}
          onCancel={() => this.handleCancel()}
          footer={false}
          width="850px"
        >
          <div className='row fav_modal'>
            <div className='col-12'>
              {
                this.props.suggestedCP?.length > 0
              ?
                <>
                  {
                    this.props.cpDiagnosisType == 'Related'
                  ?
                    <h6 className='text-primary text-uppercase mb-0'>RELATED CARE PROTOCOL</h6>
                  : 
                    <h6 className='text-primary text-uppercase mb-0'>FAVOURITE CARE PROTOCOL</h6>
                  }
                  <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                    <tbody>
                      {this.props.suggestedCP?.map((data, i) => {
                        return(<tr>
                          <td>
                            <a href="#" className='text-primary' onClick={(e) => { e.preventDefault(); this.setState({template_id: data.template_id}); this.handleModalPopup(null, 'showCareProtocolModal'); }}><span><u>{data.name}</u></span></a>
                          </td>
                        </tr>)
                      })}
                    </tbody>
                  </table>
                  {
                    this.props.otherCP?.length > 0
                  ?
                    <h6 className='text-primary text-uppercase mb-0'>Other Care Protocols</h6>
                  :
                    null
                  }
                </>
              :
                null
              }
              {
                this.props.otherCP?.length > 0
              ?
                <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                  <tbody>
                    {this.props.otherCP?.map((data, i) => {
                      return(<tr>
                        <td>
                          <a href="#" className='text-primary' onClick={(e) => { e.preventDefault(); this.setState({template_id: data.template_id}); this.handleModalPopup(null, 'showCareProtocolModal'); }}><span><u>{data.name}</u></span></a>
                        </td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              :
                null
              }

            </div>
          </div>
        </Modal>

        <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
            title="Drug Interaction"
            visible={this.state.isModalInteractionVisibleFav}
            onOk={() => this.handleInteractionOk()}
            onCancel={() => this.handleInteractionCancel()}
            footer={false}
            style={{ width: 1000 }}
          >
            <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_DIAGNOSIS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.reference_id}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${0}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${'medicineform'}&addedAssessmentList=${this.state.diagnoseId}`}></iframe>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ADD DIAGNOSIS</button>
            </div>
          </Modal>

			</>
		);
	}
}

export default AssessmentComponentSS;