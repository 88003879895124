import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Axios from 'axios';
import moment from 'moment';
import { notification, AutoComplete, Select, Modal, Button, Radio, Form, Input, Checkbox, Tooltip, Table } from 'antd';
import { MEDICINES_GETALL, MEDICINES_GETALL_CURRENT_MEDICINES, CURRENT_MEDICINES_STOP, SEARCH_DRUGS, DRUGS_CONSTANT, DRUG, DRUG_FAVOURITE, LATEST_DATA_FOR_EACH_VITAL, CIMS_INFO, CIMS_INTERACTION_EXISTS, CIMS_INTERACTION, REFERENCE_TYPE, PRESCRIPTION_DATA, DELETE_MEDICINE, MYHC_APPTYPE, WOODLANDS, HOSPITALDEFAULTS, LOADER_BLACK, DOSE_RULE, AVERAGE_BSA_FOR_MALE, AVERAGE_BSA_FOR_FEMALE, ADD_DRUGS, LOADER_RED, PRESCRIPTION_STORE, DOCTOR_AGENT_LIST } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import qs from 'qs';
import {
    LAST_VITAL_ADDED_DATA,
    BMI_CALCULATE,
    LOADER,
    BSA_CALCULATE,
    USERTYPES,
    PROFILE,
    MARK_DEFAULT_CORRECTION_DOSE,
    SAVE_TARGET_BLOOD_SUGAR,
    FETCH_DEFAULT_CORRECTION_DOSE

} from '../../utils/constant';
const { Option } = Select;


class CurrentMedsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMediListModal: false,
            current_medicine_load: false,
            current_medicine_list: [],
            patient: this.props.patient ?? null,
            constantCurrentMeds: [],
            drugDailyFrequencyMeds: {},
            showAddDrugButton: true,
            formIsFetchingDrug: false,
            selectDrugValue: '',
            searchDrugs: [],
            drug_id: '',
            drug_name: '',
            selectDrugName: '',
            addedDrugsLists: [],
        }
        this.onDrugssearch = this.onDrugssearch.bind(this);
    }


    async handleActiveMedsList() {
        var medicineData = [];
        var Mobject = {};
        var addedDrugsLists = [];
        this.setState({
            activeMediListModal: true,
            current_medicine_load: { indicator: LOADER_RED },
        });
        let { patient } = this.state;
        if (patient == null || typeof patient == 'undefined') {
            patient = this.props.patient;
        }
        const { qms_token_id, patient_id } = patient;
        await axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id, reference_type: 0, reference_id: qms_token_id })).then(success => {
            let currentMedsList = success.data.data.details.current_medicines;
            let constants = success.data.data.constants
            this.setState({
                constantCurrentMeds: constants,
            })
            this.optionDailyFrequencyLoad();
            currentMedsList.map(med => {
                let drugFreqText = med.drug_frequency ? Object.keys(constants.drugFrequency).find(key => constants.drugFrequency[key].id === med.drug_frequency) : null;
                drugFreqText = drugFreqText != null ? constants.drugFrequency[drugFreqText].description : '';
                if (med.dose_for_schedule == 'Y' && med?.schedules?.length > 1) {
                    for (var sch of med?.schedules) {
                        Mobject = {
                            drug_id: med.drug_id,
                            active: med.active,
                            drug_name: med.drug_name == null ? '-' : med.drug_name,
                            route: med.route == null ? '-' : constants.route.filter(r => { if (med.route == r.id) return r.description })[0].description,
                            priority: med.urgency == null ? '-' : constants.drugUrgency.filter(r => { if (med.urgency == r.id) return r.description })[0].description,
                            frequency: med.drug_frequency == null ? '-' : med.drug_frequency == 2 ? med.days_in_week_text : (med.drug_frequency == 1 ? this.state.drugDailyFrequencyMeds[med.daily_frequency] ? this.state.drugDailyFrequencyMeds[med.daily_frequency] : '-' : drugFreqText),
                            schedule: sch == null ? '-' : sch == 1 ? 'Morning' : sch == 2 ? 'Afternoon' : sch == 3 ? 'Evening' : sch == 4 ? 'Evening' : '-',
                            instruction: sch == null ? '-' : sch == 1 ? constants.drugMorningInstructions.filter(r => { if (med.instruction_morning == r.id) return r.description })[0].description : sch == 2 ? constants.drugAfternoonInstructions.filter(r => { if (med.instruction_afternoon == r.id) return r.description })[0].description : sch == 3 ? constants.drugEveningInstructions.filter(r => { if (med.instruction_evening == r.id) return r.description })[0].description : sch == 4 ? constants.drugNightInstructions.filter(r => { if (med.instruction_night == r.id) return r.description })[0].description : '-',
                            dosage: sch != null ? sch == 1 ? med.dosage_value_morning + ' ' + med.dosage_form_text : sch == 2 ? med.dosage_value_afternoon + ' ' + med.dosage_form_text : sch == 3 ? med.dosage_value_evening + ' ' + med.dosage_form_text : sch == 4 ? med.dosage_value_night + ' ' + med.dosage_form_text : null : '-',
                            //  duration: med.number_of_days == null ? '-' : med.number_of_days + " " + med.drug_duration_time,
                            duration: med.number_of_days ? med.number_of_days + " " + med?.number_of_days_type : (parseInt(med.drug_frequency) != 3 ? (parseInt(med?.daily_frequency) == 4 ? '-' : med.daily_frequency != null ? 'Till review' : '-') : '-'),
                            start_date: med.start_date == null ? '-' : moment(med.start_date).format('D MMM, YYYY'),
                            end_date: med.end_date == null ? '-' : moment(med.end_date).format('D MMM, YYYY'),
                            action: <span><div className='mr-4'><button type="button" className="btn btn-outline-primary px-3  small text-uppercase" disabled={med.prescriptionDrugActive == 'N' ? 'disabled' : null} onClick={() => this.stopDrug(med.prescriptionDrugId)}>{(med.prescriptionDrugActive == 'Y') ? 'STOP' : 'STOPPED'}</button></div>{(med.prescriptionDrugActive == 'N') ? <><div className="pt-2">{med.prescriptionDrugActive == 'Y' ? '' : <span className='font-weight-bold'>Stopped By : <span className='font-weight-light'>{med.doctor_name} </span></span>}</div><div>{!med.updated_at == null ? '' : <span className='font-weight-bold'>Stopped At : <span className='font-weight-light'>{moment(med.updated_at).format('D MMM, YYYY')}</span></span>}</div></> : ''}
                            </span>,
                            doseforscduled: 'Y',
                            scduled:med?.schedules?med.schedules.length:0
                        }
                        medicineData.push(Mobject);
                    }
                } else {
                    Mobject = {
                        drug_id: med.drug_id,
                        active: med.active,
                        drug_name: med.drug_name == null ? '-' : med.drug_name,
                        route: med.route == null ? '-' : constants.route.filter(r => { if (med.route == r.id) return r.description })[0].description,
                        priority: med.urgency == null ? '-' : constants.drugUrgency.filter(r => { if (med.urgency == r.id) return r.description })[0].description,
                        frequency: med.drug_frequency == null ? '-' : med.drug_frequency == 2 ? med.days_in_week_text : (med.drug_frequency == 1 ? this.state.drugDailyFrequencyMeds[med.daily_frequency] ? this.state.drugDailyFrequencyMeds[med.daily_frequency] : '-' : drugFreqText),
                        schedule: med.schedule_name == null ? '-' : med.schedule_name,
                        dosage: med.dosage_value != null ? med.dosage_value + " " + med.dosage_form_text : '-',
                        instruction: med.instruction == null ? '-' : constants.drugInstructions.filter(r => { if (med.instruction == r.id) return r.description })[0].description,
                        //   duration: med.number_of_days == null ? '-' : med.number_of_days + " " + med.drug_duration_time,
                        duration: med.number_of_days ? med.number_of_days + " " + med?.number_of_days_type : (parseInt(med.drug_frequency) != 3 ? (parseInt(med?.daily_frequency) == 4 ? '-' : med.daily_frequency != null ? 'Till review' : '-') : '-'),
                        start_date: med.start_date == null ? '-' : moment(med.start_date).format('D MMM, YYYY'),
                        end_date: med.end_date == null ? '-' : moment(med.end_date).format('D MMM, YYYY'),
                        action: <span><div className='mr-4'><button type="button" className="btn btn-outline-primary px-3  small text-uppercase" disabled={med.prescriptionDrugActive == 'N' ? 'disabled' : null} onClick={() => this.stopDrug(med.prescriptionDrugId)}>{(med.prescriptionDrugActive == 'Y') ? 'STOP' : 'STOPPED'}</button></div>{(med.prescriptionDrugActive == 'N') ? <><div className="pt-2">{med.prescriptionDrugActive == 'Y' ? '' : <span className='font-weight-bold'>Stopped By : <span className='font-weight-light'>{med.doctor_name} </span></span>}</div><div>{!med.updated_at == null ? '' : <span className='font-weight-bold'>Stopped At : <span className='font-weight-light'>{moment(med.updated_at).format('D MMM, YYYY')}</span></span>}</div></> : ''}
                        </span>,
                        doseforscduled: 'N',
                        scduled:0
                    }
                    medicineData.push(Mobject);
                }
            });
            medicineData.map((v, k) => {
                if (v.active == 'Y')
                    addedDrugsLists.push(v.drug_id)
            });
            this.setState({
                current_medicine_list: medicineData,
                current_medicine_load: false,
            });
        }).catch(err => {
            console.log(err);
        });
        const PARAMS = {
            patient_id,
            reference_type: REFERENCE_TYPE,
            reference_id: qms_token_id,
            hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
        }
        Axios.get(PRESCRIPTION_DATA(PARAMS))
            .then(success => {
                let { data } = success.data,
                    drugId = data.details.map((v, k) => {
                        if (v.prescriptionDrugActive == 'Y')
                            addedDrugsLists.push(v.drug_id)
                    });
            }).catch(err => console.log(err.response));

        this.setState({
            addedDrugsLists: addedDrugsLists
        })
    }

    handleCancel = () => {
        this.setState({
            drug_id: '',
            drug_name: '',
            selectDrugValue: '',
            current_medicine_list: [],
            searchDrugs: [],
            activeMediListModal: false,
            current_medicine_load: false,
            showAddDrugButton: true,
        });
    };

    optionDailyFrequencyLoad() {
        return this.state.constantCurrentMeds.dailyFrequency.map((obj, i) => {
            this.state.drugDailyFrequencyMeds[obj.id] = obj.description;
        }, this)
    }

    getRowSpans = (arr, key) => {
        let sameValueLength = 0;
        const RowSpans = [];
        for (let i = arr.length - 1; i >= 0; i--) {
            if (i === 0) {
                RowSpans[i] = sameValueLength + 1;
                continue;
            }

            if(arr[i][key] === arr[i - 1][key] && arr[i]['active'] == arr[i - 1]['active'] && arr[i]['active'] =='Y' && (arr[i]['doseforscduled'] =='Y' && arr[i - 1]['doseforscduled']=='N' || arr[i]['doseforscduled'] =='N' )){
                RowSpans[i] = 0;
                sameValueLength++;
            }
            else if(arr[i]['active'] == arr[i - 1]['active'] && arr[i - 1]['active']=='N'  && (arr[i]['drug_id'] == arr[i - 1]['drug_id']) && (arr[i]['doseforscduled'] == arr[i - 1]['doseforscduled'] &&  arr[i - 1]['doseforscduled']=='N') ){
                RowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
            else if (arr[i][key] === arr[i - 1][key] && arr[i]['active'] == arr[i - 1]['active'] && arr[i - 1]['active']=='N' && (arr[i]['drug_id'] == arr[i - 1]['drug_id']) && (arr[i]['doseforscduled'] == arr[i - 1]['doseforscduled']) && ((arr[i]['scduled']!=0 && sameValueLength < arr[i]['scduled']-1) || arr[i]['scduled']==0)) {
                RowSpans[i] = 0;
                sameValueLength++;
            } 
            else if (arr[i][key] === arr[i - 1][key] && arr[i]['active'] == arr[i - 1]['active'] && arr[i - 1]['active']=='Y' && (arr[i]['drug_id'] == arr[i - 1]['drug_id']) && (arr[i]['doseforscduled'] == arr[i - 1]['doseforscduled']) && ((arr[i]['scduled']!=0 && sameValueLength <= arr[i]['scduled']-1) || arr[i]['scduled']==0)) {
                RowSpans[i] = 0;
                sameValueLength++;
            }
            else {

                RowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }

        }
        return RowSpans;
    };

    getRowSpansForSchduled = (arr) => {
        let sameValueLength = 0;
        const RowSpans = [];
        for (let i = arr.length - 1; i >= 0; i--) {

            if (i === 0) {
                RowSpans[i] = sameValueLength + 1;
                continue;
            }
            if ((arr[i]['active'] == arr[i - 1]['active']) && (arr[i]['drug_id'] == arr[i - 1]['drug_id']) && (arr[i]['doseforscduled'] == arr[i - 1]['doseforscduled'] && arr[i - 1]['doseforscduled'] == 'Y') &&(arr[i]['scduled']!=0 && sameValueLength < arr[i]['scduled']-1)) {
                RowSpans[i] = 0;
                sameValueLength++;
            } else {
                RowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return RowSpans;
    }



    stopDrug = async (stopPrescriptionDrugId) => {
        let doctor_id = LS_SERVICE.get('staff_id');
        let stopDrugIds = []
        stopDrugIds.push(stopPrescriptionDrugId);
        let params = {
            stopDrugIds: stopDrugIds,
        }
        axios.put(CURRENT_MEDICINES_STOP({ stopPrescriptionDrugId, doctor_id }), params).then(res => {
            notification.success({
                message: 'drug stop succesfully',
                placement: 'topRight'
            });
            this.handleActiveMedsList();
        }).catch(
            err => {
                notification.error({
                    message: 'failed to stop medicine',
                    placement: 'topRight'
                });
                console.log(err);
            }
        );
    }

    onChangeDrugsSelect = async (value, props) => {
        await this.setState({
            drug_id: props.drug_id,
            selectDrugValue: value,
            drug_name: value,
        });
    }

    onDrugssearch(search) {
        this.setState({
            formIsFetchingDrug: true,
            selectDrugValue: search,
        })
        let params = { search: search };
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null;
        } else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
            params.hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
        } else {
            params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
        }
        Axios.post(SEARCH_DRUGS, qs.stringify(params)).then(success => {
            this.setState({
                searchDrugs: success.data.medicine_list,
                formIsFetchingDrug: false,
                showAddDrugButton: (search.length > 2 && success.data?.medicine_list?.length == 0) ? false : true,
            })
        }).catch(err => console.log(err));
    }

    saveMedicineData = async () => {
        let { patient } = this.state;
        if (patient == null || typeof patient == 'undefined') {
            patient = this.props.patient;
        }
        const { qms_token_id, patient_id } = patient;
        if (this.state.drug_id == '' || this.state.drug_id == null) {
            let emailParams = {
                drug_name: this.state.drug_name,
            }

            await Axios.post(ADD_DRUGS, {
                hospital_id: LS_SERVICE.get('slot_hospital_id'),
                hospital_master: LS_SERVICE.get('slot_hospital_master'),
                name: this.state.drug_name,
                isCurrentMeds: true,
                emailParams: emailParams
            })
                .then(success => {
                    this.setState({
                        drug_id: success.data.data.id,
                    });
                }).catch(err => {
                    console.log(err);
                })
        }
        const medicine_data = {
            drug_id: this.state.drug_id,
            drug_name: this.state.drug_name,
            prescription_drug_id: -1,
            active: 'Y',
            isOnlyCurrentMeds: 'Y'
        }
        const PRESCRIPTION_PARAMS = {
            reference_type: REFERENCE_TYPE,
            reference_id: qms_token_id,
            patient_id,
            medicine_data: [medicine_data],
        };
        await Axios.post(PRESCRIPTION_STORE, qs.stringify(PRESCRIPTION_PARAMS))
            .then(success => {
                this.handleActiveMedsList();
                this.formClear();
            }).catch(err => console.log(err));
    }

    formClear() {
        this.setState({
            searchDrugs: [],
            drug_id: '',
            drug_name: '',
            selectDrugValue: ''
        })
    }

    handleAddDrug() {
        this.setState({
            showAddDrugButton: true,
            drug_id: null,
            drug_name: this.state.selectDrugValue,
        });
    }

    optionDrugLoad() {
        if (this.state.searchDrugs !== undefined && this.state.searchDrugs.length > 0) {
            return this.state.searchDrugs.map((obj, i) => {
                return (<Option key={i} value={obj.name} drug_id={obj.id} fav={obj.fav} disabled={(this.state.addedDrugsLists && this.state.addedDrugsLists.includes(obj.id))} title={obj.name}>
                    <span className="desc">
                        {obj.name}
                    </span>
                    {/* <span className="float-right text-primary">
                {(LS_SERVICE.get("user_type") == USERTYPES.doctor) ?
                  <i className={`icon_unique icon_star align-star ${obj.fav ? "favourite" : ""}`}></i>
                  : null}
                {this.state.cimsallow ? (
                  <Button onClick={(e) => { e.stopPropagation(); this.showModalInfo() }} className="nostyle-link p-0">
                    <i className='icon_info'></i>
                  </Button>
                ) : null}
              </span> */}
                </Option>
                )
            })
        } else return null
    }

    render() {
        const rowSpans = this.getRowSpans(this.state.current_medicine_list, 'drug_name')
        const getRowSpansForSchduled = this.getRowSpansForSchduled(this.state.current_medicine_list)

        const columns = [
            {
                title: 'MEDICINE NAME',
                dataIndex: 'drug_name',
                width: '11%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = rowSpans[index];
                    return obj;
                }

            },
            {
                title: 'ROUTE',
                dataIndex: 'route',
                width: '9%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }
            },
            {
                title: 'PRIORITY',
                dataIndex: 'priority',
                width: '7%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }

            },
            {
                title: 'FREQUENCY',
                dataIndex: 'frequency',
                width: '9%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }
            },
            {
                title: 'SCHEDULE',
                dataIndex: 'schedule',
                width: '8%',
            },
            {
                title: 'DOSAGE',
                dataIndex: 'dosage',
                width: '7%',
            },
            {
                title: 'INSTRUCTION',
                dataIndex: 'instruction',
                width: '9%',
            },
            {
                title: 'DURATION',
                dataIndex: 'duration',
                width: '8%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }
            },
            {
                title: 'START DATE',
                dataIndex: 'start_date',
                width: '9%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }
            },
            {
                title: 'END DATE',
                dataIndex: 'end_date',
                width: '8%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }
            },
            {
                title: 'ACTION',
                dataIndex: 'action',
                width: '15%',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = getRowSpansForSchduled[index];
                    return obj;
                }
            },

        ];
        return (
            <Fragment>

                {
                    this.props.pageName == 'today-visit' ?

                        <div data-toggle="collapse"
                            aria-expanded="false" aria-controls="current_medicine" href="!#" style={{ color: '#5C5C5C' }} className="d-block nostyle-link collapsed" onClick={e => this.handleActiveMedsList()}
                        >
                            Current Meds
                        </div>
                        :
                        <button className="btn btn-outline-primary text-right text-uppercase  small  px-2" onClick={e => this.handleActiveMedsList()} > CURRENT MEDS </button>

                }


                <Modal
                    title={false}
                    visible={this.state.activeMediListModal}
                    onCancel={() => this.handleCancel()}
                    destroyOnClose={true}
                    footer={false}
                    width="1380px"
                    className='drug_modal'
                >
                    <Form
                        className='emrfrm'
                    >
                        <div className='row d-flex align-items-center'>
                            <div className='col-5'>
                                <h1 className="page-title mb-4">
                                    Current Medications
                                </h1>
                            </div >
                            <div className='col-5'>
                                <div className="form-group add_buttons font-col">
                                    {(this.state.showAddDrugButton) ?
                                        null
                                        :
                                        <div className={"btn btn-outline-primary"} disabled={this.state.showAddDrugButton} onClick={() => this.handleAddDrug()}>ADD</div>
                                    }
                                    <AutoComplete
                                        id='medication_name'
                                        name='medication_name'
                                        style={{ width: 100 + '%' }}
                                        showSearch={true}
                                        showArrow={this.state.showAddDrugButton}
                                        notFoundContent={this.state.formIsFetchingDrug ? LOADER_BLACK : null}
                                        loading={this.state.formIsFetchingDrug}
                                        onSelect={this.onChangeDrugsSelect}
                                        onSearch={this.onDrugssearch}
                                        className="select-drugs"
                                        value={this.state.selectDrugValue}
                                        filterOption={false}
                                        dropdownClassName="custom_drug_select"
                                        autoFocus={this.state.selectDrugValue != null && this.state.selectDrugValue != '' ? false : true}
                                        showAction={this.state.selectDrugValue != null && this.state.selectDrugValue != '' ? [] : ['focus', 'click']}
                                    //  disabled={this.state.formModeEdit}
                                    >
                                        {this.optionDrugLoad()}
                                    </AutoComplete>
                                    <label htmlFor="drug_id">Medicine Name and Strength<span className="text-danger">*</span></label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <button className="btn btn-outline-primary form-group px-4 py-2 small font-col text-uppercase"
                                    type="button"
                                    disabled={!this.state.selectDrugValue ? 'disabled' : !this.state.selectDrugValue ? 'disabled' : !this.state.showAddDrugButton ? 'disabled' : null}
                                    onClick={() => this.saveMedicineData()}
                                >ADD</button>
                            </div>
                        </div>
                        <div className=' mb-2 px-0 container drug_list_container'>
                            <Table
                                ref={el => (this.componentRef = el)}
                                columns={columns}
                                dataSource={this.state.current_medicine_list}
                                loading={this.state.current_medicine_load}
                                pagination={false}
                                scroll={{ y: 390 }}
                            />
                        </div>
                    </Form>
                </Modal>
            </Fragment>
        )
    }
}

export default CurrentMedsPopup;
