import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Radio, Select, Modal, Tooltip, Carousel, Image, Input } from 'antd';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from 'react-router-dom';
//import { headerService$, practiceService, speechService, speechTextService } from '/var/www/html/rfh_opdemr/FE/opemr/src/app/utils/rxjs-sharing';
//import ClinicalNotes from '/var/www/html/rfh_opdemr/FE/opemr/src/app/pages/patient-detail/sscomponents/clinical-notes';
//import InternalNotes from '/var/www/html/rfh_opdemr/FE/opemr/src/app/pages/patient-detail/internal-notes';
import LS_SERVICE from '../../../utils/localStorage';
import ChiefComplaintsHPI from '../sscomponents/chiefcom-hpi';
import ClinicalNotes from '../sscomponents/clinical-notes';
import InternalNotes from '../sscomponents/internal-notes';
import { speechTextService } from '../../../utils/rxjs-sharing';


import { REFERENCE_TYPE, DURATION_TYPE_OBJECT_REVERSE, SOAP_OBJECTIVE_DETAILS, SUBJECTIVE_INFRMATION_CUSTOM, ISALLOWEDITSOAP,PARENT_INFRMATION_CUSTOM,SUBJECTIVE_CUSTOM_STORE } from '../../../utils/constant';
import qs from 'qs';

export default class ChiefComplaintSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: props.patient,
      details: null,
      constants: null,
      visitVitals: [],
      vitals: [],
      vitalsFiltersLoaded: false,
      vitals_data_formatted: true,
      visitVitalsFormatted: {},
      // modal
      chartParamerModelVital: false,
      keyFindingsModal: false,
      chartModal: false,
      keyFindingChartModal: false,
      field_status: [],
      allowEdit: true,
      speech: {
        identifier: null,
        state: false
      }
    }
    this.vitalFormRef = React.createRef();
    this.keyFindingFormRef = React.createRef();
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    // hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

    this.setState({
      // hospital_id,
      // patient_id,
      // reference_id,
      // patient,
      // pres_modification: hospitalConfigData.pres_modification,
      // allow_hours: hospitalConfigData.allow_hours,
      allowEdit
    });
  }

  initialSetup = () => {
    const { patient, hospital_id } = this.state;
    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      patient_id: patient.patient_id,
      reference_id: patient.qms_token_id,
      hospital_id
    }
    axios.post(SOAP_OBJECTIVE_DETAILS, qs.stringify(PARAMS))
      .then((res) => {

        const { details, field_status } = res.data;
        let { followUpRadio, follow_up_days, follow_up_Others, otherVal } = this.state;

        let followUpType = null;
        let followUpDays = null;
        if (details.followUp !== null) {
          followUpType = details.followUp?.followUpType;
          followUpDays = details.followUp?.followUpDays;

          if (followUpType !== 0 && followUpType !== undefined) {
            followUpRadio = -1;
            otherVal = followUpDays;
            follow_up_Others = DURATION_TYPE_OBJECT_REVERSE[followUpType]?.toLowerCase();
          } else if (followUpDays !== undefined) {
            follow_up_days = followUpDays;
            followUpRadio = followUpDays
          }
        }

        const bookNowAllowFn = _ => {
          if (otherVal !== null || follow_up_days !== null) {
            if (otherVal > 0) return false;
            if (follow_up_days > 0) return false;
          }
          return true;
        }

        this.setState({
          details,
          constants: details.constants,
          field_status,
          dataLoading: false,
          physiotherapy_advised: details?.physiotherapyAdvised === null || details?.physiotherapyAdvised === 0 ? 2 : 1,
          admissionChecked: details?.admissionAdvised == 1 ? true : false,
          physioText: details?.physiotherapyComment ?? '',
          admissionComment: details?.admissionComment ?? '',
          isAdmissionAdvisedPriority: details.admissionAdvisedPriority == null || details.admissionAdvisedPriority == 0 ? false : true,
          followUpRadio,
          otherVal,
          follow_up_Others,
          follow_up_days,
          bookNowAllow: bookNowAllowFn(),
        })
      })
      .catch((err) => {
        console.log(err.data)
      })
  }

 

  componentDidMount() {

    const ss_configuration = LS_SERVICE.get('ss_configuration');
    const patient_full_data = LS_SERVICE.get('call_patient_detail');
    this.setState({
      ss_configuration: ss_configuration,
      consult_type: patient_full_data?.consult_type == 1 ? 'vc' : 'opd'
    })
    this.getCustomSubjectiveData();
    this.checkJuniorDoctorCompleteAppoitment();
    this.getCustomParentData();
    //var dataInitialSetup = this.initialSetup();
    this.subscription = speechTextService.status().subscribe(async data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'custom_field_1') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom1: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_1', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_2') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom2: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_2', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_3') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom3: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_3', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_4') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom4: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_4', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_5') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom5: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_5', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_1') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom1: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_1', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_2') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom2: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_2', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_3') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom3: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_3', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_4') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom4: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_4', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_5') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom5: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_5', value: data.text } });
        }
        if (this.state.speech.identifier === 'review_notes') {
          await this.setState({ reviewTxt: data.text });
        }
        document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
      }
    });

  }

  getCustomSubjectiveData = () => {

    // const params = this.props;
    const { reference_id } =  this.state.patient.qmsToken.qms_token_id;


    if (reference_id) {
      axios.get(SUBJECTIVE_INFRMATION_CUSTOM({ reference_type: REFERENCE_TYPE, reference_id: reference_id }))

        .then(res => {
          this.setState({
            subjective_custom: res.data.custom_data || {}
          })
        })
    }
  }

  checkJuniorDoctorCompleteAppoitment() {
    let junior_doctor_complete_appointment = LS_SERVICE.get('junior_doctor_complete_appointment');
    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    let call_patient_detail = LS_SERVICE.get('call_patient_detail');
    let current_login_doctor = LS_SERVICE.get('staff_id');
    if (is_junior_doctor == true && current_login_doctor != call_patient_detail?.doctor_id) {
      this.setState({
        junior_doctor_complete_appointment: !junior_doctor_complete_appointment
      })
    }
  }

  getCustomParentData = () => {
    // const { match: { params } } = this.props;
    const { reference_id } = this.state.patient.qmsToken.qms_token_id;

    if (reference_id) {
      axios.get(PARENT_INFRMATION_CUSTOM({ reference_type: REFERENCE_TYPE, reference_id: reference_id }))
        .then(res => {
          this.setState({
            parent_customdata: res.data.custom_data || {}
          })
        })
    }
  }

  

  autoHeight = (e) => {
    if (e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  }

  saveCustomSubjective = async e => {
    const { patient, subjective_custom } = this.state;
    let { name, value } = e.target;
    if (subjective_custom && subjective_custom != null) {
      if (name == 'custom_field_1' && value != undefined && value != null) { subjective_custom.custom1 = value; }
      if (name == 'custom_field_2' && value != undefined && value != null) { subjective_custom.custom2 = value; }
      if (name == 'custom_field_3' && value != undefined && value != null) { subjective_custom.custom3 = value; }
      if (name == 'custom_field_4' && value != undefined && value != null) { subjective_custom.custom4 = value; }
      if (name == 'custom_field_5' && value != undefined && value != null) { subjective_custom.custom5 = value; }
      await this.setState({
        subjective_custom: subjective_custom
      });

      var PARAMS = {
        reference_id: patient.qms_token_id,
        reference_type: REFERENCE_TYPE,
        patient_id: patient.patient_id,
        doctor_id: LS_SERVICE.get('staff_id'),
        [name]: value
      }
      axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS))
    }
  }

  render() {
    const { patient, ss_configuration, consult_type, field_status, physiotherapy_advised, physioText, admissionChecked, admissionComment, isAdmissionAdvisedPriority, allowEdit } = this.state;


    var parent_variable = {
      vital_and_kf: null,
      patient_hx: null,
      subjective: null,
      diagnosis: null,
      medicine: null,
      test: null,
      instruction: null,
      supporting_material: null,
      referral: null,
      other: null,
      review: null,
      custom_field_parent_1: null,
      custom_field_parent_2: null,
      custom_field_parent_3: null,
      custom_field_parent_4: null,
      custom_field_parent_5: null,

    };
    var child_variable = {
      custom_field_1: null,
      custom_field_2: null,
      custom_field_3: null,
      custom_field_4: null,
      custom_field_5: null,
      clinical_notes: null,
      internal_notes: null,
      physiotherapy: null,
      admission: null,
      followup: null
    }

    var custom1 = {};
    var custom2 = {};
    var custom3 = {};
    var custom4 = {};
    var custom5 = {};
    var parent_custom1 = {};
    var parent_custom2 = {};
    var parent_custom3 = {};
    var parent_custom4 = {};
    var parent_custom5 = {};
    var field_status_patient_history = []

    if (ss_configuration) {
      var child_subjective = ss_configuration.find(o => o?.id === 3).sub_items;
      var custom_parent = ss_configuration.find(o => o?.id === 3)
      if (custom_parent && custom_parent.sub_items) {
        custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
      }

      var child_others = ss_configuration.find(o => o?.id === 10).sub_items;
      child_variable.physiotherapy = <>
        {
          ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 18 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row mb-2 mt-2'>
              <div className='col-6 d-flex align-items-center'>
                <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">PHYSIOTHERAPY ADVISED </label>
                <div className="mr-2 d-md-inline">
                  <Radio.Group disabled={!this.state.allowEdit} onChange={e => this.handleRadioChange(e, 'physiotherapySave')} name='physiotherapy_advised' value={physiotherapy_advised}>
                    <Radio value={1} name="physiotherepy_radio">Yes</Radio>
                    <Radio value={2} name="physiotherepy_radio">No</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className={`col-6 ${physiotherapy_advised == 2 ? "d-none" : ""}`} id="therapy_text">
                <div className={`form-group mb-0 ${physioText != '' ? 'hasdata' : ''}`}>
                  <textarea disabled={!this.state.allowEdit} onInput={(e) => this.autoHeight(e)} rows={1} id="comment" className="form-control autoHeightText"
                    placeholder="Therapy Required" value={physioText} onBlur={this.physiotherapySave}
                    onChange={(e) => this.setState({ physioText: e.target.value })} />
                  <label htmlFor="comment">Therapy Required</label>
                </div>
              </div>
            </div>
            :
            null
        }
      </>
      child_variable.admission = <>
        {
          ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 19 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row mb-2 mt-2'>
              <div className='col-4 d-flex align-items-center'>
                <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">ADMISSION ADVISED </label>
                <div className="mr-2 d-md-inline">
                  <Radio.Group disabled={!this.state.allowEdit} onChange={e => this.handleRadioChange(e, 'admissionSave')} name='admissionChecked' value={admissionChecked}>
                    <Radio value={true} name="admission_radio">Yes</Radio>
                    <Radio value={false} name="admission_radio">No</Radio>
                  </Radio.Group>
                </div>
              </div>
              {admissionChecked ? (
                <>
                  <div className='col-2 d-flex align-self-center'>
                    <span className='ant_switch_button_sm phsio_advised' id="emer_elect">
                      <BootstrapSwitchButton
                        width={150} height={24}
                        size="xs"
                        checked={isAdmissionAdvisedPriority}
                        offlabel='Elective'
                        onlabel='Emergency'
                        offstyle="success"
                        onstyle="alert"
                        onChange={this.handleToggleAdmission}
                        disabled={!this.state.allowEdit}
                      />
                    </span>
                  </div>
                  <div className={`col-6 ${admissionChecked == false ? "d-none" : ""}`} id="therapy_text">
                    <div className={`form-group mb-0 ${admissionComment != '' ? 'hasdata' : ''}`}>
                      <textarea onInput={(e) => this.autoHeight(e)} rows={1} id="admission_comment" className="form-control autoHeightText"
                        placeholder="Therapy Required" value={admissionComment} onBlur={this.handleAdmissionAdvicedComment} disabled={!this.state.allowEdit}
                        onChange={(e) => this.setState({ admissionComment: e.target.value })} />
                      <label htmlFor="admission_comment">Admission Advised Remark</label>
                    </div>

                  </div>
                </>
              ) : ""}
            </div>
            :
            null
        }
      </>
     
      var child_subjective = ss_configuration.find(o => o?.id === 3).sub_items;
      child_variable.custom_field_1 = <>
        {

          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?


            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className={this.state?.subjective_custom?.custom1 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                  {this.state.allowEdit ? <Link
                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom1.name }) }}
                    className={(this.state.speech.identifier === custom1.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                  <textarea
                    placeholder={custom1?.custom?.value}
                    className={`form-control subjective-field`}
                    id={custom1.name}
                    name={custom1.name}
                    disabled={!this.state.allowEdit}
                    value={this.state?.subjective_custom?.custom1}
                    onChange={this.saveCustomSubjective}
                    onFocus={this.handleOnFocus}
                    onBlur={this.saveCustomSubjective}
                    rows="1"
                    onInput={(e) => this.autoHeight(e)}>
                  </textarea>
                  <label htmlFor={custom1.name}>{custom1?.custom?.value}</label>
                </div>
              </div>
            </div>
            :
            null
        }
      </>

      child_variable.custom_field_2 = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className={this.state?.subjective_custom?.custom2 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                  {this.state.allowEdit ? <Link
                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom2.name }) }}
                    className={(this.state.speech.identifier === custom2.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                  <textarea
                    placeholder={custom2?.custom?.value}
                    className={`form-control subjective-field`}
                    id={custom2.name}
                    name={custom2.name}
                    disabled={!this.state.allowEdit}
                    value={this.state?.subjective_custom?.custom2}
                    onChange={this.saveCustomSubjective}
                    onFocus={this.handleOnFocus}
                    onBlur={this.saveCustomSubjective}
                    rows="1"
                    onInput={(e) => this.autoHeight(e)}>
                  </textarea>
                  <label htmlFor={custom2.name}>{custom2?.custom?.value}</label>
                </div>
              </div>
            </div>
            :
            null
        }
      </>
      child_variable.custom_field_3 = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className={this.state?.subjective_custom?.custom3 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                  {this.state.allowEdit ? <Link
                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom3.name }) }}
                    className={(this.state.speech.identifier === custom3.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                  <textarea
                    placeholder={custom3?.custom?.value}
                    className={`form-control subjective-field`}
                    id={custom3.name}
                    name={custom3.name}
                    disabled={!this.state.allowEdit}
                    value={this.state?.subjective_custom?.custom3}
                    onChange={this.saveCustomSubjective}
                    onFocus={this.handleOnFocus}
                    onBlur={this.saveCustomSubjective}
                    rows="1"
                    onInput={(e) => this.autoHeight(e)}>
                  </textarea>
                  <label htmlFor={custom3.name}>{custom3?.custom?.value}</label>
                </div>
              </div>
            </div>
            :
            null
        }
      </>
      child_variable.custom_field_4 = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className={this.state?.subjective_custom?.custom4 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                  {this.state.allowEdit ? <Link
                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom4.name }) }}
                    className={(this.state.speech.identifier === custom4.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                  <textarea
                    placeholder={custom4?.custom?.value}
                    className={`form-control subjective-field`}
                    id={custom4.name}
                    name={custom4.name}
                    disabled={!this.state.allowEdit}
                    value={this.state?.subjective_custom?.custom4}
                    onChange={this.saveCustomSubjective}
                    onFocus={this.handleOnFocus}
                    onBlur={this.saveCustomSubjective}
                    rows="1"
                    onInput={(e) => this.autoHeight(e)}>
                  </textarea>
                  <label htmlFor={custom4.name}>{custom4?.custom?.value}</label>
                </div>
              </div>
            </div>
            :
            null
        }
      </>
      child_variable.custom_field_5 = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className={this.state?.subjective_custom?.custom5 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                  {this.state.allowEdit ? <Link
                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom5.name }) }}
                    className={(this.state.speech.identifier === custom5.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                  <textarea
                    placeholder={custom5?.custom?.value}
                    className={`form-control subjective-field`}
                    id={custom5.name}
                    name={custom5.name}
                    disabled={!this.state.allowEdit}
                    value={this.state?.subjective_custom?.custom5}
                    onChange={this.saveCustomSubjective}
                    onFocus={this.handleOnFocus}
                    onBlur={this.saveCustomSubjective}
                    rows="1"
                    onInput={(e) => this.autoHeight(e)}>
                  </textarea>
                  <label htmlFor={custom5.name}>{custom5?.custom?.value}</label>
                </div>
              </div>
            </div>
            :
            null
        }

      </>
      child_variable.clinical_notes = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 16 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <ClinicalNotes isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} />
            :
            null
        }
      </>
      child_variable.internal_notes = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 17 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <InternalNotes isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} />
            :
            null
        }
      </>


      parent_variable.subjective = <>
        {
          ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 16 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 17 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
            ?
            <div className='row py-2'>
              <div className='col-xl-12 col-lg-12'>
                <div className="accordion soap_accordion" id="accordionExample">
                  <div className="card card-bg-lightblue sub-card mb-0 ">
                    <div className="card-body p-0">
                      <div className='container-fluid ' style={{ 'padding-top': 1 + "%" }}>
                        {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                          <>
                            {
                              ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                ?
                                <ChiefComplaintsHPI sourcescreen="singlescreen" consult_type={consult_type} history={this.props.history} isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} loadCopyPrescriptionAddedData={this.loadCopyPrescriptionAddedData} hidePrevRx={true} />
                                :
                                null
                            }
                            {

                              child_subjective && child_subjective.length > 0
                                ?
                                (

                                  child_subjective.map((ssc, i) => {
                                    return (child_variable[ssc.name])
                                  })
                                )
                                :
                                null
                            }
                          </>
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            :
            null
        }
      </>;
      //return parent_variable.subjective;


    }
    return (
      parent_variable.subjective
    )
  }
}