import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { DatePicker, Select, Form, Input, Modal, Button, Badge, notification } from 'antd';
import VitalsSS from '../../../components/vitals/vitals-ss';
import VitalsFilters from '../../../components/vitals/vitals-filters';
import { VITALS_FILTER_SEQUENCING_LIST, VITALS_GETALL } from '../../../utils/constant';
import { AntSelectLabels } from '../../../utils/jQueryLabels';

export default class VitalsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: props.patient,
            details: null,
            constants: null,
            visitVitals: [],
            vitals: [],
            vitalsFiltersLoaded: false,
            vitals_data_formatted: true,
            visitVitalsFormatted: {},
            // modal
            chartParamerModelVital: false,
            keyFindingsModal: false,
            chartModal: false,
            keyFindingChartModal: false,
        }
        this.vitalFormRef = React.createRef();
        this.keyFindingFormRef = React.createRef();
    }

    async componentDidMount() {
        await this.handleObjectiveGetVitalsData();
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        return ({
            // details: nextProps.details,
            // constants: nextProps.constants,
            // visitVitals: nextProps.visitVitals,
            // vitals: nextProps.vitals,
            // visitVitalsFormatted: nextProps.visitVitalsFormatted,
            // patient: nextProps.patient
        })
    }

    handleObjectiveGetVitalsData = async () => {
        let { patient_id } = this.state.patient;
        if (patient_id) {
            const objectiveGetAllPromise = await axios.get(VITALS_GETALL({ patient_id }));
            if (objectiveGetAllPromise.data.status) {
                const { data } = objectiveGetAllPromise.data;
                const { details, constants } = data;

                this.setState({
                    details,
                    constants,
                    visitVitals: Object.values(details.visitVitals),
                    // visitVitalsKeyFindings: Object.values(details.visitVitalsKeyFindings),
                    objective_data_loaded: true,
                }, () => {
                    // call fn after set state
                });

                this.makeVitalsData();
                this.handleVitalFilterData();
                // this.fillFormData();
                // await this.handleKeyFindingFilterData();
                // this.makeKeyFindingsData();
            }
        }
    }

    makeVitalsData() {
        let vitalDetails = [];
        let allVitalIds = [];
        this.state.constants?.vitals_details.map((v, k) => {
            allVitalIds.push(v.id);
            vitalDetails[v.id] = [];
            this.setState({ [v.id]: null });
        });
        vitalDetails["date"] = [];
        vitalDetails["source"] = [];
        vitalDetails["update_date"] = [];
        vitalDetails["source_name"] = [];
        allVitalIds.push("date");
        allVitalIds.push("source");
        allVitalIds.push("update_date");
        allVitalIds.push("source_name");

        if (Object.keys(this.state.visitVitals).length > 0) {
            let index = 0;
            this.state.visitVitals.map((temp_vitals, k) => {
                allVitalIds.forEach((j) => {
                    if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j] ?? "";
                    /* if (
                      j == "V001" &&
                      patientHeight == null &&
                      patient.age > HOSPITALDEFAULTS.age &&
                      moment().diff(moment(temp_vitals.date[j]), "months") < 2 &&
                      temp_vitals[j]
                    )
                      patientHeight = temp_vitals[j];
                    if (
                      j == "V002" &&
                      patientWeight == null &&
                      patient.age > HOSPITALDEFAULTS.age &&
                      moment().diff(moment(temp_vitals.date[j]), "months") < 2 &&
                      temp_vitals[j]
                    )
                      patientWeight = temp_vitals[j]; */
                });
                index++;
            });
        }

        let vitals_data = {};
        let newVitalEmptyArray = vitalDetails["date"] ? Array.from({ length: vitalDetails["date"].length }, (i) => "") : [];
        this.state.constants?.vitals_details.map((v, k) => {
            vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
        });
        vitals_data.date = vitalDetails["date"];
        vitals_data.source = vitalDetails["source"];
        vitals_data.update_date = vitalDetails["update_date"];
        vitals_data.source_name = vitalDetails["source_name"];
        this.setState({
            visitVitalsFormatted: vitals_data,
            vitals_data_formatted: true,
        });
    }

    handleVitalFilterData = async () => {
        const vitalFilterPromise = await axios.get(VITALS_FILTER_SEQUENCING_LIST);
        if (vitalFilterPromise?.data?.status) {
            this.setState({
                vitals: vitalFilterPromise.data.data,
                vitalsFiltersLoaded: true,
            });
        }
        AntSelectLabels();
    }

    handleModalPopup = (e, popup, data = null) => {
        if (e !== null) e.preventDefault();
        // const { isPracticing } = this.state;
        /* if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
          if (!isPracticing && popup !== "chartModal" && popup !== "keyFindingChartModal") {
            this.setState({
              isPracticingModal: true,
            });
            return;
          }
        } */
        this.setState({
            [popup]: !this.state[popup],
        },
            () => {
                const { keyFindingsModal, chartModal, keyFindingChartModal } = this.state;
                if (!keyFindingsModal) {
                    if (this.keyFindingFormRef.current !== null) {
                        this.keyFindingFormRef.current.resetFields();
                    }
                }
                if (chartModal) {
                    this.setState({
                        chartDataLoaded: true,
                        chartsType: data.type,
                        chartHeading: data.name,
                    });
                } else if (keyFindingChartModal) {
                    this.setState({
                        keyFindingsChartDataLoaded: true,
                        keyFindingsChartsType: data.type,
                        keyFindingsChartHeading: data.name,
                        keyFindingsChartId: data.id ?? null,
                    });
                }
            }
        );
    }

    handleVitalsUpdate = async (vitals) => {
        this.setState({ vitals: vitals });
        AntSelectLabels();
    };

    /*
    vitalsFiltersLoaded
    handleModalPopup
    
    */
   
    render() {
        let { vitals_data_formatted, vitals, constants, visitVitalsFormatted } = this.state;
        // console.log('----------', { vitals, constants, visitVitalsFormatted });
        return (
            <Fragment>
                <div className="col-12 mb-2">
                    <div className="card rounded-0 cardbg">
                        <div className="mb-2 mt-2 ml-2">
                            <h6 className="float-left">Vitals</h6>
                            {this.state.vitalsFiltersLoaded ? (
                                <button
                                    className="btn_filter"
                                    onClick={async (e) => {
                                        //await this.handleModalPopup(e, "vitalViewModal");
                                        await this.handleModalPopup(e, "chartParamerModelVital");
                                    }}
                                ></button>
                            ) : null}
                            <div className="float-right mr-3 ml-auto d-flex align-content-center">
                                <Badge className="mr-2 vr-badge" color={"#ffe6e8"} text={"Vitals Entered by Patient"} />
                            </div>
                        </div>
                        {vitals.length && constants && visitVitalsFormatted ?
                            <VitalsSS
                                dataLoaded={vitals_data_formatted}
                                vitalsOrder={vitals}
                                constants={constants}
                                visitVitalsFormatted={visitVitalsFormatted}
                                handleModalPopupStateLift={this.handleModalPopup}
                            />
                            : null}
                    </div>
                </div>
                
                

                {/* || FILTERS & SEQUENCING - Vitals */}
                {this.state.vitalsFiltersLoaded ? (
                    <Modal
                        title={"Vitals - Parameters"}
                        destroyOnClose={true}
                        visible={this.state.chartParamerModelVital}
                        onCancel={async (e) => {
                            this.handleModalPopup(e, "chartParamerModelVital");
                        }}
                        style={{ top: "40px" }}
                        width="910px"
                        footer={false}
                        closable={true}
                    >
                        {constants && (
                            <VitalsFilters
                                vitalsConstants={constants.vitals_details}
                                handleModalPopup={this.handleModalPopup}
                                updateParentState={this.handleVitalsUpdate}
                            />
                        )}
                    </Modal>
                ) : null}

            </Fragment>
        )
    }
}