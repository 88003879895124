import React, { Component } from "react";
import Axios from "axios";
import qs from "qs";
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Modal, Carousel } from 'antd';
import Moment from 'react-moment';
import LS_SERVICE from '../../utils/localStorage';
import {
  LOADER_RED,
  GOBACK,
  PATIENT_RIS_REPORTS
} from "../../utils/constant";
import AsideLeft from "../../components/aside/asideleft";
import moment from "moment";
class RisReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeUrl : '',
      DataLoaded: false
    }
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    const { patient_id } = params;
    const patient = { patient_id };
    await this.setState(
    {
      patient_id,
      patient,
    });
    this.primaryLoadData();
  }

  primaryLoadData = () => {
    let patient_id = btoa("00"+LS_SERVICE.get('call_patient_detail').uhid)
    const PARAMS = {
      patient_id
    }

    Axios.post(PATIENT_RIS_REPORTS, qs.stringify(PARAMS))
      .then(response => {
        if(response.data.iframe_url != "")
        {
          this.setState({
            DataLoaded: true,
            iframeUrl : response.data.iframe_url
          })
        }
      })
  }


  render() {
    const { patient } = this.state;
    return (
      <>
        {patient != undefined && patient.patient_id != undefined ? (
          <AsideLeft
            patient_id={patient.patient_id}
            reference_id={patient.qms_token_id}
          />
        ) : null}
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title mb-0">Images</h1>
                </div>
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => GOBACK(e, this.props)}>BACK</button>
                </div>
              </div>
            </div>
            {this.state.DataLoaded === true ? (
              <iframe src={this.state.iframeUrl} width="100%" height="490"></iframe>
              ) : (
                <div className="card bg-light5">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 text-center">No reports found for this patient</div>
                    </div>
                  </div>
                </div>
              ) 
            }
          </div>
        </div>
      </>
    )
  }
}

export default RisReports;