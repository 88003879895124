import React, { Component } from 'react';
import LOGO from '../../assets/images/logo-myhealthcare-doctor.svg';
import OtpInput from 'react-otp-input';
import qs from 'qs';
// import "./verify-pin.scss";
import {
    HOSPITAL_CONFIG,
    LOADER_RED,
    OTP_LOGIN,
    SYNC_TOKEN,
    USERTYPES,
    VERIFY_PIN,
    ENCRYPTION_KEY
} from '../../utils/constant';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import AppHelpers from '../../utils/AppHelpers';
import { notification } from 'antd';
import { headerService$, vcOpdEnabledSubjectService } from '../../utils/rxjs-sharing';
import crypto from 'crypto';

class VerifyPinComponent extends Component {

    state = {
        otp: '',
        syncTokenProcessing: false,
        staff_id: '',
        staff_name: '',
        status: '',
        token: '',
        user_type: '',
        otpDisabled: false,
        verifyOtpBtnDisabled: false,
        isInputSecure: true,
        loginType: '',
        syncAppointments: false,
        display_staff_name: null
    }

    constructor(props) {
        super(props);
        if (LS_SERVICE.has('is_authenticated') && LS_SERVICE.get('is_authenticated')) {
            this.props.history.push('/dashboard');
        }
    }


    togglePinVisiblity = () => {
        const { isInputSecure } = this.state;
        this.setState({ isInputSecure: !isInputSecure });
    };

    componentDidMount() {
        this.setState({
            loginType: this.props.location.state
        })
    }
    componentWillUnmount() {
        this.setState({
            syncTokenProcessing: false
        })
    }

    handleLoginOtpClick = (event) => {
        event.preventDefault();

        this.setState({
            showMobile: !this.state.showMobile
        })
    }

    sumbitFormMN = (resetPin) => {
        const mobile = LS_SERVICE.get('loginMobile')

        this.setState({
            otpDisabled: true
        })

        Axios.post(OTP_LOGIN, qs.stringify({ mobile, loginType: this.state.loginType }))
            .then(success => {
                let data = success.data;
                notification.destroy();
                notification.info({
                    message: data.message,
                    placement: 'topRight'
                });
                this.setState({
                    otpDisabled: false
                })
                if (resetPin) {
                    LS_SERVICE.set('reset_user_pin', true);
                }
                this.props.history.push('/otp', this.state.loginType);
            })
            .catch(err => console.log(err))
    }

    decryptColumn = (string) => {
        if (string == null || string == '') {
          return null;
        }
        const encryption_key = ENCRYPTION_KEY;
        const encryptionMethod = 'AES-256-CBC';
        const secret = encryption_key.substring(0,32); // must be 32 char length
        const iv = secret.substring(0,16);
    
        const decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
        // decryptor.setAutoPadding(false);
        const decryptedString = decryptor.update(String(string), 'base64', 'utf8') + decryptor.final('utf8');
        return decryptedString;
    }


    handleOnSubmit = (event) => {
        event.preventDefault();

        const { otp } = this.state;
        this.setState({
            verifyOtpBtnDisabled: true
        });
        notification.destroy();
        let mobileNo = LS_SERVICE.get('loginMobile');
        if (mobileNo !== '' && mobileNo !== null) {
            Axios.post(VERIFY_PIN, qs.stringify({ mobile: LS_SERVICE.get('loginMobile'), pin: otp, loginType: this.state.loginType }))
                .then(success => {
                    this.setState({
                        verifyOtpBtnDisabled: false
                    });

                    let {
                        message,
                        staff_id,
                        staff_name,
                        status,
                        token,
                        user_type,
                        admin_hospital_id } = success.data;

                    if (status) {
                        this.setState({
                            staff_id,
                            staff_name,
                            token
                        })

                        LS_SERVICE.set('token', token);
                        LS_SERVICE.set('staff_name', staff_name);
                        LS_SERVICE.set('staff_id', staff_id);
                        LS_SERVICE.set('user_type', user_type);
                        LS_SERVICE.set('admin_hospital_id', admin_hospital_id);
                        LS_SERVICE.set('staff_image', success.data.profile_picture ? success.data.profile_picture : '');
                        LS_SERVICE.delete('loginMobile');
                        this.setState({
                            syncAppointments: true,
                            display_staff_name: staff_name
                        });
                        this.handleSyncToken(token);
                        if (LS_SERVICE.get('user_type') === USERTYPES.admin) {
                            LS_SERVICE.set('slot_hospital_master', success.data?.own_master || 'N');
                        }
                        if (LS_SERVICE.get('user_type') === USERTYPES.admin || LS_SERVICE.get('user_type') === USERTYPES.superadmin) {
                            notification.info({
                                message: 'Login successful!',
                                placement: 'topRight'
                            });
                        }
                        else {
                            notification.info({
                                message,
                                placement: 'topRight'
                            });
                        }
                    }

                })
                .catch(err => {
                    console.log(err);
                    const error = err.response?.data;
                    notification.error({
                        message: error?.message,
                        placement: 'topRight'
                    });
                    this.setState({
                        verifyOtpBtnDisabled: false
                    });
                })
        }
    }

    handleSyncToken = (token) => {

        Axios.defaults.headers.common["Authorization"] = token;

        const { staff_id } = this.state;

        if (LS_SERVICE.get('user_type') === USERTYPES.admin || LS_SERVICE.get('user_type') === USERTYPES.superadmin) {
            this.setState({
                syncTokenProcessing: true,
                syncAppointments: false
            })
            LS_SERVICE.set('is_authenticated', true);
            this.props.history.push('template-list');
            headerService$.showHeader({ showHeader: 'true' });
        }
        else {

            this.setState({
                syncTokenProcessing: true
            })

            if (LS_SERVICE.get('user_type') === USERTYPES.admin || LS_SERVICE.get('user_type') === USERTYPES.superadmin) {
                this.props.history.push('/template-list');
            }

            Axios.post(SYNC_TOKEN, qs.stringify({ staff_id }))
                .then(success => {
                    const result = success.data;

                    //console.log("success: ", result);

                    if (result.status) {
                        let profile = this.decryptColumn(result.staff.profile_picture);
                        LS_SERVICE.set('staff_image', profile);

                        LS_SERVICE.set('is_authenticated', true);
                        // debugger;
                        if (result.staff.role_id == USERTYPES.doctor) {

                            let SLOTS = result.slots;
                            if (typeof SLOTS != 'undefined' && SLOTS.length > 0) {
                                for (let index = 0; index < SLOTS.length; index++) {
                                    const doctorObj = SLOTS[index].doctor;
                                    doctorObj.id = this.decryptColumn(doctorObj.id);
                                    doctorObj.name = this.decryptColumn(doctorObj.name);
                                    doctorObj.first_name = this.decryptColumn(doctorObj.first_name);
                                    doctorObj.last_name = this.decryptColumn(doctorObj.last_name);
                                    doctorObj.middle_name = this.decryptColumn(doctorObj.middle_name);
                                    doctorObj.woodlands_email = this.decryptColumn(doctorObj.woodlands_email);
                                    doctorObj.woodlands_mobile = this.decryptColumn(doctorObj.woodlands_mobile);
                                    doctorObj.is_chat_enable = this.decryptColumn(doctorObj.is_chat_enable);
                                    doctorObj.is_enable_for_multiple_calling = this.decryptColumn(doctorObj.is_enable_for_multiple_calling);
                                    result.slots.doctor = doctorObj;
                                }
                            }

                            LS_SERVICE.set('doctor', {
                                name: ('Dr ' + result.staff.first_name + (result.staff.last_name != null ? result.staff.last_name : '')),
                                code: result.staff.code
                            });


                            if (result.is_junior_doctor && result.is_junior_doctor != null) {
                                LS_SERVICE.set('is_junior_doctor', result.is_junior_doctor)
                            }
                            if (result.senior_doctor && result.senior_doctor.length > 0) {
                                LS_SERVICE.set('senior_doctor', result.senior_doctor)
                            }
                            if (result.junior_doctor_complete_appointment && result.junior_doctor_complete_appointment !=null && result.junior_doctor_complete_appointment !=undefined) {
                                LS_SERVICE.set('junior_doctor_complete_appointment', result.junior_doctor_complete_appointment)
                            }

                            if (result.pending_patients && result.pending_patients.length > 0) {
                                LS_SERVICE.set(
                                    'pending_patients',
                                    result.pending_patients
                                );
                            } else {
                                LS_SERVICE.delete('pending_patients');
                            }
                            if (result.staff.doctor_speciality != undefined && result.staff.doctor_speciality.length > 0) {
                                LS_SERVICE.set('speciality_id', result.staff.doctor_speciality[0]);
                            } else {
                                LS_SERVICE.delete('speciality_id');
                            }
                            if (result.slots && result.slots.length > 0) {
                                LS_SERVICE.set(
                                    'slot_hospital_id',
                                    result.slots[0]['id']
                                );
                                LS_SERVICE.set(
                                    'slot_hospital_master',
                                    result.slots[0]['own_master']
                                );
                                this.handleBasicOrSoap(result.slots)
                                LS_SERVICE.set('slot_hospitals', result.slots);
                            } else {
                                LS_SERVICE.delete('slot_hospital_id');
                            }
                            if (result.slots && result.slots.length == 1) {
                              this.props.history.push('/patient-queue/' + result.slots[0].id);
                            } else {
                                // this.props.history.push('dashboard');
                                AppHelpers.setDashboardSlots(result);
                                this.props.history.push({
                                    pathname: 'dashboard',
                                    state: {
                                        syncdata: result
                                    }
                                });
                            }
                        }

                        if (result.staff.role_id == USERTYPES.nurse) {
                            // set selected nurse doctor ids
                            if(result?.nurse_doctors.length > 0){
                                LS_SERVICE.set('nurse_doctor_id', result?.nurse_doctors);
                            }

                            //Check if nurse logged in and set doctor speciality
                            if (result.doctor && result.doctor.length > 0) {
                                let allDoctors = result.doctor;
                                var doctorSpecialties = [];
                                allDoctors.forEach((v, k) => {
                                    var obj = {
                                        "id": v.id,
                                        "specialtiesIds": Object.keys(v.specialityDetail)
                                    };
                                    doctorSpecialties.push(obj);
                                });
                                LS_SERVICE.set('doctor_speciality_id', doctorSpecialties);
                            }

                            if (result.hospital && result.hospital.length > 0) {
                                LS_SERVICE.set(
                                    'slot_hospital_id',
                                    result.hospital[0]['hospital_id']
                                );
                                LS_SERVICE.set(
                                    'slot_hospital_master',
                                    result.hospital[0]['own_master']
                                );
                                LS_SERVICE.set('slot_hospitals', result.hospital);
                            } else {
                                LS_SERVICE.delete('slot_hospital_id');
                            }
                            if (result.doctor && result.doctor.length > 0) {
                                LS_SERVICE.set('nurse_doctors', result.doctor);
                            } else {
                                // LS_SERVICE.delete('nurse_doctors');
                                LS_SERVICE.set('nurse_doctors', []);
                                LS_SERVICE.set('nurse_homecare', true)
                            }
                            this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                            LS_SERVICE.set('nurse_doctors', result.doctor);
                            const isVcopdEnabled = result.is_vc_opd_enabled ? result.is_vc_opd_enabled : 0;
                            LS_SERVICE.set('nurse_is_vc_opd_enabled', isVcopdEnabled);
                            vcOpdEnabledSubjectService.init({
                                isVcOpdEnabled: isVcopdEnabled
                            });

                        }

                        headerService$.showHeader({ showHeader: 'true' });
                    }
                })
                .catch(err => {
                    this.setState({
                        syncAppointments: false
                    });
                    console.log(err);
                })

        }
    }

    handleBasicOrSoap = slot_hospitals => {

        slot_hospitals.forEach(sh => {
            const PARAMS = {
                hospital_id: sh.id,
                speciality_id: LS_SERVICE.get('speciality_id'),
                staff_id: LS_SERVICE.get('staff_id')
            }

            Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
            Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS))
                .then(success => {
                    const { hospital_id, opd_layout, pres_modification, allow_hours, duplicate_order } = success.data.data;
                    const HOS_DATA = {
                        hospital_id,
                        opd_layout,
                        pres_modification,
                        allow_hours,
                        duplicate_order
                    }
                    let data = [];
                    if (LS_SERVICE.has('hospitalConfigData')) {
                        data = LS_SERVICE.get('hospitalConfigData');
                        data = [...data, HOS_DATA]
                    } else {
                        data = [...data, HOS_DATA];
                    }
                    LS_SERVICE.set('hospitalConfigData', data);
                })
        })
    }

    handleChange = otp => this.setState({ otp });


    render() {
        const { isInputSecure, syncAppointments, display_staff_name } = this.state;

        return (

            <>
                <div className="container-fluid">
                    <div className="row justify-content-center full-height">
                        <div className="col-auto d-flex align-self-center">
                            <div className="text-center d-flex align-items-center">

                                {syncAppointments ? <div className="text-center" id="js-sync-widget">
                                    <p className="alert alert-danger d-none js-sync-flash-msg"></p>
                                    {display_staff_name != null ? <p id="js-staff-info">Welcome {display_staff_name}</p> : null}
                                    {LOADER_RED}
                                    <p>Please hold on for couple of seconds while we sync your hospital(s) and appointments.</p>
                                </div> : null}

                                {!syncAppointments ? <form className="otp-form" id="verifyOtpForm" onSubmit={this.handleOnSubmit}>
                                    <img className="img-logo" src={LOGO} alt="logo" />
                                    <h5 className="text-center mt-5">Enter your PIN</h5>
                                    <div className="form-group pt-5 password_attribute">
                                        <div className="otpinp_wrapper">
                                            <OtpInput
                                                type={isInputSecure ? "text" : "password"}
                                                name="pass"
                                                isInputSecure={isInputSecure}
                                                value={this.state.otp}
                                                onChange={this.handleChange}
                                                shouldAutoFocus={true}
                                                isInputNum={true}
                                                numInputs={4}
                                                hasErrored={true}
                                            />
                                            <div className={"show_password " + (isInputSecure ? "" : "on")} onClick={this.togglePinVisiblity}><i class="icon_view"></i></div>


                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <p className="alert alert-danger d-none js-flash-msg"></p>
                                        <button type="submit" disabled={this.state.verifyOtpBtnDisabled} className="btn btn-primary btn-submit otp-btn px-5 mt-4 operational-btns">
                                            SUBMIT
                                        </button>
                                        <p className="pt-3 m-0">
                                            <button type="button" onClick={() => this.sumbitFormMN(true)} disabled={this.state.otpDisabled} className="Resend-otp btn-link btn-resend operational-btns js-resend-otp textis-primary mr-4">Forgot PIN?</button>
                                            <button type="button" onClick={() => this.sumbitFormMN(false)} disabled={this.state.otpDisabled} className="Resend-otp btn-link btn-resend operational-btns js-resend-otp textis-primary">Login with OTP</button>
                                        </p>
                                        <p id="js-resend-message"></p>
                                    </div>
                                </form> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default VerifyPinComponent;
