import React from "react";
import moment from "moment";
import $, { param } from "jquery";

export const APP_NAME = process.env.REACT_APP_NAME;

//Training Data URL and static Authorization token
export const TRAINING_DATA_API =
  "https://api3.voiceai.myhealthcare.life/api/steps";
export const TRAINING_DATA_AUTH_TOKEN =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE1ODAxMjA1NzJ9.Dxt82xQNynJo4BKqRPNnnG6Tvs7KsfkGLBfWMNYPmFE";
export const APP_SECRET_KEY = "p7OF1tdd25VuRlDObmGGVhgvDM6kjUzEUvbRkgjtdd5Z0LOuJTo6Z";
export const sval = "success";
/** || React Base URL */
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_HOMECARE_URL = process.env.REACT_APP_API_BASE_HOMECARE_URL;
export const API_BASE_URL_NURSE = API_BASE_URL + "nurse/";
export const API_HOST = process.env.REACT_APP_HOST;
export const MYHC_APPTYPE = process.env.REACT_APP_OPD_APP_TYPE; /* This value should be one of the keys of APPS_USERTYPES */
export const VONAGE_INCOMING_CALL_SOCKET = process.env.REACT_APP_VONAGE_INCOMING_CALL_SOCKET;
export const PUBNUBPUBLISHKEY = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
export const PUBNUBSUBSCRIBEKEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
export const ENCRYPTION_KEY = 'TXlIZWFsdGhjYXJlLU1IVkM6OkNWSE0tZXJhY2h0bGFlSHlN';

export const PATIENT_DATA = (params) =>
  `${API_BASE_URL}this_visit?${params.reference_id
    ? "reference_id=" + params.reference_id
    : "patient_id=" + params.patient_id
  }`;
export const COMBINE_VACCINATION_DATA = (params) => `${API_BASE_URL}patient/vaccination/${params.patient_id}/${params.hospital_id}`;
export const VITALS_WHILE_VACCINATION = (params) => `${API_BASE_URL}patient/vitals_while_vaccination?patient_id=${params.patient_id}&giveOnDate=${params.giveOnDate}`;
export const LAST_VITAL_ADDED_DATA = (params) => `${API_BASE_URL}last_vital_added?patient_id=${params.patient_id}`;
export const LATEST_DATA_FOR_EACH_VITAL = (params) => `${API_BASE_URL}individual_latest_vital?patient_id=${params.patient_id}`;

//RFH Document APi
export const PATIENT_CASE_LIST = `${API_BASE_URL}patient-case-list`;
export const PATIENT_DOCUMENT_LIST = `${API_BASE_URL}get-document-list`;
export const PATIENT_DOCUMENT_VIEW = `${API_BASE_URL}get-document-view`;

export const LOGIN = `${API_BASE_URL}login`;
export const OTP_LOGIN = `${API_BASE_URL}otp-login`;
export const OTP_LOGIN_V2 = `${API_BASE_URL}otp-login-v2`;
export const OTP_VERIFY = `${API_BASE_URL}verify-otp`;
export const OTP_VERIFY_V2 = `${API_BASE_URL}verify-otp-v2`;
export const LOGOUT = `${API_BASE_URL}logout`;
export const SYNC_TOKEN = `${API_BASE_URL}sync-token`;
export const PATIENT_QUEUE = `${API_BASE_URL}patient-queue`;
export const NURSE_CURRENT_PATIENT = `${API_BASE_URL}nurse-current-patient`;
export const PATIENT_INFO = (params) => `${API_BASE_URL}this_visit?patient_id=${params.patient_id}&reference_id=${params.reference_id}`;
export const PAUSEOPD = `${API_BASE_URL}pause-opd`;
export const SEARCH_PATIENT = `${API_BASE_URL}patient/search`;
export const SCHEDULE_IMMEDIATE_APPOINTEMENT_PATIENT = `${API_BASE_URL}patient/schedule/immediate/appointment`;
export const SEARCH_EXISTING = `${API_BASE_URL}patient/existing`;
export const REGISTER_PATIENT = `${API_BASE_URL}patient/register`;
export const TEMPLATE_SEARCH = `${API_BASE_URL}template/search`;
export const TEMPLATE_FILTER = `${API_BASE_URL}template/filter`;
export const SAVE_TEMPLATELIST = `${API_BASE_URL}templatelist/save`;
export const PARENT_TEMPLATE_ACTIVATION = `${API_BASE_URL}template/parentlevelactivate`;
export const SUB_TEMPLATE_ACTIVATION = `${API_BASE_URL}template/childlevelactivate`;
export const TEMPLATE_LIST = `${API_BASE_URL}template/getlist`;
export const DELETE_TEMPLATE = `${API_BASE_URL}delete/template`;
export const FAVORITE_TEMPLATE = `${API_BASE_URL}template/favourite`;
export const VIEW_TEMPLATE = `${API_BASE_URL}view/template`;
export const PATIENT_OBS_GYNAE_HX = `${API_BASE_URL}patient-obs-gynae-primary`;
export const EDIT_TEMPLATE = `${API_BASE_URL}edit/template`;
export const ORDERSET_SEARCH = `${API_BASE_URL}orderset/search`;
export const DELETE_ORDERSET = `${API_BASE_URL}orderset/delete`;
export const FAVORITE_ORDERSET = `${API_BASE_URL}orderset/updatefavorite`;
export const VIEW_ORDERSET = `${API_BASE_URL}view/orderset`;
export const GET_ALL_EXCLUSION_WORD = `${API_BASE_URL}all/intuitive-exclusion-words`;
export const GET_ALL_NEGATIVE_WORD = `${API_BASE_URL}all/intuitive-negative-words`;
export const GET_ALL_SPLIT_WORD = `${API_BASE_URL}all/intuitive-split-words`;
export const GET_ALL_VARIENT_WORD = `${API_BASE_URL}all/intuitive-varient-words`;
export const SAVE_EXCLUSION_WORD = `${API_BASE_URL}save/intuitive-exclusion-words`;
export const SAVE_VARIENT_WORD = `${API_BASE_URL}save/intuitive-varient-words`;
export const SAVE_NEGATIVE_WORD = `${API_BASE_URL}save/intuitive-negative-words`;
export const SAVE_SPLIT_WORD = `${API_BASE_URL}save/intuitive-split-words`;
export const DELETE_EXCLUSION_WORD = `${API_BASE_URL}delete/intuitive-exclusion-words`;
export const DELETE_NEGATIVE_WORD = `${API_BASE_URL}delete/intuitive-negative-words`;
export const DELETE_SPLIT_WORD = `${API_BASE_URL}delete/intuitive-split-words`;
export const DELETE_VARIENT_WORD = `${API_BASE_URL}delete/intuitive-varient-words`;
export const STORE_DIAGNOSIS_KEYWORD = `${API_BASE_URL}store/diagnosis-keyword`;
export const GET_ALL_DIAGNOSIS_KEYWORD = `${API_BASE_URL}all/diagnosis-keyword`;
export const DELETE_DIAGNOSIS_KEYWORD = `${API_BASE_URL}delete/diagnosis-keyword`;
export const APPLY_ORDERSET = (params) =>
  `${API_BASE_URL}orderset/apply/${params.orderset_id}/${params.patient_id}/${params.reference_type}/${params.reference_id}`;
export const HOSPITAL_CONFIG = `${API_BASE_URL}module/hospital-config`;
export const PRACTICE_STATUS = `${API_BASE_URL}practice-status`;
export const TOGGLE_PRACTICE = `${API_BASE_URL}toggle-practice`;
export const CALENDAR_DATA = `${API_BASE_URL}calender/patients`;
export const CALENDAR_DOCTOR_LEAVE = `${API_BASE_URL}calender/leave`;
export const CALENDAR_PATIENT_LIST = `${API_BASE_URL}calender/appointment/list`;
export const CALENDAR_PATIENT_BOOK = `${API_BASE_URL}calender/appointment/book`;
export const CALENDAR_PATIENT_CANCEL = `${API_BASE_URL}calender/appointment/cancel`;
export const CALENDAR_PATIENT_SLOTS = `${API_BASE_URL}calender/booking/slots`;
export const CALENDAR_SLOTS_BLOCKED = `${API_BASE_URL}calender/slots/block`;
export const CALENDAR_SLOTS_UNBLOCKED = `${API_BASE_URL}calender/slots/unblock`;
export const SOAP_CONFIGURATION_LIST = `${API_BASE_URL}module/list`;
export const SOAP_TYPE_FETCH = `${API_BASE_URL}soap-type/fetch`;
export const SOAP_TYPE_STORE = `${API_BASE_URL}soap-type/store`;
export const SINGLE_SCREEN_CONFIGURATION_FETCH = `${API_BASE_URL}single-screen/fetch`;
export const SINGLE_SCREEN_CONFIGURATION_STORE = `${API_BASE_URL}single-screen/store`;
export const SOAP_CONFIGURATION_STORE = `${API_BASE_URL}configuration/store`;
export const DOSE_RULE = `${API_BASE_URL}dose/rule`;
export const PROFILE = (params) => `${params.is_doctor ? API_BASE_URL : API_BASE_URL_NURSE}profile`;
export const PROFILE_UPDATE = `${API_BASE_URL}profile/update`;
export const CHANGE_PASSWORD = `${API_BASE_URL}change-password`;
export const SEARCH_DIAGNOSIS = `${API_BASE_URL}diagnose`;
export const FAVOURITE_DIAGNOSIS = `${API_BASE_URL}diagnosis/favourite`;
export const TODAYS_VISIT = `${API_BASE_URL}todays-visit`;
export const ADD_DRUGS = `${API_BASE_URL}add/drug/external`;
export const SEARCH_DRUGS = `${API_BASE_URL}drug/search`;
export const DRUGS_CONSTANT = `${API_BASE_URL}drugs/constants`;
export const DRUG = `${API_BASE_URL}drug`;
export const INTUITIVE_CONSTANTS = `${API_BASE_URL}intuitive-constants`;
export const DRUG_FAVOURITE = `${API_BASE_URL}drug/favourite`;
export const LAB_TEST_FAVOURITE = `${API_BASE_URL}lab-tests/favourite`;
export const NON_FORMULARY_TEST_MAIL = `${API_BASE_URL}non-formulary/test/mail`;
export const LAB_TEST_SEARCH = `${API_BASE_URL}lab/test/search`;
export const LAB_TEST_CONSTANTS = `${API_BASE_URL}tests/constants`;
export const INSTRUCTION_SEARCH = `${API_BASE_URL}instructions/getAll`;
export const ORDERSET_CREATE = `${API_BASE_URL}orderset/create`;
export const ORDERSET_NEW_MAPPING = `${API_BASE_URL}orderset/maphospital`;
export const ORDERSET_CHANGE_MAPPING = `${API_BASE_URL}orderset/changemapping`;
export const PARENT_ORDERSET_ACTIVATION = `${API_BASE_URL}orderset/parentactivation`;
export const SUB_ORDERSET_ACTIVATION = `${API_BASE_URL}orderset/subactivation`;

//cims api's
export const CIMSALLOW = true;
export const CIMS_ALLOW_HOSPITALS = [1];
export const CIMS_INFO = `${API_BASE_URL}cimsplus/drug-info`;
export const CIMSINTERACTION = `${API_BASE_URL}cimsplus/drug-interaction`;
export const CIMS_INTERACTION_EXISTS = (params) =>
  `${API_BASE_URL}cimsplus/drug-interaction-exists?drugIds=${params.drugIds}&referenceId=${params.referenceId}&referenceType=${params.referenceType}&enterpriseId=${params.enterpriseId}&FacilityId=${params.FacilityId}&StoreId=${params.StoreId}&patientId=${params.patientId}&orederSetId=${params.orederSetId}&careProtoColId=${params.careProtoColId}&medicineScreen=${params.medicineScreen}&addedAssessmentList=${params.addedAssessmentList}`;

//allergy cims
export const CIMS_ALLERGY_SEARCH = `${API_BASE_URL}cimsplus/allergy-search`;
export const CIMS_ALLERGY_INTERACTION_EXISTS = (params) =>
  `${API_BASE_URL}cimsplus/allergies-interaction-exists?drugIds=${0}&referenceId=${params.referenceId}&referenceType=${params.referenceType}&enterpriseId=${params.enterpriseId}&FacilityId=${params.FacilityId}&StoreId=${params.StoreId}&patientId=${params.patientId}&orederSetId=${0}&careProtoColId=${0}&medicineScreen=${params.medicineScreen}&addedAssessmentList=${params.addedAssessmentList}&allergyCimsGuid=${params.allergyCimsGuid}&allergyCimsType=${params.allergyCimsType}`;
export const CIMS_ALLERGY_INTERACTION = `${API_BASE_URL}cimsplus/allergies-interaction`;

//diagnosis cims
export const CIMS_DIAGNOSIS_INTERACTION_EXISTS = (params) =>
  `${API_BASE_URL}cimsplus/diagnosis-interaction-exists?drugIds=${0}&referenceId=${params.referenceId}&referenceType=${params.referenceType}&enterpriseId=${params.enterpriseId}&FacilityId=${params.FacilityId}&StoreId=${params.StoreId}&patientId=${params.patientId}&orederSetId=${0}&careProtoColId=${params.careProtoColId}&medicineScreen=${params.medicineScreen}&addedAssessmentList=${params.addedAssessmentList}`;
export const CIMS_DIAGNOSIS_INTERACTION = `${API_BASE_URL}cimsplus/diagnosis-interaction`;

export const CIMSCONFIGURATION = `${API_BASE_URL}doctor-cims-alert-configuration-details`;
export const CIMSCONFIGURATIONUPDATE = `${API_BASE_URL}doctor-cims-alert-configuration-update`;

export const ALL_CIMS_INTERACTION = `${API_BASE_URL}cimsplus/all-drug-interaction`;
export const CIMS_CAREPLAN_INTERACTION = `${API_BASE_URL}cimsplus/care-plan-interaction`;
export const ALL_CIMS_INTERACTION_EXISTS = (params) =>
  `${API_BASE_URL}cimsplus/all-drug-interaction-exists?drugIds=${params.drugIds}&referenceId=${params.referenceId}&referenceType=${params.referenceType}&enterpriseId=${params.enterpriseId}&FacilityId=${params.FacilityId}&StoreId=${params.StoreId}&patientId=${params.patientId}&orederSetId=${params.orederSetId}&careProtoColId=${params.careProtoColId}&medicineScreen=${params.medicineScreen}&addedAssessmentList=${params.addedAssessmentList}`;
export const CIMS_CAREPLAN_INTERACTION_EXISTS = (params) =>
`${API_BASE_URL}cimsplus/care-plan-interaction-exists?drugIds=${params.drugIds}&referenceId=${params.referenceId}&referenceType=${params.referenceType}&enterpriseId=${params.enterpriseId}&FacilityId=${params.FacilityId}&StoreId=${params.StoreId}&patientId=${params.patientId}&orederSetId=${params.orederSetId}&careProtoColId=${params.careProtoColId}&medicineScreen=${params.medicineScreen}&addedAssessmentList=${params.addedAssessmentList}`;

export const CREATE_TEMPLATE = `${API_BASE_URL}create/template`;
export const CHECK_LATE_ALERT_STATUS = `${API_BASE_URL}late-alert-status`;
export const LATE_ALERT = `${API_BASE_URL}late-alert`;
export const REFERAL_INFORMATION = `${API_BASE_URL}referal_information`;
export const REFERRAL_LIST = `${API_BASE_URL}list/referral`;
export const REFERRAL_DETAILS = `${API_BASE_URL}referral/details`;
export const CUSTOM_SUBJECTIVE_INFRMATION = (params) =>
  `${API_BASE_URL}v3/custom_subjectives/${params.reference_type}/${params.reference_id}`;
export const SUBJECTIVE_INFRMATION = (params) =>
  `${API_BASE_URL}v2/subjectives/${params.reference_type}/${params.reference_id}`; // type is 0 and reference id is qms_token_id
export const SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS = (params) =>
  `${API_BASE_URL}v3/subjectives/chief_complaints/${params.reference_type}/${params.reference_id}`; // type is 0 and reference id is qms_token_id
export const SUBJECTIVE_INFRMATION_CUSTOM = (params) =>
  `${API_BASE_URL}subjectives/custom/${params.reference_type}/${params.reference_id}`; // type is 0 and reference id is qms_token_id
export const PARENT_INFRMATION_CUSTOM = (params) =>
  `${API_BASE_URL}parent/custom/${params.reference_type}/${params.reference_id}`; // type is 0 and reference id is qms_token_id
export const SUBJECTIVE_INFRMATION_INTERNAL_NOTES = (params) =>
  `${API_BASE_URL}v3/subjectives/internal_notes/${params.reference_type}/${params.reference_id}`;
export const SUBJECTIVE_INFRMATION_CLINICAL_NOTES = (params) =>
  `${API_BASE_URL}v3/subjectives/clinical_notes/${params.reference_type}/${params.reference_id}`;
export const SUBJECTIVE_STORE = `${API_BASE_URL}v2/subjectives/store`;
export const SUBJECTIVE_STORE_CHIEF_COMPLAINTS = `${API_BASE_URL}v3/subjectives/store/chief_complaints`;
export const SUBJECTIVE_CUSTOM_STORE = `${API_BASE_URL}subjectives/custom/store`;
export const PARENT_CUSTOM_STORE = `${API_BASE_URL}parent/custom/store`;
export const SUBJECTIVE_STORE_INTERNAL_NOTES = `${API_BASE_URL}v3/subjectives/store/internal_notes`;
export const SUBJECTIVE_STORE_CLINICAL_NOTES = `${API_BASE_URL}v3/subjectives/store/clinical_notes`;
export const COMPLETED_DOCTOR_VISIT = (params) =>
  `${API_BASE_URL}completed-doctor-visit/${params.patient_id}/${params.doctor_id}`;
export const OBJECTIVE_GETALL = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}`;
export const PDF_CONFIG_VITALS_GRAPH = (params) =>
  `${API_BASE_URL}pdf-configuration/vitals-graph/${params.reference_type}/${params.reference_id}`;
export const PDF_CONFIG_VITALS_GRAPH_UPDATE = `${API_BASE_URL}pdf-configuration/vitals-graph`;
export const VITALS_GRAPH = (params) =>
  `${API_BASE_URL}objective/vitals-graph/${params.patient_id}${params?.start_date !== undefined
    ? `?start_date=${params.start_date}&end_date=${params.end_date}`
    : ""
  }`;

export const VITAL_GRAPH_DATE = (params) =>
  `${API_BASE_URL}objective/vitals/graph/${params.patient_id}?end_date=${params.end_date}`;
export const EXISTING_CONDITION_LIST = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/conditions`;
export const EXISTING_CONDITION_DELETE = (params) =>
  `${API_BASE_URL}objective/condition/delete/${params.history_id}`;
export const SURGICAL_HISTORY_LIST = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/surgical-histories`;
export const ALLERGIES_GETALL = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/allergies`;
export const MEDICINES_GETALL = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/current-medicines`;
export const MEDICINES_GETALL_CURRENT_MEDICINES = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/all-current-medicines/${params.reference_type}/${params.reference_id}`;
export const CURRENT_MEDICINES_STOP = (params) =>
  `${API_BASE_URL}objective/getAll/stop/current-medicines/${params.stopPrescriptionDrugId}/${params.doctor_id}`;
export const GET_VISIT_VITALS = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/vitals/${params.reference_type}/${params.reference_id}`;
export const VITALS_GETALL = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}/vitals`;
export const HISTORY_DELETE = (params) =>
  `${API_BASE_URL}objective/${params.type}/delete/${params.id}/${params.patient_id}/${params.reference_id}`;
export const SURGICAL_HISTORY_DELETE = (params) =>
  `${API_BASE_URL}objective/surgical-history/delete/${params.history_id}`;
export const FAMILY_HISTORY_LIST = `${API_BASE_URL}familyHistory`;
export const FAMILY_HISTORY_DELETE = (params) => `${API_BASE_URL}delete/relation/${params.history_id}`;
export const FAMILY_STORE = `${API_BASE_URL}patient/save/objective`;
export const SOCIALHX_LIST = `${API_BASE_URL}getSocialHx`;
export const SOCIAL_HISTORY_DELETE = (params) => `${API_BASE_URL}delete/socialHx/${params.history_id}`;
export const BLOOD_GROUP_LIST = `${API_BASE_URL}getBloodGroupList`;
export const SOCIAL_HISTORY_UPDATE = `${API_BASE_URL}patient/save/PatientHistory`;
export const DISEASE_SEARCH = `${API_BASE_URL}disease`;
export const RELATIONSHIP_SEARCH = `${API_BASE_URL}relationship`;
export const KEY_FINDINGS_GRAPH = `${API_BASE_URL}patient-test-result/graph`;
export const MARK_DEFAULT_CORRECTION_DOSE = `${API_BASE_URL}default-correction-dose`;
export const FETCH_DEFAULT_CORRECTION_DOSE = `${API_BASE_URL}getAll/default-correction-dose`;
export const SAVE_TARGET_BLOOD_SUGAR = `${API_BASE_URL}save-target-bs`;
export const DETAIL_LIST_API = (params) =>
  `${API_BASE_URL}test-record/detail-list?search=${params.search}&is_abnormal=${params.is_abnormal}&patientId=${params.patient_id}&startdate=${params.startdate}&enddate=${params.enddate}`;
export const KEY_FINDING_STORE = `${API_BASE_URL}key-findings/store`;
export const SOCIALHX_HISTORY_DELETE = (params) =>
  `${API_BASE_URL}delete/socialHx/${params.history_id}`;
export const OBJECTIVE_STORE = `${API_BASE_URL}objective/store`;
export const CONDITION_SEARCH = `${API_BASE_URL}condition`;
export const CONDITION_ADD_MASTER = `${API_BASE_URL}condition/add-master`;
export const ALLERGY_ADD_MASTER = `${API_BASE_URL}allergy/add-master`;
export const ALLERGIES_DROPDOWN = (params) =>
  `${API_BASE_URL}allergies?${params.search && params.search.length > 0 ? "search=" + params.search : ""
  }&type[]=${params.type}`;
export const PHR_DOCS = `${API_BASE_URL}getPhrDetails/{patientId}`;
export const TEST_RESULTS = `${API_BASE_URL}patient-test-results`;
export const TEST_RESULT_INFO = `${API_BASE_URL}patient-test-result/{code}`;
export const TEST_RESULT_GRAPH = `${API_BASE_URL}patient-test-result/graph`;
export const PAST_VISIT_PRIMARY_DETAILS = `${API_BASE_URL}past-visit-primary-details`;
export const PREVIOUS_VISIT_DETAILS = `${API_BASE_URL}previous-visit-details`;
export const PAST_VISIT_COMPLETE_DETAILS = `${API_BASE_URL}past-visit-complete-details`;
export const AUDIO_CALL = `${API_HOST}patient/audio-call`;
// export const VONAGE_CALL_API = `${API_BASE_URL}vonage/call`;
export const VONAGE_CALL_API = `${API_BASE_URL}doctor/vonage/call`;
// export const VONAGE_CALL_NOTIFY = (params) => `${API_BASE_URL}vonage/booking/${params.bookingId}/status/${params.type}`;
export const VONAGE_CALL_NOTIFY = `${API_BASE_URL}doctor/vonage/status`;
// export const CALL_RATING = `${API_BASE_URL}call`;
export const CALL_RATING = `${API_BASE_URL}vonage/state`;
export const ASSESSMENT_DELETE = (params) =>
  `${API_BASE_URL}assessments/delete/${params.id}`;
export const ASSESSMENT_STORE = `${API_BASE_URL}assessments/store`;
// shared internal notes
export const SHARED_INTERNAL_NOTES = (params) => `${API_BASE_URL}shared-internal-notes/${params.reference_type}/${params.reference_id}/${params.type}/${params.patient_id}`;
export const STORE_SHARED_INTERNAL_NOTES = `${API_BASE_URL}store/shared_internal_notes`;

// RIS reports
export const PATIENT_RIS_REPORTS = `${API_BASE_URL}ris_report_details`;



//100ms call
export const HUNDRED_MS_CALL_API = `${API_BASE_URL}doctor/100ms/call`;
export const CANCEL_HUNDRED_MS_CALL = `${API_BASE_URL}doctor/100ms/call-cancel`;
export const MPC_HUNDREDMS_DOCTOR_JOIN_CALL = `${API_BASE_URL}doctors/mpc-hundredms-join-call`;
export const HUNDRED_MS_P2P_CALL = 'https://mhc-p2p.app.100ms.live/meeting/';


export const ASSESSMENT_API = (params) =>
  `${API_BASE_URL}assessments/${params.reference_type}/${params.reference_id}`;
export const ASSESSMENT_STATUS_UPDATE = (params) =>
  `${API_BASE_URL}assessments/update-status?assessmentID=${params.id}&status=${params.status}`;
export const ASSESSMENT_LIST = `${API_BASE_URL}template/list`;
export const INTUITIVE_DIAGNOSIS_AND_CP = `${API_BASE_URL}template/intuitive`;
export const PRESCRIPTION_DATA = (params) =>
  `${API_BASE_URL}prescription/${params.patient_id}/${params.reference_type}/${params.reference_id}/${params.hospital_id}`;
export const SOAP_DETAILS = `${API_BASE_URL}visit/details`;
export const SOAP_OBJECTIVE_DETAILS = `${API_BASE_URL}visit/objective/details`;
export const VIEW_VISIT = `${API_BASE_URL}view/visit`;
export const DOCTOR_LIST = `${API_BASE_URL}doctor/list`;
export const HOSPITAL_DOCTOR_LIST = `${API_BASE_URL}hospital_doctor/list`;
export const HOSPITAL_AGENT_LIST = `${API_BASE_URL}hospital/agentlist`;
export const SPECIALITY_AGENT_LIST = `${API_BASE_URL}speciality/agentlist`;
export const ALL_SPECIALITY_AGENT_LIST = `${API_BASE_URL}all/speciality/agentlist`;
export const DOCTOR_AGENT_LIST = `${API_BASE_URL}doctor/agentlist`;
export const ALL_DOCTOR_AGENT_LIST = `${API_BASE_URL}all/doctor/agentlist`;
export const SPECIALITY_LIST = `${API_BASE_URL}speciality/list`;
export const SAVE_REFERRAL = `${API_BASE_URL}save/referral`;
export const DELETE_REFERRAL = `${API_BASE_URL}delete/referral`;
export const PHYSIO_UPADTE = `${API_BASE_URL}visit/physiotherapy/update`;
export const BLOOD_GROUP_UPADTE = `${API_BASE_URL}patient/blood_group/update`;
export const ADMIS_UPADTE = `${API_BASE_URL}visit/admission/update`;
export const FOLLOWUP_UPDATE = `${API_BASE_URL}visit/follow-up/update`;
export const FOLLOWUP_UPDATE_BOOKING = `${API_BASE_URL}visit/follow-up/update-booking`;
export const TESTS_DELETE = (params) => `${API_BASE_URL}test/delete/${params}`;
export const ADMISSION_UPDATE = `${API_BASE_URL}visit/hospital-admission/update`;
export const COMPLETE_APPOINTEMENT = `${API_BASE_URL}complete-patient-visit`;
export const IMAGE_LIBRARY = `${API_BASE_URL}assessments/imagesLibrary`;
export const PLAN_TEST_ADVICED_STORE = `${API_BASE_URL}test/store`;
export const PLAN_TEST_ADVICED_LIST = (params) =>
  `${API_BASE_URL}test/${params.patient_id}/${params.reference_type}/${params.reference_id}`;
export const INSTRUCTION_STORE = `${API_BASE_URL}instructions/store`;
export const INSTRUCTION_FAV = `${API_BASE_URL}instructions/makefav`;
export const DELETE_ALL_INSTRUCTIONS = `${API_BASE_URL}instructions/deleteAll`;
export const INSTRUCTION_DELETE = (params) =>
  `${API_BASE_URL}instructions/delete/${params.instruction_id}/${params.reference_type}/${params.reference_id}`;
export const SUPPORTING_MATERIAL = `${API_BASE_URL}instructions/supporting-material`;
export const FETCH_SUPPORTING_MATERIAL = `${API_BASE_URL}instructions/fetch-supporting-material`;
export const UPDATE_SUPPORTING_MATERIAL = `${API_BASE_URL}instructions/update-supporting-material`;
export const DELETE_SUPPORTING_MATERIAL = (params) =>
  `${API_BASE_URL}instructions/delete-supporting-material/${params.file_id}/${params.doctor_id}/${params.formType}`;
export const DELETE_VISIT_SUPPORTING_MATERIAL = (params) => `${API_BASE_URL}instructions/delete-visit-supporting-material/${params.id}`;
export const PRESCRIPTION_STORE = `${API_BASE_URL}prescription/store`;
export const COPY_PRESCRIPTION = `${API_BASE_URL}copy-prescription`;
export const COPY_VISIT_APPLY = `${API_BASE_URL}v2/template/apply`;
export const BASIC_STORE = `${API_BASE_URL}visit/basic`;
export const GET_BASIC_DATA = `${API_BASE_URL}get/visit/basic`;
export const DELETE_MEDICINE = (params) =>
  `${API_BASE_URL}prescription/delete/${params.id}`;
export const DELETE_All_MEDICINE = `${API_BASE_URL}prescription/deleteAll`;
export const DELETE_TEST = (params) =>
  `${API_BASE_URL}test/delete/${params.id}`;
export const DELETE_ALL_TESTS = `${API_BASE_URL}test/deleteAll`;
export const REQUEST_PIN = `${API_BASE_URL}request-pin`;
export const VERIFY_PIN = `${API_BASE_URL}verify-pin`;
export const SAVE_PIN = `${API_BASE_URL}save-pin`;
export const CHAT_API = `${API_BASE_URL}chat-list`;
export const SAVE_CHAT_TIME = `${API_BASE_URL}doctors/save-chat-time`;
export const ALL_PRESCRIPTIONS = `${API_BASE_URL}past-visit-others`;
export const ALL_PRESCRIPTIONS_LIST = `${API_BASE_URL}get-all-visits`;
export const VISIT_COUNT = `${API_BASE_URL}previous/visit`;
export const NO_KNOWN_OBJECTIVE = `${API_BASE_URL}objective/no-known-objective/update`;
// export const KARDIA_ECG = params => `https://mhappapis.vc.myhealthcare.co/api/patients/web/v2/patients/ecg?mrn=${params.kardiaMrn}&page=${params.pagination}`;
export const KARDIA_ECG = (params) =>
  `${API_BASE_URL}patient/ecg?mrn=${params.kardiaMrn}&page=${params.pagination}`;
export const ALLERGIES_N_CONDITIONS = (params) =>
  `${API_BASE_URL}objective/getAll/${params.patient_id}`;
export const GET_ADDENDUM = `${API_BASE_URL}visit/get/addendum`;
export const STORE_ADDENDUM = `${API_BASE_URL}visit/addendum`;
export const UPDATE_VITALS = `${API_BASE_URL}objective/vitals/update`;
export const HOSPITAL_LIST = `${API_BASE_URL}hospital/list`;
export const ASSESSMENT_SCORE_DETAILS = `${API_BASE_URL}get-assessment-score`;

/** || PAEDIATRICS APIS */
export const ALL_MILESTONES_CHILD = `${API_BASE_URL}child-milestones`;
export const MILESTONES_CHILD_SAVE = `${API_BASE_URL}patient-child-milestones/save`;
export const MILESTONES_CHILD_COMPLETE = `${API_BASE_URL}patient-child-milestones/complete`;
export const MILESTONES_CHILD_CONTINUE = `${API_BASE_URL}patient-child-milestones/continue`;
export const MILESTONES_CHILD_LIST = `${API_BASE_URL}patient-child-milestones/list`;
export const PATIENT_VITALS_ALL = (params) =>
  `${API_BASE_URL}objective/getAllVitals/${params.patient_id}`;
export const GET_VISIT_PRESCRIPTION_PDF = (params) =>
  `${API_BASE_URL}objective/getVisitPrescriptionPdf/${params.hospital_id}/${params.patient_id}/${params.reference_type}/${params.reference_id}`;

export const VaccinationList = `${API_BASE_URL}vaccination/list`;

export const VaccinationTableList = `${API_BASE_URL}vaccination/chart/list`;
export const VaccinationAdd = `${API_BASE_URL}vaccination/store`;
export const VaccinationItemList = `${API_BASE_URL}drug/vaccine/list`;
export const VaccinationRecordList = `${API_BASE_URL}vaccination/record/list`;
export const VaccinationDelete = `${API_BASE_URL}delete/vaccination`;
export const VaccinationUpdate = `${API_BASE_URL}update/vaccination`;
export const VaccinationByRefence = `${API_BASE_URL}vaccination/past-visit/list`;

/** || DIABETES APIS */
export const OBJECTIVE_VITAL_STORE = `${API_BASE_URL}objective/vitals/store`;
export const KEYFINDINGS_FILTER_SEQUENCING_SAVE = `${API_BASE_URL}keyfindings-filter-sequencing/save`;
export const KEYFINDINGS_FILTER_SEQUENCING_LIST = `${API_BASE_URL}keyfindings-filter-sequencing/list`;
export const PDF_CONFIG_KEYFINDINGS_GRAPH_UPDATE = `${API_BASE_URL}pdf-configuration/key-findings-graph`;

export const OBJECTIVE_VITAL_STORE_SS = `${API_BASE_URL}v3/objective/vitals/store`;
export const OBJECTIVE_KEYFINDING_STORE_SS = `${API_BASE_URL}v3/objective/keyfindings/store`;
export const VITALS_FILTER_SEQUENCING_LIST = `${API_BASE_URL}vitals-filter-sequencing/list`;
export const VITALS_FILTER_SEQUENCING_SAVE = `${API_BASE_URL}vitals-filter-sequencing/save`;


/** || HOMECARE APIS */

export const HOMECARE_PATIENTLIST = (params) =>
  `${API_BASE_URL}homecare/patient-list?booking_status=${params.booking_status
  }&date=${params.date}&hospital_id=${params.hospital_id}&search=${params?.search || ""
  }&page=${params.page}&per_page=${params.per_page}`;
export const HOMECARE_VITALALERTLIST = (params) =>
  `${API_BASE_URL}homecare/doctor/patient-vital-alerts?doctor_id=${params.doctor_id}&hospital_id=${params.hospital_id}`;
export const HOMECARE_PATIENTALERTLIST = (params) =>
  `${API_BASE_URL}homecare/doctor/alerts?doctor_id=${params.doctor_id}`;
export const HOMECARE_DETAIL = (params) =>
  `${API_BASE_URL}homecare/patient-details?booking_id=${params.booking_id}&is_called_patient=${params.is_called_patient}`;
//export const HOMECARE_STORE_TEST_NOTES = `${API_BASE_URL}homecare/patient-details/store-test-notes`;
export const PROGRESSNOTES = (params) =>
  `${API_BASE_URL}homecare/patient-details/progress-notes?booking_id=${params.booking_id}`;
export const PROGRESSNOTESACCORDIAN = (params) =>
  `${API_BASE_URL}homecare/patient-details/packages?booking_id=${params.booking_id}&hospital_id=${params.hospital_id}`;
export const HOMECARE_STORE_TEST_NOTES = `${API_BASE_URL}homecare/patient-details/store-notes`;
export const HOMECARE_SEND_PRESCRIPTION = (params) =>
  `${API_BASE_URL}homecare/patient-details/send-prescription`;
export const HOMECARE_CONSULT_ALERT = (params) =>
  `${API_BASE_URL}homecare/patient/booking-alerts?booking_id=${params.booking_id}`;
export const HOMECARE_SAVE_ALERT = (params) =>
  `${API_BASE_URL}homecare/doctor/store-patient-alerts`;
export const HOMECARE_SAVE_VITAL_ALERT = (params) =>
  `${API_BASE_URL}homecare/doctor/store-patient-vital-alerts`;
export const HOMECAREPASTVISIT = (params) =>
  `${API_BASE_URL}homecare/patient/past-prescriptions?patient_id=${params.patient_id}&is_homecare=1&hospital_id=${params.hospital_id}&page=${params.page}`;

export const GETDOCURL = `${API_BASE_URL}homecare/files/get-document-dec-url`;


// For Care Plan

export const HOMECARE_CARE_PLAN = (params) =>
  `${API_BASE_URL}homecare/care-plan/get?booking_id=${params.booking_id}&patient_id=${params.patient_id}`;
export const HOMECARE_CARE_PLAN_V2 = (params) =>
  `${API_BASE_URL}V2/homecare/care-plan/get?booking_id=${params.booking_id}&patient_id=${params.patient_id}`;


export const HOMECARE_STORE_CURRENT_MEDICINE = (params) =>
  `${API_BASE_URL}homecare/patient/current-medicines/store`;
export const HOMECARE_CURRENT_MEDICINE_GET = (params) =>
  `${API_BASE_URL}homecare/patient/current-medicines/get?patient_id=${params.patient_id}&booking_id=${params.booking_id}`;
export const HOMECARE_CALL_RECORD_RATING = `${API_BASE_URL}doctors/homecare-call`;
export const HOMECARE_TEST_NOTES = (params) =>
  `${API_BASE_URL}homecare/patient/test-notes?booking_id=${params.booking_id}&page=${params.page}`;
export const HOMECARE_SAVE_INTERNAL_NOTES = `${API_BASE_URL}homecare/patient/internal-notes/store`;
export const HOMECARE_GET_INTERNAL_NOTES = (params) =>
  `${API_BASE_URL}homecare/patient/internal-notes/get?booking_id=${params.booking_id}&patient_id=${params.patient_id}`;
export const HOMECARE_SEND_CASE_SUMMARY = `${API_BASE_URL}homecare/send-case-summary`;
export const HOMECARE_DRAFT_CASE_SUMMARY = `${API_BASE_URL}homecare/patient/case-summary/store`;
export const HOMECARE_PREVIEW_CASE_SUMMARY = `${API_BASE_URL}homecare/preview-case-summary`;
export const HOMECARE_GET_CASE_SUMMARY = (params) =>
  `${API_BASE_URL}homecare/patient/case-summary/get?booking_id=${params.booking_id}&patient_id=${params.patient_id}`;
export const HOMECARE_CREATE_SLOT = `${API_BASE_URL}calendar/create-slot`;

//nurse role api
export const NURSE_MARK_ARRIVAL = `${API_BASE_URL}nurse-mark-arrival`;
export const LATE_ALERT_PATIENT = `${API_BASE_URL}late-alert-patient`;


/** || OBS GYN APIS */
export const OBSTETRICS_GYNAE_HISTORY_CONFIDENTIAL = `${API_BASE_URL}obstetrics/gynae/history/confidential`;
export const SEXUAL_HISTORY_STORE = `${API_BASE_URL}save-sexual-history`;
export const SEXUAL_HISTORY_LIST = (params) =>
  `${API_BASE_URL}sexual-history-list?patient_id=${params.patient_id}&doctor_id=${params.doctor_id}&hospital_id=${params.hospital_id}&reference_id=${params.reference_id}&reference_type=${params.reference_type}`;
export const MENSTRUAL_HISTORY_STORE = `${API_BASE_URL}save-menstrual-history`;
export const MENSTRUAL_HISTORY_LIST = (params) =>
  `${API_BASE_URL}menstrual-history-list?patient_id=${params.patient_id}&doctor_id=${params.doctor_id}&hospital_id=${params.hospital_id}&reference_id=${params.reference_id}&reference_type=${params.reference_type}`;
export const MENSTRUAL_HISTORY_GRAPH = (params) =>
  `${API_BASE_URL}menstrual-history-graph?patient_id=${params.patient_id}&doctor_id=${params.doctor_id}&hospital_id=${params.hospital_id}&reference_id=${params.reference_id}&reference_type=${params.reference_type}`;
export const MENSTRUAL_HISTORY_GRAPH_PRINT = `${API_BASE_URL}pdf-configuration/menstrual-params-graph`;

export const OBSTETRIC_HISTORY_STORE = `${API_BASE_URL}save-obstetric-history`;
export const OBSTETRIC_HISTORY_LIST = `${API_BASE_URL}obstetric-history-list`;
export const GPALE_CURRENT_PREGNANCY_DATA = `${API_BASE_URL}get-gpale-current-pregnancy-data`;
export const STORE_PHYSICAL_EXAMINATION = `${API_BASE_URL}physical-examination/store`;
export const DELETE_PHYSICAL_EXAMINATION = `${API_BASE_URL}physical-examination/delete`;
export const STORE_PHYSICAL_EXAMINATION_FETAL_PARAMS = `${API_BASE_URL}physical-examination/fetal-param/store`;
export const DELETE_PHYSICAL_EXAMINATION_FP = `${API_BASE_URL}physical-examination/fetal-param/delete`;
export const GET_ARRAY_BUFFER_FORM_URL = `${API_BASE_URL}getarraybuffer`;
export const LIST_PHYSICAL_EXAMINATION = (params) => `${API_BASE_URL}physical-examination/list?patient_id=${params.patient_id}&doctor_id=${params.doctor_id}`;
export const LIST_PHYSICAL_EXAMINATION_FETAL_PARAMS = (params) => `${API_BASE_URL}physical-examination/fetal-param/list?patient_id=${params.patient_id}&doctor_id=${params.doctor_id}`;
export const PHYSICAL_EXAM_GRAPH = (params) => `${API_BASE_URL}physical-examination-graph?patient_id=${params.patient_id}&doctor_id=${params.doctor_id}`;
export const PDF_CONFIG_FETAL_GRAPH_UPDATE = `${API_BASE_URL}pdf-configuration/fetal-params-graph`;
export const RISK_FACTORS_LIST = `${API_BASE_URL}get-risk-factors`;
export const CHIEFCOMPLAINT_LIST = `${API_BASE_URL}chiefcomplaint-list`;

export const UPDATE_QMS_STATUS = `${API_BASE_URL}update/qms-status`;
export const UPDATE_QMS_STATUS_NURSE = `${API_BASE_URL}update/nurse/qms-status`;

// subjective internal notes
export const PATIENT_INTERNAL_NOTES = (params) => `${API_BASE_URL}subjectives/internal-notes/${params.reference_type}/${params.reference_id}/${params.type}`;
// subjective internal notes
export const PATIENT_CLINICAL_NOTES = (params) => `${API_BASE_URL}subjectives/clinical-notes/${params.reference_type}/${params.reference_id}/${params.type}`;
export const PDF_CONFIG_CLINICAL_NOTES_UPDATE = `${API_BASE_URL}pdf-configuration/clinical-notes`;

// Patient checkout
export const PATIENT_CHECKOUT = `${API_BASE_URL}patient-checkout`;

// Multi party calling doctors list
export const MPC_DOCTORS = `${API_BASE_URL}mpc/doctors`;
export const MPC_DOCTOR_JOIN_CALL = `${API_BASE_URL}doctors/mpc-join-call`;

export const PATIENT_HOMECARE_MEDICATION = `${API_BASE_URL}homecare/medications-list`;
export const PATIENT_HOMECARE_INTERNAL_NOTES = `${API_BASE_HOMECARE_URL}v1/spa/get-internal-notes`;
export const PATIENT_HOMECARE_PROGRESS_NOTES = `${API_BASE_HOMECARE_URL}v1/spa/get-progress-notes`;
//diagnosis report api
export const DIAGNOSIS_LIST_API = (params) =>
  `${API_BASE_URL}diagnosis-list?data=${params.patient_id}&page=${params.pagination}&startdate=${params.startdate}&enddate=${params.enddate}&hospital_id=${params.hospital_id}`;
export const DIAGNOSIS_LIST_UPLOADS_API = (params) =>
  `${API_BASE_URL}uploads-list?data=${params.patient_id}&page=${params.pagination}&startdate=${params.startdate}&enddate=${params.enddate}`;

export const AUTO_LOGIN = (params) => `${API_BASE_URL}doctor/get-autologin?token=${params.token}`;
export const STORE_FALL_RISK_ASSESSMENT = `${API_BASE_URL}fall-risk-assessment`;
export const STORE_NUTRITION_SCREENING_TOOL = `${API_BASE_URL}nutrition-screening-tool-for-nurse`;
export const STORE_NURSE_ASSESSMENT = `${API_BASE_URL}risk-fall-assessment`;
export const GET_ALL_ASSESSMENT = (params) => `${API_BASE_URL}get-all-assessment?page=${params.page}&patient_id=${params.patient_id}&reference_id=${params.reference_id}`;
export const STORE_ASSESSMENT_REPORT = `${API_BASE_URL}nursing-assessment-report`;
export const STORE_ASSESSMENT_MEDICINE = `${API_BASE_URL}get-nursing-medicine`;
export const GET_MODEOF_ARRIVAL = `${API_BASE_URL}get-mode-of-arrival`;
/* || REACT COMMON FUNCTIONS & VARIABLES */
export const HANDLEVALIDANTMESSAGES = {
  required: "Required!",
  types: {
    number: "Not a valid number!",
  },
  number: {
    range: "Must be between ${min} and ${max}",
  },
};
export const HANDLEVALIDANTMESSAGESPATIENTAGE = {
  required: "Required!",
  types: {
    number: "Not a valid number!",
  },
  number: {
    range: "Duration cannot be greater than patient's age",
  },
};
export const TAB_NAMES = [
  "worklist",
  "completed",
  "pending",
  "homecare",
  "homecare_completed",
  'result_review'
];
export const PASTPRESCRIPTION_TAB_NAMES = ["homecare", "vcopd"];
export const MEDICATION_ON = ["No", "Yes"];
export const DURATION_TYPE = ["days", "weeks", "months", "years"];
export const DURATION_TYPE_OBJECT = {
  days: 1,
  weeks: 7,
  months: 30,
  years: 365,
};
export const DURATION_TYPE_OBJECT_REVERSE = {
  1: "Days",
  7: "Weeks",
  30: "Months",
  365: "Years",
};
export const DURATION_TYPE_OBJECT_REVERSE_SINGULAR = {
  1: "Day",
  7: "Week",
  30: "Month",
  365: "Year",
};
export const DURATION_TYPE_SMALL = ["d", "w", "m", "y"];
export const DURATION_TYPE_SINGULAR = ["day", "week", "month", "year"];
export const MONTHS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const VITAL_ARRAY = [
  "V001",
  "V002",
  "V003",
  "V004",
  "V005",
  "V007",
  "V008",
  "V009",
  "V010",
  "V011",
];
export const INFORMED_BY = ["Observed by Clinician", "Informed by Patient"];
export const PATIENT_STATUS = {
  Completed: "patientArrived",
  Arrived: "patientArrived",
  Missed: "patientNoShow",
  Confirmed: "patientConfirmed",
};
export const GETVITAL_RANGE_CLASS = (value, range) => {
  if (value == "-") {
    return "";
  }
  var $vitalRange = "";
  if (value >= range.normal.min && value <= range.normal.max) {
    $vitalRange = "text-success";
  }
  if (
    (value > range.critical.min && value < range.normal.min) ||
    (value > range.normal.max && value < range.critical.max)
  ) {
    $vitalRange = "text-warning";
  }
  if (
    (range.critical.min != null && value <= range.critical.min) ||
    (range.critical.max != null && value >= range.critical.max)
  ) {
    $vitalRange = "text-danger";
  }
  return $vitalRange;
};
export const ALLERGY_INTENSITY = [
  { name: "Low", image: "allergies-primary.png", class: "icon-severity-low" },
  {
    name: "Moderate",
    image: "allergies-warning.png",
    class: "icon-severity-medium",
  },
  { name: "High", image: "allergies-danger.png", class: "icon-severity-high" },
];
export const DRUG_URGENCY = {
  1: "STAT",
  2: "Urgent",
  3: "Routine",
};
export const DRUG_EVENTS = {
  1: "LMP +",
  2: "Birth Date +",
  3: "System Date",
  4: "Custom Date",
};
export const SOCIAL_ROUTE = {
  1: "Oral",
  2: "IV",
  3: "Others",
};
export const DRUG_INSTRUCTION = {
  1: "After meals",
  2: "Before meals",
  3: "Empty stomach",
  4: "As directed",
};
export const DRUG_FREQUENCY = {
  1: "Daily",
  2: "Specific Days",
  3: "PRN",
};
export const DOSAGE_VALUE = {
  1: 1 / 2,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
};
export const DRUG_ROUTE = {
  1: "Oral ",
  2: "Eye ",
  3: "Ear ",
  4: "Nasal ",
  5: "Inhalation ",
  6: "Skin ",
  7: "Rectal ",
  8: "Vaginal ",
  9: "Intramascular Injection",
  10: "Sublingual ",
  11: "Intra-venous Injection",
  12: "Sub-cutanious Injection",
  13: "Intra-venous Infusion",
};
export const ALLERGY_INTENSITY_IC = {
  0: "icon-severity-low",
  1: "icon-severity-medium",
  2: "icon-severity-high",
};
export const ALLERGY_INFORMED_BY = {
  0: "Observed by Clinician",
  1: "Informed by Patient",
};
export const GENDER = {
  0: "Male",
  1: "Female",
  2: "Transgender",
};
export const BLOOD_GROUP = {
  0: "A+",
  1: "B+",
  2: "AB+",
  3: "O+",
  4: "O-",
  5: "A-",
  6: "B-",
  7: "AB-",
};
export const ALLERGY_TAB_STATUS = ["DRUGS", "FOOD", "OTHERS"];
export const ALLERGY_TAB_TYPE = { DRUGS: 0, FOOD: 1, OTHERS: 2 };
export const ISD_CODES = [
  { isd_code: 1 },
  { isd_code: 7 },
  { isd_code: 20 },
  { isd_code: 27 },
  { isd_code: 30 },
  { isd_code: 31 },
  { isd_code: 32 },
  { isd_code: 33 },
  { isd_code: 34 },
  { isd_code: 36 },
  { isd_code: 38 },
  { isd_code: 39 },
  { isd_code: 40 },
  { isd_code: 41 },
  { isd_code: 43 },
  { isd_code: 44 },
  { isd_code: 45 },
  { isd_code: 46 },
  { isd_code: 47 },
  { isd_code: 48 },
  { isd_code: 49 },
  { isd_code: 51 },
  { isd_code: 52 },
  { isd_code: 53 },
  { isd_code: 54 },
  { isd_code: 55 },
  { isd_code: 56 },
  { isd_code: 57 },
  { isd_code: 58 },
  { isd_code: 60 },
  { isd_code: 61 },
  { isd_code: 62 },
  { isd_code: 63 },
  { isd_code: 64 },
  { isd_code: 65 },
  { isd_code: 66 },
  { isd_code: 70 },
  { isd_code: 81 },
  { isd_code: 82 },
  { isd_code: 84 },
  { isd_code: 86 },
  { isd_code: 90 },
  { isd_code: 91 },
  { isd_code: 92 },
  { isd_code: 93 },
  { isd_code: 94 },
  { isd_code: 95 },
  { isd_code: 98 },
  { isd_code: 211 },
  { isd_code: 212 },
  { isd_code: 213 },
  { isd_code: 216 },
  { isd_code: 218 },
  { isd_code: 220 },
  { isd_code: 221 },
  { isd_code: 222 },
  { isd_code: 223 },
  { isd_code: 224 },
  { isd_code: 225 },
  { isd_code: 226 },
  { isd_code: 227 },
  { isd_code: 228 },
  { isd_code: 229 },
  { isd_code: 230 },
  { isd_code: 231 },
  { isd_code: 232 },
  { isd_code: 233 },
  { isd_code: 234 },
  { isd_code: 235 },
  { isd_code: 236 },
  { isd_code: 237 },
  { isd_code: 238 },
  { isd_code: 239 },
  { isd_code: 240 },
  { isd_code: 241 },
  { isd_code: 242 },
  { isd_code: 244 },
  { isd_code: 245 },
  { isd_code: 246 },
  { isd_code: 248 },
  { isd_code: 249 },
  { isd_code: 250 },
  { isd_code: 251 },
  { isd_code: 252 },
  { isd_code: 253 },
  { isd_code: 254 },
  { isd_code: 255 },
  { isd_code: 256 },
  { isd_code: 257 },
  { isd_code: 258 },
  { isd_code: 260 },
  { isd_code: 261 },
  { isd_code: 262 },
  { isd_code: 263 },
  { isd_code: 264 },
  { isd_code: 265 },
  { isd_code: 266 },
  { isd_code: 267 },
  { isd_code: 268 },
  { isd_code: 269 },
  { isd_code: 290 },
  { isd_code: 291 },
  { isd_code: 297 },
  { isd_code: 298 },
  { isd_code: 299 },
  { isd_code: 350 },
  { isd_code: 351 },
  { isd_code: 352 },
  { isd_code: 353 },
  { isd_code: 354 },
  { isd_code: 355 },
  { isd_code: 356 },
  { isd_code: 357 },
  { isd_code: 358 },
  { isd_code: 359 },
  { isd_code: 370 },
  { isd_code: 371 },
  { isd_code: 372 },
  { isd_code: 373 },
  { isd_code: 374 },
  { isd_code: 375 },
  { isd_code: 376 },
  { isd_code: 377 },
  { isd_code: 378 },
  { isd_code: 380 },
  { isd_code: 381 },
  { isd_code: 385 },
  { isd_code: 386 },
  { isd_code: 387 },
  { isd_code: 389 },
  { isd_code: 420 },
  { isd_code: 421 },
  { isd_code: 423 },
  { isd_code: 500 },
  { isd_code: 501 },
  { isd_code: 502 },
  { isd_code: 503 },
  { isd_code: 504 },
  { isd_code: 505 },
  { isd_code: 506 },
  { isd_code: 507 },
  { isd_code: 508 },
  { isd_code: 509 },
  { isd_code: 590 },
  { isd_code: 591 },
  { isd_code: 592 },
  { isd_code: 593 },
  { isd_code: 594 },
  { isd_code: 595 },
  { isd_code: 596 },
  { isd_code: 597 },
  { isd_code: 598 },
  { isd_code: 599 },
  { isd_code: 670 },
  { isd_code: 672 },
  { isd_code: 673 },
  { isd_code: 674 },
  { isd_code: 675 },
  { isd_code: 676 },
  { isd_code: 677 },
  { isd_code: 678 },
  { isd_code: 679 },
  { isd_code: 680 },
  { isd_code: 681 },
  { isd_code: 682 },
  { isd_code: 683 },
  { isd_code: 684 },
  { isd_code: 686 },
  { isd_code: 687 },
  { isd_code: 688 },
  { isd_code: 689 },
  { isd_code: 690 },
  { isd_code: 691 },
  { isd_code: 692 },
  { isd_code: 850 },
  { isd_code: 852 },
  { isd_code: 853 },
  { isd_code: 855 },
  { isd_code: 856 },
  { isd_code: 880 },
  { isd_code: 886 },
  { isd_code: 960 },
  { isd_code: 961 },
  { isd_code: 962 },
  { isd_code: 963 },
  { isd_code: 964 },
  { isd_code: 965 },
  { isd_code: 966 },
  { isd_code: 967 },
  { isd_code: 968 },
  { isd_code: 970 },
  { isd_code: 971 },
  { isd_code: 972 },
  { isd_code: 973 },
  { isd_code: 974 },
  { isd_code: 975 },
  { isd_code: 976 },
  { isd_code: 977 },
  { isd_code: 992 },
  { isd_code: 994 },
  { isd_code: 995 },
  { isd_code: 996 },
  { isd_code: 998 },
  { isd_code: 1147 },
  { isd_code: 1242 },
  { isd_code: 1246 },
  { isd_code: 1264 },
  { isd_code: 1268 },
  { isd_code: 1284 },
  { isd_code: 1340 },
  { isd_code: 1345 },
  { isd_code: 1441 },
  { isd_code: 1473 },
  { isd_code: 1649 },
  { isd_code: 1664 },
  { isd_code: 1670 },
  { isd_code: 1671 },
  { isd_code: 1684 },
  { isd_code: 1758 },
  { isd_code: 1767 },
  { isd_code: 1784 },
  { isd_code: 1787 },
  { isd_code: 1809 },
  { isd_code: 1868 },
  { isd_code: 1869 },
  { isd_code: 1876 },
  { isd_code: 7370 },
];
export const SEPARATEUPLOADEDDOCSNEW = (docs) => {
  let uploadedFiles = [];
  if (docs && docs.length > 0) {
    docs.forEach((value) => {
      const fileExt = value.file.split(".").slice(-1)[0];
      if (
        fileExt === "jpg" ||
        fileExt === "jpeg" ||
        fileExt === "JPG" ||
        fileExt === "JPEG" ||
        fileExt === "gif" ||
        fileExt === "png" ||
        fileExt === "PNG"
      )
        uploadedFiles.push({ src: UPDATEFILEURL(value.file), type: "img" });
      else if (fileExt === "pdf")
        uploadedFiles.push({ src: UPDATEFILEURL(value.file), type: "pdf" });
    });
  }
  return [...uploadedFiles];
};
export const SEPARATEUPLOADEDDOCS = (docs) => {
  let uploadedImages = [];
  let uploadedDocs = [];
  if (docs && docs.length > 0) {
    docs.forEach((value) => {
      const fileExt = value.file.split(".").slice(-1)[0];
      if (fileExt === "jpg" || fileExt === "jpeg" || fileExt === "png")
        uploadedImages.push(UPDATEFILEURL(value.file));
      else if (fileExt === "pdf") uploadedDocs.push(UPDATEFILEURL(value.file));
    });
  }
  return { fileImg: uploadedImages, fileDoc: uploadedDocs };
};
export const UPDATEFILEURL = (url) => {
  return url.replace("//20", "/20");
};
export const IMAGE_EXT = (img) => img.split(".").pop();
export const BSA_CALCULATE = (V001, V002) => {
  let $weight = parseFloat(V002);
  let $height = parseFloat(V001);
  let $bsa = Math.sqrt(($weight * $height) / 3600);
  if (!isNaN($bsa)) {
    return $bsa.toFixed(1);
  } else {
    return "";
  }
};
export const IS_URL = (url) => url.includes("http");
export const BMI_CALCULATE = (V001, V002) => {
  let $weight = parseFloat(V002);
  let $height = parseFloat(V001);
  $height = $height * 0.01;
  let $bmi = $weight / ($height * $height);
  if (!isNaN($bmi)) {
    return $bmi.toFixed(1);
  } else {
    return "";
  }
};
export const HANDLETEXTAREAAUTOHEIGHT = (textarea, clear = false) => {
  const Elem = document.querySelectorAll("." + textarea);
  if (!clear) {
    Elem.forEach((el) => {
      el.style.height = el.scrollHeight + "px";
    });
  } else {
    Elem.forEach((el) => {
      el.style.height = "40px";
    });
  }
};
export const GETGENDER = (gender) => {
  if (gender === "Male") return "M";
  else if (gender === "Female") return "F";
  else if (gender === "Other") return "O";
  else return "";
};
export const UHID = (uhid) => {
  if (uhid !== "" && uhid !== "--" && uhid !== null) {
    return uhid;
  }
  return "";
};
export const TEMPERATURE_TYPE_VALIDATE_FUNCTION = (temprature_type) =>
  temprature_type ? "C" : "F";
export const HANDLE_PATIENT_VITALS_COLORS = (value) => {
  switch (value) {
    case "Critical": {
      return "text-vital-critical";
    }
    case "Abnormal": {
      return "text-vital-abnormal";
    }
    case "Normal": {
      return "text-vital-normal";
    }
  }
};
export const HANDLEIMAGEROTATE = (elem) => {
  const targetName = elem.currentTarget;
  const imgElem = document.querySelector(".modal-imgdocs figure");
  if (targetName.getAttribute("name") === "rotate-left") {
    if (imgElem.dataset.rotate === "" || imgElem.dataset.rotate === undefined) {
      $(imgElem).css("transform", "rotate(-90deg)");
      imgElem.dataset.rotate = -90;
    } else {
      const currentVal = imgElem.dataset.rotate;
      $(imgElem).css("transform", `rotate(${currentVal - 90}deg)`);
      imgElem.dataset.rotate = currentVal - 90;
    }
  } else {
    if (imgElem.dataset.rotate === "" || imgElem.dataset.rotate === undefined) {
      $(imgElem).css("transform", "rotate(90deg)");
      imgElem.dataset.rotate = 90;
    } else {
      const currentVal = imgElem.dataset.rotate;
      $(imgElem).css("transform", `rotate(${+currentVal + 90}deg)`);
      imgElem.dataset.rotate = +currentVal + 90;
    }
  }
};
export const GET_VITALS_RANGE_CLASS = (value, range) => {
  if (value == "-") return "";
  let vitalRange = "";
  //normal range
  if (value >= range.normal.min && value <= range.normal.max)
    vitalRange = "text-success";

  //abnormal range
  if (
    (value > range.critical.min && value < range.normal.min) ||
    (value > range.normal.max && value < range.critical.max)
  )
    vitalRange = "text-warning";

  //critical range
  if (
    (range.critical.min != null && value <= range.critical.min) ||
    (range.critical.max != null && value >= range.critical.max)
  )
    vitalRange = "text-danger";

  return vitalRange;
};

export const GOBACK = (event, props) => {
  event.preventDefault();
  props.history.goBack();
};
export const DISABLED_FUTURE_DATES = (current) => {
  return current && current > moment().endOf("day");
};
export const HC_GOBACK = (event, props, state) => {
  event.preventDefault();
  props.history.push({
    pathname: `/patient/homecare/details/${state.hospital_id}/${state.patient_id
      }/${REFERENCE_TYPE}/${state.reference_id || state.booking_id}`,
    state: { isCallToday: 0 },
  });
};

export const ISALLOWEDITSOAP = (
  pres_modification,
  allow_hours,
  completed_time,
  type
) => {
  if (!completed_time) return true;
  completed_time = completed_time.replace("Z", "");
  var allowed_time = moment(completed_time)
    .add(330, "minutes")
    .add(allow_hours, "hours");
  var current_time = moment();
  var diff = current_time.diff(allowed_time, "seconds");

  if (pres_modification == "no") return false;
  else if (diff < 0) {
    if (type == "patient-queue")
      return pres_modification == "allow" || pres_modification == "addendum"
        ? true
        : false;
    else if (type == "edit-prep")
      return pres_modification == "addendum" ? false : true;
    else if (type == "button")
      return pres_modification == "allow" ? true : false;
  } else return false;
};

/* || REACT COMPONENTS */

export const LOADER = (
  <div className="spinner">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);
export const LOADER_RED = (
  <div className="spinner red">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);
export const LOADER_BLACK = (
  <div className="spinner black">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

/** Environment variables */

export const APPS_USERTYPES = {
  "woodlands": {
    doctor: 1,
    nurse: 2,
    superadmin: 4,
    admin: 5,

  },
  "mhvcmarketplace": {
    doctor: 3,
    nurse: 2,
    superadmin: 4,
    admin: 5,
  }
};
export const APP_DEFAULT_VALUES = {
  woodlands: {
    maximum_medicines_allowed: 8,
    age: 5,
    sessionTimeout: 30,
  },
  mhvcmarketplace: {
    maximum_medicines_allowed: 10,
    age: 5,
    sessionTimeout: 60,
  },
};
export const USERTYPES = APPS_USERTYPES[MYHC_APPTYPE];
export const HOSPITALDEFAULTS = APP_DEFAULT_VALUES[MYHC_APPTYPE];
export const REFERENCE_TYPE = 0;
export const HOMECARE_REFERENCE_TYPE = 2;
export const DEFAULT_VC_DRIVER = 1; // 1 is for Quickblox

export const SPEECH_SUBSCRIPTION_ID = process.env.SPEECH_SUBSCRIPTION_ID;
export const SPEECH_SUBSCRIPTION_AREA = process.env.SPEECH_SUBSCRIPTION_AREA;
export const SPEECH_ENDPOINT_ID = process.env.SPEECH_ENDPOINT_ID;
export const SPEECH_LANGUAGE = process.env.SPEECH_LANGUAGE;
export const MHVCMARKETPLACE = "mhvcmarketplace";
export const WOODLANDS = "woodlands";
export const BASIC = "basic";
export const SOAP = "soap";
export const is_chat_enabled = false;
export const DEFAULTVITALGROUPID = 1;
export const VACCINATION_AGE_MESSAGE =
  "Please add age of the patient to proceed.";
export const AVERAGE_BSA_FOR_MALE = 1.9;
export const AVERAGE_BSA_FOR_FEMALE = 1.6;
export const APPS_VARIANTS = {
  basic: {
    todayVisitPage: false,
    allergies: true,
    vaccination: false,
    paediatrics: false,
    obsgynae: false
  },
  soap: {
    todayVisitPage: true,
    allergies: false,
    vaccination: true,
    paediatrics: true,
    obsgynae: true
  },
};

export const APPS_FEATURES = {
  woodlands: {
    call_next_btn: true,
    phr: true,
    pin_based_login: false,
    show_token_on_queue: true,
    show_opdconsult_flag_on_queue: false,
    header_powered_by: true,
    reshedule_appointment: true,
    aside_test_result: true,
    aside_all_prescriptions: false,
    todayVisitSoap: {
      keyfindings: true,
    },
    /** Two calendar pages one for woodland one for marketplace  */
    calendarPage: true,
    call_label: "CALL",
    call_start_consult_label: "CALL",
    select_doctor_dropdown: true, // for nurse login dropdown of multiple doctors
  },
  mhvcmarketplace: {
    call_next_btn: false,
    phr: false,
    pin_based_login: false,
    show_token_on_queue: false,
    show_opdconsult_flag_on_queue: true,
    header_powered_by: false,
    reshedule_appointment: false,
    aside_test_result: false,
    aside_all_prescriptions: true,
    todayVisitSoap: {
      keyfindings: false,
    },
    /** Two calendar pages one for woodland one for marketplace  */
    calendarPage: false,
    call_label: "RESUME CONSULT",
    call_start_consult_label: "START CONSULT",
    select_doctor_dropdown: true, // for nurse login dropdown of multiple doctors
  },
};
export const KEY_FINDING_VITALS_CONSTANTS = ["V010", "V011", "V013"];
export const APPS_FEATURES_LIST = APPS_FEATURES[MYHC_APPTYPE];
//export const IS_PAEDIATRICS = [17, 25, 36, 42, 49, 50, 56, 61, 69, 80, 110, 111, 93, 112, 59];
//export const IS_PAEDIATRICS = [17,25,36,42,49,50,56,61,69,80,110,111,150,152,163,164,165,93,112];
export const PAEDRATICAGELIMIT = 17;
export const PAEDRATICVACCINEID = 32;
export const IS_ENDOCRINOLOGY = [56, 101, 124, 147];
export const DIAGNOSIS_DO_NOT_PRINT = [79551];
//Obstetric History
export const CONCEPTION_OPTIONS = [
  { val: '1', label: 'Natural' },
  { val: '2', label: 'IVF' },
  { val: '3', label: 'IUI' }
];

export const PREGNANCY_OPTIONS = [
  { val: '1', label: 'Single' },
  { val: '2', label: 'Multiple' },
  { val: '3', label: 'Ectopic' }
];

export const DELIVERY_OPTIONS = [
  { val: '1', label: 'Normal' },
  { val: '2', label: 'LSCS' },
  { val: '3', label: 'Forceps' },
  { val: '4', label: 'Water Birthing' }
];

export const OUTCOME_OPTIONS = [
  { val: '1', label: 'Live Term' },
  { val: '2', label: 'Live Pre-Term' },
  { val: '3', label: 'Still born Term' },
  { val: '4', label: 'Still born Pre-Term' },
  { val: '5', label: 'Spontaneous Abortion' },
  { val: '6', label: 'MTP' }
];

export const PLACE_OF_DELIVERY_OPTIONS = [
  { val: '1', label: 'Hospital' },
  { val: '2', label: 'Home' },
  { val: '3', label: 'PHC' },
  { val: '4', label: 'Others' }
];

//Physical examination
export const FETAL_MOVEMENT = {
  1: "Present",
  2: "Absent",
};

export const PRESENTATION = {
  1: "Cephalic",
  2: "Breech",
  3: "Vertex",
  4: "Shoulder",
  5: "Compound",
};

export const RELATIONSHIP_TO_PP_BRIM = {
  1: "1/5",
  2: "2/5",
  3: "3/5",
  4: "4/5",
  5: "5/5",
};

export const MENSTRUAL_HX_TREND_GRAPH = [
  { val: "cycle_length", des: "Cycle Length (Days)", checkedVal: 'checkedMenstrualCycleLength' },
  { val: "duration_of_period", des: "Duration of Period (Days)", checkedVal: 'checkedMenstrualDurationOfPeriod' },
  { val: "pain_score", des: "Pain Score", checkedVal: 'checkedMenstrualPainScore' }
];

export const CHECKOUT_HOSPITALS_ENABLED = [];

/*============ opd implementation ============*/
export const OPD_VISIT_TYPE = {
  0: null,
  1: "FIRST VISIT",
  2: "FOLLOW-UP VISIT",
  3: "FREE VISIT",
};

export const FORM_TYPE = {
  'fall-risk-assessment': 1,
  'nutrition-screen-tool': 2,
  'nursing-assessment-report': 3,
}

export const NST_RADIO_VALUE = {
  0: 0,
  1: 1,
  2: 2,
  '1-5': 1,
  '6-10': 2,
  '11-14': 3,
  '15': 4,
  'Unsure': 2,
}

export const EVALUTION_INTERPRETATION = {
  "NO": {
    "nst_nutritional_status": "Not at Risk",
    "nst_implication": "Eating well with little or no weight loss.",
    "nst_action": "If Length of stay exceeds 7 days, then rescreen, repeating weekly as and when needed.",
  },
  "YES": {
    "nst_nutritional_status": "At Risk",
    "nst_implication": "Eating poorly and/or recent weight loss.",
    "nst_action": "Immediate Nutrition Assessment and Intervention within 24-72 hours depending on the risk.",
  }
}

export const BMI_SCORE_CALCULATE = (bmi) => {
  /* 
    0 - 18.4	    1
    18.5 - 24.9	  0
    25.0 - 50.0	  1
  */
  // Interpretation of BMI score
  if (!isNaN(bmi)) {
    let bmi_score = 1;
    if (parseFloat(bmi) > 0 && parseFloat(bmi) <= parseFloat(18.4))
      bmi_score = 1;
    else if (parseFloat(bmi) >= parseFloat(18.5) && parseFloat(bmi) <= parseFloat(24.9))
      bmi_score = 0;
    else if (parseFloat(bmi) >= parseFloat(25.0) && parseFloat(bmi) <= parseFloat(50.0))
      bmi_score = 1;

    return bmi_score;
  }
  return null;
}

/*============ opd implementation ============*/