import React, { Component } from 'react';
import LOGO from '../../assets/images/logo-myhealthcare.svg';
import OtpInput from 'react-otp-input';
import qs from 'qs';
// import "./otp.scss";
import { HOSPITAL_CONFIG, LOADER_RED, OTP_LOGIN, OTP_VERIFY_V2, SYNC_TOKEN, USERTYPES, ENCRYPTION_KEY, APP_SECRET_KEY, sval } from '../../utils/constant';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { notification } from 'antd';
import { headerService$, vcOpdEnabledSubjectService } from '../../utils/rxjs-sharing';
import crypto from 'crypto';
import CryptoJS from 'crypto-js';

class OtpComponent extends Component {

    state = {
        otp: '',
        syncTokenProcessing: false,
        staff_id: '',
        staff_name: '',
        status: '',
        token: '',
        user_type: '',
        otpDisabled: false,
        verifyOtpBtnDisabled: false,
        isInputSecure: true,
        loginType: ''
    }

    constructor(props) {
        super(props);
        if (LS_SERVICE.has('is_authenticated') && LS_SERVICE.get('is_authenticated')) {
            this.props.history.push('/dashboard');
        }
        else {
            let fromLogin = this.props.location.state;
            if (fromLogin == undefined || !(fromLogin === "single-otp") || !LS_SERVICE.get('xlToken')) {
                this.props.history.push('/');
            }
        }
    }

    componentDidMount() {
        this.setState({
            loginType: this.props.location.state
        })
    }

    componentWillUnmount() {
        this.setState({
            syncTokenProcessing: false
        })
    }

    handleLoginOtpClick = (event) => {
        event.preventDefault();

        this.setState({
            showMobile: !this.state.showMobile
        })
    }

    togglePinVisiblity = () => {
        const { isInputSecure } = this.state;
        this.setState({ isInputSecure: !isInputSecure });
    };

    sumbitFormMN = () => {
        const mobile = LS_SERVICE.get('loginMobile')
        this.setState({
            otpDisabled: true
        })

        Axios.post(OTP_LOGIN, qs.stringify({ mobile, loginType: this.state.loginType }))
            .then(success => {
                let data = success.data;
                notification.destroy();
                notification.info({
                    message: data.message,
                    placement: 'topRight'
                });
                this.setState({
                    otpDisabled: false
                })
            })
            .catch(err => console.log(err))
    }

    decryptColumn = (string) => {
        if (string == null || string == '') {
            return null;
        }
        const encryption_key = ENCRYPTION_KEY;
        const encryptionMethod = 'AES-256-CBC';
        const secret = encryption_key.substring(0, 32); // must be 32 char length
        const iv = secret.substring(0, 16);

        const decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
        // decryptor.setAutoPadding(false);
        const decryptedString = decryptor.update(String(string), 'base64', 'utf8') + decryptor.final('utf8');
        return decryptedString;
    }


    handleOnSubmit = (event) => {
        event.preventDefault();

        const { otp } = this.state;
        this.setState({
            verifyOtpBtnDisabled: true
        });
        notification.destroy();
        let mobileNo = LS_SERVICE.get('loginMobile');
        let isResetting = LS_SERVICE.get('reset_user_pin');
        let xlToken = LS_SERVICE.get('xlToken');

        if (mobileNo !== '' && mobileNo !== null) {
            Axios.post(OTP_VERIFY_V2, qs.stringify({ mobile: LS_SERVICE.get('loginMobile'), otp, loginType: this.state.loginType }), {
                headers: {
                    Accept: 'application/json',
                    'X-L-token': xlToken,
                }
            })
                .then(success => {
                    this.setState({
                        verifyOtpBtnDisabled: false
                    });
                    let { message, staff_id, staff_name, status, token, user_type, hid } = success.data;
                    hid = hid !== undefined && hid !== null ? hid : 1;
                    try {
                        const bytes = CryptoJS.AES.decrypt(success.data.m, APP_SECRET_KEY);
                        const originalText = bytes.toString(CryptoJS.enc.Utf8);
                        if (originalText === sval) {
                            if (status && isResetting !== null && isResetting) {
                                LS_SERVICE.delete('reset_user_pin');
                                this.props.history.push('/set-pin', this.state.loginType);
                            }

                            if (status && isResetting === null) {
                                this.setState({
                                    staff_id,
                                    staff_name,
                                    token
                                })

                                LS_SERVICE.set('token', token);
                                LS_SERVICE.set('staff_name', staff_name);
                                LS_SERVICE.set('staff_id', staff_id);
                                LS_SERVICE.set('user_type', user_type);
                                LS_SERVICE.delete('loginMobile');
                                LS_SERVICE.delete('xlToken');
                                this.handleSyncToken(token, hid);

                                notification.info({
                                    message,
                                    placement: 'topRight'
                                });
                            }
                        } else {
                            // console.log('Not a correct value!');
                            notification.error({
                                message: "Invalid Request, please try again!",
                                placement: 'topRight'
                            });
                        }
                    } catch (error) {
                        // console.log('Not decrypted!', error);
                        notification.error({
                            message: "Invalid Request!",
                            placement: 'topRight'
                        });
                    }
                })
                .catch(err => {
                    const error = err.response?.data?.m;
                    const bytes = CryptoJS.AES.decrypt(error, APP_SECRET_KEY);
                    const originalText = bytes.toString(CryptoJS.enc.Utf8);
                    notification.error({
                        message: originalText,
                        placement: 'topRight'
                    });
                    this.setState({
                        verifyOtpBtnDisabled: false
                    });
                })
        }
    }

    handleSyncToken = (token, hid) => {
        const { staff_id } = this.state;
        this.setState({ syncTokenProcessing: true })
        let syncTokenPayload = {
            staff_id: staff_id,
            hospital_id: hid
        }
        Axios.defaults.headers.common["Authorization"] = token;
        Axios.post(SYNC_TOKEN, qs.stringify(syncTokenPayload)).then(success => {
            const result = success.data;
            if (result.status) {
                let profile = this.decryptColumn(result.staff.profile_picture);
                LS_SERVICE.set('staff_image', profile);

                LS_SERVICE.set('is_authenticated', true);

                let SLOTS = result.slots;
                let isHM = result?.is_homecare_manager !== undefined && result?.is_homecare_manager !== null ? result?.is_homecare_manager : 0;
                LS_SERVICE.set('IS_HOMECARE_MANAGER', isHM);

                if (typeof SLOTS != 'undefined' && SLOTS.length > 0) {
                    for (let index = 0; index < SLOTS.length; index++) {
                        const doctorObj = SLOTS[index].doctor;
                        doctorObj.id = this.decryptColumn(doctorObj.id);
                        doctorObj.name = this.decryptColumn(doctorObj.name);
                        doctorObj.first_name = this.decryptColumn(doctorObj.first_name);
                        doctorObj.last_name = this.decryptColumn(doctorObj.last_name);
                        doctorObj.middle_name = this.decryptColumn(doctorObj.middle_name);
                        doctorObj.woodlands_email = this.decryptColumn(doctorObj.woodlands_email);
                        doctorObj.woodlands_mobile = this.decryptColumn(doctorObj.woodlands_mobile);
                        doctorObj.is_chat_enable = this.decryptColumn(doctorObj.is_chat_enable);
                        doctorObj.is_enable_for_multiple_calling = this.decryptColumn(doctorObj.is_enable_for_multiple_calling);
                        result.slots.doctor = doctorObj;
                    }
                }


                if (result.staff.role_id == USERTYPES.doctor) {
                    LS_SERVICE.set('doctor', {
                        name: ('Dr ' + result.staff.first_name + ' ' + (result.staff.last_name != null ? result.staff.last_name : '')),
                        code: result.staff.code
                    });

                    if (result.is_junior_doctor && result.is_junior_doctor != null) {
                        LS_SERVICE.set('is_junior_doctor', result.is_junior_doctor)
                    }
                    if (result.senior_doctor && result.senior_doctor.length > 0) {
                        LS_SERVICE.set('senior_doctor', result.senior_doctor)
                    }
                    if (result.junior_doctor_complete_appointment && result.junior_doctor_complete_appointment != null && result.junior_doctor_complete_appointment != undefined) {
                        LS_SERVICE.set('junior_doctor_complete_appointment', result.junior_doctor_complete_appointment)
                    }

                    if (result.pending_patients && result.pending_patients.length > 0) {
                        LS_SERVICE.set(
                            'pending_patients',
                            result.pending_patients
                        );
                    } else {
                        LS_SERVICE.delete('pending_patients');
                    }
                    if (result.staff.doctor_speciality != undefined && result.staff.doctor_speciality.length > 0) {
                        LS_SERVICE.set('speciality_id', result.staff.doctor_speciality[0]);
                    } else {
                        LS_SERVICE.delete('speciality_id');
                    }

                    if (result.slots && result.slots.length > 0) {
                        LS_SERVICE.set(
                            'slot_hospital_id',
                            result.slots[0]['id']
                        );
                        LS_SERVICE.set(
                            'slot_hospital_master',
                            result.slots[0]['own_master']
                        );
                        LS_SERVICE.set('slot_hospitals', result.slots);
                    } else {
                        LS_SERVICE.delete('slot_hospital_id');
                    }

                    if (result.slots && result.slots.length == 1) {
                        this.props.history.push('/patient-queue/' + result.slots[0].id);
                    } else {
                        this.props.history.push('dashboard');
                    }
                }

                if (result.staff.role_id == USERTYPES.nurse) {
                    // set selected nurse doctor ids
                    if (result?.nurse_doctors.length > 0) {
                        LS_SERVICE.set('nurse_doctor_id', result?.nurse_doctors);
                    }

                    //Check if nurse logged in and set doctor speciality
                    if (result.doctor && result.doctor.length > 0) {
                        let allDoctors = result.doctor;
                        var doctorSpecialties = [];
                        allDoctors.forEach((v, k) => {
                            var obj = {
                                "id": v.id,
                                "specialtiesIds": Object.keys(v.specialityDetail)
                            };
                            doctorSpecialties.push(obj);
                        });
                        LS_SERVICE.set('doctor_speciality_id', doctorSpecialties);
                    }

                    if (result.hospital && result.hospital.length > 0) {
                        LS_SERVICE.set(
                            'slot_hospital_id',
                            result.hospital[0]['hospital_id']
                        );
                        LS_SERVICE.set(
                            'slot_hospital_master',
                            result.hospital[0]['own_master']
                        );
                        LS_SERVICE.set('slot_hospitals', result.hospital);
                    } else {
                        LS_SERVICE.delete('slot_hospital_id');
                    }
                    if (result.doctor && result.doctor.length > 0) {
                        LS_SERVICE.set('nurse_doctors', result.doctor);
                    } else {
                        // LS_SERVICE.delete('nurse_doctors');
                        LS_SERVICE.set('nurse_doctors', []);
                        LS_SERVICE.set('nurse_homecare', true)
                    }
                    this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                    LS_SERVICE.set('nurse_doctors', result.doctor);
                    const isVcopdEnabled = result.is_vc_opd_enabled ? result.is_vc_opd_enabled : 0;
                    LS_SERVICE.set('nurse_is_vc_opd_enabled', isVcopdEnabled);
                    vcOpdEnabledSubjectService.init({
                        isVcOpdEnabled: isVcopdEnabled
                    });

                    // if (result.hospital && result.hospital.length > 0) {
                    //     LS_SERVICE.set(
                    //         'slot_hospital_id',
                    //         result.hospital[0]['hospital_id']
                    //     );
                    //     LS_SERVICE.set(
                    //         'slot_hospital_master',
                    //         result.hospital[0]['own_master']
                    //     );
                    //     LS_SERVICE.set('slot_hospitals', result.hospital);
                    // } else {
                    //     LS_SERVICE.delete('slot_hospital_id');
                    // }
                    // if (result.doctor && result.doctor.length > 0) {
                    //     LS_SERVICE.set('nurse_doctors', result.doctor);
                    // } else {
                    //     // LS_SERVICE.delete('nurse_doctors');
                    //     LS_SERVICE.set('nurse_doctors', []);
                    //     LS_SERVICE.set('nurse_homecare', true)
                    // }
                    // this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                }

                headerService$.showHeader({ showHeader: 'true' });
            }
        })
            .catch(err => console.log(err))
    }

    handleBasicOrSoap = slot_hospitals => {

        slot_hospitals.forEach(sh => {
            const PARAMS = {
                hospital_id: sh.id,
                speciality_id: LS_SERVICE.get('speciality_id'),
                staff_id: LS_SERVICE.get('staff_id')
            }

            Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
            Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS))
                .then(success => {
                    const { hospital_id, opd_layout, pres_modification, allow_hours, duplicate_order } = success.data.data;
                    const HOS_DATA = {
                        hospital_id,
                        opd_layout,
                        pres_modification,
                        allow_hours,
                        duplicate_order
                    }
                    let data = [];
                    if (LS_SERVICE.has('hospitalConfigData')) {
                        data = LS_SERVICE.get('hospitalConfigData');
                        data = [...data, HOS_DATA]
                    } else {
                        data = [...data, HOS_DATA];
                    }
                    LS_SERVICE.set('hospitalConfigData', data);
                })
        })
    }

    handleChange = otp => this.setState({ otp });

    render() {
        const { isInputSecure } = this.state;

        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-center full-height">
                        <div className="col-auto d-flex align-self-center">
                            <div className="text-center d-flex align-items-center">

                                <div className="text-center d-none" id="js-sync-widget">
                                    <p className="alert alert-danger d-none js-sync-flash-msg"></p>
                                    <p id="js-staff-info"></p>
                                    {LOADER_RED}
                                    <p>Please hold on for couple of seconds while we sync your hospital(s) and appointments.</p>
                                </div>

                                <form className="otp-form" id="verifyOtpForm" onSubmit={this.handleOnSubmit}>
                                    <img className="img-logo otp-logo" src={LOGO} alt="logo" />
                                    <h5 className="text-center mt-5">Enter the OTP received on the registered mobile number</h5>
                                    <div className="form-group pt-5 password_attribute">
                                        <div className="otpinp_wrapper">
                                            <OtpInput
                                                type={isInputSecure ? "text" : "password"}
                                                isInputSecure={this.state.isInputSecure}
                                                value={this.state.otp}
                                                onChange={this.handleChange}
                                                shouldAutoFocus={true}
                                                isInputNum={true}
                                                numInputs={4}
                                                hasErrored={true}
                                            />
                                            <div className={"show_password " + (isInputSecure ? "" : "on")} onClick={this.togglePinVisiblity}><i class="icon_view"></i></div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <p className="alert alert-danger d-none js-flash-msg"></p>
                                        <button type="submit" disabled={this.state.verifyOtpBtnDisabled} className="btn btn-primary btn-submit otp-btn px-5 mt-4 operational-btns">
                                            SUBMIT
                                        </button>
                                        <p className="pt-3 m-0">
                                            <button type="button" onClick={this.sumbitFormMN} disabled={this.state.otpDisabled} className="Resend-otp btn-link btn-resend operational-btns js-resend-otp textis-primary">Resend OTP</button>
                                        </p>
                                        <p id="js-resend-message"></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default OtpComponent;