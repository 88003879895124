import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import {
    ALL_PRESCRIPTIONS, LOADER_RED, GETDOCURL,
    PATIENT_CASE_LIST, PATIENT_DOCUMENT_LIST, PATIENT_DOCUMENT_VIEW,
    ALL_PRESCRIPTIONS_LIST
} from '../../utils/constant';

import AsideLeft from '../../components/aside/asideleft';
import { Pagination, notification, Space } from 'antd';
import { Modal, DatePicker, Dropdown, Menu } from 'antd';
import Moment from 'react-moment';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';
import { PrinterOutlined, DownCircleOutlined } from '@ant-design/icons';
const moment = require('moment');
const { RangePicker } = DatePicker;

class AllPastVisit extends Component {
    state = {
        patient: null,
        pagination: null,
        currentPage: 1,
        visits: [],
        isLoading: false,
        showRxModal: false,
        rxPdfPath: null,
        records_per_page: 10,
        patientCaseList: [],
        documentCaseList: [],
        expandIndexIs: null,
        isLoadingData: false,
        prevCaseId: null,
        documentcurrentPage: 1,
        caseListByPagination: [],
        documentBinarary: null,
        patientMedicalPDFModal: false,
        patientMedicalIndex: null,
        isSearchDocmentBtnClick: false,
        isSearchDocementBtnClick: false,
        case_start_date: null,
        case_end_date: null,
        document_keyword: null,
        showHideSearchBtn: false,
        date_range_value: [],
        extendResponseState: true,
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;
        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                this.documentLoadData({});
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.primaryLoadData({});
            }
        });
        this.navigateToPage = this.navigateToPage.bind(this);
    }

    goBack = (event) => {
        event.preventDefault();
        this.props.history.goBack();
    }

    primaryLoadData = (info) => {
        const { patient } = this.state;

        if (info.loader) {
            this.setState({ isLoading: true });
        }

        const PARAMS = {
            patient_id: patient.patient_id,
            page: this.state.currentPage,
            doctor_id: LS_SERVICE.get('staff_id'),
        }
        Axios.post(ALL_PRESCRIPTIONS_LIST, qs.stringify(PARAMS))
            .then(response => {
                this.setState({
                    // isLoading: false,
                    visits: response.data.past_visit || [],
                    //pagination: response.data.pagination
                });
            });
    }

    documentLoadData = (info, search = false) => {
        notification.destroy();
        const { case_start_date, case_end_date, reference_id } = this.state;

        if (info?.loader) {
            this.setState({ isLoading: true });
        }
        if (search) {
            if (case_start_date == null || case_end_date == null) {
                notification.error({
                    description: "Please select date range!"
                });
                return;
            }
            this.setState({ isSearchDocmentBtnClick: true, showHideSearchBtn: true, expandIndexIs: null });
        }
        let call_patient_detail = LS_SERVICE.get('call_patient_detail');
        const PARAMS = {
            reference_id: reference_id,
            patient_id: "00" + call_patient_detail?.uhid,
            // patient_id: "0010006608",
            start_date: "",
            end_date: ""
        }

        if (case_start_date && case_end_date) {
            PARAMS.start_date = case_start_date;
            PARAMS.end_date = case_end_date;
        }
        try {
            Axios.post(PATIENT_CASE_LIST, qs.stringify(PARAMS)).then(response => {
                if (response?.data?.result?.EX_PAYLOAD_JSON?.patcaselist.length > 0) {
                    this.setState({
                        isLoading: false,
                        isSearchDocmentBtnClick: false,
                        patientCaseList: response?.data?.result?.EX_PAYLOAD_JSON?.patcaselist,
                        caseListByPagination: response?.data?.result?.EX_PAYLOAD_JSON?.patcaselist.slice(0, 10)
                    });
                }
                else {
                    this.setState({
                        isLoading: false,
                        isSearchDocmentBtnClick: false,
                        patientCaseList: [],
                        caseListByPagination: []
                    });
                }
            });
        }
        catch (e) {
            notification.error({
                message: "Documents",
                description: 'Something went wrong, try again!'
            });
            this.setState({
                isLoading: false,
                isSearchDocmentBtnClick: false,
                patientCaseList: [],
                caseListByPagination: []
            });
        }
    }

    clearDocumentLoadData() {
        notification.destroy();
        const { case_start_date, case_end_date, reference_id } = this.state;
        if (case_start_date !== null && case_end_date !== null) {
            this.setState({
                date_range_value: [],
                case_start_date: null,
                case_end_date: null,
                isSearchDocmentBtnClick: true,
                expandIndexIs: null
            });
            let call_patient_detail = LS_SERVICE.get('call_patient_detail');
            const PARAMS = {
                reference_id: reference_id,
                patient_id: "00" + call_patient_detail?.uhid,
                // patient_id: "0010006608",
                start_date: "",
                end_date: ""
            }
            try {
                Axios.post(PATIENT_CASE_LIST, qs.stringify(PARAMS)).then(response => {
                    if (response?.data?.result?.EX_PAYLOAD_JSON?.patcaselist.length > 0) {
                        this.setState({
                            isLoading: false,
                            isSearchDocmentBtnClick: false,
                            patientCaseList: response?.data?.result?.EX_PAYLOAD_JSON?.patcaselist,
                            caseListByPagination: response?.data?.result?.EX_PAYLOAD_JSON?.patcaselist.slice(0, 10)
                        });
                    }
                    else {
                        notification.error({
                            message: "Documents",
                            description: 'No records found!'
                        });
                        this.setState({
                            isLoading: false,
                            isSearchDocmentBtnClick: false,
                            patientCaseList: [],
                            caseListByPagination: []
                        });
                    }
                });
            }
            catch (e) {
                notification.error({
                    message: "Document",
                    description: 'Something went wrong, try again!'
                });
                this.setState({
                    isLoading: false,
                    isSearchDocmentBtnClick: false,
                    patientCaseList: [],
                    caseListByPagination: []
                });
            }
        }
    }

    navigateToPage(page) {
        this.setState({
            currentPage: page
        }, () => this.primaryLoadData({ loader: false }));
    }

    navigateDocumentToPage(stratIndex) {
        let startFrom = stratIndex == 1 ? 0 : stratIndex - 1 + "0";
        let endIndex = stratIndex + "0";
        let indexDataArr = this.state.patientCaseList.length > 0 ? this.state.patientCaseList?.slice(startFrom, endIndex) : [];
        this.setState({
            caseListByPagination: indexDataArr,
            documentcurrentPage: stratIndex,
            expandIndexIs: null,
        });
    }

    getMediaUrl = (filePath) => {
        var filename = {
            f: filePath,
        };
        Axios.post(GETDOCURL, qs.stringify(filename), { responseType: 'arraybuffer' })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                this.setState({
                    rxPdfPath: url, showRxModal: true
                });
            })
    }

    allvisits() {
        return (
            <>
                {this.state.visits.map((visit, index) => (
                    <div className={"card card1 border-0 mb-4 " + (index > 0 ? 'mt-4' : '')} key={index}>
                        <div className="row">
                            <div className="col-md-12">
                                <button disabled={visit.confidential_prescription} className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed" data-referencetype="0" data-referenceid="undefined" data-toggle="collapse" data-target="#collapseBA517H2L49" role="button" aria-expanded="false" aria-controls="collapseBA517H2L49">
                                    <div className="float-left">
                                        <span className="d-block font-weight-normal">{visit.doctor_name}</span>
                                        <small className="mt-1">
                                            {visit.visit_pdf_time != null ? visit.visit_pdf_time : null}
                                        </small>
                                    </div>
                                    <div className="float-right mt-2">
                                        {
                                            visit.confidential_prescription == false
                                                ?
                                                <button onClick={() => this.getMediaUrl(visit.doctor_prescription)} className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal">VIEW RX</button>
                                                :
                                                <p class="text-uppercase text-secondary">Confidential</p>
                                        }
                                    </div>
                                    {visit.visit_history.length > 1 ?
                                        <>
                                            <div className="float-right mt-2 pt-1 pr-3">
                                                <Dropdown overlayClassName='pres-drop' trigger={['click']} overlay={
                                                    <>
                                                        <Menu overlayClassName="pres-drop">
                                                            {visit.visit_history.map((visit_history, index) => (
                                                                <Menu.Item>
                                                                    <a target="_blank" href={visit_history.visit_pdf} >
                                                                        V {index + 1} - {visit_history.createdAt != null ? moment(visit_history.createdAt, "YYYYMMDDHHmmss").add('19800', 'seconds').format("D MMM, YYYY h:mm A") : ""}
                                                                    </a>
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu>
                                                    </>
                                                }>
                                                    <a className="ant-dropdown-link pres_card" onClick={e => e.preventDefault()}>
                                                        PREVIOUS VERSIONS
                                                    </a>
                                                </Dropdown>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    allPatientDocumentList() {
        const { isLoadingData, expandIndexIs } = this.state;
        return (
            <>
                {this.state.caseListByPagination.length > 0 ?
                    this.state.caseListByPagination.map((visit, index) => (
                        <div className={"card card1 border-0 mb-4 " + (index > 0 ? 'mt-4' : '')} key={index}>
                            <div className="row">
                                <div className="col-md-12">
                                    <button className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed" data-referencetype="0" data-referenceid="undefined" data-toggle="collapse" data-target="#collapseBA517H2L49" role="button" aria-expanded="false" aria-controls="collapseBA517H2L49">

                                        <div
                                            className=''
                                            onClick={(e) => this.showHideReportWidget(visit.patcaseid, index, visit?.patientid)}
                                        >
                                            <div className='row'>
                                                <div style={{ width: '4%' }}>
                                                    <DownCircleOutlined style={{ color: '#d24350' }} />
                                                </div>
                                                <div style={{ width: '96%' }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            {visit.patcaseid}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            {visit.casetypeStext}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            {visit.visitTypeDesp}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            {visit.deptOuDesp}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            {visit.doctorNm}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            {/* {visit.visitDate} {visit.visitTime} */}
                                                            {visit.visitDate != null ? <Moment parse="YYYY-MM-DD HH:mm:ss" date={visit.visitDate + " " + visit.visitTime} format="D MMM, YYYY h:mm A" /> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div
                                            className="row"
                                            onClick={(e) => {
                                                this.showHideReportWidget(visit.patcaseid, index, visit?.patientid);
                                                // e.stopPropagation();
                                            }}
                                        >
                                            <div style={{clear: 'both'}}></div>
                                            <div className="float-left">
                                                
                                                <span className="d-block font-weight-normal">{visit.deptOuDesp}</span>
                                                <small className="mt-1">
                                                    {visit.visitDate != null ? <Moment parse="YYYY-MM-DD HH:mm" date={visit.visitDate} format="D MMM, YYYY h:mm A" /> : null}
                                                </small>
                                            </div>
                                        </div> */}

                                        {
                                            expandIndexIs == index && isLoadingData ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {LOADER_RED}
                                                    </div>
                                                </div> : expandIndexIs == index ?
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {this.state.documentCaseList.length > 0 ?
                                                                <div className='row mt-2 mb-2'>
                                                                    {this.searchFields(visit.patcaseid, index, visit?.patientid)}
                                                                </div>
                                                                : <div className='row ml-2 mt-4 mb-3'>
                                                                    No document found.
                                                                </div>
                                                            }

                                                            {this.getAllDocumetsOfCaseId()}
                                                        </div>
                                                    </div>
                                                    : null
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className={"card card1 border-0 mb-4 'mt-4"} key={0}>
                        <div className="row">
                            <div className="col-md-12">
                                <button
                                    disabled={false} className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed" data-referencetype="0" data-referenceid="undefined" data-toggle="collapse" data-target="#collapseBA517H2L49" role="button" aria-expanded="false" aria-controls="collapseBA517H2L49">
                                    <div className="float-left mt-3 mb-3">
                                        <span className="d-block font-weight-normal">
                                            No document found.
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    showHideReportWidget(caseId, index, patientid) {
        const { expandIndexIs, prevCaseId } = this.state;
        let sendRequest = false;
        let isLoadingDataVal = true;
        let expandIndexIsVal = index;
        if (index == expandIndexIs) {
            isLoadingDataVal = false;
            expandIndexIsVal = null;
        } else {
            if (prevCaseId == caseId) {
                isLoadingDataVal = false;
                expandIndexIsVal = index;
                sendRequest = false;
            } else {
                sendRequest = true;
            }
        }
        this.setState({
            isLoadingData: isLoadingDataVal,
            expandIndexIs: expandIndexIsVal
        }, () => {
            if (sendRequest) {
                this.getshowDataOfCaseId(caseId, index, patientid);
            }
        });
        return;
    }

    getshowDataOfCaseId = (caseId, index, patient_id, type = null) => {
        notification.destroy();
        const { expandIndexIs, prevCaseId, document_keyword, extendResponseState, reference_id } = this.state;

        const PARAMS = {
            case_id: caseId,
            // patient_id: "0010006608",
            patient_id: patient_id,
            reference_id: reference_id,
            start_date: "",
            end_date: "",
            short_text: "",
            title: ""
        }
        if (type == 'search') {
            if (document_keyword == null) {
                notification.error({
                    description: "Title is required!"
                });
                return;
            }
            PARAMS.title = document_keyword;
        } else {
            PARAMS.start_date = "";
            PARAMS.end_date = "";
            PARAMS.short_text = "";
            PARAMS.title = "";
            if (type == "clear") {
                document.getElementById('document_keyword').value = "";
            }
        }
        if (type !== null) {
            this.setState({ isSearchDocementBtnClick: true });
        }

        if (extendResponseState) {
            this.setState({ extendResponseState: false });
            Axios.post(PATIENT_DOCUMENT_LIST, qs.stringify(PARAMS)).then(response => {
                this.setState({
                    documentCaseList: response.data.result.EX_PAYLOAD_JSON.exDocumentsT || [],
                    isLoadingData: false,
                    prevCaseId: caseId,
                    isSearchDocementBtnClick: false,
                    extendResponseState: true,
                });
            }).catch(err => {
                console.log('-----------patient-document-list-err:', err);
                this.setState({
                    documentCaseList: [],
                    isSearchDocementBtnClick: false,
                    extendResponseState: true,
                });
            });
        }
    }

    convertDataURIToBinary(dataURI) {
        // var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        // var base64 = dataURI.substring(base64Index);
        var raw = window.atob(dataURI);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    showDocumentOfCaseId = (id, falnr, index) => {
        notification.destroy();
        this.setState({ patientMedicalIndex: index });
        let call_patient_detail = LS_SERVICE.get('call_patient_detail');
        const PARAMS = {
            document_id: id,
            im_falnr: falnr,
            im_patnr: "00" + call_patient_detail?.uhid,
            reference_id: this.state.reference_id,
            // im_patnr: '0010006608',
            // im_falnr: '1000526829'
        }
        try {
            Axios.post(PATIENT_DOCUMENT_VIEW, qs.stringify(PARAMS)).then(response => {
                if (response.data.message == "Success" && response.data.result.EX_PAYLOAD_JSON !== "") {
                    const pdfData = response.data.result.EX_PAYLOAD_JSON.etPdfPrint.binary;
                    const pdfUrl = `data:application/pdf;base64,${pdfData}`;
                    const blob = new Blob([this.convertDataURIToBinary(pdfData)], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    window.open(url);
                    this.setState({
                        // documentBinarary: pdfUrl,
                        // patientMedicalPDFModal: true,
                        patientMedicalIndex: null
                    });
                }
                else {
                    this.setState({
                        documentBinarary: [],
                        patientMedicalPDFModal: false,
                        patientMedicalIndex: null
                    });
                    notification.error({
                        message: "Document",
                        description: 'Something went wrong, try again!'
                    });
                }
            });
        } catch (e) {
            this.setState({
                documentBinarary: [],
                patientMedicalPDFModal: false,
                patientMedicalIndex: null
            });
            notification.error({
                message: "Document",
                description: 'Something went wrong, try again!'
            });
        }
    }

    searchFields(patcaseid, index, patientid) {
        return (
            <>
                <div className="col-6">
                    <label className="required" htmlFor="title">Search By Title</label>
                    <div className="form-group">
                        <input name="name" id="document_keyword" value={this.state.document_keyword} onChange={(e) => { this.setState({ document_keyword: e.target.value }); }} type="text" className="form-control" maxLength="30" />
                    </div>
                </div>
                <div className="col-6" style={{ marginTop: '37px' }}>
                    <button type="button"
                        disabled={this.state.isSearchDocementBtnClick}
                        className="btn btn-outline-primary small-btn px-2 ml-1"
                        onClick={(e) => {
                            this.getshowDataOfCaseId(patcaseid, index, patientid, 'search');
                        }}>Search </button>
                    <button type="button"
                        disabled={this.state.isSearchDocementBtnClick}
                        className="btn btn-outline-primary small-btn px-2 ml-3"
                        onClick={(e) => {
                            this.setState({ document_keyword: null });
                            this.getshowDataOfCaseId(patcaseid, index, patientid, 'clear');
                        }}>Clear </button>

                    <span> {this.state.isSearchDocementBtnClick ? LOADER_RED : null}</span>
                </div>
            </>
        )
    }

    getAllDocumetsOfCaseId = () => {
        return (
            <>
                {this.state.documentCaseList.map((visit, index) => (
                    <div className={"card card1 border-0 mb-4 " + ('mt-4')} key={index}>
                        <div className="row">
                            <div className="col-md-12">
                                <button
                                    // onClick={(e) => {
                                    //     this.showDocumentOfCaseId(visit?.id, visit?.falnr, index);
                                    //     e.stopPropagation();
                                    // }}
                                    disabled={this.state.patientMedicalIndex == index ? true : false} className="btn text-left page-title past-visit-container-button w-100 collapsed"
                                    data-referencetype="0" data-referenceid="undefined" data-toggle="collapse" data-target="#collapseBA517H2L49" role="button" aria-expanded="false" aria-controls="collapseBA517H2L49">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="d-block">
                                                <span className="font-weight-normal">Document: </span>
                                                {isNaN(visit.shortText) ? visit.shortText : visit.title}
                                                <small className="float-right" >
                                                    {visit.timestamp != null ? moment(visit.timestamp, "YYYYMMDDHHmmss").format("D MMM, YYYY h:mm A") : ""}
                                                </small>
                                            </span>
                                            <span className="mt-1" >
                                                <span className="font-weight-normal">Department: </span>{visit.serviceDepartment}
                                                {/* <a className="nostyle-link float-right"><i className="icon-pdf"></i></a> */}
                                                <a
                                                    onClick={() => this.showDocumentOfCaseId(visit?.id, visit?.falnr, index)}
                                                    className="float-right nostyle-link"
                                                    style={{ fontSize: '20px' }}
                                                >
                                                    <PrinterOutlined />
                                                </a>
                                                <span className="float-right mr-2">{visit.status} </span>
                                            </span>
                                            <span className='mt-5'>
                                                {
                                                    this.state.patientMedicalIndex !== null ?
                                                        this.state.patientMedicalIndex == index ? LOADER_RED : null
                                                        : null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                ))}
            </>
        )
    }

    handleDateChange = (dates, dateStrings) => {
        const [startDate, endDate] = dates;
        this.setState({
            case_start_date: moment(startDate).format('YYYY-MM-DD'),
            case_end_date: moment(endDate).format('YYYY-MM-DD'),
            date_range_value: dates
        })
    };

    onChangeSelectValue(field, value) {
        this.setState({ field: value });
    }

    render() {
        const { patient, visits, isLoading, patientCaseList } = this.state;
        return (
            <>
                {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                <div className="content-wrapper">
                    <div className="container-fluid emrfrm">

                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-left">
                                    <h1 className="page-title mb-0">All Prescriptions</h1>
                                </div>
                                <div className="float-right">
                                    <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                                </div>
                            </div>

                            <div className="col-6" id="PastVisitContainerFluid">
                                {visits && visits.length > 0 ?
                                    <div className="accordion" id="accordionPastVisit">
                                        {this.allvisits()}
                                    </div>
                                    : null}
                            </div>

                            <div className="col-6" id="PastVisitContainerFluid">
                                {(patientCaseList && patientCaseList.length > 0) || this.state.showHideSearchBtn ?
                                    <>
                                        <div className='row mb-2'>
                                            <div className='col-6'>
                                                <RangePicker
                                                    allowClear={false}
                                                    onChange={this.handleDateChange}
                                                    format="YYYY-MM-DD"
                                                    value={this.state.date_range_value}
                                                />
                                            </div>
                                            <Space direction="horizontal" size={10}>
                                                <button type="button"
                                                    disabled={this.state.isSearchDocmentBtnClick}
                                                    className="btn btn-outline-primary small-btn px-2 ml-2"
                                                    onClick={() => this.documentLoadData({}, true)}
                                                >Search </button>
                                                <button type="button"
                                                    disabled={this.state.isSearchDocmentBtnClick}
                                                    className="btn btn-outline-primary small-btn px-2 ml-2"
                                                    onClick={() => this.clearDocumentLoadData()}
                                                >Clear </button>
                                            </Space>
                                            <span style={{ margin: "3px 10px" }}> {this.state.isSearchDocmentBtnClick ? LOADER_RED : null}</span>
                                        </div>
                                        <div className="accordion" id="accordionPastVisit">
                                            {this.allPatientDocumentList()}
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>


                        {
                            patientCaseList && patientCaseList.length > 0 ?
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <div className="mb-5">
                                            <Pagination
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                showSizeChanger={true}
                                                onChange={(e) => this.navigateDocumentToPage(e)}
                                                defaultCurrent={this.state.documentcurrentPage}
                                                total={patientCaseList.length}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        {/* <div className="row">
                            {this.state.pagination != null && this.state.pagination.total_records > 0 ?
                               <div className="col-6">
                                <div className="mb-5">
                                    <Pagination showSizeChanger={false} defaultCurrent={this.state.currentPage} defaultPageSize={this.state.records_per_page} total={this.state.pagination.total_records} onChange={this.navigateToPage} />
                                </div> </div>: null}
                                <div className="col-6">
                                <div className="mb-5">
                                    <Pagination showSizeChanger={false} defaultCurrent={this.state.documentcurrentPage} defaultPageSize={10} total={1000} onChange={this.navigateDocumentToPage} />
                                </div> </div>
                        </div> */}

                        {isLoading ? <div className="row">
                            <div className="col-md-12 text-center">
                                {LOADER_RED}
                            </div>
                        </div> : null}

                        <Modal
                            title="View Prescription"
                            visible={this.state.showRxModal}
                            footer={false}
                            width={850}
                            onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
                            style={{ top: 20 }}
                        >
                            <div className="row">
                                <div className="col-12 my-3 text-center">
                                    {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath + "#toolbar=0"} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                                        {LOADER_RED}
                                    </iframe> : null}
                                </div>
                            </div>
                        </Modal>

                        {/* patient medical report modal */}
                        <Modal
                            title="View Prescription"
                            visible={this.state.patientMedicalPDFModal}
                            footer={false}
                            width={850}
                            onCancel={() => { this.setState({ patientMedicalPDFModal: false, patientMedicalIndex: null }) }}
                            style={{ top: 20 }}
                        >
                            <div className="row">
                                <div className="col-12 my-3 text-center">
                                    {this.state.documentBinarary !== null ? <iframe src={this.state.documentBinarary + "#toolbar=0"} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                                        {LOADER_RED}
                                    </iframe> : null}
                                </div>
                            </div>
                        </Modal>

                    </div >
                </div >
            </>
        );
    }

}

export default AllPastVisit;